import { Alert, Container } from "@mui/material";
import * as React from "react";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext";
import * as styles from "./styles.scss";

interface Props {
  error: Error;
}

function getFormattedContent(errorMessage: string) {
  if (errorMessage.includes("\n")) {
    const lines = errorMessage.split("\n");
    switch (true) {
      case errorMessage.includes("Invalid SPARQL query:"): //SPARQL AST Errors
        return (
          <>
            {<span>{lines[0]}</span>}
            {lines.length > 1 && <pre>{lines.slice(1).join("\n").trim()}</pre>}
          </>
        );
      default:
        return <span className={styles.formattedContent}>{errorMessage}</span>;
    }
  }

  return errorMessage;
}

const ErrorResponse: React.FC<Props> = ({ error }) => {
  const { customErrorMessage } = React.useContext(SparqlVisualizationContext);
  let content;
  if (customErrorMessage) {
    content = customErrorMessage;
  } else if (error.message) {
    content = getFormattedContent(error.message);
  } else {
    console.error(error);
    content = "An error has occurred";
  }
  return (
    <Alert className="responseErrorMessage" severity="error" role="alert">
      {content}
    </Alert>
  );
};

export default ErrorResponse;
