import { Alert } from "@mui/material";
import * as React from "react";
import { Button } from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { User } from "#reducers/accountCollection.ts";
import { updateProfile } from "#reducers/accounts.ts";

const DISABLE_SELF_WARNING =
  "Warning: You are disabling your own account, and you will not be able to regain access without assistance.";

const DisableAccount: React.FC<{ currentAccount: User; editingSelf: boolean }> = ({ currentAccount, editingSelf }) => {
  const dispatch = useDispatch();
  const confirm = useConfirmation();

  return (
    <div className="whiteSink">
      <h3>Disable account</h3>
      <Alert severity="warning">
        {currentAccount.disabled
          ? "This account is disabled"
          : "Disabling an account will remove all running services of the account and will prevent the owner from performing any action on the platform."}
      </Alert>
      {editingSelf && (
        <Alert severity="warning" variant="filled">
          {DISABLE_SELF_WARNING}
        </Alert>
      )}
      <Button
        color="warning"
        onClick={
          currentAccount.disabled
            ? () => dispatch<typeof updateProfile>(updateProfile(currentAccount, { disabled: false }))
            : () =>
                confirm({
                  description: editingSelf
                    ? DISABLE_SELF_WARNING
                    : `Are you sure you want to disable account '${currentAccount.accountName}'? Disabling an account will remove all running services of the account and will prevent the owner from performing any action on the platform.`,
                  title: `Disable ${editingSelf ? "your account" : `account '${currentAccount.accountName}'`}?`,
                  actionLabel: "Disable",
                  onConfirm: () => dispatch<typeof updateProfile>(updateProfile(currentAccount, { disabled: true })),
                })
        }
      >
        {currentAccount.disabled ? "Enable" : "Disable"} account
      </Button>
    </div>
  );
};

export default DisableAccount;
