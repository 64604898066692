import * as React from "react";
import { Ellipsis, TermLiteral } from "#components/index.ts";

class TermLiteralDefault extends React.PureComponent<TermLiteral.Props, {}> {
  static shouldRender(_props: TermLiteral.Props) {
    return true;
  }
  render() {
    const { value, className } = this.props;
    return (
      <div className={className}>
        <Ellipsis
          title={this.props.expanded ? this.props.language || this.props.datatype : ""}
          value={value}
          language={this.props.language}
        />
      </div>
    );
  }
}
export default TermLiteralDefault;
