import { Alert, Divider, Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { flexRender, Row } from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import * as styles from "./tableStyle.scss";

interface Props {
  loading: boolean | undefined;
  error: string | undefined;
  rows: Row<any>[];
  columnCount: number;
  subComponent?: (props: { row: Row<any> }) => JSX.Element;
}

const ReactTableBody: React.FC<Props> = ({ loading, error, columnCount, rows, subComponent }) => {
  if (error || loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={columnCount}>
            {error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <Skeleton variant="rectangular" width={"100%"} height="500px" />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (subComponent) {
    return (
      <TableBody>
        {rows.map((row) => {
          return (
            <React.Fragment key={row.id}>
              <TableRow hover>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      key={cell.id}
                      className={getClassName(styles.cell, { [styles.noBorderBottom]: row.getIsExpanded() })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.getIsExpanded() && (
                <TableRow>
                  <TableCell className={styles.subComponentCell} colSpan={row.getVisibleCells().length}>
                    {subComponent({ row })}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row) => {
        return (
          <TableRow hover key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <TableCell key={cell.id} className={styles.cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default ReactTableBody;
