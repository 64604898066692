import getClassName from "classnames";
import * as React from "react";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import { FontAwesomeIcon } from "#components/index.ts";
import { _MuiAutosuggest, default as MuiAutosuggest } from "./AutoSuggest.tsx";
import * as styles from "./style.scss";

namespace AutoSuggestMultiSortable {
  export interface Props<R = string, K = string>
    extends Pick<
      MuiAutosuggest.Props<R, K>,
      "loadSuggestions" | "renderSuggestion" | "TextFieldProps" | "transformSuggestionToReduxValue"
    > {
    value: R[];
    getDisplayValueFromSuggestion: (suggestion: R) => string;
    getValueFromString?: (key: string) => R;
    getStringFromValue: (value: R) => string;
    onChange: (values: R[]) => void;
    onBlur?: (event: React.SyntheticEvent) => void;
    renderValue: (suggestion: R) => React.ReactNode;
    placeholder?: string;
    label?: string;
    className?: string;
    allowTypedValues?: boolean;
  }
  export interface State {}
}
const DragHandle = SortableHandle(() => <FontAwesomeIcon icon="bars" className={styles.handle} />);
interface SortableItemProps<R> {
  value: R;
  onRemove: (record: R) => void;
}

interface SortableListProps<R> {
  items: R[];
  onRemove: (item: R) => void;
  getLabel: (value: R) => string;
  getRecordKey: (item: R) => string;
}

class AutoSuggestMultiSortable<R = string, K = string> extends React.PureComponent<
  AutoSuggestMultiSortable.Props<R>,
  AutoSuggestMultiSortable.State
> {
  autosuggestRef = React.createRef<_MuiAutosuggest>();
  handleRemove = (record: R) => {
    this.props.onChange(
      this.props.value.filter((r) => this.props.getStringFromValue(r) !== this.props.getStringFromValue(record)),
    );
  };

  addValue = (newValue: R) => {
    const values = this.props.value || [];
    this.props.onChange([...values, newValue]);
  };
  handleSortEnd = ({ oldIndex, newIndex }: any) => {
    this.props.onChange(arrayMove(this.props.value, oldIndex, newIndex));
  };
  //We want to filter out currently selected values from the suggestions
  filteredLoadSuggestions = (searchString: string): Promise<R[]> => {
    return this.props.loadSuggestions(searchString).then((suggestions) => {
      return suggestions.filter((s) => {
        return !this.props.value.find((v) => this.props.getStringFromValue(v) === this.props.getStringFromValue(s));
      });
    });
  };
  SortableList = SortableContainer<SortableListProps<R>>((props: SortableListProps<R>) => {
    return (
      <div>
        {props.items.map((record, index) => (
          <this.SortableListItem
            key={`item-${props.getRecordKey(record)}`}
            index={index}
            value={record}
            onRemove={props.onRemove}
          />
        ))}
      </div>
    );
  });

  SortableListItem = SortableElement<SortableItemProps<R>>(({ value, onRemove }: SortableItemProps<R>) => {
    return (
      <div className={styles.sortableElement}>
        <DragHandle />
        <div className={getClassName(styles.itemLabel, "ml-2")}>{this.props.renderValue(value)}</div>
        <FontAwesomeButton icon="times" onClick={() => onRemove(value)} title="Remove item" />
      </div>
    );
  });

  render() {
    const {
      className,
      placeholder,
      label,
      value: value,
      renderSuggestion,
      TextFieldProps,
      allowTypedValues,
      getValueFromString,
    } = this.props;
    const autosuggestProps: MuiAutosuggest.Props<R> = {
      placeholder,
      label,
      loadSuggestions: this.filteredLoadSuggestions,
      renderSuggestion: renderSuggestion,
      getSuggestionSearchText: this.props.getDisplayValueFromSuggestion,
      clearOnSelect: true,
      TextFieldProps: TextFieldProps,
      allowNewValues: allowTypedValues,
      onSuggestionSelected: (_e, suggestion) => this.addValue(suggestion.suggestion),
      onKeyPress: (event, searchText) => {
        if (event.key === "Enter" && getValueFromString) {
          // searchText can be empty if the user presses "enter" while selecting from the drop-down.
          // onSuggestionSelected() handles adding the text in that case.
          if (searchText.length === 0) return;

          // We need to handle duplicates when the user free-types values.
          if (!allowTypedValues) return;
          searchText = searchText.split(" ").join(""); // Any white space cause issues for resource display.
          const duplicateFound = value.find((value) => this.props.getStringFromValue(value) === searchText);
          if (!duplicateFound) this.addValue(getValueFromString(searchText));
          this.autosuggestRef.current?.setSearchText("");
        }
      },
    };
    return (
      <div className={getClassName(className)}>
        <div className={styles.items}>
          <this.SortableList
            helperClass={styles.cursorOverride}
            getRecordKey={this.props.getStringFromValue}
            items={value}
            onSortEnd={this.handleSortEnd}
            useDragHandle
            onRemove={this.handleRemove}
            getLabel={this.props.getDisplayValueFromSuggestion}
          />
        </div>
        <MuiAutosuggest innerRef={this.autosuggestRef} {...autosuggestProps} />
      </div>
    );
  }
}

export default AutoSuggestMultiSortable;
