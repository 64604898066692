import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";

interface Props<T = string> {
  options: { label: string; title?: string; value: T }[];
  disabled?: boolean;
}
export namespace ToggleSwitch {}

const ToggleSwitch: React.FC<WrappedFieldProps & ToggleButtonGroupProps & Props> = ({ disabled, input, options }) => {
  return (
    <ToggleButtonGroup
      value={disabled ? "disabled" : input.value}
      onChange={input.onChange}
      exclusive
      disabled={disabled}
      color="primary"
      className="ml-5"
    >
      {options.map((option) => (
        <ToggleButton key={option.value} title={option?.title} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleSwitch;
