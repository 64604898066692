import { ClickAwayListener, Popper } from "@mui/material";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useLocation } from "react-router-dom";
import * as Forms from "#components/Forms/index.ts";
import { Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import { useCreateDataset } from "#helpers/hooks/datasets.ts";
import { useCreateStory } from "#helpers/hooks/stories.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCreateQuery } from "../../helpers/hooks/queries.ts";
import * as styles from "./style.scss";

interface Props {
  matchPath: string;
}

interface LocationState {
  showDatasetForm?: boolean;
  showStoryForm?: boolean;
  showQueryForm?: boolean;
  preserveScrollPosition?: boolean;
}

const getCurrentOption = (matchPath: string) => {
  switch (matchPath) {
    case "/:account/-/stories":
      return 1;
    case "/:account/-/queries":
      return 2;
    default:
      return 0;
  }
};

const AddItemButton: React.FC<Props> = ({ matchPath }) => {
  const dispatch = useDispatch();
  const currentAccount = useCurrentAccount();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const location = useLocation<LocationState | undefined>();
  const constructUrlToApi = useConstructUrlToApi();

  const createDataset = useCreateDataset(currentAccount?.accountName, true);
  const createStory = useCreateStory(currentAccount?.accountName, true);
  const createQuery = useCreateQuery(currentAccount?.accountName, true);

  if (!currentAccount) return null;

  const options = [
    {
      label: "Create dataset",
      onClick: () => (
        setOpen(false),
        dispatch(connectedReactRouter.push({ state: { showDatasetForm: true, preserveScrollPosition: true } }))
      ),
    },
    {
      label: "Create story",
      onClick: () => (
        setOpen(false),
        dispatch(connectedReactRouter.push({ state: { showStoryForm: true, preserveScrollPosition: true } }))
      ),
    },
    {
      label: "Create query",
      onClick: () => (
        setOpen(false),
        dispatch(connectedReactRouter.push({ state: { showQueryForm: true, preserveScrollPosition: true } }))
      ),
    },
  ];

  const goBack = () => dispatch(connectedReactRouter.goBack());

  const currentOption = getCurrentOption(matchPath);

  return (
    <div className={styles.addItemButton} onMouseLeave={() => setOpen(false)}>
      <div
        ref={anchorRef}
        className={styles.mainButtonWrapper}
        // The enter event also triggers when a we click on mobile
        // Prevent by adding a attribute
        onTouchStart={(e) => e.currentTarget.setAttribute("touch", "")}
        onMouseEnter={(e) =>
          e.currentTarget.hasAttribute("touch") ? e.currentTarget.removeAttribute("touch") : setOpen(true)
        }
      >
        <Button color="secondary" onClick={options[currentOption].onClick} className={styles.mainButton}>
          {options[currentOption].label}
        </Button>
        <Button
          aria-label="Show more options"
          color="secondary"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
          className={styles.dropdownButton}
          startIcon={<FontAwesomeIcon icon={["fas", "caret-down"]} />}
        />
      </div>
      <Popper open={open} anchorEl={anchorRef.current} disablePortal placement="bottom-start" className={styles.popper}>
        <ClickAwayListener
          onClickAway={(event) => {
            if (anchorRef.current?.contains(event.target as HTMLElement)) return;
            setOpen(false);
          }}
        >
          <div style={{ width: anchorRef?.current?.offsetWidth }} className={styles.dropdown}>
            {options
              .filter((_, i) => i !== currentOption)
              .map((option) => (
                <Button key={option.label} color="secondary" onClick={option.onClick}>
                  {option.label}
                </Button>
              ))}
          </div>
        </ClickAwayListener>
      </Popper>

      <Dialog maxWidth="lg" fullWidth open={!!location.state?.showDatasetForm} onClose={goBack} title="Create dataset">
        <Forms.DatasetAdd
          form="datasetAddModal" // need to rename the form, as the in-line version can also exist on the same page
          className="p-5"
          initialValues={{ accessLevel: "private" }}
          onSubmit={createDataset}
          cancel={goBack}
          currentAccount={currentAccount}
        />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        open={!!location.state?.showStoryForm}
        onClose={goBack}
        title="Create story"
      >
        <Forms.Story
          form="storyAddModal" // need to rename the form, as the in-line version can also exist on the same page
          initialValues={{
            accessLevel: "private",
          }}
          onSubmit={createStory}
          currentAccount={currentAccount}
          cancelFunction={goBack}
        />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        open={!!location.state?.showQueryForm}
        onClose={goBack}
        title="Create query"
      >
        <Forms.QueryMeta.QueryMetaForm
          form="queryAddModal" // need to rename the form, as the in-line version can also exist on the same page
          initialValues={{
            serviceType: "speedy",
            accessLevel: "private",
          }}
          isNewQuery
          onSubmit={createQuery}
          cancelFunction={goBack}
          datasetSearchUrl={constructUrlToApi({ pathname: "/datasets" })}
          owner={currentAccount}
        />
      </Dialog>
    </div>
  );
};

export default AddItemButton;
