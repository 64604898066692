import * as React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Models } from "@triply/utils";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { storyElementToUpdateObj, updateStory } from "../../reducers/stories.ts";
import AddItemButton from "./AddItemButton.tsx";
import StoryElement from "./StoryElement.tsx";
import StoryElementDialog from "./StoryElementDialog.tsx";
import * as styles from "./StoryElement.scss";

const SortableStoryElements = SortableContainer<{
  story: Models.Story;
  editMode: boolean;
  dragging: boolean;
  className?: string;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
}>(
  (props: {
    story: Models.Story;
    editMode: boolean;
    dragging: boolean;
    className?: string;
    handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
    handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  }) => {
    return (
      <div className={props.className}>
        {props.story.content.map((storyElement, index) => (
          <React.Fragment key={storyElement.id}>
            {props.editMode && <AddItemButton position={index} dragging={props.dragging} />}
            <StoryElement
              index={index}
              storyElement={storyElement}
              story={props.story}
              editMode={props.editMode}
              dragging={props.dragging}
              handleStoryElementWidthChange={props.handleStoryElementWidthChange}
              handleStoryElementHeightChange={props.handleStoryElementHeightChange}
            />
          </React.Fragment>
        ))}
        {props.editMode && <AddItemButton dragging={props.dragging} />}
      </div>
    );
  },
);

const StoryElements: React.FC<{ story: Models.Story; editMode: boolean; className?: string }> = ({
  story,
  editMode,
  className,
}) => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = React.useState(false);
  const handleStoryElementWidthChange = React.useCallback(
    (storyElementId: string, width: Models.StoryElementWidth) => {
      const content = [...story.content];
      const storyElementIdx = content.findIndex((el) => el.id === storyElementId);
      if (storyElementIdx >= 0) {
        const newEl = { ...content[storyElementIdx], width: width };
        content[storyElementIdx] = newEl;

        return dispatch<typeof updateStory>(updateStory(story, { content: content.map(storyElementToUpdateObj) }));
      } else {
        throw new Error("StoryElement is missing");
      }
    },
    [dispatch, story],
  );
  const handleStoryElementHeightChange = React.useCallback(
    (storyElementId: string, height: Models.StoryElementHeight) => {
      const content = [...story.content];
      const storyElementIdx = content.findIndex((el) => el.id === storyElementId);
      if (storyElementIdx >= 0) {
        const newEl = { ...content[storyElementIdx], height: height };
        content[storyElementIdx] = newEl;
        return dispatch<typeof updateStory>(updateStory(story, { content: content.map(storyElementToUpdateObj) }));
      } else {
        throw new Error("StoryElement is missing");
      }
    },
    [dispatch, story],
  );

  const handleStoryElementOrderChange = React.useCallback(
    (oldIndex: number, newIndex: number) => {
      setDragging(false);
      if (!story || oldIndex === newIndex) return;
      const order = [...story.content];
      order.splice(newIndex, 0, order.splice(oldIndex, 1)[0]);
      return dispatch<typeof updateStory>(updateStory(story, { content: order.map(storyElementToUpdateObj) }, order));
    },
    [dispatch, story],
  );

  return (
    <>
      <StoryElementDialog story={story} />
      <SortableStoryElements
        className={className}
        story={story}
        editMode={editMode}
        useDragHandle
        axis="y"
        lockAxis="y"
        dragging={dragging}
        updateBeforeSortStart={() => setDragging(true)}
        onSortEnd={({ oldIndex, newIndex }) => handleStoryElementOrderChange(oldIndex, newIndex)}
        helperClass={styles.dragElement}
        getHelperDimensions={({ node }: any) => ({
          height: node.offsetHeight + 115,
          width: node.offsetWidth,
        })}
        handleStoryElementWidthChange={handleStoryElementWidthChange}
        handleStoryElementHeightChange={handleStoryElementHeightChange}
      />
    </>
  );
};

export default StoryElements;
