import {
  Chip,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import getClassName from "classnames";
import { push } from "connected-react-router";
import { sample } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Models } from "@triply/utils";
import Highlight, { substringMatch } from "#components/Highlight/index.tsx";
import { Avatar, Dialog, FontAwesomeIcon, FontAwesomeRoundIcon, StoryBadge } from "#components/index.ts";
import KeyboardKey from "#components/KeyboardKey/index.tsx";
import { useHotkeys } from "#containers/Hotkeys/index.tsx";
import { getQueryIconForType } from "#helpers/FaIcons.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useMemoizedFetch from "#helpers/hooks/useMemoizedFetch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import { Item } from "#reducers/sessionHistory.ts";
//Using a relative import here because otherwise we'd be importing the context file from utils
import * as styles from "./style.scss";

const tips: React.ReactNode[] = [
  <span>
    Use <KeyboardKey>@</KeyboardKey> to search for organizations/users
  </span>,
  <span>
    See which keyboard shortcuts are available on a page by using{" "}
    <kbd>
      <KeyboardKey>Mod</KeyboardKey>
      <KeyboardKey>shift</KeyboardKey>
      <KeyboardKey>/</KeyboardKey>
    </kbd>
  </span>,
];

const OpenHotKey = { keyBinds: "Mod+k", component: "Navigation", description: "Open Quick Navigator" };

interface SearchOption {
  index: number;
  to: string;
  text: string;
  focusAction?: Function;
  icon: JSX.Element;
}

interface OptionGroup {
  groupHeader?: string;
  options: SearchOption[];
}

function getLink(item: Item) {
  switch (item.type) {
    case "dataset":
      return `/${item.accountName}/${item.name}`;
    case "query":
      return `/${item.accountName}/-/queries/${item.name}`;
    case "story":
      return `/${item.accountName}/-/stories/${item.name}`;
  }
}

const getIcon = (item: Item) => {
  if (!item) return <FontAwesomeIcon icon="search" />;
  switch (item.type) {
    case "dataset":
      return <Avatar avatarName={item.displayName || item.name} avatarUrl={item.avatarUrl} size="sm" alt="Dataset" />;
    case "query":
      return <FontAwesomeRoundIcon icon={item.icon} size="sm" aria-label="Query" />;
    case "story":
      return <StoryBadge bannerUrl={item.bannerUrl} />;
    default:
      return <FontAwesomeIcon icon="search" aria-label="Search result" />;
  }
};

const getText = (name: string, isUserFocused: boolean, owner?: string) => {
  return `${!isUserFocused && owner ? `${owner} / ` : ""}${name}`;
};

function textInItem(text: string, item: Item) {
  return (
    (item.accountDisplayName || "").includes(text) ||
    item.accountName.includes(text) ||
    item.name.includes(text) ||
    (item.displayName || "").includes(text)
  );
}

interface SearchResults {
  datasets: Models.SomeDataset[];
  stories: Models.SomeStory[];
  queries: Models.SomeQuery[];
  users?: Models.SomeUser[];
  orgs?: Models.SomeOrg[];
}

interface ScopeElement {
  accountName: string;
  name: string;
  avatarUrl?: string;
  id: string;
}

const emptyResults: SearchResults = {
  datasets: [],
  stories: [],
  queries: [],
};

const QuickNavigate: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<SearchResults>(emptyResults);
  const tip = React.useMemo(() => {
    if (open) return sample(tips);
  }, [open]);
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const listRef = React.useRef<HTMLUListElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [scopedUser, _setScopedUser] = React.useState<ScopeElement | undefined>();

  const setScopedUser = (user: ScopeElement) => {
    _setScopedUser(user);
    setSearchString("");
    setSearchResults(emptyResults); // Otherwise a full list might appear for a split second
    getSearchResults("", user.accountName)?.catch(() => {});
  };
  const unsetScopedUser = () => {
    _setScopedUser(undefined);
  };

  const dispatch = useDispatch();
  const instanceName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const openDialog = React.useCallback(
    (event: KeyboardEvent) => {
      setOpen(true);
      event.preventDefault();
    },
    [setOpen],
  );
  useHotkeys(OpenHotKey, openDialog, {
    enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"],
  });
  const jump = (to: string) => {
    dispatch(push(to));
    close();
  };
  const close = () => {
    setSearchString("");
    setSelectedIndex(0);
    unsetScopedUser();
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // When the active index is changed scroll the list to that section
  React.useEffect(() => {
    let catNr = 0;
    let prev = 0;
    for (const category of optionGroups) {
      if (prev + category.options.length - 1 < selectedIndex) {
        prev += category.options.length;
        catNr++;
      } else {
        break;
      }
    }
    listRef.current?.children[catNr]?.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const optionGroups: OptionGroup[] = [];
  const history = useSelector((state: GlobalState) => state.sessionHistory.list);
  const historyItems = history
    .filter(
      (item) =>
        // Don't want to show the current resource
        !location.pathname.startsWith(getLink(item)) &&
        // If we're searching something filter on the search string
        (searchString !== "" ? textInItem(searchString, item) : true) &&
        // If there is an account scope filter on that user
        (scopedUser ? item.accountName === scopedUser.accountName : true),
    )
    .slice(0, 3);
  const constructUrlToApi = useConstructUrlToApi();

  const authenticatedUser = useAuthenticatedUser();
  const fetch = useMemoizedFetch<SearchResults>(30000);

  const getSearchResults = useDebounce(async (searchTerm: string, focusedUser?: string) => {
    const results = await fetch(
      constructUrlToApi({
        pathname: "/_some",
        query: {
          q: searchTerm,
          ...(focusedUser ? { o: focusedUser } : {}),
        },
      }),
    );
    setSearchResults({ ...emptyResults, ...results });
    // Reset index after the list is updated
    setSelectedIndex(0);
  }, 200);

  const emptySearchString = (!scopedUser && searchString === "") || (searchString === "@" && scopedUser);
  const accountFilterActive = searchString[0] === "@" && !scopedUser;
  let index = 0;

  historyItems.length > 0 &&
    optionGroups.push({
      groupHeader: "History",
      options: historyItems.map((item) => {
        return {
          text: getText(item.displayName || item.name, !!scopedUser, item.accountDisplayName || item.accountName),
          to: getLink(item),
          icon: getIcon(item),
          index: index++,
        };
      }),
    });

  if (!accountFilterActive && !emptySearchString && searchResults?.datasets && searchResults.datasets.length > 0) {
    optionGroups.push({
      groupHeader: "Datasets",
      options: searchResults.datasets.map((dataset) => {
        return {
          text: getText(
            dataset.displayName || dataset.name,
            !!scopedUser,
            dataset.accountDisplayName || dataset.accountName,
          ),
          to: `/${dataset.accountName}/${dataset.name}`,
          icon: (
            <Avatar
              avatarName={dataset.displayName || dataset.name}
              avatarUrl={dataset.avatarUrl}
              size="sm"
              alt="Dataset"
            />
          ),
          index: index++,
        };
      }),
    });
  }

  if (!accountFilterActive && !emptySearchString && searchResults?.stories && searchResults.stories.length > 0) {
    optionGroups.push({
      groupHeader: "Stories",
      options: searchResults.stories.map((story) => {
        return {
          text: getText(story.displayName || story.name, !!scopedUser, story.accountDisplayName || story.accountName),
          to: `/${story.accountName}/-/stories/${story.name}`,
          icon: <StoryBadge bannerUrl={story.bannerUrl} />,
          index: index++,
        };
      }),
    });
  }

  if (!accountFilterActive && !emptySearchString && searchResults?.queries && searchResults.queries.length > 0) {
    optionGroups.push({
      groupHeader: "Queries",
      options: searchResults.queries.map((query) => {
        return {
          text: getText(query.displayName || query.name, !!scopedUser, query.accountDisplayName || query.accountName),
          to: `/${query.accountName}/-/queries/${query.name}`,
          icon: <FontAwesomeRoundIcon icon={getQueryIconForType(query.resultType)} size="sm" aria-label="Query" />,
          index: index++,
        };
      }),
    });
  }

  if (!emptySearchString && searchResults?.orgs && searchResults.orgs.length > 0) {
    optionGroups.push({
      groupHeader: "Organizations",
      options: searchResults.orgs.map((account) => {
        return {
          text: account.name || account.accountName,
          to: `/${account.accountName}`,
          icon: (
            <Avatar avatarUrl={account.avatarUrl} avatarName={account.name || account.accountName} size="sm" alt="" />
          ),
          index: index++,
          focusAction: () => {
            setScopedUser({
              accountName: account.accountName,
              name: account.name || account.accountName,
              avatarUrl: account.avatarUrl,
              id: account.uid,
            });
          },
        };
      }),
    });
  }

  if (!emptySearchString && searchResults?.users && searchResults.users.length > 0) {
    optionGroups.push({
      groupHeader: "Users",
      options: searchResults.users.map((account) => {
        return {
          text: account.name || account.accountName,
          to: `/${account.accountName}`,
          icon: (
            <Avatar avatarUrl={account.avatarUrl} avatarName={account.name || account.accountName} size="sm" alt="" />
          ),
          index: index++,
          focusAction: () => {
            setScopedUser({
              accountName: account.accountName,
              name: account.name || account.accountName,
              avatarUrl: account.avatarUrl,
              id: account.uid,
            });
          },
        };
      }),
    });
  }

  if (emptySearchString && authenticatedUser?.orgs) {
    const item: OptionGroup = {
      groupHeader: "My organizations",
      options: authenticatedUser.orgs
        .filter((org) => org.uid !== scopedUser?.id)
        .slice(0, 5)
        .map((org) => {
          return {
            text: org.name || org.accountName,
            to: `/${org.accountName}`,
            icon: <Avatar avatarUrl={org.avatarUrl} avatarName={org.name || org.accountName} size="sm" alt="" />,
            index: index++,
            focusAction: () => {
              setScopedUser({
                accountName: org.accountName,
                name: org.name || org.accountName,
                avatarUrl: org.avatarUrl,
                id: org.uid,
              });
            },
          };
        }),
    };
    if (item.options.length > 0) optionGroups.push(item);
  }

  if (emptySearchString && authenticatedUser) {
    optionGroups.push({
      groupHeader: "Me",
      options: [
        {
          text: authenticatedUser.name || authenticatedUser.accountName,
          to: `/${authenticatedUser.accountName}`,
          icon: (
            <Avatar
              avatarUrl={authenticatedUser.avatarUrl}
              avatarName={authenticatedUser.name || authenticatedUser.accountName}
              size="sm"
              alt=""
            />
          ),
          index: index++,
          focusAction: () => {
            setScopedUser({
              accountName: authenticatedUser.accountName,
              name: authenticatedUser.name || authenticatedUser.accountName,
              avatarUrl: authenticatedUser.avatarUrl,
              id: authenticatedUser.uid,
            });
          },
        },
      ],
    });
  }

  optionGroups.push({
    options: searchString
      ? [
          {
            text: `Search "${searchString}" ${scopedUser ? ` in ${scopedUser.name}` : ""} across all ${instanceName}`,
            to: `/browse/datasets?q=${searchString}${scopedUser ? `&owner=${scopedUser.id}` : ""}`,
            icon: <FontAwesomeIcon icon="search" />,
            index: index++,
          },
        ]
      : [
          {
            text: `Browse datasets${scopedUser ? ` of ${scopedUser.name}` : ""}`,
            to: `/browse/datasets${scopedUser ? `?owner=${scopedUser.id}` : ""}`,
            icon: <FontAwesomeIcon icon="search" />,
            index: index++,
          },
          {
            text: `Browse stories${scopedUser ? ` of ${scopedUser.name}` : ""}`,
            to: `/browse/stories${scopedUser ? `?owner=${scopedUser.id}` : ""}`,
            icon: <FontAwesomeIcon icon="search" />,
            index: index++,
          },
          {
            text: `Browse queries${scopedUser ? ` of ${scopedUser.name}` : ""}`,
            to: `/browse/queries${scopedUser ? `?owner=${scopedUser.id}` : ""}`,
            icon: <FontAwesomeIcon icon="search" />,
            index: index++,
          },
        ],
  });

  return (
    <Dialog
      open={open}
      onClose={close}
      transitionDuration={{ appear: 5, exit: 5 }}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: styles.quickNavigateContainer,
      }}
    >
      <Container>
        <div className={styles.searchField}>
          <TextField
            autoFocus
            value={searchString}
            placeholder="Search or jump to"
            onChange={(e) => {
              setSearchString(e.target.value);
              getSearchResults(e.target.value, scopedUser?.accountName)?.catch(() => {});
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.stopPropagation();
                event.preventDefault();
                let prev = 0;
                for (const category of optionGroups) {
                  if (prev + category.options.length - 1 < selectedIndex) {
                    prev += category.options.length;
                    continue;
                  } else {
                    jump(category.options[selectedIndex - prev].to);
                    break;
                  }
                }
              } else if (event.key === "ArrowUp") {
                event.preventDefault();
                if (selectedIndex === 0 || index < selectedIndex) {
                  setSelectedIndex(index - 1);
                } else {
                  setSelectedIndex((index) => index - 1);
                }
              } else if (event.key === "ArrowDown") {
                if (selectedIndex === index - 1 || index < selectedIndex) {
                  setSelectedIndex(0);
                } else {
                  setSelectedIndex((index) => index + 1);
                }
              } else if (event.key === "Backspace") {
                if ((event.target as HTMLInputElement).selectionStart === 0) {
                  // Cursor index
                  unsetScopedUser();
                  getSearchResults(searchString)?.catch(() => {});
                }
              } else if (event.key === "Tab") {
                event.preventDefault();
                let prev = 0;
                for (const category of optionGroups) {
                  if (prev + category.options.length - 1 < selectedIndex) {
                    prev += category.options.length;
                    continue;
                  } else {
                    category.options[selectedIndex - prev].focusAction?.();
                    break;
                  }
                }
              }
            }}
            inputProps={{
              ref: inputRef,
            }}
            InputProps={{
              className: styles.inputBase,
              type: "search",
              autoComplete: "off",
              startAdornment: [
                scopedUser ? (
                  <InputAdornment position="start" key={`quickNavUserFocus-${scopedUser.name}`}>
                    <Chip
                      size="small"
                      onDelete={() => {
                        unsetScopedUser();
                        getSearchResults(searchString)?.catch(() => {});
                        inputRef.current?.focus();
                      }}
                      avatar={<Avatar avatarName={scopedUser.name} size="xs" avatarUrl={scopedUser.avatarUrl} alt="" />}
                      label={scopedUser.name}
                      title={scopedUser.name}
                      variant="outlined"
                      className={styles.accountChip}
                    />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="start" key="searchIcon">
                    <FontAwesomeIcon icon="search" />
                  </InputAdornment>
                ),
              ],
            }}
            size="medium"
            fullWidth
          />
          <IconButton
            onClick={close}
            edge="end"
            aria-label="close"
            className={getClassName("ml-3", styles.closeButton)}
            title="close"
          >
            <FontAwesomeIcon icon="xmark" style={{ width: "1em" }} />
          </IconButton>
        </div>
        {searchString === "" && !scopedUser && (
          <div className="mb-3 px-1">
            <Typography variant="caption">Tip: {tip}</Typography>
          </div>
        )}
        <List disablePadding ref={listRef}>
          {optionGroups.map(({ groupHeader, options }) => (
            <React.Fragment key={groupHeader || "search"}>
              <List
                className={styles.searchSection}
                subheader={groupHeader && <ListSubheader disableSticky>{groupHeader}</ListSubheader>}
              >
                {options.map((option) => (
                  <ListItemButton
                    key={`${groupHeader}-${option.to}`}
                    selected={option.index === selectedIndex}
                    onClick={() => jump(option.to)}
                    className={styles.searchItem}
                  >
                    {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                    <ListItemText
                      className={styles.searchItemText}
                      primary={
                        <Highlight
                          fullText={option.text}
                          highlightedText={accountFilterActive ? searchString.slice(1) : searchString}
                          matcher={substringMatch}
                        />
                      }
                      secondary={
                        option.focusAction && option.index === selectedIndex ? (
                          <span>
                            <KeyboardKey>Enter</KeyboardKey> to jump, <KeyboardKey>Tab</KeyboardKey> to focus
                          </span>
                        ) : (
                          "Jump to"
                        )
                      }
                      secondaryTypographyProps={{
                        className: styles.hintText,
                      }}
                    ></ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </React.Fragment>
          ))}
        </List>
      </Container>
    </Dialog>
  );
};

export default QuickNavigate;
