import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi.ts";
import * as styles from "./Header.scss";

const Header: React.FC<{ story: Models.Story; embedded: boolean; lncs: boolean }> = ({ story, embedded, lncs }) => {
  const constructUrlToApi = useConstructUrlToApi();

  if (lncs) {
    return <h1 className={styles.lncsTitle}>{story.displayName || story.name}</h1>;
  }

  return (
    <div className={styles.header}>
      {!!story.bannerUrl && (
        <picture className={styles.bannerImage}>
          <source
            srcSet={`${constructUrlToApi({ fullUrl: story.bannerUrl!, fromBrowser: true })}&w=1200`}
            type="image/webp"
          />
          <source
            srcSet={`${constructUrlToApi({ fullUrl: story.bannerUrl!, fromBrowser: true }).replace(
              "webp",
              "png",
            )}&w=1200`}
            type="image/png"
          />
          <img
            alt=""
            src={`${constructUrlToApi({ fullUrl: story.bannerUrl!, fromBrowser: true }).replace("webp", "png")}&w=1200`}
          />
        </picture>
      )}
      <div className={getClassName(styles.bannerContent, { [styles.withBanner]: !!story.bannerUrl })}>
        <div className="flex center">
          <h1 className="m-0">{story.displayName || story.name}</h1>
          {!embedded && (
            <AccessLevelIcon
              level={story.accessLevel}
              type="story"
              addTitleForAccountType={story.owner.type}
              size="lg"
              className={getClassName(styles.accessLevelIcon, "mx-4")}
            />
          )}
        </div>
        <div className="p-2 flex center author">
          By{" "}
          <Link to={`/${story.owner.accountName}`} target={embedded ? "_blank" : "_self"}>
            <span className="pl-2">{story.owner.name || story.owner.accountName}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
