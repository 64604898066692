import { Fab, Menu, MenuItem } from "@mui/material";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import * as Forms from "#components/Forms/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import { chownStory, copyStory, deleteStory, updateStory, uploadBanner } from "#reducers/stories.ts";
import { Dialog, FontAwesomeIcon } from "../../components/index.ts";
import { useConfirmation } from "../../helpers/hooks/confirmation.tsx";
import useAcl from "../../helpers/hooks/useAcl.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "../../reducers/app.ts";
import { useAuthenticatedUser } from "../../reducers/auth.ts";
import CopyStoryDialog from "./CopyStoryDialog.tsx";
import EmbedDialog from "./EmbedDialog.tsx";
import { LocationState } from "./index.tsx";
import TransferStoryDialog from "./TransferStoryDialog.tsx";
import * as styles from "./MenuButton.scss";

const MenuButton: React.FC<{ story: Models.Story }> = ({ story }) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const authenticatedUser = useAuthenticatedUser();
  const currentAccount = useCurrentAccount();

  const manageStoryPermissions = acl.check({
    action: "manageStory",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount), accessLevel: story?.accessLevel! },
  }).granted;

  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();

  const [openSettings, setOpenSettings] = React.useState(false);

  const menuRef = React.useRef<HTMLButtonElement>(null);
  const confirm = useConfirmation();

  const bannerRef = React.useRef<HTMLLabelElement>(null);

  const replace = React.useCallback(
    (to: {}) => dispatch(connectedReactRouter.replace(to)),
    [dispatch],
  ) as typeof connectedReactRouter.replace;

  const submitEditStory = React.useCallback(
    (values: Forms.Story.FormData) => {
      if (story) {
        return dispatch<typeof updateStory>(updateStory(story, values))
          .catch((e: Error) => {
            throw new ReduxForm.SubmissionError({ _error: e.message });
          })
          .then((response) => {
            const newStory = response.body;
            if (newStory.name !== story.name) {
              return replace({ pathname: `/${newStory.owner.accountName}/-/stories/${newStory.name}` });
            } else {
              return history.goBack();
            }
          });
      }
    },
    [dispatch, history, replace, story],
  );

  const submitDeleteStory = React.useCallback(() => {
    if (story) {
      return dispatch<typeof deleteStory>(deleteStory(story)).then(() => {
        return replace({ pathname: `/${story.owner.accountName}/-/stories` });
      });
    }
  }, [dispatch, replace, story]);

  const handleBannerUpload = React.useCallback(
    (newBanner: File) => {
      if (!story) return;
      dispatch<typeof uploadBanner>(uploadBanner(story, newBanner)).then(
        () => {},
        (e: any) => {
          dispatch<typeof showNotification>(showNotification(`Could not upload new Banner: ${e.message}`, "error"));
          throw new ReduxForm.SubmissionError({ _error: e.message });
        },
      );
    },
    [dispatch, story],
  );

  if (!currentAccount) return null;

  return (
    <>
      <Fab
        onClick={() => setOpenSettings((open) => !open)}
        ref={menuRef}
        className={styles.settingsMenu}
        aria-label="Story settings"
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </Fab>
      <Menu open={openSettings} anchorEl={menuRef.current} onClose={() => setOpenSettings(false)} disableRestoreFocus>
        {manageStoryPermissions && (
          <MenuItem
            onClick={() => {
              setOpenSettings(false);
              history.push({
                search: location.search,
                state: { storyEditModalShown: true, preserveScrollPosition: true },
              });
            }}
          >
            Settings
          </MenuItem>
        )}
        {manageStoryPermissions && (
          <MenuItem
            onClick={(event) => {
              // We want the full menu-item to use the default input behavior while keeping the styling
              // We just need to be sure that we don't fire the event twice
              if (event.target !== bannerRef.current) bannerRef.current?.click();
            }}
          >
            <label style={{ cursor: "pointer" }} ref={bannerRef}>
              <input
                type="file"
                accept="image/*"
                onChange={(changeEvent) => {
                  if (changeEvent.target.files) handleBannerUpload(changeEvent.target.files[0]);
                  setOpenSettings(false);
                }}
                style={{ display: "none" }}
              />
              Change banner
            </label>
          </MenuItem>
        )}
        {authenticatedUser && (
          <MenuItem
            onClick={() => {
              setOpenSettings(false);
              history.push({
                search: location.search,
                state: { storyCopyModalShown: true, preserveScrollPosition: true },
              });
            }}
          >
            Copy
          </MenuItem>
        )}
        {manageStoryPermissions && (
          <MenuItem
            disabled={authenticatedUser?.orgs?.length === 0}
            title={authenticatedUser?.orgs?.length === 0 ? "No account available to transfer to" : undefined}
            onClick={() => {
              setOpenSettings(false);
              history.push({
                search: location.search,
                state: { storyTransferModalShown: true, preserveScrollPosition: true },
              });
            }}
          >
            Transfer
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            history.push({
              search: location.search,
              state: { storyEmbedModalShown: true, preserveScrollPosition: true },
            });
            setOpenSettings(false);
          }}
        >
          Embed
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.print();
            setOpenSettings(false);
          }}
        >
          Print
        </MenuItem>
        {manageStoryPermissions && (
          <MenuItem
            onClick={(e) => {
              setOpenSettings(false);
              e.stopPropagation();
              confirm({
                description: "Are you sure you want to delete this story?",
                title: `Delete story '${story.name}'?`,
                actionLabel: "Delete",
                onConfirm: submitDeleteStory,
              });
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>

      <Dialog
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        open={!!location.state && !!location.state.storyEditModalShown}
        onClose={() => history.goBack()}
        title="Story settings"
      >
        <Forms.Story
          initialValues={{
            name: story.name,
            displayName: story.displayName || story.name,
            accessLevel: story.accessLevel,
          }}
          updating
          onSubmit={submitEditStory}
          currentAccount={currentAccount}
          cancelFunction={() => history.goBack()}
        />
      </Dialog>

      <CopyStoryDialog
        open={!!location.state?.storyCopyModalShown}
        goBack={() => history.goBack()}
        onSubmit={(newAccount) =>
          dispatch<typeof copyStory>(copyStory(story.name, story.owner.accountName, newAccount)).then(({ body }) => {
            setOpenSettings(false);
            replace(`/${body.owner.accountName}/-/stories/${body.name}`);
          })
        }
      />

      <TransferStoryDialog
        open={!!location.state?.storyTransferModalShown}
        goBack={() => history.goBack()}
        ownerAccountName={story.owner.accountName}
        onSubmit={(newAccount) =>
          dispatch<typeof chownStory>(chownStory(story, newAccount)).then(({ body }) => {
            setOpenSettings(false);
            replace(`/${body.owner.accountName}/-/stories/${body.name}`);
          })
        }
      />

      <EmbedDialog goBack={() => history.goBack()} story={story} />
    </>
  );
};

export default MenuButton;
