import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { Dataset } from "#reducers/datasetCollection.ts";
import { Avatar } from "../../components/index.ts";
import * as styles from "./SourceDatasets.scss";

const SourceDatasets: React.FC<{ story: Models.Story; embedded: boolean }> = ({ story, embedded }) => {
  const usedDatasets = story?.content.reduce(
    (datasetIndex, currentElement) => {
      if (currentElement.type === "query" && currentElement.query?.dataset)
        datasetIndex[currentElement.query?.dataset.id] = currentElement.query?.dataset;
      return datasetIndex;
    },
    {} as { [key: string]: Dataset },
  );

  return (
    <>
      {!!usedDatasets && Object.keys(usedDatasets).length > 0 && (
        <div className={getClassName("py-7", styles.storyFooter, "storyFooter")}>
          <div className={getClassName("mr-3", styles.sourceDatasets)}>
            <h5 className="my-4">Source datasets:</h5>
            {Object.values(usedDatasets).map((dataset) => (
              <div key={dataset.id} className="ml-4 my-2">
                <Link
                  to={`/${dataset.owner.accountName}/${dataset.name}`}
                  className="flex center"
                  target={embedded ? "_blank" : "_self"}
                  aria-label={`${dataset.displayName || dataset.name} by ${
                    dataset.owner.name || dataset.owner.accountName
                  }`}
                  title={`${dataset.displayName || dataset.name} by ${dataset.owner.name || dataset.owner.accountName}`}
                >
                  <Avatar
                    size="sm"
                    avatarName={dataset.displayName || dataset.name}
                    avatarUrl={dataset.avatarUrl}
                    alt=""
                  />
                  <span className="ml-3">{dataset.displayName || dataset.name}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SourceDatasets;
