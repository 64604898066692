import { NoSsr } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./style.scss";

export interface Props {
  src: string;
  alt: string;
  className?: string;
  title?: string;
  showLoadingIcon?: boolean;
  imageClassName?: string;
  loadingIconEl?: JSX.Element;
  createLink?: boolean;
  fallback?: React.ReactNode;
}

const Image: React.FC<Props> = ({
  src,
  alt,
  className,
  title,
  showLoadingIcon,
  imageClassName,
  loadingIconEl,
  createLink,
  fallback,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);
  const onLoad = () => {
    setImageLoaded(true);
  };
  const onError = (_e: any) => {
    setImageError(true);
  };

  // Server side rendering pre-renders images, therefore the onload and onerror will not trigger.
  // And the image DOM element doesn't contain any information if the load of the image was successful independent of the file type
  // Normally this is done by checking natural-height (on fails is a 0) however, SVG's can have a natural-height of 0
  // We've also use the NoSsr component on the client-rendered div, to avoid false errors here
  return (
    <NoSsr>
      <div className={className}>
        {showLoadingIcon &&
          !imageLoaded &&
          !imageError &&
          (loadingIconEl || <FontAwesomeIcon icon={["fas", "cog"]} spin />)}
        {(createLink && (
          <a href={src} rel="noopener noreferrer" target="_blank">
            <img
              src={src}
              alt={alt}
              title={title}
              className={getClassName({
                [styles.hidden]: imageError || !imageLoaded,
                [imageClassName || ""]: !!imageClassName,
              })}
              onLoad={onLoad}
              onError={onError}
              draggable={false}
            />
          </a>
        )) || (
          <img
            src={src}
            alt={alt}
            title={title}
            className={getClassName({
              [styles.hidden]: imageError || !imageLoaded,
              [imageClassName || ""]: !!imageClassName,
            })}
            onLoad={onLoad}
            onError={onError}
            draggable={false}
          />
        )}
        {(imageError || !imageLoaded) && fallback}
      </div>
    </NoSsr>
  );
};

export default Image;
