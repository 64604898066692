import { produce } from "immer";
import { compact, forEach, groupBy, last, reduce, uniqBy } from "lodash-es";
import memoizee from "memoizee";
import { Models, prefixUtils, Routes } from "@triply/utils";
import { QueryPattern, RESOURCE_WIDGET_PATTERNS } from "@triply/utils/Constants.js";
import { isWktDatatype } from "@triply/utils/literalUtils.js";
import { DescribePaginationOptions } from "@triply/utils/Models.js";
import { lexicalToValue } from "@triplydb/recognized-datatypes/wkt.js";
import { geoProject } from "@triplydb/utils/GeoProject.js";
import Tree from "#helpers/LdTree.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";

const GEO_JSON_GEOMETRY_TYPES = [
  "GeometryCollection",
  "LineString",
  "MultiLineString",
  "MultiPoint",
  "MultiPolygon",
  "Point",
  "Polygon",
];

function isGeoJsonObjectType(geoJsonType: string) {
  return GEO_JSON_GEOMETRY_TYPES.includes(geoJsonType);
}

export default isGeoJsonObjectType;

export const LocalActions = {
  GET_DESCRIPTION: "triply/resourceDescriptions/GET_DESCRIPTION",
  GET_DESCRIPTION_SUCCESS: "triply/resourceDescriptions/GET_DESCRIPTION_SUCCESS",
  GET_DESCRIPTION_FAIL: "triply/resourceDescriptions/GET_DESCRIPTION_FAIL",
} as const;

type GET_DESCRIPTION = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_DESCRIPTION,
      typeof LocalActions.GET_DESCRIPTION_SUCCESS,
      typeof LocalActions.GET_DESCRIPTION_FAIL,
    ];
    dataset: Dataset;
    resource: string;
  } & (
    | {
        append: true;
        concise: false;
      }
    | {
        append: false;
        concise: boolean;
      }
  ),
  Routes.datasets._account._dataset.describe.Get
>;

export type LocalAction = GET_DESCRIPTION;

export type Triple = Models.QueryResult;
export type Statements = Triple[];

interface ResourceDescription {
  statements: Statements;
  concise: boolean;
  lastGraphsUpdateTime?: string;
}
export interface ResourceDescriptions {
  [resource: string]: ResourceDescription | undefined;
}

export interface State {
  [datasetId: string]: ResourceDescriptions | undefined;
}

export const getStatementKey = (statement: Triple) =>
  statement[0].value + statement[1].value + statement[2].value + statement[2].datatype + statement[2].language;

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_DESCRIPTION_SUCCESS:
        if (action.append) {
          const existingDescription = draftState[action.dataset.id]?.[action.resource];
          if (
            !existingDescription ||
            existingDescription.concise !== action.concise ||
            existingDescription.lastGraphsUpdateTime !== action.dataset.lastGraphsUpdateTime
          ) {
            //doesn't match with existing data, ignore
            return;
          }
          existingDescription.statements = uniqBy(
            [...existingDescription.statements, ...action.result],
            getStatementKey,
          );
        } else {
          const existingDescription = draftState[action.dataset.id]?.[action.resource];
          if (
            existingDescription &&
            action.concise &&
            !existingDescription.concise &&
            existingDescription.lastGraphsUpdateTime === action.dataset.lastGraphsUpdateTime
          ) {
            //we already have up-to-date information that is not concise, don't overwrite with concise data.
            return;
          }

          const resourceDescription = {
            statements: uniqBy(action.result, getStatementKey),
            concise: action.concise,
            lastGraphsUpdateTime: action.dataset.lastGraphsUpdateTime,
          };

          draftState[action.dataset.id] = {
            ...draftState[action.dataset.id],
            [action.resource]: resourceDescription,
          };
        }
        return;
    }
  },
  <State>{},
);

interface BasicOptions {
  dataset: Dataset;
  resource: string;
  concise: boolean;
}

type GetDescriptionOptions = BasicOptions &
  (
    | {
        concise: true;
      }
    | {
        concise: false;
        paginationOptions?: DescribePaginationOptions;
      }
  );

export function getDescription(opts: GetDescriptionOptions): BeforeDispatch<GET_DESCRIPTION> {
  const { dataset, resource } = opts;
  if (opts.concise) {
    return {
      types: [Actions.GET_DESCRIPTION, Actions.GET_DESCRIPTION_SUCCESS, Actions.GET_DESCRIPTION_FAIL],
      promise: (client) => {
        return client.req({
          pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/describe.triply`,
          query: {
            resource: resource,
            concise: undefined,
          },
          method: "get",
        });
      },
      dataset: dataset,
      resource: resource,
      append: false,
      concise: true,
    };
  } else {
    const { paginationOptions } = opts;
    return {
      types: [Actions.GET_DESCRIPTION, Actions.GET_DESCRIPTION_SUCCESS, Actions.GET_DESCRIPTION_FAIL],
      promise: (client) => {
        return client.req({
          pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/describe.triply`,
          query: {
            resource: resource,
            ...paginationOptions,
          },
          method: "get",
        });
      },
      dataset: dataset,
      resource: resource,
      append: !!paginationOptions,
      concise: false,
    };
  }
}

type FromStateOptions = BasicOptions &
  (
    | {
        scope: "all";
        state: State;
      }
    | {
        scope: "dataset";
        state: ResourceDescriptions | undefined;
      }
  );

export function descriptionIsLoadedFor(opts: FromStateOptions) {
  const { dataset, resource, concise } = opts;
  const datasetDescriptions = opts.scope === "all" ? opts.state[dataset.id] : opts.state;
  if (!resource) return true;
  const resourceDescription = datasetDescriptions?.[resource];
  return (
    !!resourceDescription &&
    (concise || !resourceDescription.concise) &&
    dataset.lastGraphsUpdateTime === resourceDescription.lastGraphsUpdateTime
  );
}

export function descriptionIsOutdatedFor(opts: FromStateOptions) {
  const { dataset, resource, concise } = opts;
  const datasetDescriptions = opts.scope === "all" ? opts.state[dataset.id] : opts.state;
  if (!resource) return false;
  const resourceDescription = datasetDescriptions?.[resource];
  return (
    !!resourceDescription &&
    (concise || !resourceDescription.concise) &&
    dataset.lastGraphsUpdateTime !== resourceDescription.lastGraphsUpdateTime
  );
}

export function getDescriptionFor(opts: FromStateOptions) {
  const { dataset, resource } = opts;
  const datasetDescriptions = opts.scope === "all" ? opts.state[dataset.id] : opts.state;
  if (descriptionIsLoadedFor(opts)) {
    return datasetDescriptions?.[resource];
  }
}

export const getStatementsAsTree = memoizee(
  function getStatementsAsTree(forIri: string, statements: Statements, direction: "forward" | "backward") {
    return Tree.fromStatements({ termType: "NamedNode", value: forIri }, statements, direction);
  },
  { max: 20, length: 3 },
);

function selectLanguage(statements: Statements) {
  const english = statements.find((s) => s[2].language === "en");
  if (english) return english;
  return statements[0];
}

function findOne(subject: string, statements: Statements, properties: string[]) {
  statements = statements.filter((s) => s[0].value === subject);
  if (!statements.length) return;

  for (var i = 0; i < properties.length; i++) {
    const propertyMatches = statements.filter((s) => s[1].value === properties[i] && s[2].termType === "Literal");
    if (propertyMatches.length) return selectLanguage(propertyMatches);
  }
}

export function getLabel(tree?: Tree): string | undefined {
  if (!tree) return undefined;
  const labelWidget = selectLabel(tree);

  if (labelWidget?.values?.[0]) {
    return labelWidget.values[0].getTerm().value;
  }

  return getLabelFromLocalName(tree.getTerm().value);
}

export function getLabelFromLocalName(iri: string) {
  const lnameInfo = prefixUtils.getLocalNameInfo(iri);
  if (lnameInfo.localName) {
    return lnameInfo.localName;
  }
  return iri;
}

const PREDICATES_TO_GET_LABELS_FOR = compact(RESOURCE_WIDGET_PATTERNS.LABEL_PATTERNS.map((pattern) => pattern[0]));
export function getPredicateLabel(tree: Tree, predicate: string): string {
  const propertyMatch = findOne(predicate, tree.getStatements(), PREDICATES_TO_GET_LABELS_FOR);
  if (propertyMatch) return propertyMatch[2].value;

  return getLabelFromLocalName(predicate);
}

/**
 * Render selectors
 */

const prefixes = {
  rdfs: "http://www.w3.org/2000/01/rdf-schema#",
  rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
  geo: "http://www.opengis.net/ont/geosparql#",
  dcterms: "http://purl.org/dc/terms/",
  dc: "http://purl.org/dc/elements/1.1/",
  brt: "http://brt.basisregistraties.overheid.nl/def/top10nl#",
  xsd: "http://www.w3.org/2001/XMLSchema#",
  foaf: "http://xmlns.com/foaf/0.1/",
  schema: "https://schema.org/",
  sh: "http://www.w3.org/ns/shacl#",
};

export interface RenderConfiguration {
  // Geo
  type?: "wkt" | "latLong";
  // Media (Audio & Video)
  encodings?: Tree[];
  media?: Tree[];
}
export interface WidgetConfig {
  type:
    | "image"
    | "geometry"
    | "description"
    | "label"
    | "properties"
    | "property"
    | "classes"
    | "audio"
    | "video"
    | "resourceStatus";
  label?: string;
  config?: RenderConfiguration;
  values?: Tree[];
  children?: WidgetConfig[];
  key?: string;
}
export type SelectWidget = (tree: Tree) => WidgetConfig | undefined;
export type WidgetCollection = { [key in WidgetConfig["type"]]?: WidgetConfig };

/**
 * Render selectors
 */
export const selectImage: SelectWidget = (t) => {
  const patterns = RESOURCE_WIDGET_PATTERNS.IMAGE_PATTERNS;
  for (var i = 0; i < patterns.length; i++) {
    const nodes = t.find(patterns[i]).limit(1).exec();
    if (nodes.length) {
      const values = nodes
        .map((node) => {
          // We've matched a literal, expecting that this is the imageLocation
          if (node.getTerm().termType === "Literal") return node;

          // Some patterns might have a image below them, let's first take a look at that (https://issues.triply.cc/issues/3791)
          if (node.hasChildren()) {
            for (const mediaPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_PATTERNS) {
              const mediaNodes = node.find(mediaPattern).limit(1).exec();
              if (mediaNodes.length > 0) {
                return mediaNodes[0];
              }
            }
          }
          // Some patterns should be found within the parent object (https://issues.triply.cc/issues/4057#note-15)
          // We should use the parent rather then the root element. As it might contain a match that should be matched deeper (eg. Video and Image can  both have a contentUrl)
          const parent = node.getParent() ?? t;
          for (const mediaPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_PATTERNS) {
            const mediaNodes = parent.find(mediaPattern).limit(1).exec();
            if (mediaNodes.length > 0) {
              return mediaNodes[0];
            }
          }
          // If we haven't found a suitable sub-pattern yet, and the object is an image, we try the other patterns
          // sdo:ImageObjects are currently the only image pattern that use the rdf:type relation
          if (patterns[i][0] === "http://www.w3.org/1999/02/22-rdf-syntax-ns#type") {
            return undefined;
          }

          // Most cases should be handled in the previous returns. But let's be sure
          return node;
        })
        .filter((node) => !!node) as Tree[];
      if (values.length === 0) continue;
      return {
        values,
        type: "image",
      };
    }
  }
};
export const selectGeometry: SelectWidget = (t) => {
  for (const pattern of RESOURCE_WIDGET_PATTERNS.GEO_PATTERNS) {
    const nodes = t.find(pattern).limit(1).exec();
    const wktTerm = last(nodes)?.getTerm();
    // Skip validation of very long wkt values because of slow performance. That means that we might run the risk
    // of rendering empty thumbnails when a very long wkt value is invalid, but it remains unlikely.
    // see: https://issues.triply.cc/issues/4523
    try {
      if (wktTerm?.datatype && isWktDatatype(wktTerm.datatype)) {
        if (wktTerm.value.toLowerCase().includes("empty")) return undefined;
        // CRS84 is expected by geo libraries
        const parsedWkt = geoProject(lexicalToValue(wktTerm.value), "http://www.opengis.net/def/crs/OGC/1.3/CRS84");
        if (isGeoJsonObjectType(parsedWkt.type)) {
          return {
            values: nodes,
            type: "geometry",
            config: {
              type: "wkt",
            },
          };
        }
      }
    } catch {}
  }

  const latNodes: Tree[] = [];
  for (const pattern of RESOURCE_WIDGET_PATTERNS.LAT_PATTERNS) {
    latNodes.push(...t.find(pattern).limit(1).exec());
    if (latNodes.length) break;
  }

  const longNodes: Tree[] = [];
  for (const pattern of RESOURCE_WIDGET_PATTERNS.LONG_PATTERNS) {
    longNodes.push(...t.find(pattern).limit(1).exec());
    if (longNodes.length) break;
  }

  if (latNodes.length && longNodes.length) {
    return {
      values: [latNodes[0], longNodes[0]],
      type: "geometry",
      config: {
        type: "latLong",
      },
    };
  }
};
export const selectAudio: SelectWidget = (t) => {
  for (const pattern of RESOURCE_WIDGET_PATTERNS.AUDIO_PATTERNS) {
    const nodes = t.find(pattern).exec();
    if (nodes.length) {
      const encodings: Tree[] = [];
      const media: Tree[] = [];
      nodes.forEach((node) => {
        const nodeToCheck = node.hasChildren() ? node : t;
        for (const mediaPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_PATTERNS) {
          const mediaNodes = nodeToCheck.find(mediaPattern).limit(1).exec();
          if (mediaNodes.length > 0) {
            media.push(mediaNodes[0]);
            break;
          }
        }
        for (const encodingPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_ENCODING_PATTERNS) {
          const encoding = nodeToCheck.find(encodingPattern).limit(1).exec();
          if (encoding.length > 0) {
            encodings.push(encoding[0]);
            break;
          }
        }
      });

      return {
        values: nodes,
        config: {
          encodings: encodings,
          media: media,
        },
        type: "audio",
      };
    }
  }
};
export const selectVideo: SelectWidget = (t) => {
  for (const pattern of RESOURCE_WIDGET_PATTERNS.VIDEO_PATTERNS) {
    const nodes = t.find(pattern).exec();
    if (nodes.length) {
      const encodings: Tree[] = [];
      const media: Tree[] = [];
      nodes.forEach((node) => {
        const nodeToCheck = node.hasChildren() ? node : t;
        for (const mediaPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_PATTERNS) {
          const mediaNodes = nodeToCheck.find(mediaPattern).limit(1).exec();
          if (mediaNodes.length > 0) {
            media.push(mediaNodes[0]);
            break;
          }
        }
        for (const encodingPattern of RESOURCE_WIDGET_PATTERNS.MEDIA_ENCODING_PATTERNS) {
          const encoding = nodeToCheck.find(encodingPattern).limit(1).exec();
          if (encoding.length > 0) {
            encodings.push(encoding[0]);
            break;
          }
        }
      });

      return {
        values: nodes,
        config: {
          encodings: encodings,
          media: media,
        },

        type: "video",
      };
    }
  }
};
export const selectDescription: SelectWidget = (t) => {
  const patterns: QueryPattern<Models.NtriplyTerm>[] = [
    [prefixes.dcterms + "description", { language: "en" }],
    [prefixes.dc + "description", { language: "en" }],
    ["https://schema.org/description", { language: "en" }],
    ["http://www.w3.org/2000/01/rdf-schema#comment", { language: "en" }],
    [prefixes.sh + "description", { language: "en" }],
    [prefixes.dcterms + "description", undefined],
    [prefixes.dc + "description", undefined],
    ["https://schema.org/description", undefined],
    ["http://www.w3.org/2000/01/rdf-schema#comment", undefined],
    [prefixes.sh + "description", undefined],
  ];
  for (var i = 0; i < patterns.length; i++) {
    const node = t.find(patterns[i]).limit(1).exec();
    if (node.length) {
      return {
        values: node,
        type: "description",
      };
    }
  }
};
export const selectClasses: SelectWidget = (t) => {
  const nodes = t.find(["http://www.w3.org/1999/02/22-rdf-syntax-ns#type", undefined]).exec();
  if (nodes.length) {
    return {
      values: nodes,
      type: "classes",
      label: getPredicateLabel(t, "http://www.w3.org/1999/02/22-rdf-syntax-ns#type"),
    };
  }
};
export const selectLabel: SelectWidget = (t) => {
  const patterns = RESOURCE_WIDGET_PATTERNS.LABEL_PATTERNS;
  for (var i = 0; i < patterns.length; i++) {
    const node = t.find(patterns[i]).limit(1).exec();
    if (node.length) {
      return {
        values: node,
        type: "label",
      };
    }
  }
};
export const selectResourceStatus: SelectWidget = (t) => {
  const status = t.find(["http://www.w3.org/2003/06/sw-vocab-status/ns#term_status"]).limit(1).exec();
  if (status) {
    return {
      values: status,
      type: "resourceStatus",
    };
  }
};
export const catchAll: SelectWidget = (t) => {
  var nodes = t.find().depth(1).exec();

  const groupedByPred = groupBy(
    nodes,
    (n) =>
      n.getPredicate() +
      n
        .getParents()
        .map((p) => p.getPredicate())
        .join(","),
  );
  const selections: WidgetConfig[] = [];
  forEach(groupedByPred, (nodes, predicate) => {
    selections.push({
      label: getPredicateLabel(t, predicate),
      values: nodes,
      type: "property",
    });
  });
  if (selections.length) {
    return {
      children: selections,
      type: "properties",
    };
  }
};
export const SelectWidgets = [
  selectLabel,
  selectAudio,
  selectVideo,
  selectImage,
  selectDescription,
  selectClasses,
  selectGeometry,
  selectResourceStatus,
  catchAll,
];
export function getWidgetCollection(tree: Tree, selectWidgets: SelectWidget[] = SelectWidgets) {
  return reduce(
    selectWidgets,
    (result, selectWidget) => {
      const widget = selectWidget(tree);
      if (widget) result[widget.type] = widget;
      return result;
    },
    {} as WidgetCollection,
  );
}
export const getWidgetCollectionMemoized = memoizee(getWidgetCollection, { max: 20, length: 2 });
