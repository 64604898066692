import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "@triply/utils-private/formatting.js";
import { Avatar, Markdown, ResourceWidget, Topics } from "#components/index.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import {
  getStatementsAsTree,
  getWidgetCollection,
  ResourceDescriptions,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
  Statements,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./style.scss";

namespace DatasetWidget {
  export interface Props {
    ds: Dataset;
    datasetResourceDescriptions?: ResourceDescriptions | { [iri: string]: Statements };
    showOwnerName?: boolean;
    showAccessLevel?: boolean;
    className?: string;
    style?: React.CSSProperties;
    headerSize?: "md" | "lg"; //default lg
  }
}

const DatasetWidget: React.FC<DatasetWidget.Props> = React.memo(
  ({ ds, className, datasetResourceDescriptions, showOwnerName, headerSize, style }) => {
    const link = "/" + ds.owner.accountName + "/" + ds.name;
    return (
      <div className={getClassName(className, styles.widget)} style={style}>
        <Link
          className={getClassName("flex", "mb-2", "noLinkDecoration", styles.header, {
            [styles.small]: headerSize === "md",
          })}
          to={link}
        >
          <Avatar
            size={headerSize || "lg"}
            avatarName={ds.displayName || ds.name}
            avatarUrl={ds.avatarUrl}
            title={ds.displayName || ds.name}
            alt=""
          />
          <h2 className="headerSpacing ml-4">{ds.displayName || ds.name}</h2>
        </Link>
        {ds.topics.length > 0 && <Topics topics={ds.topics} implicitTopics={ds.implicitTopics} />}
        <div className={styles.indent}>
          {showOwnerName && (
            <span>
              by{" "}
              <Link className={styles.darkLink} to={`/${ds.owner.accountName}`}>
                {ds.owner.name || ds.owner.accountName}
              </Link>
            </span>
          )}
          <span>{`${formatNumber(ds.statements)} statements`}</span>
        </div>

        {ds.description && (
          <Markdown className={styles.markdown} compact>
            {ds.description}
          </Markdown>
        )}

        {datasetResourceDescriptions && ds.exampleResources && ds.exampleResources.length > 0 && (
          <div className={styles.exampleResources}>
            {ds.exampleResources.map((r) => {
              const desc = datasetResourceDescriptions[r];
              const statements = Array.isArray(desc) ? desc : desc?.statements;
              const tree = desc && getStatementsAsTree(r, statements || [], "forward");
              return (
                tree && (
                  <ResourceWidget
                    key={r}
                    resource={tree.getTerm()}
                    widgetCollection={getWidgetCollection(tree, [
                      selectLabel,
                      selectImage,
                      selectGeometry,
                      selectAudio,
                      selectVideo,
                    ])}
                    linkPath={`/${ds.owner.accountName}/${ds.name}/browser`}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    );
  },
);
export default DatasetWidget;
