import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { createPortal } from "react-dom";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "#components/index.ts";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { SanitizedContent } from "../displayUtils";
import GalleryCard from "./GalleryCard";
import { getWidgetsFromResults } from "./Renderer";
import { PluginConfig } from ".";
import * as styles from "./styles.scss";

function getConfigFromVariables(variables: string[] | undefined): PluginConfig {
  return {
    displayType: variables?.includes("widgetSingle") || (variables as string[])?.includes("markup") ? "None" : "Card",
    body: {
      key: variables?.includes("widgetSingle")
        ? "widgetSingle"
        : variables?.includes("markup")
          ? "markup"
          : variables?.includes("widgetDescription")
            ? "widgetDescription"
            : variables?.includes("widget")
              ? "widget"
              : "",
    },
    image: {
      src: variables?.includes("widgetImage") ? "widgetImage" : "",
      caption: {
        key: variables?.includes("widgetImageCaption") ? "widgetImageCaption" : "",
      },
    },
    label: {
      var: variables?.includes("widgetLabel") ? "widgetLabel" : "",
      link: variables?.includes("widgetLabelLink") ? "widgetLabelLink" : "",
    },
  };
}

interface Props {}

const Config: React.FC<Props> = ({}) => {
  const [open, setOpen] = React.useState(false);
  const { setVisualizationConfig, getVisualizationConfig, visualizationActionsCtrId } =
    React.useContext(SparqlVisualizationContext);
  const actionBarContainer = visualizationActionsCtrId && document.getElementById(visualizationActionsCtrId);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const onSubmit = React.useCallback(
    (data: PluginConfig) => {
      setVisualizationConfig?.("Gallery", data);
      setOpen(false);
    },
    [setVisualizationConfig],
  );

  return (
    <>
      {actionBarContainer &&
        createPortal(
          <Button
            className="my-2 mx-3"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon="gears" size="lg" />}
            onClick={() => setOpen((open) => !open)}
          >
            Configure
          </Button>,

          actionBarContainer,
        )}
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Gallery configuration</DialogTitle>
        <Form onSubmit={onSubmit} onClose={onClose} />
      </Dialog>
    </>
  );
};

const Form: React.FC<{ onSubmit: (data: PluginConfig) => void; onClose: () => void }> = ({
  onSubmit: _onSubmit,
  onClose,
}) => {
  const { results, variables, isAsk } = useSparqlResults();
  const { getVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const config = getVisualizationConfig("Gallery");
  const { control, handleSubmit, watch, setValue } = useForm<PluginConfig>({
    defaultValues: config || getConfigFromVariables(variables),
  });
  const values = watch();
  const variableItems = React.useMemo(
    () => variables?.map((variable) => <MenuItem value={variable}>{variable}</MenuItem>) || [],
    [variables],
  );
  const labelId = React.useId();
  const linkId = React.useId();
  const imageId = React.useId();
  const captionId = React.useId();
  const captionFormatId = React.useId();
  const bodyId = React.useId();
  const bodyFormatId = React.useId();
  // If there is no config, we should update each time the variables change
  const onSubmit = React.useCallback(
    (data: PluginConfig) => {
      if (!data.label?.var) {
        delete data.label;
      } else if (!data.label.link) {
        delete data.label.link;
      }
      if (!data.image?.src) {
        delete data.image;
      }
      if (!data.body?.key) {
        delete data.body;
      }
      _onSubmit(data);
    },
    [_onSubmit],
  );

  if (isAsk) return null;
  const exampleTerm = results && getWidgetsFromResults(values, results[0]);
  const exampleTermHasContent =
    values.displayType === "Card"
      ? !!(exampleTerm?.body || exampleTerm?.image || exampleTerm?.label)
      : !!exampleTerm?.body;
  return (
    <Container className={getClassName("flex", styles.configContainer)}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex column grow">
        <div className="flex grow">
          <div className="grow">
            <Controller
              name="displayType"
              control={control}
              defaultValue="Card"
              render={({ field: { onChange, ...rest } }) => (
                <FormControl color="primary">
                  <FormLabel>Template</FormLabel>
                  <ToggleButtonGroup
                    {...rest}
                    exclusive
                    onChange={(event, value) => value !== null && onChange(event, value)}
                  >
                    <ToggleButton value="Card">Cards</ToggleButton>
                    <ToggleButton value="None">None</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              )}
            />
            {values.displayType === "Card" && (
              <>
                <Divider className="mb-3 mt-5">
                  <span id={labelId}>Header</span>
                </Divider>
                <FormLabel>The label that will be shown at the top of the card</FormLabel>
                <Stack
                  direction="column"
                  className={getClassName("mt-3", styles.configSection)}
                  alignItems="flex-start"
                >
                  <div>
                    <Controller
                      name="label.var"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl className={getClassName("mr-5", styles.baseFormWidth)}>
                          <Select
                            {...field}
                            displayEmpty
                            labelId={labelId}
                            startAdornment={<InputAdornment position="start">?</InputAdornment>}
                          >
                            {field.value && !((variables || []) as string[]).includes(field.value) && (
                              <MenuItem value={field.value}>
                                <Tooltip title="This variable is not present in the current result set">
                                  <span>
                                    {field.value}{" "}
                                    <FontAwesomeIcon
                                      icon="exclamation-triangle"
                                      className={getClassName("ml-2", styles.missingVar)}
                                    />
                                  </span>
                                </Tooltip>
                              </MenuItem>
                            )}
                            {...variableItems}
                            <MenuItem value="">None</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    {values.label?.var && !values.label.link && (
                      <Button
                        variant="text"
                        size="small"
                        className="mt-1 ml-3"
                        aria-label="Add link"
                        startIcon={<FontAwesomeIcon icon="plus" />}
                        onClick={() => setValue("label.link", variables?.[0] || "widgetLabelLink")}
                      >
                        link
                      </Button>
                    )}
                  </div>
                  {values.label?.var && values.label?.link && (
                    <Controller
                      name="label.link"
                      aria-label="Header link column"
                      defaultValue=""
                      control={control}
                      render={({ field }) => {
                        return (
                          <Stack
                            direction="row"
                            className={getClassName(styles.subItemWrapper, "mt-5")}
                            alignItems="center"
                          >
                            <FormLabel className="ml-1 mr-2" id={linkId}>
                              Link
                            </FormLabel>
                            <FormControl variant="standard" className={styles.baseFormWidth}>
                              <Select
                                {...field}
                                labelId={linkId}
                                className={styles.selectWithEndAdornment}
                                displayEmpty
                                startAdornment={<InputAdornment position="start">?</InputAdornment>}
                                endAdornment={
                                  <Tooltip describeChild title="Changes the title from text to a link">
                                    <InputAdornment position="end">
                                      <FontAwesomeIcon icon="info-circle" />
                                    </InputAdornment>
                                  </Tooltip>
                                }
                              >
                                {field.value && !((variables || []) as string[]).includes(field?.value || "") && (
                                  <MenuItem value={field.value}>
                                    <Tooltip title="This variable is not present in the current result set">
                                      <span>
                                        {field.value}{" "}
                                        <FontAwesomeIcon
                                          icon="exclamation-triangle"
                                          className={getClassName("ml-2", styles.missingVar)}
                                        />
                                      </span>
                                    </Tooltip>
                                  </MenuItem>
                                )}
                                {...variableItems}
                              </Select>
                            </FormControl>
                            <IconButton
                              size="small"
                              onClick={() => setValue("label.link", "")}
                              title="Remove link"
                              aria-label="Remove link"
                            >
                              <FontAwesomeIcon icon="xmark" />
                            </IconButton>
                          </Stack>
                        );
                      }}
                    />
                  )}
                </Stack>
                <Divider className="mb-3 mt-5">
                  <span id={imageId}>Image</span>
                </Divider>
                <div>
                  <FormLabel>The URL of the image to display between the header and the body</FormLabel>
                  <Stack direction="column" alignItems="flex-start" className={styles.configSection}>
                    <div>
                      <Controller
                        name="image.src"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <FormControl className={getClassName("mr-5", styles.baseFormWidth)}>
                            <Select
                              {...field}
                              labelId={imageId}
                              aria-label="Image source column"
                              displayEmpty
                              startAdornment={<InputAdornment position="start">?</InputAdornment>}
                            >
                              {field.value && !((variables || []) as string[]).includes(field.value) && (
                                <MenuItem value={field.value}>
                                  <Tooltip title="This variable is not present in the current result set">
                                    <span>
                                      {field.value}{" "}
                                      <FontAwesomeIcon
                                        icon="exclamation-triangle"
                                        className={getClassName("ml-2", styles.missingVar)}
                                      />
                                    </span>
                                  </Tooltip>
                                </MenuItem>
                              )}
                              {...variableItems}
                              <MenuItem value="">None</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                      {values.image?.src && !values.image.caption?.key && (
                        <Button
                          size="small"
                          className="mt-1 ml-3"
                          variant="text"
                          startIcon={<FontAwesomeIcon icon="plus" />}
                          aria-label="Add caption"
                          onClick={() => setValue("image.caption.key", variables?.[0] || "imageWidgetCaption")}
                        >
                          caption
                        </Button>
                      )}
                    </div>
                    {values.image?.src && values.image?.caption?.key && (
                      <Stack
                        alignItems="center"
                        className={getClassName(styles.subItemWrapper, "mt-4")}
                        direction="row"
                      >
                        <FormLabel id={captionId}>Caption</FormLabel>
                        <Controller
                          name="image.caption.key"
                          defaultValue=""
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl className={getClassName("mx-2", styles.baseFormWidth)}>
                              <Select
                                {...field}
                                className={styles.selectWithEndAdornment}
                                displayEmpty
                                labelId={captionId}
                                startAdornment={<InputAdornment position="start">?</InputAdornment>}
                                endAdornment={
                                  <Tooltip describeChild title="The title or explanation of an illustration">
                                    <InputAdornment position="end">
                                      <FontAwesomeIcon icon="info-circle" />
                                    </InputAdornment>
                                  </Tooltip>
                                }
                              >
                                {field.value && !((variables || []) as string[]).includes(field.value) && (
                                  <MenuItem value={field.value}>
                                    <Tooltip title="This variable is not present in the current result set">
                                      <span>
                                        {field.value}{" "}
                                        <FontAwesomeIcon
                                          icon="exclamation-triangle"
                                          className={getClassName("ml-2", styles.missingVar)}
                                        />
                                      </span>
                                    </Tooltip>
                                  </MenuItem>
                                )}
                                {...variableItems}
                              </Select>
                            </FormControl>
                          )}
                        />
                        <InputLabel className="ml-1" id={captionFormatId}>
                          Format
                        </InputLabel>
                        <Controller
                          name="image.caption.renderer"
                          defaultValue=""
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl className="ml-3" size="small">
                              <Select
                                {...field}
                                value={field.value || ""}
                                displayEmpty
                                labelId={captionFormatId}
                                label="Content type"
                                className={styles.selectWithEndAdornment}
                                endAdornment={
                                  <InfoAdornment message="How the value should be rendered. 'Auto' will use the data-type of the value to detect how to render the content. See the documentation for which datatypes are supported" />
                                }
                              >
                                <MenuItem value="Markup">Markdown</MenuItem>
                                <MenuItem value="Html">HTML</MenuItem>
                                <MenuItem value="Mermaid">Mermaid</MenuItem>
                                <MenuItem value="Text">Text</MenuItem>
                                <MenuItem value="">Auto</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => setValue("image.caption.key", "")}
                          title="Remove caption"
                          aria-label="Remove caption"
                        >
                          <FontAwesomeIcon icon="xmark" />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </div>
              </>
            )}
            <Divider className="mb-3 mt-5">
              <span id={bodyId}>Body</span>
            </Divider>
            <Stack direction={"row"} alignItems="center">
              <Controller
                name="body.key"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl>
                    <Select
                      {...field}
                      displayEmpty
                      labelId={bodyId}
                      className={getClassName("mr-2", styles.baseFormWidth)}
                      startAdornment={<InputAdornment position="start">?</InputAdornment>}
                    >
                      {field.value && !((variables || []) as string[]).includes(field.value) && (
                        <MenuItem value={field.value}>
                          <Tooltip title="This variable is not present in the current result set">
                            <span>
                              {field.value}{" "}
                              <FontAwesomeIcon
                                icon="exclamation-triangle"
                                className={getClassName("ml-2", styles.missingVar)}
                              />
                            </span>
                          </Tooltip>
                        </MenuItem>
                      )}
                      {...variableItems}
                      <MenuItem value="">None</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              {values.body?.key && (
                <>
                  <InputLabel id={bodyFormatId}>Format</InputLabel>
                  <Controller
                    name="body.renderer"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl className="ml-3">
                        <Select
                          {...field}
                          labelId={bodyFormatId}
                          value={field.value || ""}
                          displayEmpty
                          className={styles.selectWithEndAdornment}
                          endAdornment={
                            <InfoAdornment message="How the value should be rendered. 'Auto' will use the data-type of the value to detect how to render the content. See the documentation for which datatypes are supported" />
                          }
                        >
                          <MenuItem value="Markup">Markdown</MenuItem>
                          <MenuItem value="Html">HTML</MenuItem>
                          <MenuItem value="Mermaid">Mermaid</MenuItem>
                          <MenuItem value="Text">Text</MenuItem>
                          <MenuItem value="">Auto</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </>
              )}
            </Stack>
          </div>
          <div className={getClassName("ml-5", styles.exampleWidgetContainer)}>
            <div className={getClassName("mx-3", "p-5", styles.exampleWidget)}>
              {!exampleTermHasContent ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                  <Typography variant="caption">No values found for current configuration</Typography>
                </div>
              ) : values.displayType === "Card" ? (
                <div>
                  <GalleryCard {...exampleTerm} config={values} />
                </div>
              ) : (
                <div>
                  <SanitizedContent term={exampleTerm?.body} renderAs={values.body?.renderer || undefined} />
                </div>
              )}
            </div>
          </div>
        </div>
        <DialogActions>
          <Button type="submit" disableElevation>
            Apply
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Container>
  );
};

export default Config;

const InfoAdornment: React.FC<{ message: string }> = ({ message }) => (
  <Tooltip describeChild title={message}>
    <InputAdornment position="end">
      <FontAwesomeIcon icon="info-circle" />
    </InputAdornment>
  </Tooltip>
);
