import { isUndefined, omitBy } from "lodash-es";
import { PrefixesArray } from "@triply/utils/prefixUtils.js";
import { factories, parse, Terms } from "@triplydb/data-factory";
import { parsers as sparqlParsers } from "@triplydb/sparql-ast";
import { StaticConfig } from "../../reducers/config";
import { VisualizationConfig, VisualizationLabel } from "./Results";

export type CacheHeader = "HIT" | "MISS" | "SKIPPED" | "DISABLED";

export interface PositionInformation {
  startLine: number;
  startColumn: number;
  endLine: number;
  endColumn: number;
}

/**
 *  These types describe https://www.w3.org/TR/2013/REC-sparql11-results-json-20130321/
 */
export type SparqlJson = SelectResult | AskResult;

export type SelectResult = {
  head: { vars: string[]; link?: string[] };
  results: { bindings: Binding[] };
};

export type AskResult = {
  head: { vars?: never; link?: string[] };
  boolean: boolean;
};

export type Binding = {
  [variable: string]: Term | undefined;
};

export type Term = Iri | Literal | BlankNode;

export type Iri = { type: "uri"; value: string }; // "uri" is not a typo :(

/**
 *  Instead of following the standard, Virtuoso returns "typed-literal" for typed literals :(
 */
export type Literal =
  | { type: "literal"; value: string }
  | { type: "literal"; value: string; "xml:lang": string }
  | { type: "literal" | "typed-literal"; value: string; datatype: string };

type BlankNode = { type: "bnode"; value: string };

export type ConstructResult = {
  subject: Term;
  predicate: Term;
  object: Term;
};
export type ConstructResults = ConstructResult[];

export type SparqlResponse = SparqlJson | ConstructResults;
export type RawResponse = SparqlJson | string;

//Chart types in google charts
const GOOGLE_CHARTS_TO_IGNORE_DEFAULT_HEIGHT = ["Table", "OrgChart"];

export function postProcessTriplesResponse(result: string): ConstructResults {
  return parse(result, { format: "n-triples" }).map((triple) => ({
    subject: rdfjsTerm2sparqlJsonTerm(triple.subject),
    predicate: rdfjsTerm2sparqlJsonTerm(triple.predicate),
    object: rdfjsTerm2sparqlJsonTerm(triple.object),
  }));
}

const xsd = factories.compliant.prefixer("http://www.w3.org/2001/XMLSchema#");
function rdfjsTerm2sparqlJsonTerm<T extends Terms.Term>(term: T): Term {
  switch (term.termType) {
    case "NamedNode":
      return { type: "uri", value: term.value } as Term;
    case "BlankNode":
      return { type: "bnode", value: term.value } as Term;
    case "Literal":
      const literal: { type: "literal"; value: string; "xml:lang"?: string; datatype?: string } = {
        type: "literal",
        value: term.value,
      };
      if (term.language) {
        literal["xml:lang"] = term.language;
      } else if (!term.datatype.equals(xsd("string"))) {
        literal.datatype = term.datatype.value;
      }
      return literal as Term;
    default:
      throw new Error("Unrecognized term");
  }
}

/**
 * Injects template variables into the results
 */
export function postProcessSparqlJsonResponse(result: SparqlJson): SparqlJson {
  if (!result) return result;
  if (isAskResponse(result)) return result;
  // When restoring from cache, the cache is immutable.
  const results = result.results.bindings.map((immutable_binding) => {
    const binding = { ...immutable_binding };
    for (const variable in binding) {
      const term = { ...binding[variable] } as Term;
      if (term === undefined || term?.type === "uri" || term?.type === "bnode") continue;
      term.value = term.value.replace(/{{\s*(\w*)\s*}}/g, (match, handlebarContent) => {
        const termToInject = binding[handlebarContent];
        return termToInject?.value !== undefined ? termToInject.value : match;
      });
      binding[variable] = term;
    }
    return binding;
  });
  return {
    ...result,
    results: {
      bindings: results,
    },
  };
}
const YASGUI_VISUALIZATION_LABELS = [
  "table",
  "response",
  "geo",
  "geo3d",
  "geoEvents",
  "gchart",
  "pivot",
  "timeline",
  "network",
  "gallery",
  "markup",
];
export function isIdeVisualization(visualization: string | VisualizationLabel): visualization is VisualizationLabel {
  return !YASGUI_VISUALIZATION_LABELS.includes(visualization);
}
export function getIdeVisualization(
  visualization: string | VisualizationLabel | undefined,
): VisualizationLabel | undefined {
  if (!visualization) return undefined;
  if (isIdeVisualization(visualization)) return visualization;
  return yasguiVisualizationToIdeVisualization(visualization);
}

/**
 * Transforms the new visualization label to the old one,
 * We use the response to identify if a markup plugin used to be rendered
 */
export function ideVisualizationToYasguiVisualization(
  visualization: VisualizationLabel,
  config: VisualizationConfig,
  results?: SparqlResponse | undefined,
): string {
  switch (visualization) {
    case "Table":
      return "table";
    case "Response":
      return "response";
    case "Geo":
      if (config && "perspective" in config && config.perspective === "Tilted") {
        return "geo3d";
      }
      return "geo";
    case "Gallery":
      if (results && "head" in results && results.head.vars?.includes("markup")) {
        return "markup";
      }
      return "gallery";
    case "Charts":
      return "gchart";
    case "Pivot":
      return "pivot";
    case "Timeline":
      return "timeline";
    case "Network":
      return "network";
    default:
      return visualization || "table";
  }
}
function yasguiVisualizationToIdeVisualization(visualization: string): VisualizationLabel {
  switch (visualization) {
    case "table":
      return "Table";
    case "response":
      return "Response";
    case "geoEvents":
    case "geo":
    case "geo3d":
      return "Geo";
    case "gallery":
    case "markup":
      return "Gallery";
    case "gchart":
      return "Charts";
    case "pivot":
      return "Pivot";
    case "timeline":
      return "Timeline";
    case "network":
      return "Network";
    default:
      return "Table";
  }
}
export function ideVisualizationConfigToYasguiVisualizationConfig(
  visualization: VisualizationLabel,
  visualizationConfig: VisualizationConfig,
) {
  if (visualization === "Charts") {
    return { chartConfig: visualizationConfig };
  }
  if (visualizationConfig && visualization === "Pivot" && "rendererName" in visualizationConfig) {
    const config = { ...visualizationConfig };
    switch (config?.rendererName) {
      case "Table Heatmap":
        config.rendererName = "Heatmap";
        break;
      case "Table Row Heatmap":
        config.rendererName = "Row Heatmap";
        break;
      case "Table Col Heatmap":
        config.rendererName = "Col Heatmap";
        break;
      case "Line Chart":
      case "Bar Chart":
      case "Stacked Bar Chart":
      case "Area Chart":
      case "Scatter Chart":
        // No need to update the rendererName
        break;
      default:
        config.rendererName = "Table";
    }

    return { pivotConfig: config };
  }
  if (visualizationConfig && visualization === "Geo") {
    if ("perspective" in visualizationConfig && visualizationConfig.perspective === "Tilted") {
      return {
        map: "map" in visualizationConfig && visualizationConfig.map,
      };
    } else {
      const baseMap =
        "layers" in visualizationConfig
          ? visualizationConfig.layers?.find((el) => el === "osm" || el === "nlmaps") || "none"
          : "osm";

      return omitBy(
        {
          visualization: { Grouped: "grouped", Heatmap: "heatmap", Default: "default" }[
            ("markerType" in visualizationConfig && visualizationConfig.markerType) || "Default"
          ],
          map: baseMap,
          activeLayers:
            ("layers" in visualizationConfig &&
              visualizationConfig.layers
                ?.slice()
                .sort()
                .filter((layer) => layer !== baseMap)) ||
            undefined,
          orderedLayers:
            ("layers" in visualizationConfig && visualizationConfig.layers?.filter((layer) => layer !== baseMap)) ||
            undefined,
        },
        isUndefined,
      );
    }
  }

  if (visualization === "Network" && visualizationConfig && "seed" in visualizationConfig) {
    return { networkConfig: { seed: visualizationConfig.seed } };
  }

  return visualizationConfig;
}
export function yasguiVisualizationConfigToIdeVisualizationConfig(
  visualization: string,
  visualizationConfig: any,
  staticConfig: StaticConfig | undefined,
): VisualizationConfig {
  if (visualization === "gchart" && visualizationConfig?.chartConfig) {
    return visualizationConfig.chartConfig;
  }
  if (visualization === "pivot" && visualizationConfig?.pivotConfig) {
    const config = { ...visualizationConfig.pivotConfig };
    switch (config?.rendererName) {
      case "Heatmap":
      case "Table Barchart":
        config.rendererName = "Table Heatmap";
        break;
      case "Row Heatmap":
        config.rendererName = "Table Row Heatmap";
        break;
      case "Col Heatmap":
        config.rendererName = "Table Col Heatmap";
        break;
      case "Line Chart":
      case "Bar Chart":
      case "Stacked Bar Chart":
      case "Area Chart":
      case "Scatter Chart":
        // No need to update the rendererName
        break;
      default:
        config.rendererName = "Table";
    }
    return config;
  }
  if (visualization === "table") {
    if (visualizationConfig?.pageSize === -1) return { pageSize: 50 };
  }
  if (visualization === "geo" || visualization === "geo3d") {
    const configuredLayer = staticConfig?.tileLayers.find((layer) => layer.id === visualizationConfig?.map);
    const baseMap =
      visualizationConfig?.map === "none"
        ? []
        : [configuredLayer ? configuredLayer.id : staticConfig?.defaultTileMap || "osm"];
    if (visualization === "geo") {
      return {
        perspective: "Top",
        markerType: ({ grouped: "Grouped", heatmap: "Heatmap" } as const)[visualizationConfig?.visualization as string],
        layers: [
          ...baseMap,
          ...(visualizationConfig?.orderedLayers?.filter((layer: string) =>
            visualizationConfig?.activeLayers?.includes(layer),
          ) || []),
        ],
      };
    }

    if (visualization === "geo3d") {
      return {
        perspective: "Tilted",
        layers: [...baseMap],
      };
    }
  }
  if (visualization === "geoEvents") {
    const baseMap = staticConfig?.defaultTileMap || "osm";
    return {
      perspective: "Top",
      markerType: "Default",
      layers: [baseMap],
    };
  }
  if (visualization === "network" && visualizationConfig && "networkConfig" in visualizationConfig) {
    return {
      seed: visualizationConfig.networkConfig.seed || undefined,
    };
  }
  return visualizationConfig;
}

export function isGeoCssApplicable(visualization: VisualizationLabel): boolean {
  if (visualization === "Geo") return true;
  return false;
}
export function isTimelineCssApplicable(visualization: VisualizationLabel): boolean {
  if (visualization === "Timeline") return true;
  return false;
}
export function isResponseCssApplicable(visualization: VisualizationLabel): boolean {
  if (visualization === "Response") return true;
  return false;
}

export function isChartCssApplicable(
  visualization: VisualizationLabel,
  visualizationConfig: VisualizationConfig,
): boolean {
  if (
    visualization === "Charts" &&
    visualizationConfig &&
    "chartType" in visualizationConfig &&
    visualizationConfig.chartType &&
    !GOOGLE_CHARTS_TO_IGNORE_DEFAULT_HEIGHT.includes(visualizationConfig.chartType)
  ) {
    return true;
  }
  return false;
}

export function isNetworkCssApplicable(visualization: VisualizationLabel): boolean {
  if (visualization === "Network") return true;
  return false;
}

export function isConstructResponse(value: SparqlResponse | undefined): value is ConstructResults {
  return value !== undefined && Array.isArray(value);
}
export function isSelectResponse(value: SparqlResponse | undefined): value is SelectResult {
  return value !== undefined && !Array.isArray(value) && !("boolean" in value);
}
export function isAskResponse(value: SparqlResponse | undefined): value is AskResult {
  return value !== undefined && !Array.isArray(value) && "boolean" in value;
}

export function extractQueryPrefixes(query?: string): PrefixesArray {
  if (query) {
    try {
      const prefixes = sparqlParsers.lenient(query, { baseIri: "https://triplydb.com/" }).prefixes;
      const queryPrefixes: PrefixesArray = Object.keys(prefixes).map((key) => {
        return {
          iri: prefixes[key],
          prefixLabel: key,
        };
      });
      return queryPrefixes;
    } catch {
      return [];
    }
  }
  return [];
}
