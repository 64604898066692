import * as React from "react";
import { useLocation } from "react-router";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import Debug from "./Debug.tsx";
import FeatureToggles from "./FeatureToggles.tsx";
import Topics from "./Topics.tsx";

const AdminAdvancedSettings: React.FC<{}> = ({}) => {
  const acl = useAcl();

  if (!acl.check({ action: "editInstanceMetadata" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <FlexContainer>
      <AdminAdvancedSettingsMeta />
      <Debug />
      {acl.check({ action: "toggleInstanceFeatures" }).granted && <FeatureToggles />}
      {acl.check({ action: "manageTopics" }).granted && <Topics />}
    </FlexContainer>
  );
};

export default AdminAdvancedSettings;

const AdminAdvancedSettingsMeta: React.FC = () => {
  const location = useLocation(); // Prevent re-rendering of the component when the location changes
  return <Meta currentPath={location.pathname} title="Advanced Settings" />;
};
