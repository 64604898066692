import { Autocomplete, debounce, TextField } from "@mui/material";
import { escapeRegExp, uniqBy } from "lodash-es";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { FormValues, IriProperty } from "../Types";
import { Editor } from "./Editor";

interface Item {
  value: string;
  label?: string;
  description?: string;
}

const AutoCompleteEditor: Editor = {
  Component: ({ name, propertyModel }) => {
    const { control, watch } = useFormContext<FormValues>();
    const val = watch(name);
    const applyPrefixes = useApplyPrefixes();
    const [initialValue] = React.useState<IriProperty | null>(val as IriProperty);
    const [focussed, setFocussed] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<readonly Item[]>([]);

    const currentDs = useCurrentDataset()!;
    const sparqlUrl = useConstructUrlToApi()({
      pathname: `/_console/sparql`,
      fromBrowser: true,
    });

    const sparql = React.useCallback(
      async (query: string, abortSignal: AbortSignal) => {
        const response = await fetch(sparqlUrl, {
          credentials: "same-origin",
          signal: abortSignal,
          method: "POST",
          headers: { Accept: "application/json" },
          body: new URLSearchParams({
            account: currentDs.owner.accountName,
            dataset: currentDs.name,
            queryString: query,
          }),
        });
        if (!response.ok) throw new Error(response.statusText);
        const result = await response.json();
        return result;
      },
      [sparqlUrl, currentDs.owner.accountName, currentDs.name],
    );

    const debouncedQuery = React.useMemo(
      () =>
        debounce(
          (
            { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
            callback: (results?: readonly Item[]) => void,
          ) => {
            searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\");
            sparql(
              `
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              prefix skos: <http://www.w3.org/2004/02/skos/core#>
              prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
              prefix owl: <http://www.w3.org/2002/07/owl#>

              select
                ?value
                ((?label_t) as ?label)
                ((?description_t) as ?description)
              where {
                {
                  select distinct ?value {
                    ?subType rdfs:subClassOf* <${propertyModel.type}> .
                    ?value a ?subType .
                  }
                }

                optional {
                  ?value rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label_t
                }

                filter (regex(?label_t, "${searchTerm}", "i") || regex(str(?value), "${searchTerm}", "i"))

                optional {
                 ?value rdfs:comment|skos:definition ?description_t
                }
              }
              limit 10
              `,
              abortSignal,
            )
              .then((results) => {
                callback(results);
              })
              .catch(() => {});
          },
          400,
        ),
      [sparql, propertyModel.type],
    );

    React.useEffect(() => {
      if (!focussed) {
        return;
      }

      const abortController = new AbortController();
      let active = true;

      if (inputValue === (initialValue?.label || applyPrefixes(initialValue?.value || ""))) {
        setOptions(initialValue?.value ? [initialValue] : []);
      }

      debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Item[]) => {
        if (active) {
          let newOptions: readonly Item[] = [];

          if (initialValue?.value && !inputValue) {
            newOptions = [initialValue];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(uniqBy(newOptions, "value"));
        }
      });

      return () => {
        active = false;
        abortController.abort("Not needed anymore");
      };
    }, [initialValue, inputValue, debouncedQuery, focussed, applyPrefixes]);

    return (
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) => {
            if (!value || !value.value) {
              return "A value is required.";
            }
          },
        }}
        defaultValue={null as any}
        render={({ field: { onChange, ref, ...rest }, fieldState: { error } }) => (
          <Autocomplete
            options={options}
            filterOptions={(x) => x}
            onChange={(_e, option: Item | null) => {
              if (option === null) {
                onChange(null);
                return;
              }
              const value: IriProperty = {
                nodeKind: "IRI",
                value: option.value,
                label: option.label || applyPrefixes(option.value),
                description: option.description,
              };
              onChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...(params as any)}
                error={!!error}
                helperText={
                  error?.message ||
                  (typeof rest.value !== "string" && rest.value?.nodeKind === "IRI" && rest.value.description)
                }
                multiline
                required={propertyModel.required}
                inputRef={ref}
              />
            )}
            noOptionsText=""
            isOptionEqualToValue={(option: any, value: any) => {
              return option.value === value.value;
            }}
            getOptionLabel={(option: any) => {
              return option.label || applyPrefixes(option.value) || "";
            }}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onFocus={() => {
              setFocussed(true);
            }}
            {...(rest as any)}
          />
        )}
      />
    );
  },
  getScore: ({ nodeKind }) => {
    if (nodeKind === "IRI") {
      return 1;
    }
    return 0;
  },
};

export default AutoCompleteEditor;
