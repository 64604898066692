// extracted by mini-css-extract-plugin
var _1 = "styles--baseFormWidth--lkR1GrhV";
var _2 = "styles--configContainer--rpBDvMF6";
var _3 = "styles--configSection--tiaytAKf";
var _4 = "styles--exampleWidget--xV9s5Fwg";
var _5 = "styles--exampleWidgetContainer--e84b97Lv";
var _6 = "styles--gallery--guetPWr4";
var _7 = "styles--galleryCardContent--MhJknG5B";
var _8 = "styles--galleryContainer--ROQgX04C";
var _9 = "styles--galleryFigureCaption--iYbrroaS";
var _a = "styles--missingVar--H4dmZQJB";
var _b = "styles--selectWithEndAdornment--eBDkhfC6";
var _c = "styles--subItemWrapper--gNF5jVIR";
export { _1 as "baseFormWidth", _2 as "configContainer", _3 as "configSection", _4 as "exampleWidget", _5 as "exampleWidgetContainer", _6 as "gallery", _7 as "galleryCardContent", _8 as "galleryContainer", _9 as "galleryFigureCaption", _a as "missingVar", _b as "selectWithEndAdornment", _c as "subItemWrapper" }
