import "#components/index.ts"; // Formfield
import * as React from "react";
// import * as ReactDOM from 'react-dom';
import * as ReduxForm from "redux-form";

class UploadFileField extends React.PureComponent<UploadFileField.Props & ReduxForm.WrappedFieldProps, any> {
  constructor(props: any) {
    super(props);
  }
  onChange = (e: any) => {
    const { submitOnChange } = this.props;
    this.props.input.onChange(e.target.files[0]);
    if (submitOnChange) {
      setTimeout(submitOnChange, 0);
    }
  };

  render() {
    const { style } = this.props;
    //should not pass value to input, as some browsers will throw an error saying that you can only programatically
    //change the value
    const { value, ...input } = this.props.input;
    return <input style={style} type="file" {...input} accept={this.props.accept} onChange={this.onChange} />;
  }
}
namespace UploadFileField {
  export const Field: new () => React.Component<Props & ReduxForm.BaseFieldProps> = ReduxForm.Field as any;
  export interface Props {
    component: any;
    name: string;
    accept?: string;
    style?: React.CSSProperties;
    submitOnChange?: Function;
  }
  export interface Value extends File {}
}
export default UploadFileField;
