import getClassName from "classnames";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { ensureTrailingDot } from "#helpers/utils.ts";
import * as styles from "./style.scss";

function getWidthOfText(txt: string, fontname: string, fontsize: string) {
  let c = document.createElement("canvas");
  let ctx = c.getContext("2d");
  if (ctx) {
    ctx.font = fontsize + " " + fontname;
    return ctx.measureText(txt).width;
  }
}

function autoAdaptWidth(inputElement: HTMLInputElement) {
  const strlen = getWidthOfText(
    // add two characters, to stop the first character to temporarily get pushed out of the input box
    inputElement.value + "WW",

    // would be better to get the fontname and fontsize from the css rules
    "Roboto",
    "16px",
  );

  inputElement.style.width = strlen + "px";
}

class PlainTextField extends React.PureComponent<
  PlainTextField.Props & ReduxForm.WrappedFieldProps,
  PlainTextField.State
> {
  input: HTMLInputElement | undefined;

  componentDidMount() {
    if (this.props.t_autoFocus && this.input) {
      this.input.focus();
      let v = this.input.value;
      this.input.value = "";
      this.input.value = v;
    }
    if (this.props.enableWidthAutoAdapt && this.input) {
      autoAdaptWidth(this.input);
    }
  }
  onKeyUpHandler = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.handleKeyUp) this.props.handleKeyUp(keyEvent);
    if (this.props.enableWidthAutoAdapt) autoAdaptWidth(keyEvent.target as HTMLInputElement);
  };

  render() {
    const shouldRenderError = !!this.props.meta.error && (!this.props.meta.pristine || !!this.props.immediateError);

    return (
      <div className={getClassName(styles.textField, this.props.className, shouldRenderError ? styles.err : null)}>
        <input
          {...this.props.input}
          disabled={this.props.disabled}
          onKeyUp={this.onKeyUpHandler}
          ref={(el) => {
            if (el) this.input = el;
          }}
        />
        {shouldRenderError && <div className={styles.error}>{ensureTrailingDot(this.props.meta.error)}</div>}
      </div>
    );
  }
}

namespace PlainTextField {
  export const Field: new () => React.Component<Props & ReduxForm.BaseFieldProps> = ReduxForm.Field as any;
  export interface Props {
    name: string;
    className?: string;
    component: any;
    disabled?: boolean;
    immediateError?: boolean;
    handleKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
    t_autoFocus?: boolean;
    enableWidthAutoAdapt?: boolean;
  }
  export interface State {}
}

export default PlainTextField;
