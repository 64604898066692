import { Autocomplete, TextField } from "@mui/material";
import { isArray } from "lodash-es";
import * as React from "react";
import { prefixUtils } from "@triply/utils";
import { Prefixes } from "@triply/utils/Models.js";
import fetch from "#helpers/fetch.ts";
import * as styles from "./style.scss";

export interface Props {
  selectGraph: (graph: string) => void;
  selectedGraph: string;
  prefixes: Prefixes;
  termsUrl: string;
}

interface Option {
  full: string;
  prefixed?: string;
}

const GraphSelector: React.FC<Props> = (props) => {
  const [graphs, setGraphs] = React.useState<string[]>([]);
  const ref = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (__SERVER__) {
      console.error("fetch cannot be used on the server");
      return;
    }
    const url = `${props.termsUrl}?pos=graph&limit=10000`;
    fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .then((terms) => {
        if (terms && isArray(terms)) {
          setGraphs(terms);
        }
      })
      .catch(console.error);
  }, [props.termsUrl]);

  return (
    <Autocomplete<Option, false, true>
      multiple={false}
      onChange={(_event, value) => {
        if (value) props.selectGraph(value.full);
        if (ref.current) ref.current.blur();
      }}
      options={graphs.map((graphName) => {
        return {
          full: graphName,
          prefixed: prefixUtils.getPrefixed(graphName, props.prefixes),
        };
      })}
      getOptionLabel={(g) => g.prefixed || g.full}
      isOptionEqualToValue={(o1, o2) => o1.full === o2.full}
      disableClearable
      value={{
        full: props.selectedGraph,
        prefixed: prefixUtils.getPrefixed(props.selectedGraph, props.prefixes),
      }}
      className={styles.graphSelector}
      classes={{ focused: styles.focused, inputRoot: styles.inputRoot, input: styles.input }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Graph"
          placeholder={prefixUtils.getPrefixed(props.selectedGraph, props.prefixes) || props.selectedGraph}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            // Fix typing issues
            width:
              typeof params.inputProps.width === "number" ? `${params.inputProps.width}px` : params.inputProps.width,
            height:
              typeof params.inputProps.height === "number" ? `${params.inputProps.height}px` : params.inputProps.height,
          }}
          inputRef={ref}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};
export default GraphSelector;
