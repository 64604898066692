import * as React from "react";
import { Prompt as ReactRouterPrompt, PromptProps } from "react-router";

const Prompt: React.FC<PromptProps> = (props) => {
  React.useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (props.when === false) return;
      e.preventDefault();
      return (e.returnValue = "Are you sure you want to leave the page?"); // Modern browsers ignore this and show their own message.
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [props.when]);

  return <ReactRouterPrompt {...props} />;
};

export default Prompt;
