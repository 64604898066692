import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Dialog, FontAwesomeRoundIcon, ListWidget } from "#components/index.ts";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { getLoggedInUser, useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  createQuery,
  formDataToUpdateObj as queryFormDataToUpdateObj,
  getQueryRecordForAccount,
} from "#reducers/queries.ts";
import * as styles from "./style.scss";

interface Props {}
interface LocationState {
  createQueryModalShown?: boolean;
  preserveScrollPosition?: boolean;
}
const SideQueries: React.FC<Props> = ({}) => {
  const authenticatedUser = useAuthenticatedUser();

  const { push, goBack, replace } = useHistory<LocationState>();
  const { state: locationState } = useLocation<LocationState>();
  const dispatch = useDispatch();
  const acl = useAcl();
  const datasetSearchUrl = useConstructUrlToApi()({ pathname: "/datasets" });
  const queries = useSelector((state: GlobalState) => {
    const loggedInUser = getLoggedInUser(state);
    const queryRecord = loggedInUser?.accountName
      ? getQueryRecordForAccount(state, loggedInUser.accountName)
      : undefined;
    return (queryRecord?.list && Object.values(queryRecord.list)) || [];
  });
  if (!authenticatedUser) return null;

  const handleCreateQuerySubmit = (data: Forms.QueryMeta.FormData) => {
    if (!authenticatedUser) return;
    return dispatch<typeof createQuery>(createQuery(authenticatedUser.accountName, queryFormDataToUpdateObj(data)))
      .then(({ body: query }) => replace(`/${query.owner.accountName}/-/queries/${query.name}`))
      .catch((e) => {
        if (e?.status == 409) {
          throw new SubmissionError({ name: "URL is already in use." });
        }
        throw new SubmissionError({ _error: e.message });
      });
  };
  const openDialog = () => {
    push({ state: { createQueryModalShown: true, preserveScrollPosition: true } });
  };
  const userAllowedToCreateQuery = acl.check({
    action: "createQuery",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(authenticatedUser),
      accessLevel: "public",
    },
  }).granted;
  return (
    <>
      <ListWidget
        key="yourQueries"
        title="Your queries"
        linkTo={`/${authenticatedUser.accountName}/-/queries`}
        noContentMsg="You don't have any queries yet"
        onAddItem={userAllowedToCreateQuery ? openDialog : undefined}
        addItemTitle="Create query"
        showMoreLink={(!!queries && queries.length > 10 && `/${authenticatedUser.accountName}/-/queries`) || undefined}
      >
        {!!queries &&
          queries.slice(0, 10).map((query) => (
            <div key={query.id}>
              <Link
                to={`/${authenticatedUser.accountName}/-/queries/${query.name}`}
                title={query.displayName || query.name}
                className={getClassName("flex center noLinkDecoration px-3 py-2", styles.listItem)}
              >
                <FontAwesomeRoundIcon
                  aria-label={
                    query.renderConfig?.output === "gchart"
                      ? query.renderConfig.settings?.chartType || "gchart"
                      : query.renderConfig?.output || "unknown"
                  }
                  size="sm"
                  icon={["far", getQueryIcon(query)]}
                  className={getClassName("mr-3", {
                    [styles.flippedIcon]:
                      query.renderConfig?.output === "gchart" &&
                      query.renderConfig?.settings?.chartConfig?.chartType === "BarChart", // since there is no icon for barchart, we use the same as columnchart and flip it
                  })}
                />
                <div className={styles.itemTitle}>{query.displayName || query.name}</div>
              </Link>
            </div>
          ))}
      </ListWidget>
      {authenticatedUser && userAllowedToCreateQuery && (
        <Dialog
          open={!!locationState?.createQueryModalShown}
          onClose={goBack}
          maxWidth="lg"
          fullWidth
          title="Create query"
        >
          <Forms.QueryMeta.QueryMetaForm
            className={getClassName("p-5")}
            initialValues={{
              serviceType: "speedy",
              accessLevel: "private",
            }}
            isNewQuery
            onSubmit={handleCreateQuerySubmit}
            cancelFunction={goBack}
            datasetSearchUrl={datasetSearchUrl}
            owner={authenticatedUser}
          />
        </Dialog>
      )}
    </>
  );
};

export default SideQueries;
