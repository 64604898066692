import { jsonLanguage } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { TabPanel } from "@mui/lab";
import { DialogTitle } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useRouteMatch } from "react-router";
import { CachePolicies } from "use-http";
import { gt } from "@triply/utils/calver.js";
import { Button, Dialog, ErrorPage, FlexContainer, FontAwesomeIcon, LoadingPage } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import ServiceSelector from "./ServiceSelector.tsx";
import useCurrentService from "./useCurrentService.ts";
import YAElQE from "./YAElQE.tsx";
import * as styles from "./style.scss";

const Mappings: React.FC<{ mappingsUrl: string }> = ({ mappingsUrl }) => {
  const { data, loading } = useFetch<any>(mappingsUrl, { cachePolicy: CachePolicies.NO_CACHE, method: "GET" }, []);
  return (
    <>
      {loading && <LoadingPage className={styles.loadingMappings} />}
      <LimitedCodeMirror value={JSON.stringify(data, null, 2) || ""} downloadFileName="mappings.json" />
    </>
  );
};

const Query: React.FC = () => {
  const currentDs = useCurrentDataset();
  const match = useRouteMatch<{ serviceName: string }>();
  const currentService = useCurrentService(match.params.serviceName);
  const [_cookies, setCookie] = useCookies(["ecp"]);
  const [storedQuery, setStoredQuery] = useLocalStorage(`ElasticQE_${currentService?.id}_query`, "");
  const [showIndexModal, setShowIndexModal] = React.useState(false);
  const constructUrlToApi = useConstructUrlToApi();
  const mappingsUrl = constructUrlToApi({
    pathname: `/datasets/${currentDs?.owner.accountName}/${currentDs?.name}/services/${currentService?.name}/mappings`,
  });
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<string | undefined>(undefined);
  const url = useConstructUrlToApi()({
    fullUrl: currentService?.endpoint || "",
  });

  const runQuery = React.useCallback(
    (query: string) => {
      if (!query) return;
      setStoredQuery(query);
      setLoading(true);
      fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then(async (res) => {
          setResponse(
            (res.headers.get("content-type")?.indexOf("application/json") ?? -1) >= 0
              ? JSON.stringify(await res.json(), null, 2)
              : res.statusText,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [url, setStoredQuery],
  );

  React.useEffect(() => {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 6);
    setCookie("ecp", "1", {
      maxAge: (60 * 60 * 24 * 365) / 2, // Keep around for half a year
      expires: expires,
      httpOnly: false,
      sameSite: "strict", // we don't need CORS since this cookie is only used in the frontend
      path: "/",
    });
  }, [setCookie]);

  if (!currentDs || !currentService) return <ErrorPage statusCode={404} />;
  if (!currentService.endpoint) {
    let message;
    switch (currentService.status) {
      case "stopped":
      case "stopping":
        message = "This service has been stopped, and is therefore not accessible";
        break;
      case "starting":
        message = "This service is starting, please try again later";
        break;
      case "removing":
        message = "This service is being removed, it can not be accessed anymore";
        break;
      case "running":
        message = "This service is running, but its endpoint is not available, please try again later";
        break;
      default:
        message = "This service is not able to connect to the endpoint yet, please try again later";
        break;
    }
    return <ErrorPage title="Elasticsearch endpoint not accessible" message={message} />;
  }

  return (
    <>
      <FlexContainer className={styles.searchContainer} innerClassName={styles.resultContainer}>
        <div className={styles.searchFieldWrapper}>
          <div className={getClassName(styles.tabWrapper, styles.query)}>
            <TabPanel value="query" className="px-0">
              <div className="flex wrap center mb-3 gx-5 gy-3">
                <div className={getClassName(styles.searchApi, "grow")}>
                  <ServiceSelector currentServiceName={currentService?.name} />
                  Search API
                  <input
                    style={{ maxWidth: `${currentService.endpoint.length + 4}ch` }}
                    value={currentService.endpoint}
                    readOnly
                    type="text"
                    onFocus={(event) => event.target.select()}
                  />
                </div>
                {currentService.status === "running" &&
                  "version" in currentService &&
                  currentService.version &&
                  (currentService.version === "latest" ||
                    currentService.version === "latest-k8" ||
                    currentService.version === "k8-latest" ||
                    gt(currentService.version, "22.04.0")) && (
                    <>
                      <Button variant="text" size="small" onClick={() => setShowIndexModal(true)}>
                        Show mappings
                      </Button>
                      <Dialog open={showIndexModal} onClose={() => setShowIndexModal(false)} closeButton fullScreen>
                        <DialogTitle>Mappings</DialogTitle>
                        <Mappings mappingsUrl={mappingsUrl} />
                      </Dialog>
                    </>
                  )}
              </div>
              <YAElQE onQuery={runQuery} loading={loading} initialQuery={storedQuery} />
            </TabPanel>
          </div>
        </div>
      </FlexContainer>

      {response && (
        <FlexContainer className="mt-4">
          <LimitedCodeMirror key={response} value={response} downloadFileName="fullQueryResult.json" />
        </FlexContainer>
      )}
    </>
  );
};

export default Query;

const LimitedCodeMirror: React.FC<{ value: string; downloadFileName: string }> = ({ value, downloadFileName }) => {
  const lines = value.split("\n");
  const [downloadUrl, setDownloadUrl] = React.useState<string | undefined>("#");
  React.useEffect(() => {
    // We use a threshold of 1500 and only show 1000 lines when we go over it. This is to prevent the situation where
    // you see only 1000 lines when the full result is, for example, 1001 lines.
    const downloadUrl =
      lines.length > 1500 ? window.URL.createObjectURL(new Blob([value], { type: "application/json" })) : undefined;
    setDownloadUrl(downloadUrl);
    return () => {
      if (downloadUrl) window.URL.revokeObjectURL(downloadUrl);
    };
  }, [value, lines.length]);
  return (
    <>
      <CodeMirror
        className={styles.codemirror}
        value={downloadUrl ? lines.slice(0, 1000).join("\n") : value}
        readOnly
        extensions={[jsonLanguage, githubLight, EditorView.lineWrapping]}
      />
      {downloadUrl && (
        <div className="flex horizontalCenter my-4">
          <Button
            href={downloadUrl}
            LinkComponent={React.forwardRef(({ children, ...props }, ref) => (
              <a {...props} ref={ref} download={downloadFileName} target="_blank">
                {children}
              </a>
            ))}
            startIcon={<FontAwesomeIcon icon="download" />}
            size="small"
            elevation
          >
            Download full result
          </Button>
        </div>
      )}
    </>
  );
};
