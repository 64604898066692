import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Avatar, FontAwesomeIcon, Label, Markdown } from "#components/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import * as styles from "./style.scss";

namespace UserListItem {
  export interface Props {
    /**
     * Generic props
     */
    account: Account;
    className?: string;
    link?: string;
  }
  export interface State {
    showNext?: boolean;
  }
}

class UserListItem extends React.PureComponent<UserListItem.Props, UserListItem.State> {
  private description: HTMLDivElement | undefined;
  state: UserListItem.State = {};

  componentDidMount() {
    if (
      this.description &&
      !this.state.showNext &&
      this.description.clientHeight &&
      this.description.clientHeight >= 180
    ) {
      this.setState({ showNext: true });
    }
  }

  render() {
    const { account, className } = this.props;
    const link = this.props.link || `/${account.accountName}`;
    return (
      <div className={getClassName(className, styles.box)}>
        <div className={styles.colAvatar}>
          {account.accountName && (
            <Avatar
              size="md"
              avatarName={account.accountName}
              avatarUrl={account.avatarUrl}
              className={styles.avatar}
              linkTo={link}
              alt=""
            />
          )}
        </div>
        <div className={styles.colDescription}>
          <div className={styles.accountDetails}>
            <Link to={link} className="p-2">
              <FontAwesomeIcon size="lg" icon={account.type === "user" ? "user" : "users"} className="mx-2" />
              <h4 className={styles.accountTitle}>{account.name || account.accountName}</h4>
            </Link>
            {account.email && <div className={styles.email}>{account.email}</div>}
            <div className={styles.space} />
            {account.type === "user" && account.verified === false && (
              <div className={styles.accountLabel}>
                <Label warning message="Unverified" />
              </div>
            )}
            {account.type === "user" && !!account.disabled && (
              <div className={styles.accountLabel}>
                <Label error message="Account disabled" />
              </div>
            )}
            {account.type === "user" && account.role === "siteAdmin" && (
              <div className={styles.accountLabel}>
                <Label primary message={"Site administrator"} />
              </div>
            )}
            {account.type === "user" && account.role === "superAdmin" && (
              <div className={styles.accountLabel}>
                <Label success message="Super administrator" />
              </div>
            )}
          </div>
          {account.description && (
            <div
              ref={(desc) => {
                if (desc) this.description = desc;
              }}
            >
              <Markdown className={styles.description} compact>
                {account.description}
              </Markdown>
              {this.state.showNext && <Link to={link}>Read further</Link>}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default UserListItem;
