// extracted by mini-css-extract-plugin
var _1 = "description--container--QmEy165N";
var _2 = "description--content--oHB8Yq5P";
var _3 = "description--contentBox--STR2b7qY";
var _4 = "description--contentDragArea--AC6eckoB";
var _5 = "description--description--HXp8tMKX";
var _6 = "description--externalLink--mXpIJTOx";
var _7 = "description--htmlDescription--hLbLfVgz";
var _8 = "description--inLinkProperties--i9zmpF3Y";
var _9 = "description--media--qI6hgQvB";
var _a = "description--navIcon--UBJqhX3J";
var _b = "description--navIconSm--_aB_4j0l";
var _c = "description--noTransition--TXiiISgV";
var _d = "description--outLink--gFfnD4Xf";
var _e = "description--outLinkProperties--JZVN8lah";
var _f = "description--resource--Z6x9zXaJ";
var _10 = "description--resourceContainer--BaQFXAU8";
var _11 = "description--resourceId--rZwJb8DR";
var _12 = "description--resourceIdSmall--vQZ8DNFG";
var _13 = "description--resourceImage--RoVpLJns";
var _14 = "description--type--iTyvM83b";
var _15 = "description--types--rcXWC_db";
export { _1 as "container", _2 as "content", _3 as "contentBox", _4 as "contentDragArea", _5 as "description", _6 as "externalLink", _7 as "htmlDescription", _8 as "inLinkProperties", _9 as "media", _a as "navIcon", _b as "navIconSm", _c as "noTransition", _d as "outLink", _e as "outLinkProperties", _f as "resource", _10 as "resourceContainer", _11 as "resourceId", _12 as "resourceIdSmall", _13 as "resourceImage", _14 as "type", _15 as "types" }
