import { Alert } from "@mui/material";
import * as React from "react";
import { isConstructResponse, SparqlResponse } from "../../../SparqlUtils";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import JsonLDEditor from "./LDFrameEditor";

interface Props {}

export function canDraw(data?: SparqlResponse) {
  if (!data) return false;
  return isConstructResponse(data);
}

export interface PluginConfig {
  frame?: object;
}

const JsonLDViewer = React.lazy(() => import("./JsonLDViewer"));

const JsonLD: React.FC<Props> = ({}) => {
  const { getVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const config = getVisualizationConfig("LDFrame");
  const { isConstruct, emptyResults, noResults } = useSparqlResults();
  const [frameToRender, setFrameToRender] = React.useState(config?.frame || {});

  if (noResults) return null;
  if (!isConstruct) {
    return (
      <Alert severity="warning" role="alert">
        This visualization can only render with Construct queries
      </Alert>
    );
  }
  if (emptyResults) {
    return <EmptyResults />;
  }

  return (
    <div>
      <JsonLDEditor onCommitFrame={setFrameToRender} />
      <React.Suspense>
        <JsonLDViewer frame={frameToRender} />
      </React.Suspense>
    </div>
  );
};

export default JsonLD;
