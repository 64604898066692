import { FormControlLabel, MenuItem, Select } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { Hook } from "@triply/utils/Models.js";
import { validation } from "@triply/utils-private";
import { Button, FormField, MaterialCheckbox, MuiSwitchRedux, MuiTextFieldRedux } from "#components/index.ts";

namespace HookForm {
  export interface FormData extends Hook {}
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    cancelFunction?: (e: React.MouseEvent<any>) => void;
    editFunction?: Function;
    removeFunction?: Function;
    className?: string;
    disableHostnameValidationFor: string[];
  }
  export interface PayloadFieldProps {
    children: any[];
  }
}

const HookForm = ReduxForm.reduxForm<HookForm.FormData, HookForm.Props>({
  validate: memoizee((formData: HookForm.FormData, props: HookForm.Props) => {
    return {
      url:
        validation.nonBlacklistedHttpUrl(props.disableHostnameValidationFor)(formData.url) && "Not a valid HTTP(s)-URL",
    };
  }),
})(
  class HookForm extends React.PureComponent<HookForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>, any> {
    edit = () => this.props.editFunction?.();
    remove = () => this.props.removeFunction?.();

    renderPayloadField = ({ input, children }: ReduxForm.WrappedFieldProps & HookForm.PayloadFieldProps) => {
      return <Select {...input}>{children}</Select>;
    };

    renderActiveField = (props: MuiSwitchRedux.Props) => (
      <div style={{ marginTop: -3, marginLeft: -12, marginBottom: -4 }}>
        <MuiSwitchRedux color="primary" {...props} />
      </div>
    );

    render() {
      const { handleSubmit, cancelFunction, className } = this.props;

      return (
        <div className={getClassName(className)}>
          <form method="POST" onSubmit={handleSubmit} name={this.props.form}>
            <FormField label="Payload target" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="url"
                component={MuiTextFieldRedux}
                props={{
                  fullWidth: true,
                }}
              />
            </FormField>

            <FormField label="Payload format" className="mb-6">
              <ReduxForm.Field name="payloadFormat" component={this.renderPayloadField}>
                <MenuItem value="JSON">JSON</MenuItem>
                <MenuItem value="formEncoded">Form-Encoded</MenuItem>
              </ReduxForm.Field>
            </FormField>

            <FormField label="Trigger events" className="mb-6">
              <div>
                <FormControlLabel
                  label="Graph import"
                  labelPlacement="end"
                  control={<MaterialCheckbox.Field name="onEvents.graphImport" component={MaterialCheckbox} />}
                />
              </div>
              <div>
                <FormControlLabel
                  label="Linked data upload"
                  labelPlacement="end"
                  control={<MaterialCheckbox.Field name="onEvents.linkedDataUpload" component={MaterialCheckbox} />}
                />
              </div>
              <div>
                <FormControlLabel
                  label="Asset upload"
                  labelPlacement="end"
                  control={<MaterialCheckbox.Field name="onEvents.fileUpload" component={MaterialCheckbox} />}
                />
              </div>
            </FormField>

            <FormField label="Webhook is active" className="mb-6">
              <ReduxForm.Field name="active" component={this.renderActiveField} />
            </FormField>

            <div>
              <Button
                type="submit"
                color="secondary"
                disabled={this.props.pristine || this.props.invalid}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              {!!cancelFunction && (
                <Button className="ml-2" onClick={cancelFunction} variant="text">
                  Cancel
                </Button>
              )}
            </div>
          </form>
        </div>
      );
    }
  } as any,
);

export default HookForm;
