import { DialogContent, LinearProgress, Typography } from "@mui/material";
import * as React from "react";
import { Prompt } from "react-router";
import { SocketEvent } from "@triply/utils/SocketEvents.js";
import { Alert, Button, Dialog } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useWebSocket from "#helpers/hooks/useWebSocket.ts";
import { issueServiceCommand, Service } from "#reducers/services.ts";

interface Props {
  open: boolean;
  onClose: () => void;
  services: Service[];
}

type ComponentState = "Verifying" | "Updating" | "Done";
// let interrupted = false;

const BulkUpdateDialog: React.FC<Props> = ({ open, onClose: _onClose, services }) => {
  const [servicesToUpdate, setServicesToUpdate] = React.useState<Service[]>([]);
  const { subscribe, unsubscribe } = useWebSocket();
  const interrupted = React.useRef(false);
  const [restartingServices, setRestartingServices] = React.useState<ComponentState>("Verifying");
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState<string[]>([]);

  const dispatch = useDispatch();

  // Reset component when dialog is opened
  React.useEffect(() => {
    if (open === true) {
      setProgress(0);
      setRestartingServices("Verifying");
      setErrors([]);
      interrupted.current = false;
    }
  }, [open]);
  // Check if a service can be upgraded
  React.useEffect(() => {
    const tempServicesToUpdate: Service[] = [];
    for (const service of services) {
      if (service.canUpdate) {
        tempServicesToUpdate.push(service);
      }
    }
    setServicesToUpdate(tempServicesToUpdate);
  }, [services]);
  const onClose = () => {
    if (restartingServices === "Updating") {
      interrupted.current = true;
      setRestartingServices("Done");
    }
    _onClose();
  };
  const updateServices = async () => {
    if (!open) return;
    setRestartingServices("Updating");
    for (const service of servicesToUpdate) {
      if (!open || interrupted.current) return;
      if (!service.dataset) throw new Error("Can't update a service with dataset information missing");
      let updateSuccess: (value?: void) => void;
      let updateFail: (e: unknown) => void;
      const servicePromise = new Promise<void>((resolve, reject) => {
        updateSuccess = resolve;
        updateFail = reject;
      });
      const handler = (event: SocketEvent) => {
        if (event.eventType !== "serviceMetadataUpdate") return;
        if (event.status === "error") {
          updateFail("Failed updating service");
        } else if (event.status === "running" && !interrupted.current) {
          setProgress((progress) => progress + 1);
          updateSuccess();
        }
      };
      subscribe(`/datasets/${service.dataset?.id}`, `/services/${service.id}/metadata`, handler);
      // We want redux to subscribe to update the table
      subscribe(`/datasets/${service.dataset?.id}`, `/services/${service.id}/metadata`);
      await dispatch<typeof issueServiceCommand>(
        issueServiceCommand(service.dataset!.owner, service.dataset!, service, "restart"),
      )
        .then(() => servicePromise)
        .catch((e) => {
          if (!interrupted.current)
            setErrors((errors) => [
              ...errors,
              `Failed updating ${service.dataset?.owner.accountName}/${service.dataset?.name}/${service.name} : ${e.message}`,
            ]);
        });
      // No reason to listen anymore
      unsubscribe(`/datasets/${service.dataset?.id}`, `/services/${service.id}/metadata`);
      unsubscribe(`/datasets/${service.dataset?.id}`, `/services/${service.id}/metadata`, handler);
    }
    setRestartingServices("Done");
  };
  return (
    <Dialog open={open} onClose={onClose} title="Update service version">
      <Prompt
        when={restartingServices === "Updating"}
        message={() => {
          // Don't prompt when we're navigating to the confirmation dialog
          return "Moving away will interrupt the updating process. Are you sure?";
        }}
      />

      <DialogContent>
        {restartingServices === "Verifying" && (
          <>
            {services.length !== servicesToUpdate.length && (
              <Alert warning message="Some selected services cannot be updated at this time" />
            )}
            <Typography>The following services will be updated</Typography>

            <ul>
              {servicesToUpdate.map((service) => (
                <li key={service.id}>
                  {service.dataset?.owner.accountName}/{service.dataset?.name}/{service.name}
                </li>
              ))}
            </ul>
          </>
        )}
        {restartingServices === "Updating" && (
          <>
            <Typography>Updating services, closing this window will interrupt the process</Typography>
            <LinearProgress variant="determinate" value={(progress / servicesToUpdate.length) * 100} />
          </>
        )}
        {restartingServices === "Done" && <>Successfully updated {progress} services</>}
        {errors.map((error, idx) => {
          return <Alert className="my-2" key={`service-update-error-${idx}`} message={error} />;
        })}
      </DialogContent>
      <div className="m-5">
        {restartingServices === "Verifying" && (
          <Button color="warning" onClick={updateServices} className="mr-4" disabled={servicesToUpdate.length === 0}>
            Update ({servicesToUpdate.length}) services
          </Button>
        )}
        <Button variant="text" onClick={onClose}>
          {restartingServices === "Done" ? "Close" : "Cancel"}
        </Button>
      </div>
    </Dialog>
  );
};

export default BulkUpdateDialog;
