import { factories } from "@triplydb/data-factory";

const factory = factories.compliant;

export const DATATYPE_SUGGESTIONS: { label: string; iri: string }[] = [
  { label: "Boolean", iri: "http://www.w3.org/2001/XMLSchema#boolean" },
  { label: "Date", iri: "http://www.w3.org/2001/XMLSchema#date" },
  { label: "Date-time", iri: "http://www.w3.org/2001/XMLSchema#dateTime" },
  { label: "Double", iri: "http://www.w3.org/2001/XMLSchema#double" },
  { label: "Float", iri: "http://www.w3.org/2001/XMLSchema#float" },
  { label: "Year", iri: "http://www.w3.org/2001/XMLSchema#gYear" },
  { label: "Int", iri: "http://www.w3.org/2001/XMLSchema#int" },
  { label: "Integer", iri: "http://www.w3.org/2001/XMLSchema#integer" },
  { label: "String", iri: "http://www.w3.org/2001/XMLSchema#string" },
];

export function validateIri(value: string | undefined | null) {
  if (typeof value === "string") {
    try {
      factory.namedNode(value.trim());
    } catch (e: any) {
      console.info(`Parsing IRI '${value}' failed: ${e?.message}`);
      return "Not a valid IRI";
    }
  } else {
    // try {
    //   if (value?.datatype) factory.literal(value.id, factory.namedNode(value.datatype!));
    // } catch (e: any) {
    //   const { prefix, label } = getPrefixAndLabel(prefixes, value!.datatype!);
    //   console.error(e);
    //   return `Not a valid ${prefix ? `${prefix}:${label}` : label}.`;
    // }
  }
}
