// included by default:
// clike, css, html, javascript, js, markup, mathml, svg, xml
// NB: these require statements should match the supportedMarkdownLanguages list in helpers.ts

// NB : prism highlighters may extend each other.  For example, sparql extends turtle.
// This can be seen in node_modules/prismjs/components/prism-sparql.min.js, which includes
// `Prism.languages.extend('turtle', ...`
// When this is the case, the extended highlighter must also be included,
// and it must be required before the extension.
// Failing to do this leads to error `Cannot set property 'variable' of undefined` in prism.js

import "prismjs";
import "prismjs/components/prism-bash.min.js";
import "prismjs/components/prism-c.min.js";
import "prismjs/components/prism-cpp.min.js";
import "prismjs/components/prism-csharp.min.js";
import "prismjs/components/prism-ebnf.min.js";
import "prismjs/components/prism-go.min.js";
import "prismjs/components/prism-haskell.min.js";
import "prismjs/components/prism-java.min.js";
import "prismjs/components/prism-json.min.js";
import "prismjs/components/prism-latex.min.js";
import "prismjs/components/prism-makefile.min.js";
import "prismjs/components/prism-markdown.min.js";
import "prismjs/components/prism-objectivec.min.js";
import "prismjs/components/prism-pascal.min.js";
import "prismjs/components/prism-perl.min.js";
import "prismjs/components/prism-powershell.min.js";
import "prismjs/components/prism-prolog.min.js";
import "prismjs/components/prism-python.min.js";
import "prismjs/components/prism-r.min.js";
import "prismjs/components/prism-regex.min.js";
import "prismjs/components/prism-ruby.min.js";
import "prismjs/components/prism-scala.min.js";
import "prismjs/components/prism-sql.min.js";
import "prismjs/components/prism-turtle.min.js";
import "prismjs/components/prism-sparql.min.js";
import "prismjs/components/prism-typescript.min.js";
import "prismjs/components/prism-yaml.min.js";

//Should be the same as list above
const supportedPrismClassNames = [
  "language-bash",
  "language-c",
  "language-cpp",
  "language-csharp",
  "language-ebnf",
  "language-go",
  "language-haskell",
  "language-java",
  "language-json",
  "language-latex",
  "language-makefile",
  "language-markdown",
  "language-objectivec",
  "language-pascal",
  "language-perl",
  "language-powershell",
  "language-prolog",
  "language-python",
  "language-r",
  "language-regex",
  "language-ruby",
  "language-scala",
  "language-sql",
  "language-sparql",
  "language-turtle",
  "language-typescript",
  "language-yaml",
  // languages supported by default
  "language-clike",
  "language-css",
  "language-html",
  "language-javascript",
  "language-js",
  "language-markup",
  "language-mathml",
  "language-svg",
  "language-xml",
];
export function isAllowedPrismClassName(className: string) {
  return isPrismClassName(className) && supportedPrismClassNames.indexOf(className) >= 0;
}
export function isPrismClassName(className: string) {
  return className.indexOf("language-") === 0;
}
