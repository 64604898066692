import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { Dataset } from "@triply/utils/Models.js";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";

export const LocalActions = {
  GET_REDIRECTS: "triply/redirects/GET_REDIRECTS",
  GET_REDIRECTS_SUCCESS: "triply/redirects/GET_REDIRECTS_SUCCESS",
  GET_REDIRECTS_FAIL: "triply/redirects/GET_REDIRECTS_FAIL",

  PUT_REDIRECTS: "triply/redirects/PUT_REDIRECTS",
  PUT_REDIRECTS_SUCCESS: "triply/redirects/PUT_REDIRECTS_SUCCESS",
  PUT_REDIRECTS_FAIL: "triply/redirects/PUT_REDIRECTS_FAIL",
} as const;

type GET_REDIRECTS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_REDIRECTS,
      typeof LocalActions.GET_REDIRECTS_SUCCESS,
      typeof LocalActions.GET_REDIRECTS_FAIL,
    ];
  },
  Routes.admin.redirects.Get
>;

type PUT_REDIRECTS = GlobalAction<
  {
    types: [
      typeof LocalActions.PUT_REDIRECTS,
      typeof LocalActions.PUT_REDIRECTS_SUCCESS,
      typeof LocalActions.PUT_REDIRECTS_FAIL,
    ];
    redirects: Redirects;
  },
  Routes.admin.redirects.Put
>;

export type LocalAction = GET_REDIRECTS | PUT_REDIRECTS;

export type Redirects = Models.RedirectRule[];

export interface State {
  list: Redirects;
}

//The immer producer has useful generics, but these'll cause `Type instantiation is excessively deep and possibly infinite.`
//typescript errors, or possible out-of-memory errors caused by the `Immutable<>` immer typings on our state.
//So, avoid using the immer typings, and add our own for now. We can try to move to the generics in a later stage
//when either typescript or immer solved this issue
export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.PUT_REDIRECTS:
        draftState.list = [...action.redirects];
        return;

      case Actions.PUT_REDIRECTS_SUCCESS:
      case Actions.GET_REDIRECTS_SUCCESS:
        draftState.list = action.result;
        return;
    }
  },
  <State>{
    list: [],
  },
) as any;

export function needToFetchRedirects(state: GlobalState) {
  return !state.redirects.list.length;
}

export function getRedirects(): BeforeDispatch<GET_REDIRECTS> {
  return {
    types: [Actions.GET_REDIRECTS, Actions.GET_REDIRECTS_SUCCESS, Actions.GET_REDIRECTS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/redirects",
        method: "get",
      }),
  };
}

export function putRedirects(redirects: Redirects): BeforeDispatch<PUT_REDIRECTS> {
  return {
    types: [Actions.PUT_REDIRECTS, Actions.PUT_REDIRECTS_SUCCESS, Actions.PUT_REDIRECTS_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/admin/redirects",
        method: "put",
        body: redirects.map((redirect) => {
          return {
            ...redirect,
            toDataset: (<Dataset>redirect.toDataset).id,
          };
        }),
      }),
    redirects,
  };
}
