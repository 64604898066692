import { Alert } from "@mui/material";
import * as React from "react";
import { DashViewer, DashViewerProps } from ".";

const XSD_ANY_URI = "http://www.w3.org/2001/XMLSchema#anyURI";

const HyperlinkViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "Literal" || property.datatype !== XSD_ANY_URI)
    return <Alert severity="error">Wrongly configured Hyperlink</Alert>;
  return (
    <a href={property.value} rel="noopener noreferrer">
      {property.value}
    </a>
  );
};

const HyperlinkViewer: DashViewer = {
  component: HyperlinkViewerComponent,
  getScore({ datatype }) {
    if (datatype === XSD_ANY_URI) return 50;
    return 0;
  },
};

export default HyperlinkViewer;
