import { CircularProgress, Container } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { factories } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { Button, Dialog, FontAwesomeIcon } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import useFetchInitialValues from "../InstanceForm/useFetchInitialValues";

const factory = factories.compliant;

const CopyResource: React.FC<{}> = ({}) => {
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const history = useHistory();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const fetchInitialValues = useFetchInitialValues();

  const authorAcc = useAuthenticatedUser();
  const resource = parseSearchString(useLocation().search).resource as string;

  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;

  const dispatch = useDispatch();

  return (
    <>
      <Button
        color="info"
        elevation
        onClick={async (e) => {
          // e.preventDefault();
          // setSaving(true);
          //
          // const values = await fetchInitialValues(resource);
          // const id = factory.namedNode(`${datasetUrl}/id/${Date.now()}`);
          // const properties = values.properties.map((p: any) => {
          //   let value = `(<${p.property!.id}> `;
          //   if (p.value?.datatype) {
          //     value += `"${p.value.id.trim()}"`;
          //     p.value.datatype === "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString"
          //       ? (value += "@nl")
          //       : (value += `^^<${p.value.datatype}>`);
          //   } else {
          //     value += `<${p.value?.id.trim() || ""}>`;
          //   }
          //   return value + ")";
          // });
          //
          // const query = dedent`
          //       prefix skos: <http://www.w3.org/2004/02/skos/core#>
          //       prefix vs: <http://www.w3.org/2003/06/sw-vocab-status/ns#>
          //       prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
          //       prefix dct: <http://purl.org/dc/terms/>
          //       insert {
          //         ?id a <${values.type!.id}>;
          //             vs:term_status "draft"@nl;
          //             rdfs:isDefinedBy <${factory.namedNode(authorUrl).id}>;
          //             dct:created ?creationDate;
          //             ?predicate ?object;
          //       } where {
          //         bind(<${id.id}> as ?id)
          //         bind(now() as ?creationDate)
          //         values (?predicate ?object) {
          //           ${properties.join("\n\t\t")}
          //         }
          //       }`;
          //
          // const body = new FormData();
          // body.set("update", query);
          //
          // await fetch(updateUrl, {
          //   credentials: "same-origin",
          //   method: "POST",
          //   body: body,
          // });
          //
          // await dispatch<typeof refreshDatasetsInfo>(
          //   refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          // );
          // await dispatch<typeof getGraphs>(
          //   getGraphs({
          //     accountName: currentDs.owner.accountName,
          //     datasetName: currentDs.name,
          //     datasetId: currentDs.id,
          //   }),
          // );
          // setSaving(false);
          // history.push({
          //   search: stringifyQuery({ resource: id.value }),
          // });
        }}
        title="Copy instance"
        startIcon={<FontAwesomeIcon icon="copy" />}
        size="small"
      >
        Copy
      </Button>
      <Dialog open={saving} maxWidth="sm" title="Copying resource">
        <Container className="pb-3">
          <CircularProgress />
        </Container>
      </Dialog>
    </>
  );
};

export default CopyResource;
