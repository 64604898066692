import React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Button, PlainTextField } from "#components/index.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import * as styles from "./style.scss";

export namespace RenameServiceForm {
  export interface FormData {
    name: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    handleCancel: (e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent) => void;
    otherServiceNames: Array<string>;
    serviceName: string;
  }
}

const _RenameService: React.FC<RenameServiceForm.FormData & RenameServiceForm.Props> = ({
  handleSubmit,
  initialValues,
  submitting,
  pristine,
  invalid,
  handleCancel,
}) => {
  const formElRef = React.useRef<HTMLFormElement>(null);
  useClickOutside(handleCancel, formElRef);
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // cancel upon hit ESCAPE key
    if (event.key === "Escape") {
      handleCancel(event);
    }
  };
  return (
    <>
      <form className={styles.container} method="POST" onSubmit={handleSubmit} ref={formElRef}>
        <div className={styles.formInput}>
          <ReduxForm.Field
            name="name"
            component={PlainTextField}
            placeholder={initialValues?.name}
            className={styles.textInput}
            t_autoFocus={true}
            handleKeyUp={handleKeyUp}
          />
          <div className={styles.buttons}>
            <LoadingButton
              type="submit"
              color="secondary"
              size="small"
              disabled={pristine || invalid || submitting}
              loading={submitting}
            >
              Save
            </LoadingButton>
            <Button size="small" variant="text" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
const serviceNameValidator = validation.toStringValidator(validation.serviceNameValidations);
const RenameService = ReduxForm.reduxForm<RenameServiceForm.FormData, RenameServiceForm.Props>({
  validate: (formData: RenameServiceForm.FormData, props: RenameServiceForm.Props) => {
    const serviceNameIsInvalidMessage = serviceNameValidator(formData.name);
    const serviceNameAlreadyTakenMessage = props.otherServiceNames.includes(formData.name?.toLowerCase())
      ? "A service with this name already exists"
      : undefined;
    return {
      name: serviceNameIsInvalidMessage || serviceNameAlreadyTakenMessage,
    };
  },
})(_RenameService as any);
export default RenameService;
