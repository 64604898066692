import { Card, CardActionArea, CardContent, CardHeader, CardMedia } from "@mui/material";
import * as React from "react";
import { Term } from "#components/Sparql/SparqlUtils.ts";
import { SanitizedContent } from "../displayUtils";
import { PluginConfig } from ".";
import * as styles from "./styles.scss";

export interface Props {
  config: PluginConfig;
  body?: Term;
  label?: {
    text?: Term;
    link?: Term;
  };
  image?: {
    src?: Term;
    caption?: Term;
  };
}

const Item: React.FC<Props> = ({ image, label, body, config }) => {
  return (
    <Card variant="outlined">
      {label?.text && <GalleryCardHeader label={label.text} link={label.link} />}
      {image?.src && (
        <figure>
          <CardMedia component="img" src={image.src.value} alt={""} />
          {image.caption?.value && (
            <CardContent component="figcaption" className={styles.galleryFigureCaption}>
              <SanitizedContent term={image.caption} renderAs={config.image?.caption?.renderer || undefined} />
            </CardContent>
          )}
        </figure>
      )}
      {body?.value && (
        <CardContent className={styles.galleryCardContent}>
          <SanitizedContent term={body} renderAs={config?.body?.renderer || undefined} />
        </CardContent>
      )}
    </Card>
  );
};

const GalleryCardHeader: React.FC<{ label: Term; link?: Term }> = ({ label, link }) => {
  if (link) {
    return (
      <CardActionArea href={link.value} target="_blank" rel="noopener noreferrer">
        <CardHeader title={label.value} />
      </CardActionArea>
    );
  } else {
    return <CardHeader title={label.value} />;
  }
};

export default Item;
