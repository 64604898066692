import { Paper } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { Alert, Button } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import { accountHasPasswordAuthentication } from "#reducers/accounts.ts";
import { resetPassword } from "#reducers/auth.ts";
import { showNotification } from "#reducers/notifications.ts";
import ConfigureMfa from "./ConfigureMfa.tsx";
import RevokeSessions from "./RevokeSessions.tsx";

const Security: React.FC<{ currentAccount: Account; loggedInUser?: User }> = ({ currentAccount, loggedInUser }) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const location = useLocation();
  const query = parseSearchString(location.search);
  const disabled2Fa = query.disabled2fa === "1";

  React.useEffect(() => {
    if (!currentAccount.mfaEnabled && disabled2Fa) {
      dispatch(
        showNotification("A recovery code was recently used, which disabled two-factor authentication.", "warning"),
      );
    }
  }, [currentAccount.mfaEnabled, disabled2Fa, dispatch]);

  return (
    <div className="whiteSink">
      <h3>Security</h3>
      {accountHasPasswordAuthentication(currentAccount) && (
        <Paper variant="outlined" className="p-3">
          <h4>Change password</h4>
          <Forms.ResetPassword
            key={currentAccount.accountName + "_ResetPasswordForm"}
            form={currentAccount.accountName + "_ResetPasswordForm"}
            askForCurrentPw={
              !acl.check({
                action: "setNewPasswordWithoutCurrentPassword",
                context: { changingSelf: loggedInUser?.accountName === currentAccount.accountName },
              }).granted
            }
            onSubmit={(values: Forms.ResetPassword.FormData) => {
              return dispatch<typeof resetPassword>(
                resetPassword({ currentPassword: values.currentPassword }, values.password, currentAccount),
              ).catch((e: any) => {
                throw new SubmissionError({ _error: e.message });
              });
            }}
            submitBehavior="resetForm"
            email={currentAccount.email}
          />
        </Paper>
      )}

      <Paper variant="outlined" className="p-3">
        <h4 className="mb-2">Two-factor authentication</h4>
        <p className="mb-3">
          Two-factor authentication is currently{" "}
          <strong>{currentAccount.mfaEnabled ? "enabled" : "not enabled"}</strong>
        </p>
        {!currentAccount.mfaEnabled && disabled2Fa && (
          <Alert
            className="mb-6"
            warning
            message="A recovery code was recently used, which disabled two-factor authentication."
          />
        )}
        <ConfigureMfa />
      </Paper>
      <Paper variant="outlined" className="p-3">
        <h4 className="mb-2 mb-3">Active web sessions</h4>
        <RevokeSessions />
      </Paper>
    </div>
  );
};

export default Security;
