import { InputAdornment, TextField } from "@mui/material";
import { Column } from "@tanstack/react-table";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";

const TextFilter: React.FC<{ column: Column<any, string> }> = ({ column }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const debouncedSetFilterValue = useDebounce(() => column.setFilterValue(inputRef.current?.value), 200);
  return (
    <TextField
      onChange={() => debouncedSetFilterValue()}
      placeholder="filter"
      onClick={(e) => e.stopPropagation()}
      defaultValue={column.getFilterValue() || ""}
      fullWidth
      InputProps={{
        inputRef: inputRef,
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => {
              if (inputRef.current) inputRef.current.value = "";
              column.setFilterValue("");
            }}
            title="Reset filter"
          >
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon="times" />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default TextFilter;
