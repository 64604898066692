import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Meta } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";

const DATE_FORMAT = "YYYY-MM-DD"; // ISO-8601 date format

const StoryMetaData = () => {
  const constructConsoleUrl = useConstructConsoleUrl();
  const owner = useCurrentAccount();
  const story = useSelector((state: GlobalState) => state.stories.current);
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  if (!story || !owner) return null;
  const ownerName = owner.name || owner.accountName;
  const storyName = story.displayName || story.name;
  const title = `${storyName} by ${owner.type === "org" ? "organization" : "user"} ${ownerName} on ${brandingName}.`;
  const description = title;
  const storyUrl = constructConsoleUrl({ pathname: `/${owner.accountName}/-/stories/${story.name}` });
  const accountUrl = constructConsoleUrl({ pathname: `/${owner.accountName}` });
  const publisher = {
    "@type": owner.type === "org" ? "Organization" : "Person",
    "@id": accountUrl,
    name: ownerName,
  };

  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: storyUrl,
    mainEntity: filterEmptyVals({
      "@id": storyUrl,
      "@type": "Article",
      dateModified: moment(story.updatedAt).format(DATE_FORMAT),
      datePublished: moment(story.createdAt).format(DATE_FORMAT),
      description: description,
      identifier: storyUrl,
      name: storyName,
      creator: publisher,
      url: storyUrl,
      accessMode: "textual",
      image: story.bannerUrl || logoLg,
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@property='og:description']/@content"],
    },
  };
  return (
    <Meta
      title={`${storyName} - ${ownerName}`}
      currentPath={`/${owner.accountName}/-/stories/${story.name}`}
      longTitle={title}
      jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]}
    />
  );
};
export default React.memo(StoryMetaData);
