import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { asyncConnect } from "redux-connect";
import { ErrorPage } from "#components/index.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { setLastDataEditorResource } from "#reducers/datasets.ts";
import { Dispatched, GlobalState } from "#reducers/index.ts";
import {
  classIsLoadedFore,
  descriptionIsLoadedFor,
  getClassDescription,
  getDescription,
} from "#reducers/resourceEditorDescriptions.ts";
import { getTerms } from "#reducers/triples.ts";
import InstanceView from "../InstanceView";
import Meta from "./Meta.tsx";
import SkosTree from "./SkosTree.tsx";
import * as styles from "./style.scss";

const Container: React.FC<{}> = ({}) => {
  const resource = useCurrentResource();
  const ds = useCurrentDataset();
  const account = useCurrentAccount();
  const resourceDescription = useSelector((state: GlobalState) =>
    !!ds && !!resource ? state.resourceEditorDescriptions[ds!.id]?.resources?.[resource] : undefined,
  );
  if (!account || !ds || !resourceDescription) {
    return <ErrorPage statusCode={404} />;
  }
  return (
    <div className={getClassName("flex g-3 p-3", styles.skos)}>
      <Meta currentAccount={account} currentDs={ds} resourceDescription={resourceDescription} />
      <SkosTree />
      <InstanceView
        resource={resource}
        dsId={ds.id}
        className={styles.instancePaper}
        innerClassName={styles.instanceScroll}
      />
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: async ({ location, store: { dispatch, getState }, helpers: { redirect } }) => {
      try {
        const state: GlobalState = getState();
        const query = parseSearchString(location.search);
        const currentDs = getCurrentDataset(state);
        if (!state.config.staticConfig) return;

        if (currentDs) {
          let resource: string | undefined;
          if (typeof query.resource === "string" && query.resource.length) {
            /**
             * We've got request a resource via url arguments
             */
            resource = query.resource;
          } else if (currentDs.exampleResources.length) {
            /**
             * Choose a resource from example resources
             */
            resource = currentDs.exampleResources[0];
            return redirect(
              `/${currentDs.owner.accountName}/${currentDs.name}/data-editor/skos?${stringifyQuery({ resource: resource })}`,
            );
          } else if (currentDs.graphCount) {
            /**
             * Choose a random resource
             */

            const terms = await (dispatch<any>(
              getTerms(currentDs, { pos: "subject", termType: "NamedNode" }),
            ) as Dispatched<typeof getTerms>);

            if (terms.body[0]) {
              return redirect(
                `/${currentDs.owner.accountName}/${currentDs.name}/data-editor/skos?${stringifyQuery({
                  resource: terms.body[0],
                })}`,
              );
            }
          }
          if (resource && state.datasets[currentDs.id].lastDataEditorResource !== resource) {
            /**
             * Mark this resource as the last data editor resource visited
             */
            dispatch(setLastDataEditorResource(currentDs.id, resource));
          }
          if (
            resource &&
            !descriptionIsLoadedFor({
              state: state.resourceEditorDescriptions,
              dataset: currentDs,
              resource: resource,
            })
          ) {
            /**
             * Fetch resource description when needed
             */
            await dispatch<any>(getDescription({ dataset: currentDs, resource: resource }));
          }
          const res = !!resource && getState().resourceEditorDescriptions[currentDs.id]?.resources?.[resource];
          if (!res) return;
          const classIri = res.type;
          const promises: Promise<any>[] = [];
          const classesOfBnodes = new Set<string>();
          if (
            classIri &&
            !classIsLoadedFore({
              state: state.resourceEditorDescriptions,
              dataset: currentDs,
              classIri: classIri,
            })
          ) {
            classesOfBnodes.add(classIri);
          }
          for (const propertyArray of Object.values(res.properties || {})) {
            for (const property of propertyArray) {
              if (
                property.nodeKind === "BlankNode" &&
                property.type &&
                !classIsLoadedFore({
                  state: state.resourceEditorDescriptions,
                  dataset: currentDs,
                  classIri: property.type,
                })
              ) {
                classesOfBnodes.add(property.type);
              }
            }
          }
          classesOfBnodes.forEach((classIri) =>
            promises.push(dispatch<any>(getClassDescription({ dataset: currentDs, classIri: classIri }))),
          );
          await Promise.all(promises);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
])(Container);
