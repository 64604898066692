import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeRoundIcon, Markdown } from "#components/index.ts";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import { Query } from "#reducers/queries.ts";
import * as styles from "./style.scss";

interface Props {
  query: Query;
  className?: string;
  style?: React.CSSProperties;
}

const QueryWidget: React.FC<Props> = ({ query, className, style }) => {
  const queryLink = `/${query.owner.accountName}/-/queries/${query.name}`;
  if (!query) return null;
  return (
    <div className={getClassName(className, "p-5", styles.queryWidget)} style={style}>
      <div className={getClassName("flex", "center", styles.header)}>
        <Link
          to={queryLink}
          aria-label={query.displayName || query.name}
          className={getClassName("flex", styles.titleRow)}
        >
          <FontAwesomeRoundIcon
            icon={getQueryIcon(query)}
            className={styles.queryTypeIcon}
            size="md"
            aria-label={
              query.renderConfig?.output === "gchart"
                ? query.renderConfig.settings?.chartType || "gchart"
                : query.renderConfig?.output || "unknown"
            }
          />
          <h2 className={getClassName("ml-3")}>{query.displayName || query.name}</h2>
        </Link>
      </div>
      <div className={getClassName("mt-3")}>
        <Markdown>{query.description}</Markdown>
      </div>
      {query.dataset && (
        <em className={getClassName("pt-5 break", styles.footer)}>
          Uses data from{" "}
          <Link to={`/${query.dataset.owner.accountName}/${query.dataset.name}`}>
            {query.dataset.displayName || query.dataset.name}
          </Link>{" "}
          by{" "}
          <Link to={`/${query.dataset.owner.accountName}`}>
            {query.dataset.owner.name || query.dataset.owner.accountName}
          </Link>
        </em>
      )}
    </div>
  );
};
export default QueryWidget;
