import { StreamLanguage } from "@codemirror/language";
import { turtle } from "@codemirror/legacy-modes/mode/turtle";
import { linter } from "@codemirror/lint";
import { lineNumbers } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import * as React from "react";
import { ErrorResponse } from "@triply/utils/Models.js";
import * as styles from "./style.scss";

const ParseErrorContext: React.FC<{ error: ErrorResponse }> = ({ error }) => {
  if (!error?.parsingContext?.lines) return null;
  const { line, lines } = error.parsingContext;
  const lineStart = +Object.keys(lines)[0];
  return (
    <CodeMirror
      readOnly
      className={styles.codemirror}
      value={Object.values(lines).join("\n")}
      basicSetup={{
        lineNumbers: false,
      }}
      extensions={[
        StreamLanguage.define(turtle),
        lineNumbers({ formatNumber: (line) => line + lineStart - 1 + "" }), // Linecount in CM starts at 1
        linter((view) => {
          const { from, to } = view.state.doc.line(line - lineStart + 1); // Line selection starts at 0
          return [
            {
              from,
              to,
              message: error.message,
              severity: "error",
            },
          ];
        }),
      ]}
    />
  );
};
export default ParseErrorContext;
