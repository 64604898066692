import { produce } from "immer";
import { isEqual, reduce } from "lodash-es";
import { useSelector } from "react-redux";
import { Models, Routes } from "@triply/utils";
import * as Forms from "#components/Forms/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";

export const LocalActions = {
  GET_STORIES: "triply/stories/GET_STORIES",
  GET_STORIES_SUCCESS: "triply/stories/GET_STORIES_SUCCESS",
  GET_STORIES_FAIL: "triply/stories/GET_STORIES_FAIL",
  GET_STORY: "triply/stories/GET_STORY",
  GET_STORY_SUCCESS: "triply/stories/GET_STORY_SUCCESS",
  GET_STORY_FAIL: "triply/stories/GET_STORY_FAIL",
  CHECK_STORY_AVAILABILITY: "triply/stories/CHECK_STORY_AVAILABILITY",
  CHECK_STORY_AVAILABILITY_SUCCESS: "triply/stories/CHECK_STORY_AVAILABILITY_SUCCESS",
  CHECK_STORY_AVAILABILITY_FAIL: "triply/stories/CHECK_STORY_AVAILABILITY_FAIL",
  CREATE_STORY: "triply/stories/CREATE_STORY",
  CREATE_STORY_SUCCESS: "triply/stories/CREATE_STORY_SUCCESS",
  CREATE_STORY_FAIL: "triply/stories/CREATE_STORY_FAIL",
  COPY_STORY: "triply/stories/COPY_STORY",
  COPY_STORY_SUCCESS: "triply/stories/COPY_STORY_SUCCESS",
  COPY_STORY_FAIL: "triply/stories/COPY_STORY_FAIL",
  CHOWN_STORY: "triply/stories/CHOWN_STORY",
  CHOWN_STORY_SUCCESS: "triply/stories/CHOWN_STORY_SUCCESS",
  CHOWN_STORY_FAIL: "triply/stories/CHOWN_STORY_FAIL",
  UPDATE_STORY: "triply/stories/UPDATE_STORY",
  UPDATE_STORY_SUCCESS: "triply/stories/UPDATE_STORY_SUCCESS",
  UPDATE_STORY_FAIL: "triply/stories/UPDATE_STORY_FAIL",
  DELETE_STORY: "triply/stories/DELETE_STORY",
  DELETE_STORY_SUCCESS: "triply/stories/DELETE_STORY_SUCCESS",
  DELETE_STORY_FAIL: "triply/stories/DELETE_STORY_FAIL",
  UPLOAD_BANNER: "triply/app/UPLOAD_BANNER",
  UPLOAD_BANNER_SUCCESS: "triply/app/UPLOAD_BANNER_SUCCESS",
  UPLOAD_BANNER_FAIL: "triply/app/UPLOAD_BANNER_FAIL",
} as const;

type GET_STORIES = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_STORIES,
      typeof LocalActions.GET_STORIES_SUCCESS,
      typeof LocalActions.GET_STORIES_FAIL,
    ];
    listFor: ListFor | undefined;
    append: boolean;
  },
  Routes.stories._account.Get
>;

type GET_STORY = GlobalAction<
  {
    types: [typeof LocalActions.GET_STORY, typeof LocalActions.GET_STORY_SUCCESS, typeof LocalActions.GET_STORY_FAIL];
  },
  Routes.stories._account._story.Get
>;

type CREATE_STORY = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_STORY,
      typeof LocalActions.CREATE_STORY_SUCCESS,
      typeof LocalActions.CREATE_STORY_FAIL,
    ];
  },
  Routes.stories._account.Post
>;

type COPY_STORY = GlobalAction<
  {
    types: [
      typeof LocalActions.COPY_STORY,
      typeof LocalActions.COPY_STORY_SUCCESS,
      typeof LocalActions.COPY_STORY_FAIL,
    ];
  },
  Routes.stories._account._story.copy.Post
>;
type CHOWN_STORY = GlobalAction<
  {
    types: [
      typeof LocalActions.CHOWN_STORY,
      typeof LocalActions.CHOWN_STORY_SUCCESS,
      typeof LocalActions.CHOWN_STORY_FAIL,
    ];
    fromUid: string;
  },
  Routes.stories._account._story.chown.Post
>;

type UPDATE_STORY = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_STORY,
      typeof LocalActions.UPDATE_STORY_SUCCESS,
      typeof LocalActions.UPDATE_STORY_FAIL,
    ];
    story: Story;
    storyElementsAfterReorder?: Models.StoryElement[];
  },
  Routes.stories._account._story.Patch
>;

type DELETE_STORY = GlobalAction<
  {
    types: [
      typeof LocalActions.DELETE_STORY,
      typeof LocalActions.DELETE_STORY_SUCCESS,
      typeof LocalActions.DELETE_STORY_FAIL,
    ];
    story: Story;
  },
  Routes.stories._account._story.Delete
>;

type CHECK_STORY_AVAILABILITY = GlobalAction<{
  types: [
    typeof LocalActions.CHECK_STORY_AVAILABILITY,
    typeof LocalActions.CHECK_STORY_AVAILABILITY_SUCCESS,
    typeof LocalActions.CHECK_STORY_AVAILABILITY_FAIL,
  ];
}>;
type UPLOAD_BANNER = GlobalAction<
  {
    types: [
      typeof LocalActions.UPLOAD_BANNER,
      typeof LocalActions.UPLOAD_BANNER_SUCCESS,
      typeof LocalActions.UPLOAD_BANNER_FAIL,
    ];
    story: Story;
  },
  Routes.stories._account._story.banner.Post
>;

export type LocalAction =
  | GET_STORIES
  | GET_STORY
  | CREATE_STORY
  | UPDATE_STORY
  | DELETE_STORY
  | COPY_STORY
  | CHOWN_STORY
  | CHECK_STORY_AVAILABILITY
  | UPLOAD_BANNER;

export type Story = Models.Story;

export interface StoryList {
  [storyId: string]: Story;
}
export interface ListFor {
  account?: string | false;
  searchTerm?: string | false;
}
export interface State {
  list?: StoryList;
  listFor?: ListFor;
  nextPage?: string;
  current?: Story;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_STORIES_SUCCESS:
        draftState.list = reduce(
          action.result,
          (result, story) => {
            result[story.id] = story;
            return result;
          },
          action.append && draftState.list ? draftState.list : <StoryList>{},
        );
        if (!action.append) draftState.listFor = action.listFor;
        draftState.nextPage =
          action.meta && action.meta.links && action.meta.links["next"] ? action.meta.links["next"].url : undefined;
        return draftState;

      case Actions.GET_STORY:
        draftState.current = undefined;
        return draftState;

      case Actions.GET_STORY_SUCCESS:
        draftState.current = action.result;
        if (draftState.list && draftState.list[action.result.id]) {
          draftState.list[action.result.id] = action.result;
        }
        return draftState;

      case Actions.UPDATE_STORY:
        if (action.storyElementsAfterReorder && draftState.current && draftState.current.id === action.story.id) {
          draftState.current.content = action.storyElementsAfterReorder;
        }
        return draftState;

      case Actions.UPDATE_STORY_FAIL:
        if (action.storyElementsAfterReorder && draftState.current && draftState.current.id === action.story.id) {
          draftState.current.content = action.story.content;
        }
        return draftState;

      case Actions.UPDATE_STORY_SUCCESS:
        if (action.storyElementsAfterReorder && draftState.current) {
          // Actual reordering happened before, only update relevant data
          draftState.current = {
            ...action.result,
            content: draftState.current.content,
          };
        } else {
          draftState.current = action.result;
        }
        if (draftState.list && draftState.list[action.result.id]) {
          draftState.list[action.result.id] = action.result;
        }
        return draftState;
      case Actions.UPLOAD_BANNER_SUCCESS:
        draftState.current = action.result;
        if (draftState.list && draftState.list[action.result.id]) {
          draftState.list[action.result.id] = action.result;
        }
        return draftState;

      case Actions.CREATE_STORY_SUCCESS:
        draftState.current = action.result;
        if (
          draftState.list &&
          isEqual(draftState.listFor, { account: action.result.owner.accountName, searchTerm: false })
        ) {
          // Only add if the list is already loaded
          draftState.list = {
            [action.result.id]: action.result,
            ...draftState.list,
          };
        }

        return draftState;

      case Actions.COPY_STORY_SUCCESS:
      case Actions.CHOWN_STORY_SUCCESS:
        draftState.list = undefined;
        draftState.listFor = undefined;
        return;

      case Actions.DELETE_STORY_SUCCESS:
        if (draftState.list) delete draftState.list[action.story.id];
        if (draftState.current && draftState.current.id === action.story.id) {
          draftState.current = undefined;
        }
        return draftState;
      case Actions.UPDATE_QUERY_SUCCESS:
        if (draftState.current) {
          // Grab the index of all query elements in the current story that use the updated query
          const queryIndexes = draftState.current.content.reduce((indexList, currentElement, currentIndex) => {
            if (currentElement.type === "query" && currentElement.query?.id === action.result.id)
              indexList.push(currentIndex);
            return indexList;
          }, [] as number[]);
          // Replace the query at that index with the result
          for (const queryId of queryIndexes) {
            (draftState.current.content[queryId] as Models.StoryElementQuery).query = action.result;
          }
        }
        // For now we don't need to update the list as we retrieve the story on load anyway
        return draftState;
      case Actions.ADD_QUERY_VERSION_SUCCESS:
        // Clear stories list when a new query version is made
        draftState.current = undefined;
        return draftState;
      // We need to cause a hard refresh of the current story so that it updates with the updated service Metadata
      case Actions.UPDATE_SERVICE_SUCCESS:
        draftState.current = undefined;
        return draftState;
    }
  },
  <State>{},
) as any;

export function needToFetchStories(state: GlobalState, forAccount: Account) {
  if (!forAccount) return false;
  return !isEqual(state.stories.listFor, { account: forAccount.accountName, searchTerm: false });
}

export function getStories(
  forAccount?: Account,
  query?: Routes.stories.Get["Req"]["Query"],
  nextPage?: string,
): BeforeDispatch<GET_STORIES> {
  return {
    types: [Actions.GET_STORIES, Actions.GET_STORIES_SUCCESS, Actions.GET_STORIES_FAIL],
    promise: (client) => {
      if (nextPage)
        return client.req({
          url: nextPage,
          method: "get",
          query: query,
        });
      return client.req({
        pathname: forAccount ? `/stories/${forAccount.accountName}` : "/search/stories",
        method: "get",
        query: query,
      });
    },
    listFor: nextPage
      ? undefined
      : { account: !!forAccount && forAccount.accountName, searchTerm: !!query && !!query.q && query.q },
    append: !!nextPage,
  };
}

export function getStory(ownerName: string, storyName: string): BeforeDispatch<GET_STORY> {
  return {
    types: [Actions.GET_STORY, Actions.GET_STORY_SUCCESS, Actions.GET_STORY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/stories/${ownerName}/${storyName}`,
        method: "get",
      }),
  };
}

export function createStory(forAccountName: string, data: Models.StoryCreate): BeforeDispatch<CREATE_STORY> {
  return {
    types: [Actions.CREATE_STORY, Actions.CREATE_STORY_SUCCESS, Actions.CREATE_STORY_FAIL],
    promise: (client) =>
      client.req({
        pathname: "/stories/" + forAccountName,
        method: "post",
        body: data,
      }),
  };
}

export function copyStory(
  storyName: string,
  fromAccountName: string,
  toAccountName: string,
): BeforeDispatch<COPY_STORY> {
  return {
    types: [Actions.COPY_STORY, Actions.COPY_STORY_SUCCESS, Actions.COPY_STORY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/stories/${fromAccountName}/${storyName}/copy`,
        method: "post",
        body: { toAccount: toAccountName },
      }),
  };
}
export function chownStory(story: Story, toAccountName: string): BeforeDispatch<CHOWN_STORY> {
  return {
    types: [Actions.CHOWN_STORY, Actions.CHOWN_STORY_SUCCESS, Actions.CHOWN_STORY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/stories/${story.owner.accountName}/${story.name}/chown`,
        method: "post",
        body: { toAccount: toAccountName },
      }),
    fromUid: story.owner.uid,
  };
}

export function updateStory(
  story: Story,
  data: Models.StoryUpdate,
  storyElementsAfterReorder?: Models.StoryElement[],
): BeforeDispatch<UPDATE_STORY> {
  return {
    types: [Actions.UPDATE_STORY, Actions.UPDATE_STORY_SUCCESS, Actions.UPDATE_STORY_FAIL],
    promise: (client) =>
      client.req({
        url: story.link,
        method: "patch",
        body: data,
      }),
    story: story,
    storyElementsAfterReorder: storyElementsAfterReorder,
  };
}
export function deleteStory(story: Story): BeforeDispatch<DELETE_STORY> {
  return {
    types: [Actions.DELETE_STORY, Actions.DELETE_STORY_SUCCESS, Actions.DELETE_STORY_FAIL],
    promise: (client) =>
      client.req({
        url: story.link,
        method: "delete",
      }),
    story: story,
  };
}
export function uploadBanner(story: Story, file: File): BeforeDispatch<UPLOAD_BANNER> {
  return {
    types: [Actions.UPLOAD_BANNER, Actions.UPLOAD_BANNER_SUCCESS, Actions.UPLOAD_BANNER_FAIL],
    promise: (client) =>
      client.req({
        url: story.link + "/banner.webp",
        method: "post",
        files: { banner: file },
      }),
    story: story,
  };
}

export function isStoryNameAvailable(
  formItemName: string,
  forAccount: Account,
  storyName: string,
): BeforeDispatch<CHECK_STORY_AVAILABILITY> {
  return {
    types: [
      Actions.CHECK_STORY_AVAILABILITY,
      Actions.CHECK_STORY_AVAILABILITY_SUCCESS,
      Actions.CHECK_STORY_AVAILABILITY_FAIL,
    ],
    promise: (client) =>
      client
        .req({
          pathname: "/stories/" + forAccount.accountName + "/" + storyName,
          method: "get",
          statusCodeMap: { 404: 202 },
        })
        .then((d) => {
          if (d.body.name)
            throw {
              [formItemName]: "A story named " + d.body.name + " already exists",
            };
          return null;
        }),
  };
}

export function storyElementToUpdateObj(
  storyElement: Models.StoryElement | Forms.StoryElement.FormData,
): Models.StoryElementUpdate {
  return {
    id: storyElement.id,
    caption: storyElement.type === "query" ? storyElement.caption : undefined,
    type: storyElement.type,
    query: (storyElement.type === "query" && storyElement.query?.id) || undefined,
    queryVersion: (storyElement.type === "query" && storyElement.queryVersion) || undefined,
    paragraph: (storyElement.type === "paragraph" && storyElement.paragraph) || undefined,
    height: ("height" in storyElement && storyElement.type === "query" && storyElement.height) || undefined,
    width: ("width" in storyElement && storyElement.type === "query" && storyElement.width) || undefined,
  };
}

export function isStoryListLoaded(state: GlobalState, listFor: ListFor) {
  return isEqual(state.stories.listFor, listFor);
}

export function getStoryList(state: GlobalState, listFor: ListFor) {
  if (!state.stories.list || !isEqual(listFor, state.stories.listFor)) return [];
  return Object.values(state.stories.list);
}

export const useStories = (listFor: ListFor) => {
  const listFromState = useSelector((state: GlobalState) => state.stories.list);
  const listForFromState = useSelector((state: GlobalState) => state.stories.listFor);
  if (!listFromState || !isEqual(listFor, listForFromState)) return [];
  return Object.values(listFromState);
};
