import * as React from "react";
import { LiteralUtils } from "@triply/utils";
import { formatNumericTerm } from "@triply/utils-private/formatting.js";
import { TermLiteral } from "#components/index.ts";

class TermLiteralNumeric extends React.PureComponent<TermLiteral.Props, any> {
  static shouldRender(props: TermLiteral.Props) {
    return props.datatype && LiteralUtils.isNumericDatatype(props.datatype);
  }
  render() {
    const { value, className, datatype } = this.props;
    if (!datatype) return null;

    return (
      <div className={className} title={this.props.expanded ? this.props.datatype : value}>
        {formatNumericTerm(value, datatype)}
      </div>
    );
  }
}
export default TermLiteralNumeric;
