import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "#reducers/index.ts";
import { TimezoneContext } from "../../context.ts";

namespace DateComponent {
  export interface Props {
    date: string | number | Date;
    showAbsoluteTime?: boolean;
    humanizeDuration?: (date: Date) => string;
    hideTitle?: boolean;
    showDateOnly?: boolean;
  }
}
function getDate(date: DateComponent.Props["date"]): Date {
  if (date instanceof Date) return date;
  return new Date(date);
}
function getDuration(date: Date): moment.Duration {
  const now = new Date();
  return moment.duration(moment(date < now ? date : now).diff(moment(now), "minutes"), "minutes");
}
function getDateString(date: Date, locale: string, timezone: string, showDateOnly: boolean): string {
  try {
    if (showDateOnly) return date.toLocaleDateString(locale, { timeZone: timezone });
    return date.toLocaleString(locale, { timeZone: timezone });
  } catch {
    //IE11 only supports setting UTC as timezone (yes, I know, what's the use of this timezone argument then...)
    //Anyway, make sure we have a fallback whenever the command with timezone throws
    if (showDateOnly) return date.toLocaleDateString(locale);
    return date.toLocaleString(locale);
  }
}

const HumanizedDate: React.FC<DateComponent.Props> = ({
  date: rawDate,
  hideTitle,
  humanizeDuration,
  showAbsoluteTime,
  showDateOnly,
}) => {
  const date = getDate(rawDate);
  const [duration, setDuration] = React.useState(getDuration(date));
  const [updateInterval, setUpdateInterval] = React.useState(60);

  // We update the duration every minute to rerender and show the up-to-date
  // duration to the user. After 1 hour we change the interval to 1 hour as
  // the humanized date will change less often from that point on.
  React.useEffect(() => {
    // This is quite a performance hog, so if we don't need to update, don't use the Effect
    if (showAbsoluteTime) return;
    if (duration < moment.duration(-60, "minutes")) setUpdateInterval(60 * 60);

    const interval = setInterval(() => setDuration(getDuration(date)), updateInterval * 1000);
    return () => clearInterval(interval);
  }, [duration, updateInterval, date, showAbsoluteTime]);

  const timezone = React.useContext(TimezoneContext);
  const locale = useSelector((state: GlobalState) => state.app.locale);
  let mainText: string;
  if (showAbsoluteTime && locale) {
    mainText = getDateString(date, locale, timezone, !!showDateOnly);
  } else if (humanizeDuration) {
    mainText = humanizeDuration(date);
  } else {
    mainText = duration.humanize(true);
  }

  // Using suppressHydrationWarning to avoid errors when the browser and api use a different locale
  // This is causing differences in time representations, causing hydration errors.
  // We cannot fix this properly (as we cannot read the browser locale on the api), so using this flag instead
  return (
    <span
      suppressHydrationWarning
      title={!hideTitle && locale ? getDateString(date, locale, timezone, !!showDateOnly) : undefined}
    >
      {mainText}
    </span>
  );
};

export default HumanizedDate;
