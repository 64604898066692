import { Button } from "@mui/material";
import * as React from "react";
import FontAwesomeIcon from "../FontAwesomeIcon";

interface Props {
  accept?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children: string | (string | number)[];
  loading?: boolean;
}

const FileUploadButton: React.FC<Props> = ({ accept, onChange, children, loading }) => {
  return (
    <label>
      <input type="file" accept={accept} hidden onChange={onChange} />
      <Button
        variant="contained"
        component="span"
        startIcon={loading && <FontAwesomeIcon icon={["fas", "cog"]} spin />}
      >
        {children}
      </Button>
    </label>
  );
};

export default FileUploadButton;
