import { IconButton } from "@mui/material";
import getClassName from "classnames";
import { capitalize } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import { formatNumber } from "@triply/utils-private/formatting.js";
import RenameService, { RenameServiceForm } from "#components/Forms/RenameService/index.tsx";
import {
  Alert,
  Button,
  Circle,
  Dialog,
  FontAwesomeButton,
  FontAwesomeIcon,
  HumanizedDate,
  ServiceTypeBadge,
} from "#components/index.ts";
import LinkButton from "#components/LinkButton/index.tsx";
import fetch from "#helpers/fetch.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useTabOutside from "#helpers/hooks/useTabOutside.ts";
import humanizeDuration from "#helpers/HumanizeDate.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import { issueServiceCommand, Service, shouldShowServiceAsRunning, updateService } from "#reducers/services.ts";
import AdminInfo from "./AdminInfo.tsx";
import { ServiceGraphs } from "./Graphs.tsx";
import Logs from "./Logs.tsx";
import * as styles from "./style.scss";

interface ServiceHandler {
  (service: Service): void;
}

export const SERVICE_DESCRIPTIONS = {
  speedy:
    "Speedy is actively maintained by Triply and is standards conformant, always available and always up-to-date.",
  elasticSearch: "Elasticsearch creates a text-index for your linked data.",
  virtuoso:
    "Virtuoso does not follow the SPARQL standard well, but is able to scale to large datasets, and has support for geospatial queries.",
  jena: "Jena is standards-conforming and can apply RDFS/OWL reasoning, but does not scale very well to larger datasets.",
  blazegraph: undefined,
} as const;

export interface Props {
  ds?: Dataset; // Optional as it can be part of the service object
  service: Service;
  currentAccount: Account;
  writeAccess: boolean;
  deleteHandler: ServiceHandler;
  startHandler: ServiceHandler;
  stopHandler: ServiceHandler;
  stopWithAutoresumeHandler: ServiceHandler;
  syncHandler: ServiceHandler;
  className?: string;
  linkTo?: string;
  isHighlighted: boolean;
}

interface DescriptionItem {
  key: string; // Keys must be strings to make them unique
  keyContent?: React.ReactNode; // Not all keys can be pure strings
  value: React.ReactNode;
}

function serviceExpired(service: Service) {
  // might be neater to look at the error code (a http status code),
  // but we return 500 or 400 regardless of the error code stored in mongo.
  return service.error?.message.startsWith("This service has expired");
}

function getCircleColor(service: Service) {
  if (!service) return undefined;
  if (service.status === "updating" || service.status === "starting") return "flash-green";
  if (shouldShowServiceAsRunning(service)) return "green";
  if (service.status === "stopping") return "flash-orange";
  if (service.error || service.status === "error") {
    return serviceExpired(service) ? "orange" : "red";
  }
  return undefined;
}

const ServiceListItem: React.FC<Props> = (props) => {
  const service = props.service;
  // Use ds if provided, some calls give back more info then others to fill out the service
  const ds = props.ds ? props.ds : service && service.dataset;

  const alertMessages = {
    error: [] as Array<JSX.Element | string | undefined>,
    warning: [] as Array<JSX.Element | string | undefined>,
    info: [] as Array<JSX.Element | string | undefined>,
  };
  // Hooks
  const [logsOpen, setLogsOpen] = React.useState(false);
  const [isRenamingService, setIsRenamingService] = React.useState(false);
  const currentServices = useSelector((state: GlobalState) => state.services);
  const constructUrlToApi = useConstructUrlToApi();
  // We're fetching admin service info directly from the admin api, instead of the regular service document in redux.
  // That route contains more
  const [adminServiceInfo, setAdminServiceInfo] = React.useState<Models.ServiceMetadata>();
  const highlightedRef = React.useRef<HTMLDivElement>(null);
  const [isServiceHighlighted, setIsServiceHighlighted] = React.useState<boolean>(false);
  useClickOutside(() => {
    if (isServiceHighlighted) setIsServiceHighlighted(false);
  }, highlightedRef);
  useTabOutside(() => {
    if (isServiceHighlighted) setIsServiceHighlighted(false);
  }, highlightedRef);
  React.useEffect(() => {
    if (isServiceHighlighted) highlightedRef.current?.getElementsByTagName("a")[0]?.focus();
  }, [isServiceHighlighted]);
  React.useEffect(() => {
    if (props.isHighlighted) setIsServiceHighlighted(true);
  }, [props.isHighlighted]);
  const otherServiceNames = ds
    ? currentServices[ds.id].filter((s) => s.id !== service.id).map((s) => s.name.toLowerCase())
    : [];
  const dispatch = useDispatch();
  const acl = useAcl();

  // Variables
  const consoleDatasetPath = service && ds && ds.owner ? "/" + ds.owner.accountName + "/" + ds.name : undefined;

  const consoleServicePath = service.capabilities.includes("sparql")
    ? `${consoleDatasetPath}/sparql`
    : service.name
      ? `${consoleDatasetPath}/elasticsearch/${service.name}`
      : undefined;
  const apiServicePath =
    service && ds && ds.owner
      ? "/datasets/" + ds.owner.accountName + "/" + ds.name + "/services/" + service.name
      : undefined;

  /**
   * We're keeping track whether a service is out-of-sync in this component. This caters to a usecase where the
   * redux service object is cached, and where we e.g. remove graphs via the UI. The cached service object wont
   * be updated, but we do want to draw the sync button. Ideally, this should be fixed on the redux side, but
   * for now this suffices.
   */
  const outOfSync =
    service.outOfSync ||
    (service.status !== "starting" &&
      ds &&
      ((service.numberOfGraphs && ds.graphCount !== service.numberOfGraphs) ||
        new Date(ds.lastGraphsUpdateTime || 0) > new Date(service.loadedAt || service.createdAt)));
  const serviceIsQueryable =
    service.status === "running" ||
    ((service.status === "stopped" || service.status === "stopping") && service.autoResume);
  // Header element
  const serviceTitle = service ? (
    <h3>
      <Link
        to={{
          pathname: shouldShowServiceAsRunning(service) && consoleServicePath ? consoleServicePath : "./",
          state: {
            serviceName: service.name,
          },
        }}
        className={getClassName({ [styles.disabled]: !serviceIsQueryable })}
      >
        {service.name}
      </Link>
    </h3>
  ) : (
    <h4>N/A</h4>
  );

  // Create description list here, keeps render clean
  const descriptionItems: DescriptionItem[] = [];
  descriptionItems.push({
    key: "Type",
    value: capitalize(service.type),
  });
  if (service.type === "jena")
    descriptionItems.push({
      key: "Reasoner",
      //The reasonertype might not have been validated, causing typing+react issues
      //See here for more info about this bug: https://issues.triply.cc/issues/3409
      value:
        service.config && "reasonerType" in service.config && typeof service.config.reasonerType === "string"
          ? service.config.reasonerType
          : "None",
    });

  let statusText = capitalize(serviceExpired(service) ? "Expired" : service.status);

  if ((service.status === "stopped" || service.status === "stopping") && service.autoResume) {
    statusText += " (with auto-resume)";
  }
  descriptionItems.push({
    key: "Status",
    value: (
      <div className="flex center">
        <Circle color={getCircleColor(service)} />
        <div className={"pl-2"}>{statusText}</div>
      </div>
    ),
  });
  descriptionItems.push({
    key: "Protocol",
    value: service.type === "elasticSearch" ? "Elasticsearch" : "SPARQL",
  });
  descriptionItems.push({
    key: "Created",
    value: service.createdAt && <HumanizedDate date={service.createdAt} />,
  });
  descriptionItems.push({
    key: "Loaded",
    value: service.loadedAt ? <HumanizedDate date={service.loadedAt} /> : "Never",
  });
  descriptionItems.push({
    key: "Up-to-date",
    value: (
      <span aria-label={outOfSync || !service.loadedAt ? "False" : "True"}>
        <FontAwesomeIcon icon={outOfSync || !service.loadedAt ? "xmark" : "check"} />
      </span>
    ),
  });
  descriptionItems.push({
    key: "Loaded statements",
    value: formatNumber(service.numberOfLoadedStatements || 0),
  });
  // Do not display image when in compact view:
  descriptionItems.push({
    key: "Loaded Graphs",
    keyContent: (
      <div className="flex">
        Loaded graphs
        {!!service.numberOfGraphErrors && (
          <div
            title={`${service.numberOfGraphErrors} error${
              service.numberOfGraphErrors > 1 ? "s" : ""
            } occurred while loading the graphs`}
            className={getClassName("ml-3")}
          >
            <FontAwesomeIcon icon="exclamation-triangle" />
          </div>
        )}
      </div>
    ),
    value: !!consoleDatasetPath && !!apiServicePath && !!ds && (
      <ServiceGraphsWrapper
        service={service}
        consoleDatasetPath={consoleDatasetPath}
        apiServicePath={apiServicePath}
        datasetId={ds.id}
      />
    ),
  });
  if (props.writeAccess) {
    descriptionItems.push({
      key: "Last queried",
      value: service.queriedAt ? (
        <HumanizedDate
          date={service.queriedAt}
          humanizeDuration={(date) => humanizeDuration(date, "past", "capitalize")}
          hideTitle
        />
      ) : (
        "This service has not been queried yet."
      ),
    });
    if (service.autostopsAt && acl.check({ action: "stopServiceWithAutostart" }).granted) {
      descriptionItems.push({
        key: "Autostops",
        value: (
          <HumanizedDate
            date={service.autostopsAt}
            humanizeDuration={(date) => humanizeDuration(date, "future", "capitalize")}
            hideTitle
          />
        ),
      });
    }
    if (
      acl.check({ action: "stopOrStartService" }).granted ||
      acl.check({ action: "stopServiceWithAutostart" }).granted ||
      (acl.check({ action: "updateServiceVersion" }).granted && service.canUpdate) ||
      acl.check({ action: "showServiceDebugInformation" }).granted
    ) {
      descriptionItems.push({
        key: "Admin Controls",
        keyContent: <div className={styles.centeredTitle}>Admin Controls</div>,
        value: (
          <div className="flex column g-2">
            {(acl.check({ action: "stopOrStartService" }).granted ||
              acl.check({ action: "stopServiceWithAutostart" }).granted) && (
              <div className={styles.actionButtons}>
                {acl.check({ action: "stopOrStartService" }).granted && (
                  <>
                    <Button
                      size="small"
                      color="success"
                      disabled={service.status !== "stopped"}
                      onClick={() => props.startHandler(service)}
                      startIcon={<FontAwesomeIcon icon={["fas", "play"]} />}
                    >
                      Start
                    </Button>
                    <Button
                      size="small"
                      color="warning"
                      disabled={service.status !== "running"}
                      onClick={() => props.stopHandler(service)}
                      startIcon={<FontAwesomeIcon icon={["fas", "stop"]} />}
                    >
                      Stop
                    </Button>
                  </>
                )}
                {acl.check({ action: "stopServiceWithAutostart" }).granted && (
                  <Button
                    size="small"
                    color="warning"
                    startIcon={<FontAwesomeIcon icon={["fas", "stop"]} />}
                    disabled={service.status !== "running"}
                    onClick={() => props.stopWithAutoresumeHandler(service)}
                  >
                    Stop with auto-resume
                  </Button>
                )}
              </div>
            )}
            {((acl.check({ action: "updateServiceVersion" }).granted && service.canUpdate) ||
              acl.check({ action: "showServiceDebugInformation" }).granted) && (
              <div className={styles.actionButtons}>
                {acl.check({ action: "updateServiceVersion" }).granted && service.canUpdate && (
                  <Button
                    size="small"
                    color="warning"
                    disabled={service.status !== "running"}
                    startIcon={<FontAwesomeIcon icon="arrow-up" />}
                    title={
                      service.status === "running"
                        ? "Update the service to the latest version"
                        : "You can only update running services"
                    }
                    onClick={() =>
                      dispatch<typeof issueServiceCommand>(issueServiceCommand(ds!.owner, ds!, service, "restart"))
                    }
                  >
                    Update service version
                  </Button>
                )}
                {acl.check({ action: "showServiceDebugInformation" }).granted && service && (
                  <>
                    <Dialog
                      open={!!adminServiceInfo}
                      onClose={() => setAdminServiceInfo(undefined)}
                      maxWidth="xl"
                      fullWidth
                      closeButton
                      spacing
                    >
                      {adminServiceInfo && <AdminInfo service={adminServiceInfo} />}
                    </Dialog>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={["fas", "info-circle"]} />}
                      onClick={() => {
                        return fetch(constructUrlToApi({ pathname: `/admin/services/${service.id}` }), {
                          credentials: "same-origin",
                        })
                          .then((response) => {
                            if (response.ok) return response.json();
                            else if (response.status === 404) {
                              dispatch(showNotification(response.statusText, "error"));
                            }
                            throw new Error("Unexpected response");
                          })
                          .then((serviceInfo) => setAdminServiceInfo(serviceInfo))
                          .catch((error) => {
                            console.error(error);
                          });
                      }}
                    >
                      Show admin info
                    </Button>
                  </>
                )}
                {!!apiServicePath && acl.check({ action: "showServiceDebugInformation" }).granted && (
                  <>
                    <Dialog open={logsOpen} onClose={() => setLogsOpen(false)} fullScreen closeButton>
                      <Logs apiServicePath={apiServicePath} />
                    </Dialog>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setLogsOpen(true)}
                      startIcon={<FontAwesomeIcon icon={"stream"} />}
                    >
                      Show logs
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        ),
      });
    }
  }

  let alertMessage;
  if (service.error) {
    alertMessage = (
      <div key={"alertMessage"}>
        <div>{ensureTrailingDot(service.error.message)}</div>
        {service.error.serverError && <div>Admin error message: {ensureTrailingDot(service.error.serverError)}</div>}
      </div>
    );
    // Not all users get these to values, so checking for false is required
  } else if (service.foundInDocker === false) {
    alertMessage = "Docker container not found";
  } else if (service.foundInMongo === false) {
    alertMessage = "Docker container found, but metadata is missing or is not referenced by any dataset";
  }

  if (serviceExpired(service)) {
    alertMessages.warning.push(alertMessage);
  } else {
    // set to `error` (instead of `warning`) in #4888
    alertMessages.error.push(alertMessage);
  }

  // We should only show the update message when the service is out of sync, and isn't updating or has another error
  if (!alertMessage && outOfSync && service.status !== "updating") {
    let updateMessage = (
      <span key={"updateMessage"}>
        This service is outdated due to dataset changes.{" "}
        {props.writeAccess && shouldShowServiceAsRunning(service) && !!ds?.graphCount && (
          <LinkButton onClickOrEnter={() => props.syncHandler(service)}>Click here to update.</LinkButton>
        )}
        {["stopped", "stopping"].indexOf(service.status) > -1 && (
          <span>You may update it after starting the service.</span>
        )}
      </span>
    );

    alertMessages.warning.push(updateMessage);
  }
  const handleSubmit = async (formInfo: RenameServiceForm.FormData) => {
    if (!props.writeAccess || !ds) return;
    try {
      await dispatch<typeof updateService>(updateService(props.currentAccount, ds, service, formInfo));
      setIsRenamingService(false);
    } catch (e: any) {
      throw new ReduxForm.SubmissionError({ _error: e.message });
    }
  };
  return (
    <div className={isServiceHighlighted ? styles.addHighlight : styles.noHighlight} ref={highlightedRef}>
      <div className="white shadow my-5 py-4 px-5" id={service.name}>
        <div className="flex center">
          <ServiceTypeBadge type={props.service.type} />
          <div className={styles.title}>
            {!isRenamingService && serviceTitle}
            {props.writeAccess && isRenamingService && (
              <RenameService
                form={`renameService-${service.id}`}
                onSubmit={handleSubmit}
                initialValues={{ name: service.name }}
                serviceName={service && service.name}
                handleCancel={(e) => {
                  e.stopPropagation();
                  setIsRenamingService(false);
                }}
                otherServiceNames={otherServiceNames}
                key={service.id}
              />
            )}
            {props.writeAccess && !isRenamingService && (
              <FontAwesomeButton
                title="Edit service name"
                icon="pencil"
                iconClassName="ml-3"
                onClick={(e) => {
                  // the stop propagation is needed to stop the 'useOutsideClick' in the 'renameService' hook from listening to this click and canceling
                  e.stopPropagation();
                  setIsRenamingService(true);
                }}
                key={service.id}
              />
            )}
            <div className="grow" />
            {props.writeAccess && (
              <IconButton aria-label="Remove service" onClick={() => props.deleteHandler(service)} size="medium">
                <FontAwesomeIcon icon="xmark" style={{ width: "1em" }} />
              </IconButton>
            )}
          </div>
        </div>
        <div>
          {Object.entries(alertMessages).map((entry, i) => {
            const alertLevel = entry[0];
            const messages = entry[1].filter(Boolean); // filter undefined elements
            if (messages.length === 0) return;
            if (messages.length === 1)
              return (
                <Alert
                  key={"message" + i}
                  className="my-4"
                  transparent
                  {...{ [alertLevel]: true }}
                  message={<div>{messages[0]}</div>}
                />
              );
            return (
              <Alert
                key={"message" + i}
                className="my-4"
                transparent
                {...{ [alertLevel]: true }}
                message={
                  <div>
                    <ul>
                      {messages.map((item: any, j: any) => (
                        <li key={"liMessage" + j}>{item}</li>
                      ))}
                    </ul>
                  </div>
                }
              />
            );
          })}
        </div>
        {SERVICE_DESCRIPTIONS[service.type] && <p className="mx-2">{SERVICE_DESCRIPTIONS[service.type]}</p>}
        <dl key="ServiceDescription" className="mb-2 mx-2">
          {descriptionItems.map((item) => (
            <React.Fragment key={"Fragment" + item.key}>
              <dt>{item.keyContent ? item.keyContent : item.key}</dt>
              <dd>{item.value && item.value !== 0 ? item.value : "N/A"}</dd>
            </React.Fragment>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ServiceListItem;

const ServiceGraphsWrapper: React.FC<{
  service: Models.ServiceMetadata;
  consoleDatasetPath: string;
  apiServicePath: string;
  datasetId: string;
}> = ({ service, consoleDatasetPath, apiServicePath, datasetId }) => {
  const [extended, setExtended] = React.useState(false);

  return !service.numberOfGraphs ? (
    <div>None</div>
  ) : (
    <details className={styles.graphSummary}>
      <summary
        role="button"
        tabIndex={0}
        onClick={(e) => {
          setExtended(!e.currentTarget.parentElement?.hasAttribute("open"));
        }}
      >
        {service.numberOfLoadedGraphs} / {service.numberOfGraphs}
      </summary>

      <div>
        {extended && (
          <ServiceGraphs
            serviceId={service.id}
            apiServicePath={apiServicePath}
            consoleDatasetPath={consoleDatasetPath}
            datasetId={datasetId}
            numberOfGraphs={service.numberOfGraphs}
          />
        )}
      </div>
    </details>
  );
};
