import { Button } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import * as ReduxForm from "redux-form";
import {
  // Avatar,
  Alert,
  FontAwesomeIcon,
  UploadFileField,
} from "#components/index.ts";
import * as styles from "./style.scss";

namespace IconUpload {
  export interface FormData {
    avatarFile: UploadFileField.Value;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    className?: string;
    // onSubmit?:Function,//passed from parent property. Use the `handleSubmit` as actual submit handler for the form
    children?: React.ReactElement<any>;
    submitText?: string;
    accept?: string;
  }
}

const IconUpload = class IconUpload extends React.PureComponent<
  IconUpload.Props & Partial<ReduxForm.InjectedFormProps<FormData>>,
  any
> {
  render() {
    const { className, error, handleSubmit, submitting, submitText, accept } = this.props;
    const classNames: { [className: string]: boolean } = {
      [styles.wrapper]: true,
      [className || ""]: !!className,
    };
    return (
      <div className={getClassName(classNames)}>
        {this.props.children}
        <div>
          <label>
            <Button
              disabled={submitting}
              component="span"
              variant="outlined"
              startIcon={submitting && <FontAwesomeIcon icon={["fas", "cog"]} spin />}
            >
              {submitText ? <span>{submitText}</span> : "Upload new avatar"}
            </Button>
            <UploadFileField.Field
              submitOnChange={handleSubmit}
              name="avatarFile"
              style={{ display: "none" }}
              component={UploadFileField}
              accept={accept}
            />
          </label>
          <Alert className="mt-5" transparent message={error} />
        </div>
      </div>
    );
  }
};

export default IconUpload;

export var AvatarUpload = ReduxForm.reduxForm<IconUpload.FormData, IconUpload.Props>({
  form: "avatarUpload",
})(IconUpload as any);
// export var AvatarUploadForm = AvatarUploadForm;

export var LogoUpload = ReduxForm.reduxForm<IconUpload.FormData, IconUpload.Props>({
  form: "logoUpload",
})(IconUpload as any);

export var LogoLgUpload = ReduxForm.reduxForm<IconUpload.FormData, IconUpload.Props>({
  form: "logoLgUpload",
})(IconUpload as any);

export var bannerUpload = ReduxForm.reduxForm<IconUpload.FormData, IconUpload.Props>({
  form: "bannerUpload",
})(IconUpload as any);
