import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { pruneEmptyClasses } from "#containers/Insights/helpers.tsx";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Account } from "./accountCollection.ts";

export const LocalActions = {
  GET_CLASS_FREQUENCY: "triply/insights/GET_CLASS_FREQUENCY",
  GET_CLASS_FREQUENCY_SUCCESS: "triply/insights/GET_CLASS_FREQUENCY_SUCCESS",
  GET_CLASS_FREQUENCY_FAIL: "triply/insights/GET_CLASS_FREQUENCY_FAIL",
  GET_CLASS_HIERARCHY: "triply/insights/GET_CLASS_HIERARCHY",
  GET_CLASS_HIERARCHY_SUCCESS: "triply/insights/GET_CLASS_HIERARCHY_SUCCESS",
  GET_CLASS_HIERARCHY_FAIL: "triply/insights/GET_CLASS_HIERARCHY_FAIL",
} as const;

type GET_CLASS_FREQUENCY = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CLASS_FREQUENCY,
      typeof LocalActions.GET_CLASS_FREQUENCY_SUCCESS,
      typeof LocalActions.GET_CLASS_FREQUENCY_FAIL,
    ];
    forDataset: Dataset;
    graphName: string;
    append: boolean;
  },
  Routes.datasets._account._dataset.insights.classFrequency.Get
>;

type GET_CLASS_HIERARCHY = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_CLASS_HIERARCHY,
      typeof LocalActions.GET_CLASS_HIERARCHY_SUCCESS,
      typeof LocalActions.GET_CLASS_HIERARCHY_FAIL,
    ];
    forDataset: Dataset;
  },
  Routes.datasets._account._dataset.insights.classHierarchy.Get
>;

export type LocalAction = GET_CLASS_FREQUENCY | GET_CLASS_HIERARCHY;

export interface State {
  [datasetId: string]: {
    classHierarchy?: Models.ClassHierarchy | null;
    classHierarchyLastGraphsUpdateTime?: string;
    classHierarchyError?: string;
    classFrequency: {
      [graphName: string]: { data: Models.ClassFrequency; nextPage?: string; lastGraphsUpdateTime?: string };
    };
  };
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_CLASS_FREQUENCY_SUCCESS:
        if (!draftState[action.forDataset.id]) {
          draftState[action.forDataset.id] = { classHierarchy: undefined, classFrequency: {} };
        }
        draftState[action.forDataset.id].classFrequency[action.graphName] = {
          data: [
            ...(action.append ? draftState[action.forDataset.id].classFrequency[action.graphName].data : []),
            ...action.result,
          ],
          nextPage: action.meta.links.next ? action.meta.links.next.url : undefined,
          lastGraphsUpdateTime: action.forDataset.lastGraphsUpdateTime,
        };
        return;

      case Actions.GET_CLASS_HIERARCHY_FAIL:
        if (!draftState[action.forDataset.id]) {
          draftState[action.forDataset.id] = {
            classHierarchy: undefined,
            classFrequency: {},
          };
        }
        draftState[action.forDataset.id].classHierarchy = undefined;
        draftState[action.forDataset.id].classHierarchyError = action.message;
        draftState[action.forDataset.id].classHierarchyLastGraphsUpdateTime = action.forDataset.lastGraphsUpdateTime;
        return;

      case Actions.GET_CLASS_HIERARCHY_SUCCESS:
        if (!draftState[action.forDataset.id]) {
          draftState[action.forDataset.id] = { classHierarchy: undefined, classFrequency: {} };
        }
        try {
          draftState[action.forDataset.id].classHierarchy =
            action.result.length > 0 ? pruneEmptyClasses(action.result) : action.result;
          draftState[action.forDataset.id].classHierarchyError = undefined;
        } catch (e) {
          let message = "Failed to store class hierarchy";
          if (e instanceof Error) {
            message = e.message;
          }
          console.error(message);
          draftState[action.forDataset.id].classHierarchy = null;
          // pruneEmptyClasses might throw an error with message 'cycle'.
          draftState[action.forDataset.id].classHierarchyError = message;
        }
        draftState[action.forDataset.id].classHierarchyLastGraphsUpdateTime = action.forDataset.lastGraphsUpdateTime;
        return;
    }
  },
  <State>{},
);

export function classFrequencyIsLoadedFor(state: GlobalState, dataset: Dataset, graphName: string) {
  return (
    !!state.insights[dataset.id]?.classFrequency[graphName] &&
    state.insights[dataset.id].classFrequency[graphName].lastGraphsUpdateTime === dataset.lastGraphsUpdateTime
  );
}

export function classFrequencyIsOutdatedFor(state: GlobalState, dataset: Dataset, graphName: string) {
  return (
    !!state.insights[dataset.id]?.classFrequency[graphName] &&
    state.insights[dataset.id].classFrequency[graphName].lastGraphsUpdateTime !== dataset.lastGraphsUpdateTime
  );
}

export function getClassFrequencyFor(state: GlobalState, dataset: Dataset, graphName: string) {
  if (classFrequencyIsLoadedFor(state, dataset, graphName)) {
    return state.insights[dataset.id]?.classFrequency[graphName];
  }
}

export function getClassFrequency(
  forAccount: Account,
  forDataset: Dataset,
  graphName: string,
  withLink?: string,
): BeforeDispatch<GET_CLASS_FREQUENCY> {
  return {
    types: [Actions.GET_CLASS_FREQUENCY, Actions.GET_CLASS_FREQUENCY_SUCCESS, Actions.GET_CLASS_FREQUENCY_FAIL],
    promise: (client) => {
      if (withLink)
        return client.req({
          url: withLink,
          method: "get",
        });
      return client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/insights/classFrequency`,
        query: {
          graphName: graphName,
        },
        method: "get",
      });
    },
    forDataset: forDataset,
    graphName: graphName,
    append: !!withLink,
  };
}

export function classHierarchyIsLoadedFor(state: GlobalState, dataset: Dataset) {
  return (
    (state.insights[dataset.id]?.classHierarchy !== undefined ||
      state.insights[dataset.id]?.classHierarchyError?.startsWith("Too much data to show")) &&
    state.insights[dataset.id]?.classHierarchyLastGraphsUpdateTime === dataset.lastGraphsUpdateTime
  );
}

export function classHierarchyIsOutdatedFor(state: GlobalState, dataset: Dataset) {
  return (
    (state.insights[dataset.id]?.classHierarchy !== undefined ||
      state.insights[dataset.id]?.classHierarchyError?.startsWith("Too much data to show")) &&
    state.insights[dataset.id]?.classHierarchyLastGraphsUpdateTime !== dataset.lastGraphsUpdateTime
  );
}

export function getClassHierarchyFor(state: GlobalState, dataset: Dataset) {
  if (classHierarchyIsLoadedFor(state, dataset)) {
    return state.insights[dataset.id]?.classHierarchy;
  }
}

export function getClassHierarchy(forAccount: Account, forDataset: Dataset): BeforeDispatch<GET_CLASS_HIERARCHY> {
  return {
    types: [Actions.GET_CLASS_HIERARCHY, Actions.GET_CLASS_HIERARCHY_SUCCESS, Actions.GET_CLASS_HIERARCHY_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/insights/classHierarchy`,
        method: "get",
      }),
    forDataset: forDataset,
  };
}
