import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as ReduxForm from "redux-form";
import { NO_ACCOUNTS_LEFT_MESSAGE } from "@triply/utils/Constants.js";
import * as Forms from "#components/Forms/index.ts";
import { Button, ErrorPage, FlexContainer, LinkButton, Meta, Svg } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { register, resendVerificationEmail, useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import ConsentDialog from "./ConsentDialog.tsx";
import githubSvg from "./github.svg";
import googleSvg from "./google.svg";
import * as styles from "./style.scss";

export function formulateAccountAvailabilityMessage(numAvailableAccounts: number) {
  if (numAvailableAccounts <= 0) {
    return NO_ACCOUNTS_LEFT_MESSAGE;
  } else if (numAvailableAccounts === 1) {
    return (
      <>
        There is still <b>1</b> account available.
      </>
    );
  } else {
    return (
      <>
        There are still <b>{numAvailableAccounts}</b> accounts available.
      </>
    );
  }
}

interface LocationState {
  consentModelShown?: string;
}

const Register: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useAuthenticatedUser();
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const query = parseSearchString(location.search);
  const { enabledOauth, samlIdps, passwordSignupEnabled, generalTermsUrl, privacyPolicyUrl } = useSelector(
    (state: GlobalState) => {
      return {
        enabledOauth: state.config.clientConfig?.enabledOauth,
        samlIdps: state.config.clientConfig?.saml,
        passwordSignupEnabled: !!state.config.clientConfig?.passwordSignup,
        generalTermsUrl: state.config.clientConfig?.legal?.generalTermsUrl,
        privacyPolicyUrl: state.config.clientConfig?.legal?.privacyPolicyUrl,
      };
    },
  );
  const [showVerificationNotification, setShowVerificationNotification] = React.useState(false);
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    //already logged in, redirect to account page
    if (authenticatedUser) {
      const destination = (query?.returnTo as string) ?? `/${authenticatedUser.accountName}`;
      history.push(destination);
    }
  }, [authenticatedUser, history, location.search, query]);

  const handleRegistration = (values: Forms.Register.FormData) => {
    return dispatch<typeof register>(
      register(
        values.email,
        values.password || "",
        values.accountName,
        values.consent,
        (query?.returnTo as string) || undefined,
      ),
    ).then(
      () => {},
      (e: any) => {
        // arbitrary code to signal console to show pretty 'please verify your account' component
        if (e.code === 99) {
          setShowVerificationNotification(true);
          setEmail(values.email);
          setPassword(values.password);
        }
        throw new ReduxForm.SubmissionError({ _error: e.message });
      },
    );
  };

  const resendMail = () => {
    if (!email || !password) throw new Error("Cannot send verification email. Missing info");
    dispatch(resendVerificationEmail(email, password));
  };
  if (authenticatedUser) return null;

  if (query?.error) return <ErrorPage message={query.error as string} />;
  //should have an array at least
  if (!enabledOauth) return <ErrorPage />;
  const showPasswordForm = passwordSignupEnabled;
  const showSsoButtons = !!enabledOauth.length || !!samlIdps?.length;
  const showConsentForm = generalTermsUrl || privacyPolicyUrl;
  const containerStyles = {
    [styles.container]: !!styles.container,
    [styles.ssoDisabled]: !showSsoButtons,
    [styles.passwordSignUpDisabled]: !passwordSignupEnabled,
  };
  if (showVerificationNotification) {
    return (
      <FlexContainer innerClassName={getClassName(containerStyles)}>
        <Meta currentPath={location.pathname} title={"Verify your account"} />
        <div>
          <div className={getClassName(styles.box, "whiteSink")}>
            <div className={styles.title}>
              <h3 className="headerSpacing">Verify your account</h3>
            </div>

            <div className={styles.content}>
              {
                <div>
                  <p>Please check your inbox and follow the link we've sent you to verify your account.</p>
                  <small>
                    If you did not receive a verification e-mail,{" "}
                    <LinkButton onClickOrEnter={resendMail}>click here</LinkButton> to resend.
                  </small>
                </div>
              }
            </div>
          </div>
        </div>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer innerClassName={getClassName(containerStyles)}>
      <Meta currentPath={location.pathname} title="Register" />
      <div>
        <div className={getClassName(styles.box, "whiteSink")}>
          <div className={styles.title}>
            <h3 className="headerSpacing">Register</h3>
          </div>
          <div style={{ textAlign: "center" }}>Store, explore and share linked data.</div>
          <div className={styles.content}>
            {showSsoButtons && (
              <div
                className={getClassName(styles.openIdProviders, "px-5")}
                style={{ flexBasis: showPasswordForm ? "240px" : "100%" }}
              >
                <ul>
                  {enabledOauth.indexOf("google") >= 0 && (
                    <li>
                      <Button
                        variant="outlined"
                        {...(showConsentForm
                          ? {
                              onClick: () =>
                                history.push({
                                  state: { consentModelShown: `/web/auth/oauth/google?from=register` },
                                }),
                            }
                          : { href: `/web/auth/oauth/google?from=register` })}
                        startIcon={<Svg src={googleSvg} alt="" />}
                      >
                        Register using Google
                      </Button>
                    </li>
                  )}

                  {enabledOauth.indexOf("github") >= 0 && (
                    <li>
                      <Button
                        variant="outlined"
                        {...(showConsentForm
                          ? {
                              onClick: () =>
                                history.push({
                                  state: { consentModelShown: `/web/auth/oauth/github?from=register` },
                                }),
                            }
                          : { href: `/web/auth/oauth/github?from=register` })}
                        startIcon={<Svg src={githubSvg} alt="" />}
                      >
                        Register using GitHub
                      </Button>
                    </li>
                  )}
                  {!!samlIdps?.length &&
                    samlIdps.map((saml) => {
                      const href = "/web/auth/saml?" + stringifyQuery({ idp: saml.idp });
                      return (
                        <li>
                          <Button
                            variant="outlined"
                            {...(showConsentForm
                              ? { onClick: () => history.push({ state: { consentModelShown: href } }) }
                              : { href: href })}
                          >
                            {saml.label}
                          </Button>
                        </li>
                      );
                    })}
                </ul>
                <ConsentDialog />
              </div>
            )}

            {showSsoButtons && showPasswordForm && (
              <div className={getClassName(styles.separator)}>
                <div className={styles.line} />
                <div className={styles.wordwrapper}>or</div>
              </div>
            )}

            {showPasswordForm && (
              <div
                className={getClassName(styles.authForm, "px-5")}
                style={{ flexBasis: showSsoButtons ? "50%" : "100%" }}
              >
                <Forms.Register onSubmit={handleRegistration} />
              </div>
            )}
          </div>
        </div>
      </div>
    </FlexContainer>
  );
};

export default Register;
