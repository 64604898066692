import getClassName from "classnames";
import * as React from "react";
import {} from "@triply/utils/Models.js";
import Ellipsis from "#components/Ellipsis/index.tsx";
import { Html, TermLiteral } from "#components/index.ts";
import * as styles from "./style.scss";

class TermLiteralHTML extends React.PureComponent<TermLiteral.Props, {}> {
  static shouldRender(props: TermLiteral.Props) {
    return props.datatype && RDF_HTML_TYPES.includes(props.datatype);
  }
  render() {
    if (this.props.expanded) {
      return (
        <div
          className={getClassName("literalHtml", styles.htmlLiteral, this.props.className)}
          title={this.props.datatype}
        >
          <Html>{this.props.value}</Html>
        </div>
      );
    }
    return (
      <div className={getClassName("literalHtml", styles.htmlLiteral, this.props.className)}>
        <Ellipsis value={this.props.value} renderAsHTML />
      </div>
    );
  }
}
export default TermLiteralHTML;

export const RDF_HTML_TYPES = ["http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML"];
