import * as React from "react";
import { useLocation } from "react-router";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import ContactEmail from "./ContactEmail.tsx";
import MainPageItems from "./MainPageItems.tsx";
import SiteLogos from "./SiteLogos.tsx";
import SiteMetadata from "./SiteMetadata.tsx";
import StarterDataset from "./StarterDataset.tsx";

const AdminSiteProfile: React.FC<{}> = ({}) => {
  const acl = useAcl();

  if (!acl.check({ action: "editInstanceMetadata" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <FlexContainer>
      <SiteProfileMeta />
      <SiteLogos />
      <SiteMetadata />
      <ContactEmail />
      <MainPageItems />
      <StarterDataset />
    </FlexContainer>
  );
};

export default AdminSiteProfile;

const SiteProfileMeta: React.FC = () => {
  const location = useLocation(); // Prevent re-rendering of the component when the location changes
  return <Meta currentPath={location.pathname} title="Site Profile" />;
};
