import { Chip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { parseTopic } from "@triply/utils-private/SearchQueryParser.js";
import { Button, FontAwesomeIcon } from "#components/index.ts";
import BasicTree from "#helpers/BasicTree.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import Tree from "./Tree";
import * as styles from "./style.scss";

export interface Props {
  style?: React.CSSProperties;
  className?: string;
  ariaLabel: string;
  search: (term: string) => any;
  searching?: boolean;
  initialSearchTerm?: string;
  topics?: Array<BasicTree>;
  placeHolder?: string;
  autoFocus?: boolean;
  onChange?: (term: string) => any;
}

const SearchField: React.FC<Props> = ({
  search: _search,
  style,
  className,
  searching,
  initialSearchTerm,
  ariaLabel,
  topics,
  autoFocus,
  placeHolder,
  onChange,
}) => {
  const topicRef = React.useRef<HTMLDivElement>(null);
  const [showTopics, setShowTopics] = React.useState(false);
  const [topicButtonWidth, setTopicButtonWidth] = React.useState(10);
  // Init these empty, settings these now will causes the input to focus at the start
  const [currentTopic, setCurrentTopic] = React.useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    if (topicRef.current) setTopicButtonWidth(10 + topicRef.current.offsetWidth);
  }, [topicRef.current?.offsetWidth, currentTopic]);

  const search = () => {
    if (searching || (searchTerm.trim() === "" && !currentTopic)) return;
    _search(`${currentTopic ? `topic:${currentTopic} ` : ""}${searchTerm}`);
  };
  // Get the topic from the initial query
  React.useEffect(() => {
    if (initialSearchTerm) {
      const parsedQuery = parseTopic(initialSearchTerm);
      setSearchTerm(parsedQuery.q || "");
      setCurrentTopic(parsedQuery.topic);
    }
  }, [initialSearchTerm]);
  // Execute search when a topic is set
  React.useEffect(() => {
    const parsedQuery = parseTopic(initialSearchTerm || "");
    if (parsedQuery.topic !== currentTopic) search();
    // Explicitly left initialSearchTerm out of this as the previous hook should trigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTopic]);

  useClickOutside(() => setShowTopics(false), topicRef);
  return (
    <form
      className={getClassName(className, styles.container)}
      style={style}
      role="search"
      aria-label={ariaLabel}
      onSubmit={(e) => {
        e.preventDefault();
        search();
      }}
    >
      {topics && topics.length > 0 && (
        <div ref={topicRef} className={getClassName("mt-0", styles.topic)}>
          {currentTopic ? (
            <Chip
              size="small"
              className={getClassName("mt-0", styles.topicSelected)}
              onDelete={() => {
                setCurrentTopic(undefined);
              }}
              label={currentTopic}
              color="primary"
              variant="outlined"
            />
          ) : (
            <Button
              onClick={() => {
                setShowTopics((currentValue) => !currentValue);
              }}
              className="mt-0"
              endIcon={<FontAwesomeIcon icon={["fas", "caret-down"]} />}
            >
              Topic
            </Button>
          )}
          {showTopics && (
            <div className={getClassName("py-2", styles.topics)}>
              {topics.map((n) => (
                <Tree
                  key={n.getData().id}
                  node={n}
                  onClick={(node) => {
                    setCurrentTopic(node.getData().label);
                    setShowTopics(false);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <input
        type={"search"}
        value={searchTerm}
        autoFocus={!!autoFocus}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            search();
            e.currentTarget.blur();
          }
        }}
        onChange={(e) => {
          setSearchTerm(e.currentTarget.value);
          onChange?.(e.currentTarget.value);
        }}
        className={styles.searchFieldInput}
        style={{ paddingLeft: topicButtonWidth }}
        placeholder={placeHolder}
      />
      <Button
        color="secondary"
        aria-label="Search"
        type="submit"
        disabled={searching || (searchTerm.trim() === "" && !currentTopic)}
        className={getClassName("mt-0", styles.search)}
        startIcon={
          <FontAwesomeIcon icon="search" size="2x" className={getClassName({ [styles.searching]: searching })} />
        }
      />
    </form>
  );
};

export default SearchField;
