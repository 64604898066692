import { Switch, SwitchProps } from "@mui/material";
import * as React from "react";
import * as ReduxForm from "redux-form";

/**
 * Regular switch. Use mui switch as-is
 */
namespace MuiSwitch {
  export interface Props extends SwitchProps {}
}
const MuiSwitch = Switch;
export default MuiSwitch;

/**
 * Switch wrapped for easy integration in redux-form field
 */
export namespace MuiSwitchRedux {
  export type Props = ReduxForm.WrappedFieldProps & MuiSwitch.Props;
}
export const MuiSwitchRedux = React.memo<ReduxForm.WrappedFieldProps & MuiSwitch.Props>(
  ({ input: { onChange, value, ...inputProps }, checked, meta, ...props }) => {
    return <MuiSwitch {...inputProps} {...props} onChange={onChange} checked={!!value} />;
  },
);
