import { ThemeOptions } from "@mui/material";

export function getThemeOptions(theme: {
  primary: string;
  secondary: string;
  error: string;
  success: string;
  warning: string;
}): ThemeOptions {
  const { primary, secondary, success, warning, error } = { ...theme };
  return {
    components: {
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
        styleOverrides: {
          root: {
            letterSpacing: "0.02857em",
            "&:hover": {
              textDecoration: "none",
            },
            "&:focus": {
              textDecoration: "none",
            },
          },
          startIcon: {
            "> :nth-of-type(1)": {
              fontSize: "inherit",
            },
          },
          endIcon: {
            "> :nth-of-type(1)": {
              fontSize: "inherit",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            height: 48,
            minHeight: 48,
          },
        },
      },

      MuiFormControl: {
        defaultProps: {
          variant: "standard",
        },
        styleOverrides: {
          root: {
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "@media print": {
              display: "none",
            },
          },
          underline: {
            //By default mui makes the bottom line of the input field bold on hover
            //For consistency with other text components, we shouldn't do this
            //And, it looks ugly having it bold
            "&:hover:not(.Mui-disabled):before": {
              //color taken from here https://github.com/mui-org/material-ui/blob/ec559c45c9c0d040e2ebcdd2e70a58d20edb3c6c/packages/material-ui/src/Input/Input.js#L12
              borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "12px",
          },
          adornedEnd: {
            paddingRight: "4px",
          },
          root: {
            //By default mui makes darkens the color when hovering
            //For consistency with other text components, we shouldn't do this
            //And, it looks ugly having it bold
            "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
              // color taken from here https://github.com/mui-org/material-ui/blob/ec559c45c9c0d040e2ebcdd2e70a58d20edb3c6c/packages/material-ui/src/OutlinedInput/OutlinedInput.js#L13
              borderColor: "rgba(0,0,0, 0.23)",
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "@media print": {
              display: "none",
            },
          },
          switchBase: {
            //switch heigh is a lot larger by default, causing too much spacing
            //see https://github.com/mui-org/material-ui/issues/11147
            height: "100%",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            //by default there is _lots_ of padding
            //To be consistent with the previous styling of mui, I'm resetting this
            paddingTop: 0,
            paddingBottom: 0,
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            //Same as MuiCheckbox
            paddingTop: 0,
            paddingBottom: 0,
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.Mui-focusVisible": {
              outline: `2px solid ${secondary}`,
            },
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            "&.Mui-focusVisible": {
              outline: `3px solid ${secondary}`,
            },
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard",
        },
        styleOverrides: {
          select: {
            "@media print": {
              display: "none",
            },
            "&.Mui-focusVisible": {
              outline: `2px solid ${secondary}`,
              background: "none",
            },
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            "@media print": {
              display: "none",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.Mui-focusVisible": {
              outline: `2px solid ${secondary}`,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          filledWarning: {
            color: "#fff",
          },
        },
      },
    },
    typography: {
      fontFamily: `"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
        contrastText: "#fff",
      },
      success: {
        main: success,
        contrastText: "#fff",
      },
      error: {
        main: error,
      },
      warning: {
        main: warning,
        contrastText: "#fff",
      },
    },
  };
}
