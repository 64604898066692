import { TextField } from "@mui/material";
import React from "react";
import OtpInput, { OTPInputProps } from "react-otp-input";
import { MarkOptional } from "ts-essentials";

type Props = Omit<MarkOptional<OTPInputProps, "numInputs">, "renderSeparator" | "renderInput"> & {
  hasErrored: boolean;
  disabled?: boolean;
};
const AuthenticatorCode: React.FC<Props> = ({ numInputs, hasErrored, disabled, ...rest }) => {
  return (
    <OtpInput
      {...rest}
      numInputs={numInputs ?? 6}
      renderSeparator={() => <span className="mx-1">-</span>}
      renderInput={({ ref, style, ...props }) => (
        <TextField
          {...props}
          hiddenLabel
          inputProps={{ ref: ref, style: style }}
          variant="filled"
          error={hasErrored}
          disabled={disabled}
        />
      )}
    />
  );
};

export default AuthenticatorCode;
