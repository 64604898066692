import { Chip } from "@mui/material";
import * as React from "react";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";

const Abstract: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data } = useSparql<any>(
    currentClass &&
      `
    prefix dash: <http://datashapes.org/dash#>

    ask {
      bind(<${currentClass}> as ?currentClass)
      ?currentClass dash:abstract true .
    }
  `,
  );

  if (!currentClass) return null;

  if (data)
    return (
      <div>
        <Chip label="Abstract class" title="This class is an abstract class. It cannot have direct instances." />
      </div>
    );

  return null;
};

export default Abstract;
