import * as React from "react";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { User } from "#reducers/accountCollection.ts";
import { updateProfile } from "#reducers/accounts.ts";
import { trimUntouched } from "./utils.ts";

const Permissions: React.FC<{ currentAccount: User; editingSelf: boolean }> = ({ currentAccount, editingSelf }) => {
  const dispatch = useDispatch();

  const handleToggleAdmin = (values: Forms.AccountPermissions.FormData) => {
    return dispatch<typeof updateProfile>(
      updateProfile(
        currentAccount,
        trimUntouched(
          {
            role: values.role,
            expiresAt: values.expiresAt && new Date(values.expiresAt).toISOString(),
            verified: values.verified,
          },
          currentAccount,
        ),
      ),
    ).catch((e: any) => {
      throw new SubmissionError({ _error: e.message });
    });
  };

  return (
    <div className="whiteSink">
      <h3>Permissions &amp; access</h3>
      <Forms.AccountPermissions
        key={currentAccount.accountName + "_AccountPermissionsForm"}
        form={currentAccount.accountName + "_AccountPermissionsForm"}
        initialValues={{
          role: currentAccount.role,
          verified: !!currentAccount.verified,
          expiresAt: (() => {
            if (!currentAccount.expiresAt) return "";
            const d = new Date(currentAccount.expiresAt);

            let month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          })(),
        }}
        editingSelf={editingSelf}
        authMethod={currentAccount.authMethod}
        onSubmit={handleToggleAdmin}
      />
    </div>
  );
};

export default Permissions;
