// github schema is the default one used by rehype-sanitize
import { Root } from "hast";
import { defaultSchema, Schema as SanitizeSchema } from "hast-util-sanitize";
import { select as hastSelect, selectAll as hastSelectAll } from "hast-util-select";
import { toString as hastToString } from "hast-util-to-string";
import { cloneDeep, merge, replace } from "lodash-es";
import { MarkRequired } from "ts-essentials";
import { Plugin, Processor } from "unified";
import { Node } from "unist";
import { visit } from "unist-util-visit";
import { isAllowedPrismClassName, isPrismClassName } from "./prism.ts";

function getBaseSchema() {
  return cloneDeep(defaultSchema) as MarkRequired<SanitizeSchema, "tagNames">;
}
export interface HtmlElementNode extends Node {
  type: "element";
  tagName: string;
  properties?: {
    [prop: string]: unknown;
  };
  children?: Node[];
}

export function rehypeToPlainText(this: Processor) {
  this.compiler = (tree) => {
    return hastToString(tree as any);
  };
}

export function getSanitizeSchema(type: "fullHtml" | "compactHtml" | "text") {
  const baseSchema = getBaseSchema();

  //Always want to include all classnames (these are postprocessed by a different plugin)
  const customSchema = {
    attributes: {
      "*": ["className", "style"],
      video: ["controls", "src", "preload"],
      audio: ["controls", "src", "preload"],
      source: ["src", "type", "srcSet"],
    },
    protocols: {
      srcSet: ["http", "https", "data"],
    },
    ancestors: {
      source: ["picture", "video", "audio"],
    },
  } satisfies Partial<SanitizeSchema>;
  const finalizedSchema = merge({ tagNames: [] }, baseSchema, customSchema);
  if (type === "fullHtml") {
    finalizedSchema.tagNames.push("video", "audio", "picture", "source", "center", "style"); // merging list will override based on idx
    return finalizedSchema;
  }

  let stripTags: string[];
  if (type === "compactHtml") {
    stripTags = ["img", "style"];
  } else {
    //text
    stripTags = ["img", "code", "pre", "style"];
  }

  for (const stripTag of stripTags) {
    /**
     * We want to remove these from the list of allowed tags, and force these to be stripped
     * If we wouldnt use the strip attribute (and just exclude them from the attribute whitelist)
     * we would still render the content of these tags
     */
    finalizedSchema.tagNames = finalizedSchema.tagNames.filter((t: string) => t !== stripTag);
    if (!finalizedSchema.strip) finalizedSchema.strip = [];
    finalizedSchema.strip.push(stripTag);
  }
  return finalizedSchema;
}

export const wrapTable: Plugin<[{ compact: boolean }], Root> = (config) => {
  return function transformer(tree) {
    visit(tree, "element", function visitor(node, index, parent) {
      if (parent && node.tagName === "table" && index !== undefined) {
        if (config.compact) {
          //in compact mode remove the table altogether
          parent.children.splice(index, 1);
        } else {
          parent.children.splice(index, 1, {
            type: "element",
            tagName: "div",
            properties: {
              className: ["tableWrapper"],
            },
            children: [node],
          });
        }
      }
    });
  };
};

export const mermaidCodeblockToDiv: Plugin<[], Root> = () => {
  return function transformer(tree) {
    visit(tree, "element", function visitor(node, index, parent) {
      const { tagName } = node;
      if (tagName === "pre" && index !== undefined) {
        const mermaidBlock = hastSelect("code.language-mermaid", node);
        if (parent && mermaidBlock) {
          const child = mermaidBlock.children[0];
          // Add leading linebreaks to the mermaid chart, fixing issue caused by dedent package in mermaid.
          // See https://issues.triply.cc/issues/7834
          if (child.type === "text") child.value = "\n\n" + child.value;
          parent.children.splice(index, 1, {
            type: "element",
            tagName: "div",
            properties: {
              className: ["mermaid"],
            },
            children: mermaidBlock.children,
          });
        }
      }
    });
  };
};

export const removeMermaidCodeblock: Plugin<[], Root> = () => {
  return function transformer(tree) {
    visit(tree, "element", function visitor(node, index, parent) {
      const { tagName } = node;
      if (tagName === "pre" && index !== undefined) {
        const mermaidBlock = hastSelect("code.language-mermaid", node);
        if (parent && mermaidBlock) {
          parent.children.splice(index, 1);
        }
      }
    });
  };
};

/**
 * Manually removing class names, as we want to whitelist other parts
 * The rehype-sanitize does not support excluding class names by value..
 *
 */
export const cleanupClasses: Plugin<[{ compact: boolean }], Root> = (config) => {
  return function transformer(tree) {
    let node: HtmlElementNode;
    for (node of hastSelectAll("[class]", tree)) {
      const classNames = node.properties?.className;
      if (node.properties && Array.isArray(classNames)) {
        let i = classNames.length;

        while (i--) {
          const className = classNames[i];
          if (!config?.compact && node.tagName === "div" && className === "mermaid") {
            // ok, keep it
            continue;
          } else if (node.tagName === "code" && isAllowedPrismClassName(className)) {
            // ok, keep it
            continue;
          } else if (node.tagName === "code" && isPrismClassName(className)) {
            //It's a prism classname, but we dont support this language
            classNames[i] = "language-none";
            continue;
          }
          //Remove all other classnames
          classNames.splice(i, 1);
        }
        node.properties.className = classNames;
      }
    }
  };
};

export function isLinkToDomain(href: string, domain: string | undefined) {
  const baseUrl = "https://" + domain;
  try {
    return new URL(href, baseUrl).hostname === domain;
  } catch {
    return false;
    // Probably a malformed URL.
  }
}

export function modifyAnchorTags(openLinkInCurrentWindow: (href: string) => boolean = () => false): Plugin<[], Root> {
  return function attacher() {
    return function transformer(tree) {
      visit(tree, { tagName: "a" }, function visitor(node) {
        if (node.properties && typeof node.properties.href === "string") {
          if (typeof node.properties.href === "string") {
            if (openLinkInCurrentWindow(node.properties.href)) {
              node.properties.target = "_self";
            } else {
              node.properties.rel = "noreferrer noopener";
              node.properties.target = "_blank";
            }
          }
        }
      });
    };
  };
}

export const applyPreloadToVideo: Plugin<[], Root> = () => {
  return function transformer(tree) {
    visit(tree, { tagName: "video" }, function visitor(node) {
      if (node.properties) {
        node.properties.preload = "metadata";
      }
    });
  };
};

/*
 * Wraps the whole css within @scope
 * Removes all position fixed styles from the css
 */
export const sanitizeStyles: Plugin<[], Root> = () => {
  return function transformer(tree) {
    visit(tree, { tagName: "style" }, function visitor(node) {
      const child = node.children[0];
      if ("value" in child) {
        const css = child.value;
        child.value = `@scope { ${replace(css, /position\s*:\s*fixed/i, "")} }`;
        node.children[0] = child;
      }
    });
  };
};
/**
 * We've had some issues when rendering in the console (client-side),
 * where an empty root document would result in client-side errors.
 * This only happened when we had a markdown description that included tags that were
 * filtered out by the sanitize middleware.
 * Adding empty content at the end of the unified pipeline fixes things
 */
export const handleEmptyDocument: Plugin<[], Root> = () =>
  function (tree) {
    if (tree.children.length === 0)
      tree.children.push({
        type: "element",
        tagName: "div",
        properties: {},
        children: [],
      });
    return tree;
  };
