//Using a relative import here because otherwise we'd be importing the context file from utils
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { renderRoutes, RouteConfig } from "react-router-config";
import { asyncConnect } from "redux-connect";
import ConsentHelper from "#components/ConsentHelper/index.tsx";
import { ErrorPage, GlobalLoadingBar } from "#components/index.ts";
import HotKeysProvider from "#containers/Hotkeys/index.tsx";
import QuickNavigate from "#containers/QuickNavigate/index.tsx";
import { getPredefinedAcl } from "#helpers/Acl.ts";
import { ConfirmationServiceProvider } from "#helpers/hooks/confirmation.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useWebSocketContext from "#helpers/hooks/useWebSocketContext.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { User } from "#reducers/accountCollection.ts";
import { getLoggedInUser, load as loadAuth, shouldLoadLoggedInUser } from "#reducers/auth.ts";
import { loadclientConfig, shouldEnforceTfaForUser, shouldLoadConfig } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import { urlInfoToString } from "#staticConfig.ts";
import * as Context from "../../context.ts";

export interface Props {
  route: RouteConfig;
}

const CheckUserDetails: React.FC<{}> = () => {
  const location = useLocation();
  const query = parseSearchString(location.search);
  const needToEnterEmailAddress = useSelector(
    (state: GlobalState) => !!state.auth.loggedInUser && !state.accountCollection[state.auth.loggedInUser]?.email,
  );
  const needToSetupTfa = useSelector((state: GlobalState) => {
    if (!state.auth.loggedInUser) return false;
    const user = state.accountCollection[state.auth.loggedInUser] as User;
    if (user.mfaEnabled) return false;
    if (shouldEnforceTfaForUser(user, state.config.clientConfig?.enforceTfa)) {
      return user.accountName;
    }
    return false;
  });

  if (needToSetupTfa && location.pathname !== `/${needToSetupTfa}/-/2faSetup`) {
    return (
      <Redirect
        to={{
          pathname: `/${needToSetupTfa}/-/2faSetup`,
          search: stringifyQuery({ returnTo: query.returnTo || location.pathname + location.search + location.hash }),
        }}
      />
    );
  }

  if (needToEnterEmailAddress && location.pathname !== "/_details") {
    return <Redirect to="/_details" />;
  }

  return null;
};

const App: React.FC<Props> = ({ route }) => {
  const dispatch = useDispatch();
  const [timeZone, setTimeZone] = React.useState("UTC");
  const { authenticatedAccountName, authenticatedUid, isLoggedIn, role } = useSelector((state: GlobalState) => {
    const loggedInUser = getLoggedInUser(state);
    return {
      authenticatedAccountName: loggedInUser?.accountName,
      authenticatedUid: loggedInUser?.uid,
      isLoggedIn: !!loggedInUser,
      role: loggedInUser?.role,
    };
  }, shallowEqual);
  const clientConfig = useSelector((state: GlobalState) => state.config.clientConfig);
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);

  const webSocketContext = useWebSocketContext(urlInfoToString(staticConfig?.apiUrl), isLoggedIn);

  React.useEffect(() => {
    //The timezone value is something we cannot read on the server, as it's not part of the request.
    //I.e., we're using the default UTC timezone on the server, and on mounting the app we're resetting it to the
    //correct one. This way we avoid out-of-sync issues between server and client
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz) setTimeZone(tz); //IE11 does not support this. I.e., just use the UTC in IE11

    // Remove the server-side injected CSS.
    if (__DEVELOPMENT__) {
      window.addEventListener("error", (e) => {
        // Ignore ResizeObserver error, it is not shown in the devtools and it doesn't do harm
        // https://stackoverflow.com/a/50387233
        if (e?.message === "ResizeObserver loop limit exceeded") return;
        if (e?.message === "ResizeObserver loop completed with undelivered notifications.") return;
        dispatch(showNotification(`Error (see browser developer tools for more info)`, "error"));
      });
      window.addEventListener("unhandledrejection", () => {
        dispatch(showNotification(`Unhandled rejection (see browser developer tools for more info)`, "error"));
      });
    }

    // eslint-disable-next-line
  }, []); //run only onmount

  if (!clientConfig) {
    // We should always have a client config. Something is really wrong (e.g. api down) when we dont have this info
    return <ErrorPage statusCode={503} message="Undergoing maintenance" />;
  }
  return (
    <ConfirmationServiceProvider>
      <CheckUserDetails />
      <Helmet>
        {staticConfig?.plausible && __CLIENT__ && (
          <script async defer data-domain={staticConfig?.consoleUrl.domain} src={staticConfig.plausible}></script>
        )}
        <style>
          {`:root {
            --color-primary: ${staticConfig?.theme.primary};
            --color-secondary: ${staticConfig?.theme.secondary};
            --color-banner: ${staticConfig?.theme.banner};
            --color-error: ${staticConfig?.theme.error};
            --color-warning: ${staticConfig?.theme.warning};
            --color-success: ${staticConfig?.theme.success};
            --color-info: ${staticConfig?.theme.info};
            --color-link: ${staticConfig?.theme.link};
            --color-link-hover: ${staticConfig?.theme.linkHover};
            --color-gray-lighter: ${staticConfig?.theme.grayLighter};
            --color-gray-light: ${staticConfig?.theme.grayLight};
            --color-gray: ${staticConfig?.theme.gray};
            --color-gray-dark:${staticConfig?.theme.grayDark};
          }`}
        </style>
        {staticConfig?.customCss && <style>{staticConfig.customCss}</style>}
      </Helmet>
      <Context.TimezoneContext.Provider value={timeZone}>
        <Context.AclContext.Provider value={getPredefinedAcl(authenticatedAccountName, authenticatedUid, role)}>
          <HotKeysProvider>
            <QuickNavigate />
            <GlobalLoadingBar />
            <ConsentHelper />
            <Context.WebSocketContext.Provider value={webSocketContext}>
              {clientConfig && renderRoutes(route.routes)}
            </Context.WebSocketContext.Provider>
          </HotKeysProvider>
        </Context.AclContext.Provider>
      </Context.TimezoneContext.Provider>
    </ConfirmationServiceProvider>
  );
};

const AppMemo = React.memo(App);

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      const promises: any[] = [];
      if (shouldLoadLoggedInUser(getState())) {
        promises.push(dispatch<any>(loadAuth()).catch(() => {}));
      }
      if (shouldLoadConfig(getState())) {
        promises.push(dispatch<any>(loadclientConfig()).catch(() => {}));
      }
      return Promise.all(promises);
    },
  },
])(({ route }) => <AppMemo route={route} />) as typeof App;
