import getClassName from "classnames";
import * as React from "react";
import * as styles from "./style.scss";

export interface LabelProps {
  className?: string;
  style?: React.CSSProperties;
  message?: string | React.ReactElement<any>;
  children?: React.ReactChild;

  //colors
  error?: boolean;
  warning?: boolean;
  neutral?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const { className, message, style, error, warning, success, neutral, primary, children, secondary } = props;

  if (!message && !children) return null;

  const classNames: { [className: string]: boolean } = {
    [styles.label]: !!styles.label,
    [styles.error]: !!error,
    [styles.warning]: !!warning,
    [styles.primary]: !!primary,
    [styles.secondary]: !!secondary,
    [styles.success]: !!success,
    [styles.neutral]: neutral || (!error && !warning && !success && !primary && !secondary),
    [className || ""]: !!className,
  };

  return (
    <div style={style || {}} className={getClassName(classNames)}>
      {children || <span>{message}</span>}
    </div>
  );
};

export default Label;
