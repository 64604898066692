import getClassName from "classnames";
import * as React from "react";
import * as styles from "./style.scss";

namespace FlexContainer {
  export interface Props {
    className?: string;
    innerClassName?: string;
    style?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
    innerId?: string;
    children: React.ReactNode;
  }
}
const FlexContainer: React.FC<FlexContainer.Props> = ({
  className,
  style,
  innerClassName,
  innerId,
  innerStyle,
  children,
}) => {
  return (
    <div className={getClassName(styles.flex, className)} style={style}>
      <div className={styles.side} />
      <div id={innerId} className={getClassName(styles.container, innerClassName)} style={innerStyle}>
        {children}
      </div>
      <div className={styles.side} />
    </div>
  );
};

export default FlexContainer;
