import { BaseType, schemeCategory10, select, transition } from "d3";
import numeral from "numeral";
import React, { useEffect, useRef, useState } from "react";
import { Models, prefixUtils } from "@triply/utils";
import { INSIGHTS_IRI_FOR_CLASS_UNKNOWN } from "@triply/utils/Constants.js";
import * as styles from "./style.scss";

const getLocalName = (iri: string) =>
  iri.indexOf("#") >= 0 ? iri.split("#").slice(-1)[0] : iri.split("/").slice(-1)[0];

const formatInstances = (n: number | undefined) =>
  n === 1 ? "1 instance" : `${numeral(n || 0).format("0,0")} instances`;

export interface Props {
  data: Models.ClassFrequency;
  prefixes: Models.Prefixes;
}

const BarChart: React.FC<Props> = (props) => {
  const [selectedClass, setSelectedClass] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && props.data) {
      visualizeDatabars(
        ref.current,
        processData(props.data, props.prefixes, selectedClass),
        setSelectedClass,
        selectedClass,
      );
    }
  }, [props.data, selectedClass, props.prefixes]);

  return <div ref={ref} className={styles.barChart} />;
};

interface ClassData {
  iri: string;
  localName: string;
  prefixed: string;
  number: number;
  selected: boolean;
  properties: PropertyData[];
  numberOfPropertiesShown: number;
  ratio: number;
  i: number;
}
interface PropertyData {
  iri: string;
  localName: string;
  prefixed: string;
  number: number;
  percent: number;
  parent: ClassData;
}

const processData = (data: Models.ClassFrequency, prefixes: Models.Prefixes, selectedClass?: number) =>
  data.map((classData, i) => {
    const result: ClassData = {
      ...classData,
      i,
      localName: classData.iri === INSIGHTS_IRI_FOR_CLASS_UNKNOWN ? "Class unknown" : getLocalName(classData.iri),
      prefixed:
        classData.iri === INSIGHTS_IRI_FOR_CLASS_UNKNOWN
          ? "Class unknown"
          : prefixUtils.getPrefixed(classData.iri, prefixes),
      selected: i === selectedClass,
      properties: [],
      numberOfPropertiesShown: Math.min(10, classData.properties ? classData.properties.length : 0),
      ratio: classData.number / data[selectedClass === undefined ? 0 : selectedClass].number,
    };
    result.properties =
      (classData.properties &&
        classData.properties.map((propertyData) => {
          return {
            ...propertyData,
            localName: getLocalName(propertyData.iri),
            prefixed: prefixUtils.getPrefixed(propertyData.iri, prefixes),
            percent: (100 * propertyData.number) / classData.number,
            parent: result,
          };
        })) ||
      [];

    return result;
  });

export default BarChart;

function visualizeDatabars(
  node: HTMLDivElement,
  data: ReturnType<typeof processData>,
  setSelectedClass: (i: number | undefined) => void,
  selectedClass?: number,
) {
  const getTransition = () => {
    return transition().duration(350);
  };
  const t = getTransition();
  const root = select(node).selectAll("div.root").data([0]).join("div").classed("root", true);
  const rootContainer = root
    .selectAll<BaseType, ClassData>(`div.${styles.classContainer}`)
    .data(data, (d) => d.iri)
    .join((enter) => {
      const classContainer = enter.append("div").classed(styles.classContainer, true);
      const classRow = classContainer
        .append("div")
        .classed(styles.classRow, true)
        .style("height", "0px")
        .style("opacity", 0)
        .style("padding-top", "0px")
        .style("padding-bottom", "0px")
        .attr("title", (d) => {
          if (d.iri === INSIGHTS_IRI_FOR_CLASS_UNKNOWN)
            return `${numeral(d.number).format("0,0")} resources are not assigned to any class`;
          return `${d.prefixed || d.iri} has ${formatInstances(d.number)}`;
        })
        .on("click", function (this, _event, _data) {
          const d = select<BaseType, ClassData>(this.parentNode as HTMLElement).datum();
          setSelectedClass(d?.selected ? undefined : d.i);
        });

      classRow
        .transition(t as any)
        .style("height", "29px")
        .style("opacity", 1)
        .style("padding-top", "4px")
        .style("padding-bottom", "4px");

      classRow
        .append("div")
        .text((d) => d.localName || d.prefixed || d.iri)
        .style("font-style", (d) => (d.iri === INSIGHTS_IRI_FOR_CLASS_UNKNOWN ? "italic" : null))
        .classed(styles.label, true);

      classRow
        .append("div")
        .classed(styles.barContainer, true)
        .append("div")
        .classed(styles.bar, true)
        .style("width", "0px");

      classContainer.append("div").classed(styles.properties, true);

      classContainer
        .append("div")
        .classed(styles.showMoreProperties, true)
        .text("Show more...")
        .style("height", "0px")
        .attr("title", "Show more properties")
        .on("click", function () {
          const d = select<BaseType, ClassData>(this.parentNode as HTMLElement).datum();
          d.numberOfPropertiesShown += 10;
          renderProperties();
        });

      return classContainer;
    });

  rootContainer
    .select<HTMLDivElement>(`.${styles.bar}`)
    .transition(t as any)
    .style("background-color", (d) => (d.selected ? schemeCategory10[1] : schemeCategory10[0]))
    .style("opacity", (d) => (!d.selected && selectedClass !== undefined && 0.3) || null)
    .transition()
    .style("width", (d) => `${Math.min(100 * d.ratio, 100)}%`);

  rootContainer
    .transition(t as any)
    .style("opacity", (d) => (selectedClass !== undefined && !d.selected ? 0.5 : 1))
    .style("font-weight", (d) => (!d.selected ? "normal" : "bold"));

  rootContainer
    .select(`.${styles.properties}`)
    .selectAll<any, any>(`div.${styles.propertyRow}`)
    .data(
      (d) => d.properties,
      (d: PropertyData) => d.iri,
    )
    .join((enter) => {
      const propertyRow = enter
        .append("div")
        .classed(styles.propertyRow, true)
        .attr(
          "title",
          (d: PropertyData) =>
            `${numeral(d.percent).format("0.0")}% (${numeral(d.number).format("0,0")} out of ${numeral(
              d.parent.number,
            ).format("0,0")}) of the ${
              d.parent.iri === INSIGHTS_IRI_FOR_CLASS_UNKNOWN
                ? "resources that are not assigned to any class"
                : `instances of ${d.parent.prefixed || d.parent.iri}`
            } have property ${d.prefixed || d.iri}`,
        )
        .style("height", "0px");

      propertyRow
        .append("div")
        .text((d: PropertyData) => d.localName || d.prefixed || d.iri)
        .classed(styles.label, true);

      propertyRow
        .append("div")
        .classed(styles.barContainer, true)
        .append("div")
        .classed(styles.bar, true)
        .style("width", "0px")
        .style("background-color", (d: PropertyData) =>
          d.percent === 100 ? schemeCategory10[2] : schemeCategory10[0],
        );

      return propertyRow;
    });

  const renderProperties = () => {
    const transition = getTransition();
    rootContainer
      .selectAll<BaseType, HTMLDivElement>(`.${styles.propertyRow}`)
      .transition(transition as any)
      .style("height", (d: any, i: any) => (d.parent.selected && i < d.parent.numberOfPropertiesShown ? "25px" : "0px"))
      .select(`.${styles.bar}`)
      .transition()
      .style("width", ((d: PropertyData) => `${d.percent * d.parent.ratio}%`) as any);

    rootContainer
      .selectAll<HTMLDivElement, BaseType>(`.${styles.showMoreProperties}`)
      .transition(transition as any)
      .style("height", function (): string {
        const d = select<BaseType, ClassData>(this.parentNode as HTMLElement).datum();
        return d.selected && d.numberOfPropertiesShown < d.properties.length ? "25px" : "0px";
      });
  };

  renderProperties();
}
