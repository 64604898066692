import memoizee from "memoizee";
import * as React from "react";
import fetch from "#helpers/fetch.ts";

export default function useMemoizedFetch<R = any>(maxAge?: number) {
  const ref = React.useRef(
    memoizee(
      async (url: string) => {
        return fetch(url, { credentials: "same-origin" })
          .then((response) => {
            if (response.status === 200) return response.json() as Promise<R>;
          })
          .catch((error) => {
            console.error(error);
            return undefined;
          });
      },
      { primitive: true, promise: true, maxAge: maxAge },
    ),
  );
  return ref.current;
}
