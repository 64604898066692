import * as React from "react";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { color } from "./useData.ts";
import { getPrefixAndLabel } from "./utils.ts";

const ColorPrefixed: React.FC<{ children: string }> = ({ children }) => {
  const prefixes = useDatasetPrefixes();
  const { prefix, label } = getPrefixAndLabel(prefixes, children);
  return (
    <>
      {prefix && <span style={{ color: color(prefix) }}>{prefix + ":"}</span>}
      {label}
    </>
  );
};

export default ColorPrefixed;
