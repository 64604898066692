import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Constants } from "@triply/utils";
import { DatasetMetadata } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { Services } from "#reducers/services.ts";

const DATE_FORMAT = "YYYY-MM-DD"; // ISO-8601 date format

const DOWNLOADS = [
  { ext: ".trig.gz", mediaType: "application/trig", description: "TriG download" },
  { ext: ".nt.gz", mediaType: "application/n-triples", description: "N-Triples download" },
  { ext: ".nq.gz", mediaType: "application/n-quads", description: "N-Quads download" },
  { ext: ".ttl.gz", mediaType: "text/turtle", description: "Turtle download" },
  { ext: ".jsonld.gz", mediaType: "application/ld+json", description: "JSON-LD download" },
];
export interface Props {
  currentAccount: Account;
  currentDs: Dataset;
  services?: Services;
}
const DatasetInfoMetadata: React.FC<Props> = ({ currentAccount, currentDs, services }) => {
  const constructConsoleUrl = useConstructConsoleUrl();
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const brandingQuote = useSelector((state: GlobalState) => state.config.clientConfig?.branding.welcomeMessage);
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  const description =
    currentDs.description ||
    `Dataset ${currentDs.displayName || currentDs.name} of ${currentAccount.type === "org" ? "organization" : "user"} ${
      currentAccount.name || currentAccount.accountName
    } on ${brandingName}.`;

  const datasetUrl = constructConsoleUrl({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });

  const accountUrl = constructConsoleUrl({ pathname: `/${currentDs.owner.accountName}` });
  const landingpageUrl = constructConsoleUrl();
  const licenseLink = currentDs.license && Constants.LICENSES[currentDs.license].url;
  const image = currentDs.avatarUrl || currentAccount.avatarUrl || logoLg;
  const topics = [...currentDs.topics, ...currentDs.implicitTopics];
  const sparqlServices = (services || []).filter(
    (service) => service.capabilities.includes("sparqlApi") && service.status === "running",
  );
  const elasticServices = (services || []).filter(
    (service) => service.capabilities.includes("elasticSearch") && service.status === "running",
  );
  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: datasetUrl,
    mainEntity: filterEmptyVals({
      "@id": datasetUrl,
      "@type": "Dataset",
      about: topics.map((t) => t.iri),
      dateModified: moment(currentDs.updatedAt).format(DATE_FORMAT),
      datePublished: moment(currentDs.createdAt).format(DATE_FORMAT),
      description: description,
      distribution: [
        ...DOWNLOADS.map((d) => ({
          "@type": "DataDownload",
          encodingFormat: d.mediaType,
          contentUrl: `${datasetUrl}/download${d.ext}`,
          description: d.description,
        })),
        ...sparqlServices.map((service) => ({
          "@id": `${datasetUrl}/sparql/${service.name}`,
          "@type": "DataDownload",
          name: service.name,
          encodingFormat: "application/sparql-results+json",
          contentUrl: service.endpoint,
          description: "SPARQL endpoint",
        })),
        ...elasticServices.map((service) => ({
          "@id": `${datasetUrl}/elasticsearch/${service.name}`,
          "@type": "DataDownload",
          name: service.name,
          encodingFormat: "application/json",
          contentUrl: service.endpoint,
          description: "Elasticsearch endpoint",
        })),
      ],
      identifier: datasetUrl,
      image: image,
      inLanguage: "iso639-1:en",
      includedInDataCatalog: {
        "@type": "DataCatalog",
        "@id": landingpageUrl,
        name: brandingName,
        description: brandingQuote,
        publisher: {
          "@type": currentAccount.type === "org" ? "Organization" : "Person",
          "@id": accountUrl,
          name: currentAccount.name || currentAccount.accountName,
        },
        dataset: {
          "@id": datasetUrl,
        },
      },
      keywords: topics.map((t) => t.label),
      license: licenseLink && {
        "@id": licenseLink,
        "@type": "CreativeWork",
        name: currentDs.license,
        url: licenseLink,
      },
      name: currentDs.displayName || currentDs.name,
      publisher: {
        "@type": currentAccount.type === "org" ? "Organization" : "Person",
        "@id": accountUrl,
        name: currentAccount.name || currentAccount.accountName,
      },
      creator: {
        "@type": currentAccount.type === "org" ? "Organization" : "Person",
        "@id": accountUrl,
        name: currentAccount.name || currentAccount.accountName,
      },
      url: datasetUrl,
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    },
  };
  return (
    <DatasetMetadata
      currentAccount={currentAccount}
      currentDs={currentDs}
      currentPath={`/${currentAccount.accountName}/${currentDs.name}`}
      title={``}
      jsonLd={(currentDs.accessLevel === "public" && [{ key: "schema", json: schemaDotOrgJsonLd }]) || undefined}
    />
  );
};
export default DatasetInfoMetadata;
