import * as React from "react";
import { useSelector } from "react-redux";
import { ErrorPage } from "#components/index.ts";
import { TabLink } from "#containers/ConsoleTabs/index.tsx";
import { ConsoleTabs, IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";

const AccountSettingsTabs: React.FC<IComponentProps> = (props) => {
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const etlLicenseKey = useSelector((state: GlobalState) => state.config.staticConfig?.etlLicenseKey);

  if (!currentAccount) return <ErrorPage statusCode={404} />;

  const tabLinks: TabLink[] = [];
  if (
    acl.check({ action: "editAccountMetadata", context: { roleInAccount: acl.getRoleInAccount(currentAccount) } })
      .granted
  ) {
    tabLinks.push({ pathname: "", name: "Profile" });
  }
  if (
    currentAccount.type === "user" &&
    acl.check({ action: "manageTokens", context: { roleInUser: acl.getRoleInAccount(currentAccount) } }).granted
  ) {
    tabLinks.push({ pathname: "/tokens", name: "API Tokens" });
  }
  tabLinks.push(
    currentAccount.type === "user"
      ? { pathname: "/orgs", name: "My organizations" }
      : { pathname: "/members", name: "Organization members" },
  );
  if (currentAccount.type === "user" && etlLicenseKey) {
    tabLinks.push({ pathname: "/etl", name: "Triply ETL" });
  }
  if (
    acl.check({ action: "readQueryJob", context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) } }).granted
  ) {
    tabLinks.push({ pathname: "/jobs", name: "Jobs" });
  }
  if (!tabLinks.length) return <ErrorPage statusCode={404} />;

  return (
    <ConsoleTabs
      {...props}
      title={`${currentAccount.type === "org" ? "Organization" : "User"} settings`}
      tabLinks={tabLinks}
    />
  );
};

export default AccountSettingsTabs;
