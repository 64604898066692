import getClassName from "classnames";
import * as React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { FontAwesomeButton, Image, ResourceWidget } from "#components/index.ts";
import { RDF_HTML_TYPES } from "#components/TermLiteralHTML/index.tsx";
import LdTreeNode from "#helpers/LdTree.ts";
import renderMarkup from "#helpers/markup/index.ts";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  getLabelFromLocalName,
  getWidgetCollection,
  selectLabel,
  Statements,
  WidgetCollection,
} from "#reducers/resourceDescriptions.ts";
import BackwardDescription from "./BackwardDescription.tsx";
import ForwardDescription from "./ForwardDescription.tsx";
import * as styles from "./styles/description.scss";

export interface Props {
  className?: string;
  style?: {};
  resource: string;
  inLinkWidgets?: WidgetCollection;
  outLinkWidgets?: WidgetCollection;
  loadBackwardResource: (resource: string, propertyLabel: string) => {};
  loadForwardResource: (resource: string, propertyLabel: string) => {};
  changeFocus: (focus: Props["focus"]) => void;
  focus: "forward" | "backward";
  linkPath: string;
  loadPage: (direction: "forward" | "backward", page: number, predicate?: string) => Promise<void>;
  statements: Statements;
}

const BannerDescription: React.FC<{ description: LdTreeNode }> = ({ description }) => {
  const consoleDomain = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl.domain);
  if (!consoleDomain) return null;
  const datatype = description.getTerm().datatype;
  if (datatype && RDF_HTML_TYPES.includes(datatype)) {
    return (
      <div className={getClassName(styles.description, styles.htmlDescription)}>
        {renderMarkup(description.getTerm().value, {
          openLinkInCurrentWindow: (href: string) => isLinkToDomain(href, consoleDomain),
          from: "html",
          to: "text",
        })}
      </div>
    );
  } else {
    return <div className={styles.description}>{description.getTerm().value.trim()}</div>;
  }
};

interface BannerProps {
  label: string;
  outLinkWidgets: WidgetCollection;
  linkPath: Props["linkPath"];
  loadForwardResource: Props["loadForwardResource"];
  resource: Props["resource"];
}
const Banner: React.FC<BannerProps> = ({ outLinkWidgets, label, linkPath, loadForwardResource, resource }) => {
  return (
    <div>
      <h2>{label}</h2>
      {outLinkWidgets.description?.values && <BannerDescription description={outLinkWidgets.description.values[0]} />}
      {outLinkWidgets.image?.values && (
        <Image src={outLinkWidgets.image.values[0].getTerm().value} imageClassName={styles.resourceImage} alt="" />
      )}
      {outLinkWidgets.classes?.values && (
        <div className={styles.types}>
          {outLinkWidgets.classes.values.map((v) => (
            <div className={styles.type} key={v.getKey()}>
              <ResourceWidget
                resource={v.getTerm()}
                widgetCollection={getWidgetCollection(v, [selectLabel])}
                linkPath={linkPath}
                loadResource={(resource) =>
                  loadForwardResource(
                    resource,
                    outLinkWidgets.classes?.label || getLabelFromLocalName(v.getTerm().value),
                  )
                }
                className="noLinkDecoration"
              />
            </div>
          ))}
        </div>
      )}
      <div className={styles.externalLink}>
        <a href={resource} rel="noopener noreferrer" target="_blank">
          {resource}
        </a>
      </div>
    </div>
  );
};

const Description: React.FC<Props> = ({
  changeFocus,
  focus,
  className,
  inLinkWidgets,
  outLinkWidgets,
  resource,
  linkPath,
  style,
  loadForwardResource,
  loadBackwardResource,
  loadPage,
  statements,
}) => {
  const dragAreaEl = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState(
    focus === "forward" ? 0 : !dragAreaEl.current ? 0 : (2 * dragAreaEl.current?.offsetWidth) / 7,
  );
  const resizeObserver = useResizeObserver();

  const onDragStop = (_e: any, positionObject: any) => {
    if (dragAreaEl.current && focus === "forward" && positionObject.x > 100) {
      changeFocus("backward");
    } else if (focus === "backward" && positionObject.x < position - 100) {
      changeFocus("forward");
    }
  };
  /**
   * This effect triggers when focus changes
   */
  React.useEffect(() => {
    // Enable transitions on focus change
    dragAreaEl.current?.classList.remove(styles.noTransition);
    if (focus === "backward") setPosition(!dragAreaEl.current ? 0 : (2 * dragAreaEl.current?.offsetWidth) / 7);
    if (focus === "forward") setPosition(0);
  }, [focus, setPosition]);
  /**
   * This effect triggers the dragContainer is resized
   */
  React.useEffect(() => {
    // Disable transitions on resize
    dragAreaEl.current?.classList.add(styles.noTransition);
    if (focus === "backward") setPosition(!dragAreaEl.current ? 0 : (2 * dragAreaEl.current?.offsetWidth) / 7);
    if (focus === "forward") setPosition(0);
    // We don't want this effect to trigger when focus changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPosition, resizeObserver.width]);

  const draggableNodeRef = React.useRef<HTMLDivElement>(null);

  const activeClasses = {
    [className || ""]: !!className,
    [styles.resource]: !!styles.resource,
    rounding: true,
    shadow: true,
  };

  const label =
    (outLinkWidgets?.label?.values && outLinkWidgets.label.values[0].getTerm().value) ||
    getLabelFromLocalName(resource);

  return (
    <div className={getClassName(activeClasses)} style={style}>
      <div className={styles.contentBox} ref={resizeObserver.ref}>
        <div
          className={getClassName(styles.resourceIdSmall, "py-5 px-6")}
          style={(focus === "backward" && { textAlign: "end" }) || {}}
        >
          {outLinkWidgets && (
            <Banner
              label={label}
              linkPath={linkPath}
              loadForwardResource={loadForwardResource}
              outLinkWidgets={outLinkWidgets}
              resource={resource}
            />
          )}
        </div>

        <div className={styles.contentDragArea} ref={dragAreaEl}>
          <Draggable
            nodeRef={draggableNodeRef}
            handle={".dragHandle"}
            position={{ x: position, y: 0 }}
            bounds="parent"
            onStop={onDragStop}
          >
            <div
              className={getClassName({
                [styles.content]: !!styles.content,
              })}
              ref={draggableNodeRef}
            >
              <BackwardDescription
                inLinkWidgets={inLinkWidgets}
                linkPath={linkPath}
                loadBackwardResource={loadBackwardResource}
                loadPage={loadPage}
                statements={statements}
                resource={resource}
              />

              <FontAwesomeButton
                key="goForward"
                className={getClassName(styles.navIconSm, "shadow", "dragHandle")}
                onClick={() => changeFocus("forward")}
                onTouchEnd={() => changeFocus("forward")}
                icon="chevron-right"
                title="View outgoing statements"
                disableHoverStyling
              />

              <div className={styles.resourceContainer}>
                {(focus === "forward" && (
                  <FontAwesomeButton
                    key="goBackward"
                    className={getClassName(styles.navIcon, "shadow")}
                    onClick={() => changeFocus("backward")}
                    icon="chevron-left"
                    title="view incoming statements"
                    disableHoverStyling
                  />
                )) || <div className="dragHandle" style={{ minWidth: 30 }} />}

                <div className={getClassName(styles.resourceId, "dragHandle py-6 px-3")}>
                  {outLinkWidgets && (
                    <Banner
                      label={label}
                      linkPath={linkPath}
                      loadForwardResource={loadForwardResource}
                      outLinkWidgets={outLinkWidgets}
                      resource={resource}
                    />
                  )}
                </div>

                {(focus === "backward" && (
                  <FontAwesomeButton
                    key="goForward"
                    className={getClassName(styles.navIcon, "shadow")}
                    onClick={() => changeFocus("forward")}
                    icon="chevron-right"
                    title="View outgoing statements"
                    disableHoverStyling
                  />
                )) || <div className="dragHandle" style={{ minWidth: 30 }} />}
              </div>
              <FontAwesomeButton
                key="goBackward"
                className={getClassName(styles.navIconSm, "shadow", "dragHandle")}
                onClick={() => changeFocus("backward")}
                onTouchEnd={() => changeFocus("backward")}
                icon="chevron-left"
                title="view incoming statements"
                disableHoverStyling
              />
              <ForwardDescription
                linkPath={linkPath}
                outLinkWidgets={outLinkWidgets}
                loadForwardResource={loadForwardResource}
                loadPage={loadPage}
                statements={statements}
                resource={resource}
              />
            </div>
          </Draggable>
        </div>
      </div>
    </div>
  );
};
export default Description;
