import * as React from "react";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";

export default function useApplyPrefixes() {
  const prefixes = useDatasetPrefixes();
  const applyPrefixes = React.useCallback(
    <A extends string | undefined | null>(iri: A) => {
      if (iri === undefined || iri === null) return iri as A;
      for (const p of prefixes) {
        if (iri?.startsWith(p.iri)) {
          return `${p.prefixLabel}:${iri.slice(p.iri.length)}` as A;
        }
      }
      return iri as A;
    },
    [prefixes],
  );
  return applyPrefixes;
}
