import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { ContactEmail as ContactEmailForm } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateClientConfig } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

interface Props {}

const ContactEmail: React.FC<Props> = ({}) => {
  const email = useSelector((state: GlobalState) => state.config.clientConfig?.contactEmail);

  const dispatch = useDispatch();

  const handleContactEmailSubmit = (values: ContactEmailForm.FormData) => {
    return dispatch<typeof updateClientConfig>(
      updateClientConfig({
        contactEmail: values.contactEmail,
      }),
    ).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  return (
    <div className="whiteSink">
      <h3>Contact Email</h3>
      <div className={styles.contactEmail}>
        <ContactEmailForm
          initialValues={{
            contactEmail: email,
          }}
          onSubmit={handleContactEmailSubmit}
          enableReinitialize
        />
      </div>
    </div>
  );
};
export default ContactEmail;
