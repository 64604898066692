import { Alert, Paper } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { AuthenticatorCode, LoadingButton, Meta } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { showNotification } from "#reducers/notifications.ts";
import * as styles from "./style.scss";

const RECOVERY_DIGITS = 8;

const Recovery2FA: React.FC<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [forbidden, setForbidden] = React.useState<boolean>(false);
  const [recoveryCode, setRecoveryCode] = React.useState<string>("");
  const authenticatedUser = useAuthenticatedUser();
  const history = useHistory();

  React.useEffect(() => {
    //already logged in, redirect to the account page
    if (authenticatedUser) {
      const destination = `/${authenticatedUser.accountName}`;
      history.push(destination);
    }
  }, [authenticatedUser, history, location.search]);

  async function sendCode(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(constructUrlToApi({ pathname: "/web/auth/local/login/recovery" }), {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ recoveryCode: recoveryCode }),
      });
      const body = response.headers.get("content-type")?.includes("application/json")
        ? await response.json()
        : undefined;

      if (response.status === 200) {
        window.location.replace(`/${body?.accountName}/-/settings?disabled2fa=1`);
        return;
      }

      setLoading(false);
      if (response.status === 401 && body?.code === 97) {
        // User-error, due to an incorrect recovery code.
        setError(
          `${body.message} If you exceed this number, you will not be able to use recovery codes to recover your account anymore.`,
        );
      } else if (response.status === 403) {
        // User-error, incorrect recovery code more than three times.
        setError(
          "Maximum number of incorrect recovery attempts reached. Please contact an administrator or Triply support.",
        );
        setForbidden(true);
      } else if (response.status >= 500) {
        // Server-error, due to api disruptions.
        dispatch(showNotification("Failed sending recovery code. Please try again.", "error"));
      } else {
        dispatch(showNotification("Something went wrong. Please try again.", "error"));
      }
    } catch (e) {
      setLoading(false);
      dispatch(showNotification("Failed sending recovery code. Please try again.", "error"));
    }
  }

  return (
    <div className="flex horizontalCenter mt-6">
      <Meta currentPath={location.pathname} title="Two-factor authentication - Recovery" />
      <Paper square className={styles.paper}>
        <form onSubmit={sendCode}>
          <Alert severity="warning">Two-factor authentication will be disabled after you recover your account.</Alert>
          <p className={styles.label}>Please enter a recovery code.</p>
          <p className={styles.labelSubtle}>These were provided when you enabled two-factor authentication.</p>
          <AuthenticatorCode
            value={recoveryCode}
            onChange={(code: string) => {
              if (error) setError(undefined);
              setRecoveryCode(code);
            }}
            disabled={forbidden}
            numInputs={RECOVERY_DIGITS}
            hasErrored={!!error}
            shouldAutoFocus
            containerStyle={getClassName("my-4", styles.recoveryCodeContainer)}
          />
          <LoadingButton
            type="submit"
            color="warning"
            disabled={recoveryCode.length !== RECOVERY_DIGITS || !!error || loading || forbidden}
            loading={loading}
          >
            Recover my account
          </LoadingButton>
          {error && <Alert severity="error">{error}</Alert>}
        </form>
      </Paper>
    </div>
  );
};

export default Recovery2FA;
