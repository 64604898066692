import { Alert, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import RemoveClass from "./RemoveClass";

const Label: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparql<[{ label: string }?]>(
    currentClass &&
      `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select ?label where {
      bind(<${currentClass}> as ?currentClass)
      ?currentClass rdfs:label ?label
    } limit 1
  `,
  );

  const applyPrefixes = useApplyPrefixes();

  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const mayManageDataModel = acl.check({
    action: "manageDataModel",
    context: { roleInOwnerAccount: acl.getRoleInAccount(currentAccount) },
  }).granted;

  if (!currentClass) return null;

  if (error) return <Alert severity="warning">Label could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  return (
    <div className="flex center g-5">
      <Typography className="grow" variant="h5" component="h1">
        {data?.[0]?.label || applyPrefixes(currentClass)}
      </Typography>
      {mayManageDataModel && <RemoveClass />}
    </div>
  );
};

export default Label;
