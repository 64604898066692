import { DialogContent, LinearProgress, Typography } from "@mui/material";
import * as React from "react";
import { Prompt } from "react-router";
import { Alert, Button, Dialog } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { deleteServiceFromAdminList, Service } from "#reducers/services.ts";

interface Props {
  open: boolean;
  onClose: () => void;
  services: Service[];
}

const BulkRemoveDialog: React.FC<Props> = ({ open, onClose: _onClose, services }) => {
  const [removingServices, setRemovingServices] = React.useState<"Verifying" | "Removing" | "Done">("Verifying");
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState<string[]>([]);
  const interrupted = React.useRef(false);
  const dispatch = useDispatch();
  // Reset component when dialog is opened
  React.useEffect(() => {
    if (open === true) {
      setProgress(0);
      setRemovingServices("Verifying");
      setErrors([]);
      interrupted.current = false;
    }
  }, [open]);
  const onClose = () => {
    if (removingServices === "Removing") {
      interrupted.current = true;
      setRemovingServices("Done");
    }
    _onClose();
  };
  const removeServices = async () => {
    if (!open) return;
    setRemovingServices("Removing");
    for (const service of services) {
      if (interrupted.current) return;
      await dispatch<typeof deleteServiceFromAdminList>(deleteServiceFromAdminList(service))
        .then(() => {
          // Make sure we're not updating an unmounted component
          if (!interrupted.current) setProgress((progress) => progress + 1);
        })
        .catch((e) => {
          // Make sure we're not updating an unmounted component
          if (!interrupted.current) {
            setErrors((errors) => [
              ...errors,
              `Failed removing ${service.dataset?.owner.accountName}/${service.dataset?.name}/${service.name} : ${e.message}`,
            ]);
          }
        });
    }
    if (!interrupted.current) setRemovingServices("Done");
  };
  return (
    <Dialog open={open} onClose={onClose} title="Remove services">
      <Prompt
        when={removingServices === "Removing"}
        message={() => {
          return "Moving away will interrupt the process. Are you sure?";
        }}
      />
      <DialogContent>
        {removingServices === "Verifying" && (
          <>
            <Typography>Are you sure you want to remove these services?</Typography>
            <ul>
              {services.map((service) => (
                <li key={service.id}>
                  {service.dataset?.owner.accountName}/{service.dataset?.name}/{service.name}
                </li>
              ))}
            </ul>
          </>
        )}
        {removingServices === "Removing" && (
          <>
            <Typography>Removing services, closing this window will interrupt the process</Typography>
            <LinearProgress variant="determinate" value={(progress / services.length) * 100} />
          </>
        )}
        {removingServices === "Done" && <>Successfully removed {progress} services</>}
        {errors.map((error, idx) => {
          return <Alert className="my-2" key={`service-removal-error-${idx}`} message={error} />;
        })}
      </DialogContent>
      <div className="m-5">
        {removingServices === "Verifying" && (
          <Button color="error" onClick={removeServices} className="mr-4">
            Delete ({services.length}) services
          </Button>
        )}
        <Button variant="text" onClick={onClose}>
          {removingServices === "Done" ? "Close" : "Cancel"}
        </Button>
      </div>
    </Dialog>
  );
};

export default BulkRemoveDialog;
