import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { withCookies } from "react-cookie";
import { connect, useSelector } from "react-redux";
import { renderRoutes, RouteConfig } from "react-router-config";
import { asyncConnect } from "redux-connect";
import { ErrorPage, LoadingPage } from "#components/index.ts";
import { DatasetPanel, IComponentProps } from "#containers/index.ts";
import useWebSocket from "#helpers/hooks/useWebSocket.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import { Dataset, getCurrentDataset, getDatasetInfo, shouldLoadDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";

export namespace NavDataset {
  export interface OwnProps extends IComponentProps {
    cookies?: any;
  }
  export interface DispatchProps {
    push: typeof connectedReactRouter.push;
  }
  export interface PropsFromState {
    authenticatedUser?: User;
    currentAccount?: Account;
    currentDs?: Dataset;
    reduxLocationKey?: string;
    reduxLocationPathname?: string;
  }
  export interface State {
    error?: Error;
    errorInfo?: any;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

/**
 * Functional component for rendering children. This way we can render hooks and such
 */
const DatasetChildren: React.FC<{
  error?: Error;
  errorInfo?: any;
  routes?: RouteConfig[];
  datasetId: string;
}> = (props) => {
  const { subscribe } = useWebSocket();
  const jobs = useSelector((state: GlobalState) => state.datasets[props.datasetId].jobs);
  const services = useSelector((state: GlobalState) => state.services[props.datasetId]);

  React.useEffect(() => {
    if (jobs) {
      for (const job of jobs) {
        subscribe(`/datasets/${props.datasetId}`, `/index-jobs/${job.jobId}`);
      }
    }
  }, [jobs, props.datasetId, subscribe]);

  React.useEffect(() => {
    if (services) {
      for (const service of services) {
        subscribe(`/datasets/${props.datasetId}`, `/services/${service.id}/metadata`);
      }
    }
  }, [services, props.datasetId, subscribe]);

  if (props.error) {
    return <ErrorPage error={props.error} />;
  } else if (!props.routes) {
    return null;
  } else {
    return renderRoutes(props.routes);
  }
};

/**
 * Class component for the dataset panel + the children. Useful, because it contains an error boundary,
 * and we can't use error boundaries with functional components.
 */
@asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      const promises: Promise<any>[] = [];
      if (!accountIsCurrentAccount(getState(), params.account))
        promises.push(dispatch<any>(getAccountInfo(getState(), params.account)).catch(() => {}));
      if (shouldLoadDataset(getState(), params.account, params.dataset))
        promises.push(dispatch<any>(getDatasetInfo(params.account, params.dataset)).catch(() => {}));
      return Promise.all(promises);
    },
  },
])
class NavDataset extends React.PureComponent<NavDataset.Props, NavDataset.State> {
  state: NavDataset.State = {
    error: undefined,
    errorInfo: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps: NavDataset.Props) {
    if (this.state.error && this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ error: undefined, errorInfo: undefined });
    }
  }

  componentDidCatch(error: Error, info: any) {
    this.setState({ error: error, errorInfo: info });
  }

  render() {
    const {
      authenticatedUser,
      currentDs,
      currentAccount,
      reduxLocationKey,
      reduxLocationPathname,
      match: { params },
      route,
      location,
    } = this.props;

    const loading = reduxLocationKey && reduxLocationKey !== location.key;
    const switchingDatasets =
      reduxLocationPathname && !reduxLocationPathname.startsWith(`/${params.account}/${params.dataset}`);

    if (loading && switchingDatasets) {
      return <LoadingPage />;
    }

    // return 404 page if account or dataset is not unknown
    if (!currentAccount || !currentDs) {
      let message = "Not found";
      if (!currentAccount && params.account?.[0] === "_") {
        message = `Account '${params.account}' not found. `;
      } else if (!currentDs && params.dataset?.[0] !== "_") {
        message = `Dataset '${params.dataset}' not found. `;
        if (!authenticatedUser) {
          message += "Log in to view datasets that are not public.";
        }
      }

      return <ErrorPage statusCode={404} message={message} />;
    }

    // show dataset page
    return (
      <>
        {route && <DatasetPanel route={route} />}
        <DatasetChildren
          datasetId={currentDs.id}
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          routes={route?.routes}
        />
      </>
    );
  }
}
export default withCookies(
  connect<NavDataset.PropsFromState, NavDataset.DispatchProps, NavDataset.OwnProps, GlobalState>(
    (state, _ownProps) => {
      return {
        authenticatedUser: getLoggedInUser(state),
        currentAccount: getCurrentAccount(state),
        currentDs: getCurrentDataset(state),
        reduxLocationKey: state.router.location.key,
        reduxLocationPathname: state.router.location.pathname,
      };
    },
    //dispatch
    {
      push: connectedReactRouter.push,
    },
  )(NavDataset) as unknown as typeof NavDataset,
);
