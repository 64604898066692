// extracted by mini-css-extract-plugin
var _1 = "style--avatar--CneUrBbX";
var _2 = "style--box--szlQkwmg";
var _3 = "style--colAvatar--kz9PpnPf";
var _4 = "style--colDescription--ho8tvbeb";
var _5 = "style--colIcons--mymlBOYT";
var _6 = "style--description--a8lFZKWH";
var _7 = "style--descriptionWrapper--H1fLa74Q";
var _8 = "style--dsTitle--epM_rjrB";
var _9 = "style--topics--L5lIElu0";
export { _1 as "avatar", _2 as "box", _3 as "colAvatar", _4 as "colDescription", _5 as "colIcons", _6 as "description", _7 as "descriptionWrapper", _8 as "dsTitle", _9 as "topics" }
