import { Typography } from "@mui/material";
import getClassName from "classnames";
import platform from "platform";
import * as React from "react";
import * as styles from "./style.scss";

const getKeyToDisplay = (input: string) => {
  switch (input) {
    case "Mod":
      return platform.os?.family?.startsWith("OS") ? "⌘" : "CTRL";
    case "up":
      return "⬆";
    case "down":
      return "⬇";
    case "left":
      return "⬅";
    case "right":
      return "➡";
    default:
      return input.toUpperCase();
  }
};

const KeyboardKey: React.FC<{ children: string; className?: string }> = ({ children, className }) => {
  return (
    <Typography
      component="kbd"
      variant="caption"
      className={getClassName(styles.key, className)}
      suppressHydrationWarning={children === "Mod"}
    >
      {getKeyToDisplay(children)}
    </Typography>
  );
};

export default KeyboardKey;
