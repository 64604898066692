import { Alert } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Avatar, Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import * as styles from "./styles.scss";

export interface Props {
  authenticatedUser: User;
  disabled?: boolean;
  onSubmit: (accountName: string) => Promise<void>;
  close: () => void;
}

const SaveQueryModal: React.FC<Props> = (props) => {
  const [toAccount, setToAccount] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  const submit = React.useCallback(
    (accountName?: string) => {
      if (!accountName) return;
      if (!toAccount) {
        setToAccount(accountName);
        props.onSubmit(accountName).catch((err: Error) => setError(err.message));
      }
    },
    [props, toAccount],
  );

  React.useEffect(() => {
    if ((!props.authenticatedUser.orgs || !props.authenticatedUser.orgs.length) && props.authenticatedUser.accountName)
      submit(props.authenticatedUser.accountName);
  }, [props.authenticatedUser.accountName, props.authenticatedUser.orgs, submit]);

  if (!props.authenticatedUser) return null;

  const accounts = [props.authenticatedUser as Account].concat(props.authenticatedUser.orgs || []);

  return (
    <Dialog open={true} onClose={props.close} title="Save query">
      <div className="px-5 pb-5">
        {!toAccount && accounts.length > 1 && <h5 className="mt-0 mb-3">Select the account to save the query to:</h5>}
        {!toAccount && accounts.length > 1 && (
          <div className={getClassName(styles.accounts, "py-3")}>
            {accounts.map((account) => (
              <div
                key={account.accountName}
                className={getClassName(styles.account, "p-2")}
                onClick={() => submit(account.accountName)}
                role="button"
                tabIndex={0}
              >
                <Avatar
                  size="lg"
                  avatarName={account.accountName || ""}
                  avatarUrl={account.avatarUrl}
                  title={account.accountName}
                  alt=""
                />
                <div className={getClassName(styles.accountName, "mt-2")}>{account.accountName}</div>
              </div>
            ))}
          </div>
        )}
        {toAccount &&
          (error ? (
            <Alert severity="error" className="mb-6">
              {error}
            </Alert>
          ) : (
            <div className="mb-6">
              <FontAwesomeIcon icon={["fas", "cog"]} spin /> Saving query to {toAccount}
            </div>
          ))}
        <Button variant="text" onClick={props.close} disabled={props.disabled}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};
export default SaveQueryModal;
