import { Alert } from "@mui/material";
import * as React from "react";
import { Html } from "#components/index.ts";
import { DashViewer, DashViewerProps } from ".";
import * as styles from "./style.scss";

const RDF_HTML = "http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML";

const HTMLViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "Literal" || property.datatype !== RDF_HTML)
    return <Alert severity="error">Wrongly configured HTML</Alert>;
  return <Html children={property.value} className={styles.htmlView} />;
};

const HTMLViewer: DashViewer = {
  component: HTMLViewerComponent,
  getScore({ datatype }) {
    if (datatype === RDF_HTML) return 50;
    return 0;
  },
};

export default HTMLViewer;
