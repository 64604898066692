import { jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";
import { keymap } from "@codemirror/view";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./style.scss";

/**
 * Yet another Elasticsearch query editor.
 */
interface Props {
  onQuery: (query: string) => any;
  initialQuery?: string;
  loading?: boolean;
}

const DEFAULT_QUERY = `{
  "query": {
    "simple_query_string" : {
      "query": "*"
    }
  }
}`;

const YAElQE: React.FC<Props> = ({ initialQuery, onQuery, loading }) => {
  const [value, setValue] = React.useState(initialQuery || DEFAULT_QUERY);
  const [syntaxError, setSyntaxError] = React.useState<string>();

  return (
    <div className={styles.advancedSearchField}>
      <CodeMirror
        className={styles.codeMirror}
        value={value}
        basicSetup={{
          defaultKeymap: false,
        }}
        extensions={[
          jsonLanguage,
          linter(jsonParseLinter()),
          githubLight,
          keymap.of([
            {
              key: "Mod-Enter",
              run: () => {
                if (syntaxError) return false;
                onQuery(value);
                return true;
              },
              preventDefault: true,
            },
          ]),
        ]}
        onChange={(value) => {
          try {
            setValue(value);
            JSON.parse(value);
            setSyntaxError(undefined);
          } catch (e) {
            if (e instanceof SyntaxError) {
              setSyntaxError(e.message);
            } else {
              throw e;
            }
          }
        }}
      />
      <button
        type="button"
        onClick={() => onQuery(value)}
        disabled={loading || !!syntaxError || !value}
        className={styles.queryButton}
        title="Run query"
        aria-label="Run query"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          height="81.9"
          width="72.9"
          version="1.1"
          y="0px"
          x="0px"
          viewBox="0 0 72.900002 81.900002"
        >
          <path
            id="queryIcon"
            d="m69.6 35.2-60.3-34.3c-2.2-1.2-4.4-1.2-6.4 0s-2.9 3.4-2.9 5.6v68.8c0 2.2 1.2 4.4 2.9 5.6 1 0.5 2.2 1 3.4 1s2.2-0.5 2.9-1l60.3-34.3c2.2-1.2 3.4-3.4 3.4-5.6s-1.1-4.3-3.3-5.8z"
            fill="currentColor"
          />
          <path
            id="loadingIcon"
            className={getClassName({ [styles.loading]: loading })}
            d="m61.184 36.167-48.73-27.719c-1.7779-0.96976-3.5558-0.96976-5.172 0-1.6163 0.96976-2.3436 2.7476-2.3436 4.5255v55.599c0 1.7779 0.96976 3.5558 2.3436 4.5255 0.80813 0.40407 1.7779 0.80813 2.7476 0.80813 0.96975 0 1.7779-0.40406 2.3436-0.80813l48.73-27.719c1.7779-0.96976 2.7476-2.7476 2.7476-4.5255s-0.88894-3.475-2.6668-4.6872z"
            fill="none"
          />
        </svg>
      </button>

      <a
        href="https://docs.triply.cc/triply-api/#elasticsearch"
        target="_blank"
        className={getClassName("noLinkDecoration", styles.infoButton)}
        title="Documentation"
        aria-label="Documentation"
      >
        <FontAwesomeIcon icon={["fas", "info"]} />
      </a>
    </div>
  );
};

export default YAElQE;
