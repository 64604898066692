import { zip as zipGeoAsShapefile } from "@mapbox/shp-write";
import JSZip from "jszip";
import { groupBy } from "lodash-es";
import { lexicalToValue } from "@triplydb/recognized-datatypes/wkt.js";
import { commonCrsProjectionDefs, isSupportedAsCommonCrs } from "@triplydb/utils/Crs.js";
import { Binding, isConstructResponse, isSelectResponse, SparqlResponse } from "../SparqlUtils";
import { canDraw, getGeoVariables } from "./Visualizations/Geo";

export function canCreateShapeFile(data: SparqlResponse) {
  return canDraw(data);
}

function bindingToProperties(binding: Binding) {
  // Using reduce here, the original bindings are immutable
  return Object.entries(binding).reduce(
    (properties, [key, term]) => {
      if (term?.value) properties[key] = term?.value;
      return properties;
    },
    {} as { [key: string]: string },
  );
}

export function downloadSparqlResponseAsShapefile(data: SparqlResponse, queryName: string) {
  let features: GeoJSON.Feature<any, any>[] = [];
  if (isConstructResponse(data)) {
    // Using reduce here, to combine map and filter
    features = data.flatMap((binding) => {
      try {
        return [
          {
            type: "Feature",
            // In construct queries, a WKT can only occur in the object position
            geometry: lexicalToValue(binding.object.value), // This throws when the wkt is invalid
            properties: bindingToProperties(binding),
          },
        ];
      } catch (e) {
        // Assumption: Invalid WKT / unrecognized CRS, skip
        return [];
      }
    });
  } else if (isSelectResponse(data)) {
    // In case of an select query there could be more then one variable containing a wkt
    const geoVars = getGeoVariables(data.results.bindings, data.head.vars);
    // Using reduce here, to combine map and filter
    features = data.results.bindings.flatMap((binding) => {
      let geometry;
      for (const geometryVar of geoVars) {
        if (binding[geometryVar]?.value === undefined) continue;
        try {
          geometry = lexicalToValue(binding[geometryVar]!.value);
          break; // Valid, no reason to continue
        } catch (e) {
          // Assumption: Invalid WKT / unrecognized CRS, try next
        }
      }
      // All detected geo variables were invalid, so skip this row.
      if (!geometry) return [];
      return [
        {
          type: "Feature",
          geometry: geometry, // This throws when the wkt is invalid
          properties: bindingToProperties(binding),
        },
      ];
    });
  }

  if (features.length > 0) {
    // We now have all geoJson, we need now to group them by CRS
    const zippingFiles: Promise<Blob>[] = [];
    // Shapefiles only support one projection per type of geometries
    // Geometries get their own files anyway
    // Assumption: Mixed projections are rare...
    const featuresGroupedByCrs = groupBy(features, "geometry.crs");
    for (const [crs, features] of Object.entries(featuresGroupedByCrs)) {
      if (isSupportedAsCommonCrs(crs)) {
        zippingFiles.push(
          zipGeoAsShapefile(
            // Top-level geojson
            {
              type: "FeatureCollection",
              features: features,
            },
            // Zip options
            // Note: filename doesn't do anything in zip
            {
              compression: "STORE",
              outputType: "blob",
              folder: Object.keys(featuresGroupedByCrs).length > 1 ? commonCrsProjectionDefs[crs].name : undefined, // Only needed when there  re multiple CRS
              prj: commonCrsProjectionDefs[crs].ogcWkt,
            },
          ) as Promise<Blob>,
        );
      } else {
        console.warn("Not creating entry for unrecognized crs:", crs);
      }
    }
    Promise.all(zippingFiles)
      .then(async (blobs) => {
        let data = blobs[0];
        // If there are multiple CRS systems in play, we need to unpack and re-zip,
        if (blobs.length > 1) {
          const zipper = new JSZip();
          for (const blob of blobs) {
            await zipper.loadAsync(blob, { createFolders: true });
          }
          data = await zipper.generateAsync({ type: "blob" });
        }
        // Mimic download click
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = `${queryName}.zip`;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => console.error(e));
  }
}
