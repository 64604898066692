import { MenuItem, Select } from "@mui/material";
import { Column } from "@tanstack/react-table";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";

const BooleanFilter: React.FC<{ column: Column<any, boolean | "n/a" | undefined> }> = ({ column }) => {
  const values = column.getFacetedUniqueValues();
  return (
    <Select
      defaultValue=""
      displayEmpty
      onChange={(e) => {
        let filterValue;
        switch (e.target.value) {
          case "true":
            filterValue = true;
            break;
          case "false":
            filterValue = false;
            break;
          case "n/a":
            filterValue = "n/a";
            break;
          default:
            filterValue = "";
        }
        column.setFilterValue(filterValue);
      }}
      onClick={(e) => e.stopPropagation()}
      fullWidth
    >
      <MenuItem title="All" value="">
        All
      </MenuItem>
      {values.has(true) && (
        <MenuItem title="True" value="true">
          <FontAwesomeIcon icon="check" className="mr-1"></FontAwesomeIcon>({values.get(true)})
        </MenuItem>
      )}
      {values.has(false) && (
        <MenuItem title="False" value="false">
          <FontAwesomeIcon icon="times" className="mr-1"></FontAwesomeIcon>({values.get(false)})
        </MenuItem>
      )}
      {values.has("n/a") && (
        <MenuItem value="n/a" title="unset">
          Unset ({values.get("n/a")})
        </MenuItem>
      )}
    </Select>
  );
};

export default BooleanFilter;
