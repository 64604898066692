import * as React from "react";
// @ts-ignore
import { Droppable } from "react-drag-and-drop";
import { prefixUtils } from "@triply/utils";
import { TermLink, TermLiteral } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { Prefixes, PrefixUpdate } from "#reducers/datasetManagement.ts";
import { Position, Term } from "#reducers/triples.ts";
import { Query } from "./index.tsx";

interface Props {
  term?: Term;
  pos: Position;
  prefixes: Prefixes;
  createPrefix?: (prefix: PrefixUpdate) => void;
  filter: (query: Query) => void;
  pathname: string;
  query: Query;
}

const TermFC: React.FC<Props> = ({ query, pos, term, prefixes, createPrefix, pathname }) => {
  if (!term) return <></>;
  switch (term.termType) {
    case "NamedNode":
    case "BlankNode":
      return (
        <TermLink
          isFiltered={!!query[pos]}
          iri={term.value}
          prefixInfo={prefixUtils.getPrefixInfoFromIri(term.value, prefixes)}
          createPrefix={createPrefix}
          link={{
            to: {
              pathname: pathname,
              search: stringifyQuery({ ...query, [pos]: term.value }),
            },
          }}
        />
      );
    case "Literal":
    default:
      return <TermLiteral {...term} />;
  }
};

const Field: React.FC<Props> = (props) => {
  if (!props.term) return null;

  return (
    <Droppable
      types={["text/plain"]}
      onDrop={(data: any) => {
        if (!data || !data["text/plain"]) return;
        props.filter({ [props.pos]: data["text/plain"] });
      }}
    >
      <TermFC {...props} />
    </Droppable>
  );
};

export default Field;
