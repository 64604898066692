import { IconButton } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { factories } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { Dialog, FontAwesomeIcon } from "../../components";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import DatatypePropertyForm from "./Forms/DatatypeProperty";
import { DatatypePropertyData } from "./Forms/Types";
import { escapeQuotes } from "./utils";

const factory = factories.compliant;

const AddDatatypeProperty: React.FC<{
  classShapeIri: string;
}> = ({ classShapeIri }) => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  return (
    <>
      <IconButton color="primary" aria-label="add" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="plus" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        title="Add a datatype property"
        closeButton
      >
        <div className="px-5 pb-5">
          <DatatypePropertyForm
            onSubmit={async (newProperty: DatatypePropertyData) => {
              const id = factory.namedNode(removePrefixes(newProperty.id)).id;
              const propertyShape = factory.namedNode(`${consoleUrl}/${datasetPath}/shp/${Date.now()}`).id;
              const propertyLabel = newProperty.label?.trim();
              const propertyDescription = newProperty.description?.trim();
              const minCount = newProperty.minCount;
              const maxCount = newProperty.maxCount;
              const query = dedent`
              prefix sh: <http://www.w3.org/ns/shacl#>
              prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
              prefix owl: <http://www.w3.org/2002/07/owl#>
              insert {
                ?shapeNode sh:property ?propertyShape.
                ?id a owl:DatatypeProperty.
                ?propertyShape a sh:ProperyShape;
                              sh:path ?id;
                              sh:name ?label;
                              sh:description ?description;
                              sh:datatype ?datatype;
                              sh:minCount ?minCount;
                              sh:maxCount ?maxCount;
                              sh:minLength 1.
              } where {
                bind(<${classShapeIri}> as ?shapeNode)
                bind(<${propertyShape}> as ?propertyShape)
                bind(<${id}> as ?id)
                bind(<${newProperty.datatype}> as ?datatype)
                optional {
                  values ?label {
                    ${propertyLabel ? `"${escapeQuotes(propertyLabel)}"` : ""}
                  }
                }
                optional {
                  values ?description {
                    ${propertyDescription ? `"${escapeQuotes(propertyDescription)}"` : ""}
                  }
                }
                optional {
                  values ?maxCount {
                    ${maxCount || ""}
                  }
                }
                optional {
                  values ?minCount {
                    ${minCount || ""}
                  }
                }
              }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddDatatypeProperty;
