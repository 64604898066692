/**
 * Requiring the CSS ourselves. It'll work otherwise as well, but then the icon will only be styled _after_
 * the JS loaded/ran.
 * See https://github.com/FortAwesome/vue-fontawesome/issues/86#issuecomment-426727373
 */
import { IconDefinition, IconName, library } from "@fortawesome/fontawesome-svg-core";
import { faGithub, faOrcid, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
/**
 * When adding an icon, first import that icon, and then make sure that icon is added to the lib (bottom of this file) as well
 */
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAnalytics,
  faAngleDown,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsToCircle,
  faArrowsToDottedLine,
  faArrowsTurnRight,
  faArrowToBottom,
  faArrowUp,
  faBan,
  faBars,
  faBook,
  faBookOpen,
  faBracketsCurly,
  faBracketSquare,
  faBracketSquareRight,
  faBracketsSquare,
  faBriefcase,
  faBroomWide,
  faBrowser,
  faBuilding,
  faCalculator,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faChartScatter,
  faCheck,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClipboard,
  faCloud,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faCopy,
  faCube,
  faDatabase,
  faDiagramSankey,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faExpand,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFileCode,
  faFileExport,
  faFilter,
  faFlask,
  faGearComplexCode,
  faGlasses,
  faGlobe,
  faGripDotsVertical,
  faHome,
  faHorizontalRule,
  faHourglass,
  faHourglassClock,
  faIdCard,
  faImage,
  faImages,
  faInfoCircle,
  faInputText,
  faKeyboard,
  faLink,
  faLinkHorizontal,
  faListCheck,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlay,
  faMagnifyingGlassPlus,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMusic,
  faPaperclip,
  faPencil,
  faPlus,
  faQuestion,
  faRabbitFast,
  faRectangleWide,
  faRulerTriangle,
  faSearch,
  faShareAlt,
  faShield,
  faShieldHalved,
  faSitemap,
  faSlidersH,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faStream,
  faSync,
  faTable,
  faTachometer,
  faTh,
  faThumbtack,
  faTimes,
  faTrash,
  faUpload,
  faUser,
  faUserEdit,
  faUserPlus,
  //faGroup
  faUsers,
  faUsersMedical,
  faVideo,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheckCircle,
  faCircle,
  faCloudUpload,
  faCog as faCogSolid,
  faCopy as faCopySolid,
  faDatabase as faDatabaseSolid,
  faExclamationCircle,
  faExclamationTriangle as faExclamationTriangleSolid,
  faFileExclamation as faFileExclamationSolid,
  faFileImport as faFileImportSolid,
  faFloppyDisk,
  faInfo,
  faInfoCircle as faInfoCircleSolid,
  faPencil as faPencilSolid,
  faPlay,
  faRotateRight as faRotateRightSolid,
  faSquare as faSquareSolid,
  faSquareFull as faSquareFullSolid,
  faSquareSmall as faSquareSmallSolid,
  faStop,
  faTimes as faTimesSolid,
  faTimesCircle,
  faUpload as faUploadSolid,
  faUserSecret,
} from "@fortawesome/pro-solid-svg-icons";
import { Query } from "#reducers/queries.ts";
import {
  getIdeVisualization,
  isIdeVisualization,
  yasguiVisualizationConfigToIdeVisualizationConfig,
} from "../components/Sparql/SparqlUtils";
import "@fortawesome/fontawesome-svg-core/styles.css";

// Graphql is not added to fontawesome yet so we want to manually add in our own icon under a predefined name since it doesn't allow us to add new names.
const faGraphql: IconDefinition = {
  //extend iconname to include graphql
  prefix: "far",
  iconName: "gramophone",
  icon: [
    350, // width
    350, // height
    [], // ligatures
    "", // unicode (if relevant)
    "M57.468 302.66l-14.376-8.3 160.15-277.38 14.376 8.3z M39.8 272.2h320.3v16.6H39.8z M206.348 374.026l-160.21-92.5 8.3-14.376 160.21 92.5zM345.522 132.947l-160.21-92.5 8.3-14.376 160.21 92.5z M54.482 132.883l-8.3-14.375 160.21-92.5 8.3 14.376z M342.568 302.663l-160.15-277.38 14.376-8.3 160.15 277.38zM52.5 107.5h16.6v185H52.5zM330.9 107.5h16.6v185h-16.6z M203.522 367l-7.25-12.558 139.34-80.45 7.25 12.557z M369.5 297.9c-9.6 16.7-31 22.4-47.7 12.8-16.7-9.6-22.4-31-12.8-47.7 9.6-16.7 31-22.4 47.7-12.8 16.8 9.7 22.5 31 12.8 47.7M90.9 137c-9.6 16.7-31 22.4-47.7 12.8-16.7-9.6-22.4-31-12.8-47.7 9.6-16.7 31-22.4 47.7-12.8 16.7 9.7 22.4 31 12.8 47.7M30.5 297.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.8 9.6-38.1 3.9-47.7-12.8M309.1 137c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.7 9.6-38.1 3.9-47.7-12.8M200 395.8c-19.3 0-34.9-15.6-34.9-34.9 0-19.3 15.6-34.9 34.9-34.9 19.3 0 34.9 15.6 34.9 34.9 0 19.2-15.6 34.9-34.9 34.9M200 74c-19.3 0-34.9-15.6-34.9-34.9 0-19.3 15.6-34.9 34.9-34.9 19.3 0 34.9 15.6 34.9 34.9 0 19.3-15.6 34.9-34.9 34.9", // svg path data
  ],
};
const faRdf: IconDefinition = {
  //extend iconname to include graphql
  prefix: "far",
  iconName: "bath",
  icon: [
    741.22548, // width
    799.66842, // height
    [], // ligatures
    "", // unicode (if relevant)
    "m 564.61251,-4.9473684 q -40,0 -74.5,20.5000044 -34.5,20.5 -53.5,56.5 -23,42.999994 -15,91.999994 l -2,-2 v 6 q -1,9 -7,19 -7,15 -23,30 -20,19 -50.5,38 -30.5,19 -57.5,28 -22,7 -40,7 -14,0 -24,-4 l -8,-3 3,3 -8,-4 q -35,-19 -73.5,-17.5 -38.499999,1.5 -72.00001,22 -33.5,20.5 -51.9999999,55.5 -18.5000001,35 -17.0000001,74 1.5,39 22,72 20.5,33 55.5,52 40.00001,21 83.50001,16.5 43.5,-4.5 76.5,-31.5 l -1,2 7,-4 q 9,-3 21,-3 17,-1 39,5 27,8 59,25 48,26 70,56 15,19 16,37 -1,42 19.5,78.5 20.5,36.5 58.5,56.5 35,18 73.5,16.5 38.5,-1.5 72,-22 33.5,-20.5 52,-55.5 18.5,-35 17,-74 -1.5,-39 -22,-72 -20.5,-33 -55.5,-51 -7,-4 -15,-7 l 3,-1 -5,-4 q -6,-5 -11,-16 -8,-15 -12,-36 -6,-27 -7,-63 -1,-36 3,-63 4,-22 11,-37 5,-11 12,-18 l 5,-4 h -5 q 41,-21 62,-62 19,-35 17.5,-73.5 -1.5,-38.499998 -22,-71.499994 -20.5,-33 -55.5,-52.000004 -33,-18.0000004 -70,-17.0000004 z m 2,23.0000044 q 39,0 66,25 v 0 q -15,-13 -44.5,-10 -29.5,3 -58.5,22 8,8 8.5,20 0.5,12 -8,21.499996 -8.5,9.499998 -21.5,9.499998 h -8 l -2,-1 q -12,-4 -21,8 -16,29 -16,56.5 0,27.5 15,42.5 -20,-19 -27,-46.5 -7,-27.5 0.5,-56 7.5,-28.499994 28.5,-51.499994 18,-19 41,-29.5 23,-10.5 47,-10.5 z m -101,240.999994 q 11,0 18,3.5 7,3.5 14,7.5 13,7 27,11 11,11 20,33 11,33 12.5,84 1.5,51 -10.5,84 -9,22 -22,34 -20,9 -36,23 -14,5 -36,1 -34,-6 -81,-31 -49,-26 -73,-54 -14,-17 -17,-32 2,-18 -1,-37 v 1 l 1,-6 q 1,-8 7,-18 8,-13 24,-28 20,-18 50,-37 62,-38 103,-39 z m -326,34 q 39,0 66,25 h -1 q -14,-13 -44,-10 -30,3 -58,22 7,8 7.5,20 0.5,12 -8,21.5 -8.499999,9.5 -20.5,9.5 -4,1 -8,0 l -2,-1 q -13.00001,-4 -22.00001,8 -15,29 -15.5,56.5 -0.5,27.5 14.5,42.5 -20,-19 -26.5,-46.5 -6.5,-27.5 1,-56 7.5,-28.5 28.5,-51.5 18,-19 41.000011,-29.5 22.999999,-10.5 46.999999,-10.5 z m 452,233 q 39,0 66,25 v 0 q -14,-13 -44,-10 -30,3 -59,22 8,8 8.5,20 0.5,12 -8,21.5 -8.5,9.5 -21.5,9.5 -4,0 -8,-1 h -2 q -12,-4 -21,8 -16,29 -16,56.5 0,27.5 15,42.5 -20,-19 -27,-46.5 -7,-27.5 0.5,-56 7.5,-28.5 28.5,-51.5 18,-19 41,-29.5 23,-10.5 47,-10.5 z", // svg path data
  ],
};

export default function registerIcons() {
  library.add(
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faAnalytics,
    faAngleDown,
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsToCircle,
    faArrowsToDottedLine,
    faArrowUp,
    faArrowsTurnRight,
    faBan,
    faBars,
    faBook,
    faBookOpen,
    faBracketsCurly,
    faBracketSquare,
    faBracketSquareRight,
    faBracketsSquare,
    faBriefcase,
    faBroomWide,
    faBrowser,
    faBuilding,
    faCalculator,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartNetwork,
    faChartPie,
    faChartScatter,
    faCheck,
    faCheckCircle,
    faChevronUp,
    faChevronDown,
    faChevronDoubleLeft,
    faChevronLeft,
    faArrowToBottom,
    faChevronDoubleRight,
    faChevronRight,
    faCircle,
    faCircleNotch,
    faClipboard,
    faCloud,
    faCloudUpload,
    faCode,
    faCodeBranch,
    faCog,
    faCogs,
    faCogSolid,
    faCopy,
    faCopySolid,
    faCube,
    faDatabase,
    faDatabaseSolid,
    faDiagramSankey,
    faDotCircle,
    faDownload,
    faEdit,
    faEllipsisV,
    faEmptySet,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faExclamationTriangleSolid,
    faExpand,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFileCode,
    faFileExport,
    faFileExclamationSolid,
    faFileImportSolid,
    faFilter,
    faFlask,
    faFloppyDisk,
    faGearComplexCode,
    faGithub,
    faGlasses,
    faGlobe,
    faGraphql,
    faGripDotsVertical,
    faHome,
    faHorizontalRule,
    faHourglass,
    faHourglassClock,
    faIdCard,
    faImage,
    faImages,
    faInfo,
    faInfoCircle,
    faInfoCircleSolid,
    faInputText,
    faKeyboard,
    faLink,
    faLinkHorizontal,
    faListCheck,
    faLock,
    faLongArrowLeft,
    faLongArrowRight,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlay,
    faMagnifyingGlassPlus,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMinus,
    faMusic,
    faOrcid,
    faPaperclip,
    faPencil,
    faPencilSolid,
    faPlay,
    faPlus,
    faQuestion,
    faRulerTriangle,
    faSearch,
    faShareAlt,
    faRabbitFast,
    faRdf,
    faRectangleWide,
    faRotateRightSolid,
    faShield,
    faShieldHalved,
    faSitemap,
    faSlidersH,
    faSpinner,
    faSpinnerThird,
    faSquare,
    faSquareCheck,
    faSquareFullSolid,
    faSquareMinus,
    faSquareSmallSolid,
    faSquareSolid,
    faStop,
    faStream,
    faSync,
    faTable,
    faTachometer,
    faTh,
    faThumbtack,
    faTimes,
    faTimesCircle,
    faTimesSolid,
    faTrash,
    faTwitter,
    faUpload,
    faUploadSolid,
    faUser,
    faUserEdit,
    faUserPlus,
    faUsers, //faGroup
    faUserSecret,
    faUsersMedical,
    faVideo,
    faXmark,
  );
}
declare module "@fortawesome/fontawesome-svg-core" {
  export interface Props {
    title: string;
  }
}

export function getQueryIcon(query: Query): IconName {
  return getQueryIconForType(getQueryType(query));
}

export function getQueryType(query: Query): string {
  if (query.requestConfig?.ldFrame) {
    return "LdFrame";
  }

  let outputType: string | undefined = getIdeVisualization(query.renderConfig?.output);
  if (outputType === "Charts") {
    if (isIdeVisualization(query.renderConfig?.output || "Table")) {
      outputType = query.renderConfig?.settings?.chartType || "Table";
    } else {
      outputType = (query.renderConfig?.settings?.chartConfig?.chartType as string) || "Table";
    }
  }
  if (outputType === "Pivot") {
    if (isIdeVisualization(query.renderConfig?.output || "Table")) {
      outputType = query.renderConfig?.settings?.rendererName || "Table";
    } else {
      const transformedConfig = yasguiVisualizationConfigToIdeVisualizationConfig(
        "pivot",
        query.renderConfig?.settings,
        undefined,
      );
      if (transformedConfig && "rendererName" in transformedConfig) {
        outputType = transformedConfig.rendererName || "Table";
      }
    }
  }
  return outputType || "unknown";
}

export function getQueryIconForType(outputType: string | undefined, retry?: boolean): IconName {
  switch (outputType) {
    case "Table":
    case "Pivot":
    case "Table Heatmap":
    case "Table Row Heatmap":
    case "Table Col Heatmap":
      return "th";
    case "Response":
      return "align-left";
    case "Gallery":
      return "images";
    case "Geo":
      return "map-marker-alt";
    case "Timeline":
      return "sliders-h";
    case "Network":
      return "chart-network";
    case "LineChart":
    case "Line Chart":
      return "chart-line";
    case "Area Chart":
    case "AreaChart":
      return "chart-area";
    case "ColumnChart":
      return "chart-bar";
    case "BarChart":
    case "Bar Chart":
    case "Stacked Bar Chart":
      return "chart-bar";
    case "ScatterChart":
    case "Scatter Chart":
      return "chart-scatter";
    case "PieChart":
      return "chart-pie";
    case "GeoChart":
      return "map-marker-alt";
    case "ImageSparkLine":
      return "chart-line";
    case "Gauge":
      return "tachometer";
    case "OrgChart":
      return "sitemap";
    case "TreeMap":
      return "calculator";
    case "ComboChart":
      return "analytics";
    case "ImageChart":
      return "dot-circle";
    case "SteppedAreaChart":
      return "chart-bar";
    case "Histogram":
      return "chart-bar";
    case "BubbleChart":
      return "chart-scatter";
    case "AnnotationChart":
      return "stream";
    case "LdFrame":
      return "brackets-curly";
    case "unknown":
      return "question";
    case undefined:
      return "question";
    default:
      return retry ? "question" : getQueryIconForType(getIdeVisualization(outputType), true);
  }
}
