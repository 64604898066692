export default class BasicTreeNode {
  private data = <any>{};
  private parent?: BasicTreeNode;
  private children: BasicTreeNode[] = [];
  private depth = 0;

  private constructor(data: any, depth: number, parent?: BasicTreeNode) {
    this.data = data;
    this.depth = depth;
    this.parent = parent;
  }

  public getRoot(): BasicTreeNode {
    if (!this.parent) return this;
    return this.parent.getRoot();
  }
  private populateChildren(dataArray: Array<any>, idField = "id") {
    dataArray
      .filter((d) => d.parent === this.data[idField])
      .forEach((d) => {
        const child = new BasicTreeNode(d, this.depth + 1, this);
        child.populateChildren(dataArray, idField);
        this.children.push(child);
      });
  }
  public getData() {
    return this.data;
  }
  public getChildren(predicate?: string): BasicTreeNode[] {
    return this.children;
  }
  public hasChildren(): boolean {
    return !!this.children.length;
  }
  public getDepth() {
    return this.depth;
  }
  public getParent() {
    return this.parent;
  }
  public getParents(): BasicTreeNode[] {
    if (this.parent) {
      return [this.parent].concat(this.parent.getParents());
    }
    return [];
  }
  public getLevel() {
    return this.depth;
  }
  public static fromArray(dataArray: Array<any>, idField = "id") {
    if (!dataArray) return [];
    return dataArray
      .filter((d) => !d.parent)
      .map((d) => {
        const tree = new BasicTreeNode(d, 0);
        tree.populateChildren(dataArray, idField);
        return tree;
      });
  }
}
