export const classesQuery = `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix sh: <http://www.w3.org/ns/shacl#>

select * {
  ?nodeShape a sh:NodeShape ;
             sh:targetClass ?id .
  ?id rdfs:label ?label .
  optional {
    ?id rdfs:comment ?description
  }
  optional {
    ?nodeShape sh:property ?properties_propertyShape .
    ?properties_propertyShape sh:path ?properties_id .
    ?properties_id rdfs:label ?properties_label
    optional {
      ?properties_id rdfs:comment ?properties_description
    }
    optional {
      ?properties_propertyShape sh:datatype ?properties_datatype
    }

    optional {
      ?properties_propertyShape sh:class ?properties_range .
      ?properties_values_id a ?properties_range .
      ?properties_values_id rdfs:label ?properties_values_label
      optional {
        ?properties_values_id rdfs:comment ?properties_values_description
      }
    }
  }
}
`;

export const valuesQuery = (resource: string) => `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix p: <https://triplydb.com/academy/pokemon/id/pokemon/>
prefix sh: <http://www.w3.org/ns/shacl#>

select * {
  <${resource}> a ?type_id ;
                rdfs:label ?label .
  ?type_nodeShape a sh:NodeShape ;
             sh:targetClass ?type_id .
  ?type_id rdfs:label ?type_label .
  optional {
    ?type_id rdfs:comment ?type_description
  }
  optional {
    ?type_nodeShape sh:property ?properties_property_propertyShape .
    ?properties_property_propertyShape sh:path ?properties_property_id .
    <${resource}> ?properties_property_id ?properties_value .
    ?properties_property_id rdfs:label ?properties_property_label .

    optional {
      ?properties_property_id rdfs:comment ?properties_property_description
    }

    optional {
      ?properties_property_propertyShape sh:datatype ?properties_property_datatype
    }

    optional {
      ?properties_property_propertyShape sh:class ?properties_property_range.
      ?properties_property_values_id a ?properties_property_range ;
                                     rdfs:label ?properties_property_values_label

      optional {
        ?properties_property_values_id rdfs:comment ?properties_property_values_description
      }
    }
  }
}
`;
