import { FormControl, InputLabel, MenuItem, Paper, Select, Toolbar as MuiToolbar } from "@mui/material";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import useAcl from "#helpers/hooks/useAcl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import CreateResource from "./Actions/CreateResource.tsx";
import FindResource from "./FindResource.tsx";
import { getAllowedActions } from "./helpers.ts";
import * as styles from "./style.scss";

const Toolbar: React.FC<{}> = () => {
  const selectorLabelId = React.useId();
  const history = useHistory();
  const match = useRouteMatch<{ renderer?: string }>();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const authenticatedUserRole = acl.getRoleInAccount(currentAccount);
  const allowedEditActions = getAllowedActions(undefined, authenticatedUserRole);

  return (
    <MuiToolbar className="center g-2" component={(props) => <Paper {...props} square elevation={2} />}>
      {allowedEditActions.includes("AddResource") && <CreateResource className={styles.createButton} />}
      <FindResource />
      <div className="grow" />
      <FormControl size="small" variant="filled" className="noShrink">
        <InputLabel id={selectorLabelId}>View</InputLabel>
        <Select
          value={match.params.renderer}
          labelId={selectorLabelId}
          variant="filled"
          size="small"
          onChange={(e) => {
            const path = location.pathname.split("/");
            path[path.length - 1] = e.target.value;
            history.push({
              ...location,
              pathname: path.join("/"),
            });
          }}
        >
          <MenuItem value="skos">SKOS</MenuItem>
        </Select>
      </FormControl>
    </MuiToolbar>
  );
};

export default Toolbar;
