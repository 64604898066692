import * as React from "react";
import * as styles from "./style.scss";

export interface Props {
  progress: number;
  animationDuration: number;
}

const Bar: React.FC<Props> = ({ progress, animationDuration }) => (
  <div
    className={styles.barOuter}
    style={{
      marginLeft: `${(-1 + progress) * 100}%`,
      transition: `margin-left ${animationDuration}ms linear`,
    }}
  >
    <div className={styles.barInner} />
  </div>
);

export default Bar;
