import { Autocomplete, Chip, debounce, ListItem, ListItemText, TextField } from "@mui/material";
import getClassName from "classnames";
import dedent from "dedent";
import * as React from "react";
import { useHistory } from "react-router";
import { stringifyQuery } from "@triply/utils-private";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "./InstanceForm/useSparql";
import * as styles from "./style.scss";

interface SearchResult {
  iri: string;
  label?: string;
  class?: string;
}

function getQuery(search: string) {
  return dedent`
prefix sdo: <https://schema.org/>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix dcat: <http://www.w3.org/ns/dcat#>
select ?s (sample(?_label) as ?label) ?class {
  {
    select ?s (sample(?_classLabel) as ?class) where {
      ?s a ?_class.
      [] sh:targetClass ?_class.
      optional {
        ?_class rdfs:label ?_classLabel
      }
    } group by ?s
  }
  values ?property {
    rdfs:label foaf:name sdo:name skos:prefLabel dcat:title  <http://purl.org/dc/elements/1.1/title> sh:name
  }
  {
    bind("${search}" as ?search)
    ?s ?property ?_label .
    filter(contains(lcase(?_label),lcase(?search)))
    bind(0 as ?score)
  } union {
    bind("${search}" as ?search)
    filter(contains(lcase(str(?s)),lcase(?search)))
    optional {
      ?s ?property ?_label
    }
    bind(if(bound(?_label),1,2) as ?score)
  }
} group by ?s ?class order by min(?score) limit 20
`;
}

const FindResource: React.FC<{}> = ({}) => {
  const sparql = useSparql();
  const { push } = useHistory();
  const [searchString, setSearchString] = React.useState("");
  const [options, setOptions] = React.useState<readonly SearchResult[]>([]);
  const applyPrefixes = useApplyPrefixes();
  const resource = useCurrentResource();

  const debouncedQuery = React.useMemo(
    () =>
      debounce(({ searchTerm }: { searchTerm: string }, callback: (results?: readonly SearchResult[]) => void) => {
        sparql(getQuery(searchTerm))
          .then((results) => {
            callback(
              results.results.bindings.map((binding) => {
                return {
                  iri: binding["s"]!.value,
                  label: binding["label"]?.value,
                  class: binding["class"]?.value,
                };
              }),
            );
          })
          .catch(() => {});
      }, 400),
    [sparql],
  );

  React.useEffect(() => {
    let active = true;
    debouncedQuery({ searchTerm: searchString }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [debouncedQuery, searchString]);

  return (
    <Autocomplete<SearchResult>
      size="small"
      className={getClassName(styles.instanceSearch)}
      componentsProps={{
        popper: {
          style: { width: "fit-content" },
        },
      }}
      renderInput={(props) => {
        return (
          <TextField
            {...(props as any)}
            label="Find"
            fullWidth
            variant="outlined"
            placeholder={applyPrefixes(resource)}
            InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
          />
        );
      }}
      filterOptions={(x) => x}
      options={options}
      onInputChange={(_event, newInputValue) => {
        setSearchString(newInputValue.replace(/"/g, ""));
      }}
      onChange={(_event, newValue) => {
        if (!newValue) return;
        push({
          search: stringifyQuery({ resource: newValue.iri }),
        });
      }}
      getOptionLabel={(option) => option.label || option.iri}
      getOptionKey={(option) => option.iri}
      noOptionsText={searchString === "" ? "No instances found according to the data model" : "No instances found"}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={
                <span>
                  {option.label || applyPrefixes(option.iri)}
                  {option.class && <Chip label={option.class} size="small" className="ml-2" />}
                </span>
              }
              secondary={option.label ? applyPrefixes(option.iri) : undefined}
            />
          </ListItem>
        );
      }}
    />
  );
};

export default FindResource;
