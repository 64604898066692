import { Fade, Paper, Popper } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useLocation } from "react-router";
import { Models } from "@triply/utils";
import { FontAwesomeButton, FontAwesomeIcon, RemoveBtn } from "#components/index.ts";
import useClickOrHover from "#helpers/hooks/useClickOrHover.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { QueryVariableField } from "../../components/QueryVariableUtils/QueryVariableField.tsx";
import { getVariableType } from "./QueryVars.tsx";
import * as styles from "./style.scss";

export interface LocationState {
  addVariableModalOpen: boolean;
  editingVariable: Models.VariableConfig | undefined;
  preserveScrollPosition?: boolean;
}

export interface Props {
  variableDefinition: Models.VariableConfig;
  warning?: string;
  onRemove: (variable: Models.VariableConfig) => void;
  onTestValueChange: (val: string) => void;
  testValue: string | undefined;
  datasetPath: string | undefined;
  getQueryString: () => string | undefined;
}

function getVariableTypeLabel(variableConfig: Models.VariableConfig) {
  switch (getVariableType(variableConfig)) {
    case "NamedNode":
      return "IRI";
    case "LanguageStringLiteral":
      return "Literal with language tag";
    case "StringLiteral":
      return "String Literal";
    case "TypedLiteral":
      return "Literal with datatype";
  }
}

const QueryVar: React.FC<Props> = ({
  testValue,
  onTestValueChange,
  onRemove,
  variableDefinition,
  warning,
  datasetPath,
  getQueryString,
}) => {
  const dispatch = useDispatch();
  const moreInfoPopupRef = React.useRef<HTMLDivElement>(null);
  const moreInfoButtonRef = React.useRef<HTMLDivElement>(null);
  const [moreInfoOpen, setMoreInfoOpen] = useClickOrHover(moreInfoButtonRef, moreInfoPopupRef);
  const { search } = useLocation();

  return (
    <div key={variableDefinition.name} className={getClassName("flex", styles.queryVariable)}>
      <div className={styles.variableInfo}>
        <div className="flex center wrap">
          <QueryVariableField
            getQueryString={getQueryString}
            datasetPath={datasetPath}
            testValue={testValue}
            onTestValueChange={onTestValueChange}
            variableDefinition={variableDefinition}
          />
        </div>

        <div
          ref={moreInfoButtonRef}
          aria-label="More info"
          onClick={() => setMoreInfoOpen(true)}
          className={getClassName(styles.info, { [styles.moreInfoOpen]: moreInfoOpen })}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon className={styles.infoIconActive} icon={["fas", "info-circle"]} />
          <FontAwesomeIcon className={styles.infoIcon} icon={"info-circle"} />
        </div>

        {!!warning && (
          <div className={styles.warning} title={warning}>
            <FontAwesomeIcon icon="exclamation-triangle" />
          </div>
        )}

        <Popper
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          open={moreInfoOpen}
          anchorEl={moreInfoButtonRef.current}
          placement="right"
          transition
          className={styles.popper}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper className={styles.moreInfoPopup} elevation={3} ref={moreInfoPopupRef}>
                <dl>
                  <dt>Type</dt>
                  <dd>{getVariableTypeLabel(variableDefinition)}</dd>
                  {"language" in variableDefinition && (
                    <>
                      <dt>Language tag</dt>
                      <dd>{variableDefinition.language}</dd>
                    </>
                  )}
                  {"datatype" in variableDefinition && (
                    <>
                      <dt>Datatype</dt>
                      <dd>{variableDefinition.datatype}</dd>
                    </>
                  )}
                  <dt>Required</dt>
                  <dd>
                    {variableDefinition.required ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />}
                  </dd>
                  <dt>Default value</dt>
                  <dd>{variableDefinition.defaultValue}</dd>
                </dl>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>

      <div className={styles.variableAction}>
        <FontAwesomeButton
          title="Edit variable"
          onClick={() =>
            dispatch(
              connectedReactRouter.push<LocationState>({
                state: {
                  addVariableModalOpen: true,
                  editingVariable: variableDefinition,
                  preserveScrollPosition: true,
                },
                search: search,
              }),
            )
          }
          icon="pencil"
        />
        <RemoveBtn title="Remove variable" onClick={() => onRemove(variableDefinition)} />
      </div>
    </div>
  );
};
export default React.memo(QueryVar);
