import { orderBy } from "lodash-es";
import * as React from "react";
import { NodeKind, Property } from "#reducers/resourceEditorDescriptions.js";
import BlankNodeViewer from "./BlanknodeViewer";
import HTMLViewer from "./HTMLViewer";
import HyperlinkViewer from "./HyperlinkViewer";
import ImageViewer from "./ImageViewer";
import LabelViewer from "./LabelViewer";
import LangStringViewer from "./LangStringViewer";
import LiteralViewer from "./LiteralViewer";
import UriViewer from "./URIViewer";

export interface DashViewer {
  getScore: (opts: { nodeKind: NodeKind; datatype?: string; value: string; label?: string }) => number;
  component: React.FC<DashViewerProps>;
}
export interface DashViewerProps {
  property: Property;
}

const DashViewer: React.FC<DashViewerProps> = ({ property }) => {
  const PropertyViewer = orderBy(
    [
      BlankNodeViewer,
      HTMLViewer,
      HyperlinkViewer,
      ImageViewer,
      LabelViewer,
      LiteralViewer,
      LangStringViewer,
      UriViewer,
    ],
    (viewer) =>
      viewer.getScore({
        nodeKind: property.nodeKind,
        value: property.value,
        label: property.nodeKind === "IRI" ? property.valueLabel : undefined,
        datatype: property.nodeKind === "Literal" ? property.datatype : undefined,
      }),
  ).pop()!.component;
  return <PropertyViewer property={property} />;
};

export default DashViewer;
