import { Paper } from "@mui/material";
import * as React from "react";
import NodeShape from "../NodeShape";
import { Editor } from "./Editor";

const BlankNodeEditor: Editor = {
  Component: ({ name, propertyModel }) => {
    if (!propertyModel.type) {
      console.error("Cannot draw BlankNodeEditor, 'type' is missing.");
      return null;
    }
    // This is a recursive form. For the sake of simplicity we say that the `namePrefix` is "properties".
    return (
      <Paper variant="outlined" className="p-5 grow flex column g-7">
        <NodeShape classIri={propertyModel.type} namePrefix={`${name}.properties` as "properties"} />
      </Paper>
    );
  },
  getScore: ({ nodeKind }) => {
    if (nodeKind === "BlankNode") {
      return 1;
    }
    return 0;
  },
};

export default BlankNodeEditor;
