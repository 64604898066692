import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { StarterDataset as StarterDatasetForm } from "#components/Forms/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateClientConfig } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const StarterDataset: React.FC<Props> = ({}) => {
  const starterDataset = useSelector((state: GlobalState) => state.config.clientConfig?.starterDataset);
  const constructUrlToApi = useConstructUrlToApi();

  const dispatch = useDispatch();

  const handleStarterDatasetSubmit = (values: StarterDatasetForm.FormData) => {
    return dispatch<typeof updateClientConfig>(
      updateClientConfig({
        starterDataset: values.starterDataset.id,
      }),
    ).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  return (
    <div className="whiteSink">
      <h3>Starter dataset</h3>
      <StarterDatasetForm
        initialValues={{
          starterDataset: starterDataset,
        }}
        datasetPath={constructUrlToApi({ pathname: `/datasets` })}
        onSubmit={handleStarterDatasetSubmit}
      />
    </div>
  );
};
export default StarterDataset;
