import { Alert, Avatar, Chip, Container, Paper, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { stringifyQuery } from "@triply/utils-private";
import { HumanizedDate } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.js";
import CopyResource from "../Actions/CopyResource";
import EditResource from "../Actions/EditResource";
import RejectResource from "../Actions/RejectResource";
import RemoveResource from "../Actions/RemoveResource";
import { ApproveResource, ArchiveResource, PublishResource, StageResource } from "../Actions/ResourceAction";
import {
  AUTHOR_IRI,
  CREATED_AT_IRI,
  EDITOR_NOTE_IRI,
  getAllowedActions,
  MODIFIED_AT_IRI,
  STATUS_IRI,
} from "../helpers";
import PropertiesViewer from "./viewers/PropertiesViewer";

interface Props {
  dsId?: string;
  resource: string;
  className?: string;
  innerClassName?: string;
}

const InstanceView: React.FC<Props> = ({ dsId, resource, className, innerClassName }) => {
  const acl = useAcl();
  const account = useCurrentAccount();

  const resourceDescription = useSelector((state: GlobalState) =>
    dsId ? state.resourceEditorDescriptions[dsId]?.resources[resource] : undefined,
  );
  const applyPrefixes = useApplyPrefixes();
  if (!resourceDescription) return <Skeleton>Loading</Skeleton>;
  const resourceStatus = resourceDescription?.properties?.[STATUS_IRI]?.[0]?.value;
  const authorIri = resourceDescription?.properties?.[AUTHOR_IRI]?.[0]?.value;
  const createdDate = resourceDescription?.properties?.[CREATED_AT_IRI]?.[0]?.value;
  const modifiedDate = resourceDescription?.properties?.[MODIFIED_AT_IRI]?.[0]?.value;
  const editorNote = resourceDescription?.properties?.[EDITOR_NOTE_IRI]?.[0]?.value;

  const authenticatedUserRole = acl.getRoleInAccount(account);

  const allowedEditActions = getAllowedActions(resourceStatus, authenticatedUserRole);

  return (
    <Paper className={getClassName("p-5", className)}>
      <Container className={getClassName("flex column g-5", innerClassName)}>
        {!resourceDescription ? null : (
          <Stack gap={1} direction={"row"} alignItems={"flex-start"} flexWrap="wrap">
            <div className="my-2">
              <Typography variant="h4" component={"h1"}>
                {resourceDescription?.valueLabel || applyPrefixes(resourceDescription.value)}
              </Typography>
              <a href={resourceDescription.value}>{resourceDescription.value}</a>{" "}
              <Chip
                label={resourceDescription.typeLabel || resourceDescription.type}
                component={Link}
                to={{ search: stringifyQuery({ resource: resourceDescription.type }) }}
              />
            </div>
            <div className="grow" />

            {allowedEditActions.includes("EditResource") && <EditResource resource={resource} />}
            {!!resourceDescription && allowedEditActions.includes("CopyResource") && 1 != 1 && <CopyResource />}
            {allowedEditActions.includes("StageResource") && <StageResource resource={resource} />}
            {allowedEditActions.includes("ApproveResource") && <ApproveResource resource={resource} />}
            {allowedEditActions.includes("PublishResource") && <PublishResource />}
            {allowedEditActions.includes("RejectResource") && <RejectResource />}
            {allowedEditActions.includes("ArchiveResource") && <ArchiveResource />}
            {allowedEditActions.includes("DeleteResource") && <RemoveResource />}
          </Stack>
        )}

        {allowedEditActions.length > 0 && resourceStatus && (
          <Toolbar className="flex wrap center g-3" component={(props) => <Paper {...props} variant="outlined" />}>
            <div className="flex g-3">
              <Stack>
                <Typography variant="caption">Status</Typography>
                <Chip label={resourceStatus} />
              </Stack>
              {modifiedDate && (
                <Stack>
                  <Typography variant="caption">Modified</Typography>
                  <HumanizedDate date={modifiedDate} />
                </Stack>
              )}
              {authorIri && (
                <Stack>
                  <Typography variant="caption">Author</Typography>
                  <Chip
                    label={authorIri.split("/").pop()}
                    avatar={<Avatar>{authorIri.split("/").pop()?.[0] || "?"}</Avatar>}
                  />
                </Stack>
              )}
              {createdDate && (
                <Stack>
                  <Typography variant="caption">Created</Typography>
                  <HumanizedDate date={createdDate} />
                </Stack>
              )}
            </div>
          </Toolbar>
        )}
        {editorNote && (
          <Alert severity="info" className="my-5">
            {editorNote}
          </Alert>
        )}
        {dsId && resourceDescription && <PropertiesViewer dsId={dsId} description={resourceDescription} />}
      </Container>
    </Paper>
  );
};

export default InstanceView;
