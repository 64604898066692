import * as React from "react";
import { useLocation } from "react-router";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import Prefixes from "./Prefixes.tsx";

const AdminPrefixes: React.FC<{}> = ({}) => {
  const acl = useAcl();

  if (!acl.check({ action: "editInstanceMetadata" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <FlexContainer>
      <PrefixesMeta />
      <Prefixes />
    </FlexContainer>
  );
};

export default AdminPrefixes;

const PrefixesMeta: React.FC = () => {
  const location = useLocation(); // Prevent re-rendering of the component when the location changes
  return <Meta currentPath={location.pathname} title="Prefixes" />;
};
