import * as React from "react";
import { DatasetPublic } from "@triply/utils/Models.js";
import { Prefix } from "@triply/utils/prefixUtils.js";
import {
  isAskResponse,
  isConstructResponse,
  isSelectResponse,
  RawResponse,
  SparqlResponse,
} from "#components/Sparql/SparqlUtils.ts";
import { PrefixUpdate } from "../../../reducers/datasetManagement.ts";
import { RenderConfig, VisualizationLabel, VisualizationProperties } from "./index.tsx";

export interface DownloadVisualization {
  id: VisualizationLabel;
  displayName: string;
  extension: string;
  getDataUrl: () => string | Promise<string>;
}
const constructVars: string[] = ["subject", "predicate", "object"];
interface SparqlVisualizationContext {
  data: SparqlResponse | undefined;
  rawData: RawResponse | undefined;
  queryName?: string;
  prefixes: Prefix[];
  onCreatePrefix?: (prefix: PrefixUpdate) => Promise<true | string>;
  visualizationActionsCtrId?: string;
  getVisualizationConfig: <T extends keyof RenderConfig>(plugin: T) => RenderConfig[T];
  setVisualizationConfig?: <T extends keyof RenderConfig>(selectedPlugin: T, config: RenderConfig[T]) => void;
  registerDownload?: (download: DownloadVisualization) => void;
  unregisterDownload?: (id: VisualizationLabel) => void;
  customErrorMessage?: string;
  customNoResultsMessage?: string;
  datasetPath: string | undefined;
  responseSize?: number;
  checkLimits?: boolean;
}

export const SparqlVisualizationContext = React.createContext<SparqlVisualizationContext>({
  data: undefined,
  rawData: undefined,
  prefixes: [],
  visualizationActionsCtrId: "",
  getVisualizationConfig: () => {
    throw new Error("Missing provider");
  },
  datasetPath: "",
  checkLimits: false,
});

export const useSparqlResults = () => {
  const { data } = React.useContext(SparqlVisualizationContext);
  if (data) {
    if (isConstructResponse(data)) {
      return {
        results: data,
        variables: constructVars,
        isConstruct: true,
        isAsk: false,
        isSelect: false,
        isTabular: true,
        emptyResults: data.length === 0,
        noResults: false,
      } as const;
    }
    if (isAskResponse(data)) {
      return {
        results: data.boolean,
        variables: ["boolean"] as string[],
        isConstruct: false,
        isAsk: true,
        isSelect: false,
        isTabular: false,
        emptyResults: false,
        noResults: false,
      } as const;
    }
    if (isSelectResponse(data)) {
      return {
        results: data.results.bindings,
        variables: data.head.vars,
        isConstruct: false,
        isAsk: false,
        isTabular: true,
        isSelect: true,
        emptyResults: data.results.bindings.length === 0,
        noResults: false,
      } as const;
    }
  }
  return {
    noResults: true,
  } as const;
};
