import { Location } from "history";
import * as React from "react";
import useResizeObserver from "use-resize-observer";
import { parseSearchString } from "#helpers/utils.ts";

export default function useEmbedded({
  location,
  resizeObserver,
  pageRef,
}: {
  location: Location;
  resizeObserver: ReturnType<typeof useResizeObserver>;
  pageRef: any;
}) {
  const embedded = parseSearchString(location.search).embed !== undefined;
  const reportedHeight = React.useRef(resizeObserver.height);

  React.useEffect(() => {
    if (embedded && __CLIENT__ && window.parent) {
      const newHeight = resizeObserver.height || pageRef.current?.offsetHeight || 1000;
      const difference = newHeight - (reportedHeight.current || 0);
      // Only report size when, initially setting the height, and only shrink after a difference of 5px
      if (!reportedHeight.current || difference > 0 || difference < -5) {
        try {
          window.parent.postMessage(newHeight, "*");
          reportedHeight.current = newHeight;
        } catch (e) {
          console.error("Could not update embedded height", e);
        }
      }
    }
  }, [embedded, resizeObserver.height, pageRef]);

  return embedded;
}
