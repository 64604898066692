import { fromPairs, groupBy } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { FormValues } from "./Types";
import useSparql from "./useSparql";

const converter = new Converter({ materializeRdfJsTerms: true });

const valuesQuery = (resource: string) => `
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

select
  *
where {
  bind(<${resource}> as ?value)
  ?value a ?type_id .
  optional {
    ?type_id rdfs:label|skos:prefLabel ?type_label .
  }
  bind("IRI" as ?nodeKind)
  optional {
    ?value ?properties_predicate ?properties_rawValue.
    filter(!strstarts(str(?properties_predicate), str(meta:)))
    bind(str(?properties_rawValue) as ?properties_value)
    filter(?properties_predicate != rdf:type)
    bind(if(isiri(?properties_rawValue), if(regex(?properties_value, "\.well-known/genid"), "BlankNode", "IRI"), "Literal") as ?properties_nodeKind)

    optional {
      filter(?properties_nodeKind = "Literal")
      bind(datatype(?properties_rawValue) as ?properties_datatype)
      bind(lang(?properties_rawValue) as ?properties_language)
    }
    optional {
      filter(?properties_nodeKind = "BlankNode")
      ?properties_rawValue a ?properties_type .
      ?properties_rawValue ?properties_properties_predicate ?properties_properties_rawValue
      bind(str(?properties_properties_rawValue) as ?properties_properties_value)
      filter(?properties_properties_predicate != rdf:type)
      bind(if(isiri(?properties_properties_rawValue), if(regex(?properties_properties_value, "\.well-known/genid"), "BlankNode", "IRI"), "Literal") as ?properties_properties_nodeKind)
      optional {
        filter(?properties_properties_nodeKind = "Literal")
        bind(datatype(?properties_properties_rawValue) as ?properties_properties_datatype)
        bind(lang(?properties_properties_rawValue) as ?properties_properties_language)
      }
    }
  }
}

`;

const transformProperties = (properties: any) => {
  if (!properties) return;
  return groupBy(
    properties.map((v: any) => ({
      ...v,
      key: v.predicate.replace(/\./g, " "),
      properties: transformProperties(v.properties),
    })),
    "key",
  );
};

const useFetchInitialValues = () => {
  const sparql = useSparql();

  const fetchInitialValues = async (resource: string) => {
    const results = await sparql(valuesQuery(resource));
    let json = converter.sparqlJsonResultsToTree(results, {
      singularizeVariables: {
        "": true,
        type: true,
        ...fromPairs(results.head!.vars.map((v) => [v, true])),
      },
    });

    json.properties = transformProperties(json.properties);

    return json as FormValues;
  };

  return fetchInitialValues;
};

export default useFetchInitialValues;
