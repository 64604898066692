import getClassName from "classnames";
import * as React from "react";
import { KeyboardKey } from "#components/index.ts";

const KeyboardKeyCombination: React.FC<{ className?: string; children: string }> = ({ children, className }) => {
  if (children === "+") {
    return (
      <kbd className={getClassName(className)}>
        <KeyboardKey>{children.trim()}</KeyboardKey>
      </kbd>
    );
  }
  return (
    <kbd className={getClassName(className)}>
      {children.split("+").map((key, i) => (
        <React.Fragment key={key}>
          {i !== 0 && <small>+</small>}
          <KeyboardKey>{key.trim()}</KeyboardKey>
        </React.Fragment>
      ))}
    </kbd>
  );
};

export default KeyboardKeyCombination;
