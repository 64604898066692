import { Icon } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";

const TableBooleanRenderer: React.FC<CellContext<any, boolean | "n/a" | undefined>> = ({ cell }) => {
  const value = cell.getValue();
  if (value === undefined) return null;
  if (value === "n/a") return null;
  if (value) {
    return (
      <div title="True" aria-label="Yes">
        <Icon color="success" fontSize="inherit">
          <FontAwesomeIcon icon="check"></FontAwesomeIcon>
        </Icon>
      </div>
    );
  } else {
    return (
      <div title="False" aria-label="No">
        <Icon color="error" fontSize="inherit">
          <FontAwesomeIcon icon="times"></FontAwesomeIcon>
        </Icon>
      </div>
    );
  }
};

export default TableBooleanRenderer;
