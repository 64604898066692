import * as React from "react";
import { Models } from "@triply/utils";
import { Button } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { logout } from "#reducers/auth.ts";

const RevokeSessions: React.FC<{}> = () => {
  const constructUrlToApi = useConstructUrlToApi();
  const currentAccount = useCurrentAccount();
  const acl = useAcl();
  const { del, error, loading } = useFetch<Models.StoryFacets>(
    constructUrlToApi({ pathname: `/accounts/${currentAccount?.accountName}/session` }),
  );
  if (!currentAccount) {
    throw new Error("Expected account to be present");
  }
  return (
    <Button
      color="warning"
      onClick={() => {
        del()
          .then(() => {
            if (acl.isAuthenticatedUser(currentAccount)) logout();
          })
          .catch(() => {});
      }}
      disabled={!!error || !!loading}
    >
      Revoke all active web sessions on all of your devices
    </Button>
  );
};

export default RevokeSessions;
