import { fromPairs } from "lodash-es";
import * as React from "react";
import { Converter } from "sparqljson-to-tree";
import fetch from "../../helpers/fetch";
import { useCurrentDataset } from "../../reducers/datasetManagement";
import useConstructUrlToApi from "./useConstructUrlToApi";

const converter = new Converter({ materializeRdfJsTerms: true });

const useSparql = <R>(
  query: string | false,
  initialJsonTree?: { singularizeVariables: { [variable: string]: boolean } },
) => {
  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });
  const jsonTree = React.useRef(initialJsonTree);
  const [data, setData] = React.useState<R>();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: jsonTree.current ? "application/sparql-results+json" : "application/json" },
        body: new URLSearchParams({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    [sparqlUrl, currentDs.owner.accountName, currentDs.name],
  );

  React.useEffect(() => {
    if (!query) return;
    setLoading(true);
    setError(undefined);
    const abortController = new AbortController();
    let aborted = false;
    sparql(query, abortController.signal)
      .then((result) => {
        if (!aborted) {
          if (jsonTree.current) {
            result = converter.sparqlJsonResultsToTree(result, {
              singularizeVariables: {
                ...fromPairs(result.head.vars.map((v: any) => [v, true])),
                ...jsonTree.current.singularizeVariables,
              },
            });
          }
          setData(result);
        }
      })
      .catch((e) => {
        if (!aborted) setError(e.message);
      })
      .finally(() => setLoading(false));
    return () => {
      aborted = true;
      abortController.abort(new Error("Not needed anymore."));
    };
  }, [query, sparql, currentDs.lastGraphsUpdateTime]);

  return { data, error, loading };
};

export default useSparql;
