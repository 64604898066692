import * as React from "react";
import { UseFetch, useFetch as _useFetch, UseFetchArgs } from "use-http"; // eslint-disable-line no-restricted-imports
import { checkSessionEnd } from "#helpers/fetch.ts";

export default function useFetch<TData = any>(...args: UseFetchArgs): UseFetch<TData> {
  const everything = _useFetch(...args);

  React.useEffect(() => {
    checkSessionEnd(everything.response?.headers?.get("X-Triply-Session-Ended"));
  }, [everything.response]);

  return everything;
}
