import { Alert } from "@mui/material";
import * as React from "react";
import { Prefix } from "@triply/utils";
import { getPrefixed } from "@triply/utils/prefixUtils.js";
import { Html, Markdown } from "#components/index.ts";
import renderMarkup from "#helpers/markup/index.ts";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import { Term } from "../../SparqlUtils";
import { SparqlVisualizationContext } from "../SparqlVisualizationContext";

const DATATYPE_HTML = "http://www.w3.org/1999/02/22-rdf-syntax-ns#HTML";
const DATATYPE_MARKDOWN = "https://triplydb.com/Triply/vocab/def/markdown";
const DATATYPE_MERMAID = "https://triplydb.com/Triply/vocab/def/mermaid";

function isMarkUp(datatype: string): boolean {
  return datatype === DATATYPE_MARKDOWN;
}

function isHtml(datatype: string): boolean {
  return datatype === DATATYPE_HTML;
}
function isMermaid(datatype: string): boolean {
  return datatype === DATATYPE_MERMAID;
}

function getRenderer(term: Term): Renderer {
  if (term.type === "uri") return "IRI";
  if ("datatype" in term && !!term.datatype) {
    if (isMarkUp(term.datatype)) return "Markup";
    if (isHtml(term.datatype)) return "Html";
    if (isMermaid(term.datatype)) return "Mermaid";
  }
  return "Text";
}

export type Renderer = "IRI" | "Markup" | "Html" | "Mermaid" | "Text";

export const SanitizedContent: React.FC<{ term: Term | undefined; className?: string; renderAs?: Renderer }> = ({
  term,
  className,
  renderAs,
}) => {
  const { prefixes } = React.useContext(SparqlVisualizationContext);
  if (!term) return null;
  const value = term.value;
  let shouldRenderAs = renderAs || getRenderer(term);
  switch (shouldRenderAs) {
    case "IRI":
      return <Markdown className={className}>{`[${getPrefixed(value, prefixes) || value}](${value})`}</Markdown>;
    case "Markup":
      return <Markdown className={className}>{value}</Markdown>;
    case "Html":
      return <Html className={className}>{value}</Html>;
    case "Mermaid":
      return <Markdown className={className}>{`\`\`\`mermaid\n${value}\n\`\`\``}</Markdown>;
    case "Text":
    default:
      if ("xml:lang" in term) {
        return (
          <span className={className} lang={term["xml:lang"]}>
            {value}
          </span>
        );
      }
      return <span className={className}>{value}</span>;
  }
};

export function termToSanitizedHtml(term: Term | undefined, prefixes?: Prefix[]) {
  if (!term) return "";
  let value = term.value;
  const renderAs = getRenderer(term);
  let markupRenderer: "markdown" | "html" | "text" = "text";
  switch (renderAs) {
    case "IRI":
      markupRenderer = "markdown";
      value = `[${prefixes ? getPrefixed(value, prefixes) || value : value}](${value})`;
      break;
    case "Markup":
      markupRenderer = "markdown";
      break;
    case "Html":
      markupRenderer = "html";
      break;
    case "Mermaid":
      markupRenderer = "markdown";
      value = "```mermaid\n" + value + "\n```";
      break;
    case "Text":
    default:
      break;
  }
  if (markupRenderer !== "text") {
    return renderMarkup(value, {
      from: markupRenderer,
      to: "html",
      openLinkInCurrentWindow: (href: string) => isLinkToDomain(href, location.hostname),
      compact: false,
    });
  } else {
    // Use the browser to clean the text
    const element = document.createElement("div");
    element.innerText = value;
    const result = element.innerHTML;
    element.remove();
    return result;
  }
}

export const EmptyResults: React.FC<{}> = () => {
  const { customNoResultsMessage } = React.useContext(SparqlVisualizationContext);
  return (
    <Alert severity="info" role="status" className="noResultsMessage">
      <span>{customNoResultsMessage || "No results"}</span>
    </Alert>
  );
};
