import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Account } from "./accountCollection.ts";

export const LocalActions = {
  GET_GRAPHS: "triply/graphs/GET_GRAPHS",
  GET_GRAPHS_SUCCESS: "triply/graphs/GET_GRAPHS_SUCCESS",
  GET_GRAPHS_FAIL: "triply/graphs/GET_GRAPHS_FAIL",
  REMOVE_GRAPH: "triply/graphs/REMOVE_GRAPH",
  REMOVE_GRAPH_SUCCESS: "triply/graphs/REMOVE_GRAPH_SUCCESS",
  REMOVE_GRAPH_FAIL: "triply/graphs/REMOVE_GRAPH_FAIL",
  REMOVE_ALL_GRAPHS: "triply/graphs/REMOVE_ALL_GRAPHS",
  REMOVE_ALL_GRAPHS_SUCCESS: "triply/graphs/REMOVE_ALL_GRAPHS_SUCCESS",
  REMOVE_ALL_GRAPHS_FAIL: "triply/graphs/REMOVE_ALL_GRAPHS_FAIL",
  RENAME_GRAPH: "triply/graphs/RENAME_GRAPH",
  RENAME_GRAPH_SUCCESS: "triply/graphs/RENAME_GRAPH_SUCCESS",
  RENAME_GRAPH_FAIL: "triply/graphs/RENAME_GRAPH_FAIL",
} as const;

type GET_GRAPHS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_GRAPHS,
      typeof LocalActions.GET_GRAPHS_SUCCESS,
      typeof LocalActions.GET_GRAPHS_FAIL,
    ];
    datasetId: string;
  },
  Routes.datasets._account._dataset.graphs.Get
>;

type REMOVE_GRAPH = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_GRAPH,
      typeof LocalActions.REMOVE_GRAPH_SUCCESS,
      typeof LocalActions.REMOVE_GRAPH_FAIL,
    ];
    forDataset: Dataset;
    graph: Graph;
  },
  Routes.datasets._account._dataset.graphs._graphId.Delete
>;

type RENAME_GRAPH = GlobalAction<
  {
    types: [
      typeof LocalActions.RENAME_GRAPH,
      typeof LocalActions.RENAME_GRAPH_SUCCESS,
      typeof LocalActions.RENAME_GRAPH_FAIL,
    ];
    forDataset: Dataset;
    graphId: string;
    graphName: string;
  },
  Routes.datasets._account._dataset.graphs._graphId.Patch
>;

type REMOVE_ALL_GRAPHS = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_ALL_GRAPHS,
      typeof LocalActions.REMOVE_ALL_GRAPHS_SUCCESS,
      typeof LocalActions.REMOVE_ALL_GRAPHS_FAIL,
    ];
    forDataset: Dataset;
  },
  Routes.datasets._account._dataset.graphs.Delete
>;

export type LocalAction = GET_GRAPHS | REMOVE_GRAPH | RENAME_GRAPH | REMOVE_ALL_GRAPHS;

export type Graph = Models.Graph;
export type Graphs = Models.Graphs;

export interface State {
  [datasetId: string]: {
    list?: Graphs;
    largestList: Graph[];
  };
}

function ensureInitStateForId(draftState: State, datasetId: string) {
  if (!draftState[datasetId]) {
    draftState[datasetId] = { list: undefined, largestList: [] };
  }
}
export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.SOCKET_EVENT.indexJobFinished:
        const dataset = action.data.dataset;
        ensureInitStateForId(draftState, dataset.id);
        draftState[dataset.id].largestList = dataset.largestGraphs;
        draftState[dataset.id].list = action.data.graphs;
        return;
      case Actions.GET_GRAPHS_SUCCESS:
        ensureInitStateForId(draftState, action.datasetId);
        draftState[action.datasetId].list = action.result;
        return;

      case Actions.REMOVE_GRAPH_SUCCESS:
        draftState[action.forDataset.id].list = draftState[action.forDataset.id].list?.filter(
          (g) => g.id !== action.graph.id,
        );
        draftState[action.forDataset.id].largestList = draftState[action.forDataset.id].largestList.filter(
          (g) => g.id !== action.graph.id,
        );
        return;

      case Actions.REMOVE_ALL_GRAPHS_SUCCESS:
        delete draftState[action.forDataset.id];
        return;

      case Actions.RENAME_GRAPH_SUCCESS:
        const renameList = draftState[action.forDataset.id].list || [];
        const listIndex = renameList.findIndex((g) => g.id === action.graphId);
        if (listIndex >= 0) renameList[listIndex] = action.result;
        const largestListIndex = draftState[action.forDataset.id].largestList.findIndex((g) => g.id === action.graphId);
        if (largestListIndex >= 0) draftState[action.forDataset.id].largestList[largestListIndex] = action.result;
        return;

      case Actions.GET_CURRENT_DATASET_SUCCESS:
      case Actions.REFRESH_CURRENT_DATASET_SUCCESS:
        ensureInitStateForId(draftState, action.result.id);
        draftState[action.result.id].largestList = action.result.largestGraphs;
        return;
    }
  },
  <State>{},
);

export function getGraphs({
  accountName,
  datasetName,
  datasetId,
}: {
  accountName: string;
  datasetName: string;
  datasetId: string;
}): BeforeDispatch<GET_GRAPHS> {
  return {
    types: [Actions.GET_GRAPHS, Actions.GET_GRAPHS_SUCCESS, Actions.GET_GRAPHS_FAIL],
    promise: (client) => {
      return client.req({
        pathname: `/datasets/${accountName}/${datasetName}/graphs`,
        method: "get",
      });
    },

    datasetId,
  };
}

export function needToFetchGraphs(globalState: GlobalState, forDataset: Dataset) {
  if (!forDataset || forDataset.graphCount === 0) return false;
  return !globalState.graphs || !globalState.graphs[forDataset.id] || !globalState.graphs[forDataset.id].list;
}

export function removeGraph(forAccount: Account, forDataset: Dataset, graph: Graph): BeforeDispatch<REMOVE_GRAPH> {
  return {
    types: [Actions.REMOVE_GRAPH, Actions.REMOVE_GRAPH_SUCCESS, Actions.REMOVE_GRAPH_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/graphs/${graph.id}`,
        method: "delete",
      }),
    forDataset,
    graph,
  };
}

export function renameGraph(
  forAccount: Account,
  forDataset: Dataset,
  graphId: string,
  graphName: string,
): BeforeDispatch<RENAME_GRAPH> {
  return {
    types: [Actions.RENAME_GRAPH, Actions.RENAME_GRAPH_SUCCESS, Actions.RENAME_GRAPH_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/graphs/${graphId}`,
        method: "patch",
        body: { graphName: graphName },
      }),
    forDataset,
    graphId,
    graphName,
  };
}

export function removeAllGraphs(forDataset: Dataset): BeforeDispatch<REMOVE_ALL_GRAPHS> {
  return {
    types: [Actions.REMOVE_ALL_GRAPHS, Actions.REMOVE_ALL_GRAPHS_SUCCESS, Actions.REMOVE_ALL_GRAPHS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forDataset.owner.accountName}/${forDataset.name}/graphs`,
        method: "delete",
      }),
    forDataset,
  };
}
