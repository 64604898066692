import { jsonLanguage } from "@codemirror/lang-json";
import { Alert, Box, Chip, MenuItem, Select, SelectChangeEvent, Theme, useTheme } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import { fromPairs, toPairs, uniq } from "lodash-es";
import * as React from "react";
import { Converter } from "sparqljson-to-tree";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import { isSelectResponse, SparqlResponse } from "../../../SparqlUtils";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext";

export interface PluginConfig {
  group: string[];
}

export const canDraw = (data?: SparqlResponse) => {
  if (!data) return false;
  return isSelectResponse(data);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type SingularizeVariables = { [v: string]: boolean };

function getStyles(name: string, personName: SingularizeVariables, theme: Theme) {
  return {
    fontWeight: personName[name] ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

const converter = new Converter({ materializeRdfJsTerms: true });

const toSingularizeVariables = (vars: string[]) => {
  return fromPairs(vars.map((v) => [v, true]));
};

const Json: React.FC<{}> = () => {
  const theme = useTheme();
  const { data } = React.useContext(SparqlVisualizationContext);

  const [variablesToSingularize, setVariablesToSingularize] = React.useState<SingularizeVariables>({});

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setVariablesToSingularize(
      // On autofill we get a stringified value.
      toSingularizeVariables(typeof value === "string" ? value.split(",") : value),
    );
  };

  React.useEffect(() => {
    setVariablesToSingularize((variables) => {
      if (data && "head" in data && data.head.vars) {
        return {
          ...toSingularizeVariables(data.head.vars),
          ...variables,
        };
      }
      return variables;
    });
  }, [data]);

  if (data === undefined) return null;

  if (!("head" in data) || !data.head.vars) return <Alert>No variables!</Alert>;

  let json: any;
  let parseError = false;
  try {
    json = converter.sparqlJsonResultsToTree(data, {
      singularizeVariables: variablesToSingularize,
    });
  } catch (e) {
    console.error(e);
    json = data;
    parseError = true;
  }

  const vars = data.head.vars;
  const options = uniq(vars.flatMap((v) => v.split("_").map((_v, i, t) => t.slice(0, i + 1).join("_"))));

  return (
    <>
      <div className="flex center g-5 px-3">
        <Select
          multiple
          value={toPairs(variablesToSingularize).reduce((a, v) => {
            return [...a, ...(v[1] ? [v[0]] : [])];
          }, [] as string[])}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          fullWidth
          className="my-3"
        >
          <MenuItem key={"root"} value={""} style={getStyles("", variablesToSingularize, theme)}>
            root
          </MenuItem>
          {options.map((v) => (
            <MenuItem key={v} value={v} style={getStyles(v, variablesToSingularize, theme)}>
              {v}
            </MenuItem>
          ))}
        </Select>
        {parseError && (
          <span title="Something went wrong parsing with these variables.">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
        )}
      </div>
      <CodeMirror readOnly value={JSON.stringify(json, null, 2)} extensions={[jsonLanguage, githubLight]} />
    </>
  );
};

export default Json;
