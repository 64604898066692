import { extname } from "path";
import * as React from "react";
import UrlParse from "url-parse";
import { FontAwesomeIcon, Image, TermLiteral, TermLiteralDefault } from "#components/index.ts";
import * as styles from "./style.scss";

const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg", ".bmp", ".tiff"];

class TermLiteralImage extends React.PureComponent<TermLiteral.Props, {}> {
  static shouldRender(props: TermLiteral.Props) {
    return (
      props.datatype === "https://triply.cc/triply/def/imageURI" ||
      (props.datatype === "http://www.w3.org/2001/XMLSchema#anyURI" &&
        // Typing doesn't seem to be up to date (url.query doesn't match the type definition) so use href instead, search both path or query for file extention
        imageExtensions.indexOf(extname(UrlParse(props.value).pathname).toLowerCase()) >= 0) ||
      imageExtensions.indexOf(extname(UrlParse(props.value).href).toLowerCase()) >= 0
    );
  }
  render() {
    const { value, className } = this.props;

    return (
      <div className={className}>
        <Image
          src={value}
          title={this.props.expanded ? this.props.datatype : value}
          createLink
          showLoadingIcon
          loadingIconEl={<FontAwesomeIcon icon={["fas", "cog"]} spin className={styles.spinner} />}
          imageClassName={styles.image}
          alt=""
          fallback={<TermLiteralDefault {...this.props} />}
        />
      </div>
    );
  }
}
export default TermLiteralImage;
