import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { asyncConnect } from "redux-connect";
import * as ReduxForm from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { groups, tokenGroupsToScopes } from "#components/Forms/Token/tokenGroups.ts";
import {
  AccountMetadata,
  Button,
  Dialog,
  ErrorPage,
  FlexContainer,
  FontAwesomeIcon,
  FontAwesomeRoundIcon,
  TokenList,
} from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { accountIsCurrentAccount, getAccountInfo, getCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import { createToken, getTokens, revokeToken, tokensLoadedFor } from "#reducers/tokens.ts";
import { urlInfoToString } from "#staticConfig.ts";
import * as styles from "./style.scss";

const TokenManager: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const { push, goBack } = useHistory();
  const { currentAccount, currentToken, tokens, apiUrl } = useSelector((state: GlobalState) => ({
    currentAccount: getCurrentAccount(state),
    currentToken: state.tokens.currentToken,
    tokens: state.tokens.list,
    apiUrl: state.config.staticConfig?.apiUrl,
  }));
  const revokeTokenHandler = React.useCallback(
    (token: string) => {
      if (!currentAccount) throw new Error("Cannot revoke token when account is unknown");
      return dispatch(revokeToken(currentAccount, token));
    },
    [currentAccount, dispatch],
  );
  const addNewToken = React.useCallback(
    (values: Forms.Token.FormData) => {
      if (!currentAccount) throw new Error("Cannot add new token when account is unset");
      return dispatch<typeof createToken>(
        createToken(currentAccount, values.description, tokenGroupsToScopes(values)),
      ).then(
        () => {},
        (e: any) => {
          throw new ReduxForm.SubmissionError({ _error: e.message });
        },
      );
    },
    [dispatch, currentAccount],
  );

  if (
    !currentAccount ||
    !acl.check({ action: "manageTokens", context: { roleInUser: acl.getRoleInAccount(currentAccount) } }).granted
  ) {
    return <ErrorPage statusCode={401} />;
  }
  if (!apiUrl) {
    return <></>;
  }
  const apiPath = urlInfoToString(apiUrl, { pathname: "" });
  return (
    <FlexContainer>
      <AccountMetadata
        currentAccount={currentAccount}
        currentPath={props.location.pathname}
        title="API Tokens - User settings"
      />
      <div className="whiteSink">
        <div>
          <p>
            API tokens are used by applications to access or modify information in the TriplyDB API. Visit the{" "}
            <a href="https://triply.cc/docs/triply-api" target="_blank">
              docs
            </a>{" "}
            for more information on accessing the TriplyDB API.
          </p>
          <p>
            <span className={styles.apiPathLabel}>API path: </span>
            <a href={apiPath} target="_blank">
              {apiPath}
            </a>
          </p>
          <h4>Token Access Levels</h4>
          <dl className={styles.authDescription}>
            {groups.map((group) => {
              return (
                <React.Fragment key={group.mainField.name}>
                  <dt className="mr-2">
                    <FontAwesomeRoundIcon icon={group.mainField.icon} className={styles.authLevelIcon} aria-label="" />
                  </dt>
                  <dd>
                    <p className={getClassName(styles.tokenLabel, "my-3")}>{group.mainField.label}</p>
                    <p className={getClassName("my-3")}>{group.mainField.description}</p>
                  </dd>
                </React.Fragment>
              );
            })}
          </dl>
        </div>
      </div>
      <Button
        elevation
        color="secondary"
        className="mt-3 ml-3"
        startIcon={<FontAwesomeIcon icon="plus" />}
        onClick={() => {
          push({ state: { tokenAddModalShown: true } });
        }}
      >
        Create token
      </Button>
      <TokenList revokeTokenHandler={revokeTokenHandler} tokens={tokens || []} />
      <Dialog
        maxWidth="md"
        fullWidth
        open={!!props.location.state && !!props.location.state.tokenAddModalShown}
        onClose={goBack}
        title="Create Token"
      >
        <Forms.Token
          className="m-5"
          currentAccount={currentAccount}
          onSubmit={addNewToken}
          cancel={goBack}
          token={currentToken}
          initialValues={{ description: "", authLevel: "sc_acc" }}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ match: { params }, store: { dispatch, getState } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        return dispatch<any>(getAccountInfo(getState(), params.account)).then(() => {
          const currentAccount = getCurrentAccount(getState());
          if (currentAccount && !tokensLoadedFor(getState(), currentAccount.uid))
            return dispatch<any>(getTokens(currentAccount));
        });
      } else {
        const currentAccount = getCurrentAccount(getState());
        if (currentAccount && !tokensLoadedFor(getState(), currentAccount.uid))
          return dispatch<any>(getTokens(currentAccount));
      }
    },
  },
])(TokenManager) as typeof TokenManager;
