import { fromPairs } from "lodash-es";
import * as React from "react";
import { Converter } from "sparqljson-to-tree";
import { classesQuery } from "./Queries.ts";
import { ClassInfo } from "./Types.ts";
import useSparql from "./useSparql";

const converter = new Converter({ materializeRdfJsTerms: true });

const useClasses = () => {
  const sparql = useSparql();
  const [classes, setClasses] = React.useState<ClassInfo[]>();

  React.useEffect(() => {
    sparql(classesQuery)
      .then((results) => {
        const json = converter.sparqlJsonResultsToTree(results, {
          singularizeVariables: fromPairs(results.head!.vars.map((v) => [v, true])),
        });
        setClasses(json);
      })
      .catch(() => {});
  }, [sparql]);

  return classes;
};

export default useClasses;
