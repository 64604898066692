import { Alert } from "@mui/material";
import * as React from "react";
import { DashViewer, DashViewerProps } from ".";

const LabelViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "IRI" || property.valueLabel === undefined)
    return <Alert severity="error">Wrongly configured Labelviewer</Alert>;
  return (
    <a href={property.value} rel="noopener noreferrer">
      {property.valueLabel}
    </a>
  );
};

const LabelViewer: DashViewer = {
  component: LabelViewerComponent,
  getScore({ nodeKind, label }) {
    if (nodeKind === "IRI" && label !== undefined) return 5;
    return 0;
  },
};

export default LabelViewer;
