import * as React from "react";
import { useSelector } from "react-redux";
import { Constants } from "@triply/utils";
import { Meta } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import { GlobalState } from "#reducers/index.ts";

export interface Props {}

const HomePageMetadata: React.FC<Props> = (_props) => {
  const constructConsoleUrl = useConstructConsoleUrl();
  const welcomeMessage = useSelector((state: GlobalState) => state.config.clientConfig?.branding.welcomeMessage);
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  const mainPageItems = useSelector((state: GlobalState) => state.config.frontpage?.items);
  const contactEmail = useSelector((state: GlobalState) => state.config.clientConfig?.contactEmail);
  const url = constructConsoleUrl();

  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: url,
    mainEntity: filterEmptyVals({
      "@id": url,
      "@type": "DataCatalog",
      url: url,
      name: brandingName,
      description: welcomeMessage,
      image: logoLg,
      exampleOfWork: (mainPageItems || []).map((ex) => {
        if (ex.type === "Dataset") {
          return filterEmptyVals({
            "@type": "Dataset",
            "@id": constructConsoleUrl({ pathname: `/${ex.item.owner.accountName}/${ex.item.name}` }),
            name: ex.item.displayName || ex.item.name,
            description: ex.item.description,
            license: Constants.LICENSES[ex.item.license || ""]?.url,
          });
        } else if (ex.type === "Query") {
          return filterEmptyVals({
            "@type": "CreativeWork",
            "@id": constructConsoleUrl({ pathname: `/${ex.item.owner.accountName}/-/queries/${ex.item.name}` }),
            name: ex.item.displayName || ex.item.name,
            description: ex.item.description,
          });
        } else {
          return filterEmptyVals({
            "@type": "Article",
            "@id": constructConsoleUrl({
              pathname: `/${ex.item.owner.accountName}/-/stories/${ex.item.name}`,
            }),
            name: ex.item.displayName || ex.item.name,
            description: `${ex.item.displayName || ex.item.name} by ${
              ex.item.owner.type === "org" ? "organization" : "user"
            } ${ex.item.owner.name || ex.item.owner.accountName} on ${brandingName}.`,
          });
        }
      }),
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    },
  };

  return (
    <Meta
      currentPath={``}
      titleTemplate={"%s"}
      description={welcomeMessage}
      jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]}
    />
  );
};
export default HomePageMetadata;
