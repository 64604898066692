// extracted by mini-css-extract-plugin
var _1 = "style--footer--yn6AIcnF";
var _2 = "style--footerContainer--ilzn7RoG";
var _3 = "style--footerItem--zXx_Jgc2";
var _4 = "style--footerText--JF1i3mnz";
var _5 = "style--grayIcon--F7K2UxzY";
var _6 = "style--lowerFooter--sh5NGTlO";
var _7 = "style--pageCollection--OwpQg3HW";
var _8 = "style--pageHeader--VLM5wVqB";
var _9 = "style--upperFooter--AN5j9JlQ";
export { _1 as "footer", _2 as "footerContainer", _3 as "footerItem", _4 as "footerText", _5 as "grayIcon", _6 as "lowerFooter", _7 as "pageCollection", _8 as "pageHeader", _9 as "upperFooter" }
