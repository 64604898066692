import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { ErrorPage, FlexContainer, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import { submitExtraUserDetails } from "#reducers/accounts.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export namespace ExtraUserDetails {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    submitExtraUserDetails: DispatchedFn<typeof submitExtraUserDetails>;
    pushState: typeof connectedReactRouter.push;
  }
  export interface PropsFromState {
    user: Account;
    email: string;
  }
  export interface State {}
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

const mapStateToProps = (state: GlobalState) => {
  const loggedInUser = getLoggedInUser(state);
  return {
    user: loggedInUser,
    email: loggedInUser ? loggedInUser.email : undefined,
  } as ExtraUserDetails.PropsFromState;
};

class ExtraUserDetails extends React.PureComponent<ExtraUserDetails.Props, ExtraUserDetails.State> {
  handleSubmit = (values: Forms.RequiredDetails.FormData) => {
    return this.props.submitExtraUserDetails?.({ email: values.email }).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps: ExtraUserDetails.Props) {
    if (!!nextProps.user.email && this.props.location.pathname.indexOf("/_") === 0) {
      //email address was added, now redirecting to '/me'
      //the router will re-fetch updated user info automatically, and redirect
      //to /<accountname>
      //we should only redirect when current path is _, as that means we've haven't  executed a redirect yet
      this.props.pushState("/me");
    }
  }

  render() {
    const { email, user } = this.props;
    if (!user) return <ErrorPage statusCode={404} />;
    return (
      <FlexContainer innerClassName={styles.container}>
        <Meta currentPath={this.props.location.pathname} title="Welcome" />
        <div className="whiteSink">
          <h4>Welcome!</h4>
          <div>Before we get started, we need the following information:</div>
          <hr />
          <Forms.RequiredDetails askEmail={!email} onSubmit={this.handleSubmit} />
        </div>
      </FlexContainer>
    );
  }
}

export default connect<
  ExtraUserDetails.PropsFromState,
  { [K in keyof ExtraUserDetails.DispatchProps]: any },
  ExtraUserDetails.OwnProps,
  GlobalState
>(mapStateToProps, {
  pushState: connectedReactRouter.push,
  submitExtraUserDetails: submitExtraUserDetails,
})(ExtraUserDetails);
