import { Alert, MenuItem } from "@mui/material";
import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { Models } from "@triply/utils";
import { Avatar, Button, Dialog, FontAwesomeIcon } from "#components/index.ts";
import { VisualizationConfig, VisualizationLabel } from "#components/Sparql/Results/index.js";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { Account } from "#reducers/accountCollection.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { createQuery } from "#reducers/queries.ts";
import { useContextualEditor } from "../../components/Sparql/Editor/EditorContext";
import { useSavedQuery } from "./SavedQueryContext";
import * as styles from "./style.scss";

export interface Props {
  visualization: VisualizationLabel | undefined;
  visualizationConfig: VisualizationConfig | undefined;
  variableConfig: Models.VariableConfig[] | undefined;
}

const QueryCopyModal: React.FC<Props> = ({ variableConfig, visualization, visualizationConfig }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [toAccount, setToAccount] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const { query } = useSavedQuery();
  const { getQueryString } = useContextualEditor();
  const authenticatedUser = useAuthenticatedUser();
  const dispatch = useDispatch();
  const isFramed = visualization === "LDFrame";
  const close = () => {
    setShowModal(false);
    setToAccount(undefined);
  };
  const submit = (selectedAccount: string) => {
    setToAccount(selectedAccount);
    dispatch<typeof createQuery>(
      createQuery(selectedAccount, {
        name: query.name,
        displayName: query.displayName,
        accessLevel: query.accessLevel,
        dataset: query.dataset?.id,
        description: query.description,
        generateNewName: true,
        serviceConfig: {
          type: query.serviceConfig.type || "speedy",
        },
        requestConfig: {
          payload: {
            query: getQueryString(),
          },
          ...(isFramed
            ? {
                ldFrame: (visualizationConfig && "frame" in visualizationConfig && visualizationConfig.frame) || {},
                headers: {
                  Accept: "application/ld+json;profile=http://www.w3.org/ns/json-ld#framed",
                },
              }
            : {}),
        },
        renderConfig:
          !isFramed && visualization
            ? {
                output: visualization,
                settings: visualizationConfig,
              }
            : undefined,
        variables: variableConfig,
      }),
    )
      .then(({ body: query }) => {
        close();
        dispatch(
          connectedReactRouter.push(`/${query.owner.accountName}/-/queries/${query.name}/1`, {
            skipDraftPrompt: true,
          }),
        );
      })
      .catch((e: Error) => setError(e.message));
  };
  if (!authenticatedUser) return null;
  const accounts: Account[] = [authenticatedUser, ...(authenticatedUser.orgs || [])];
  return (
    <>
      <MenuItem
        onClick={() => {
          setShowModal(true);
        }}
      >
        Copy
      </MenuItem>
      <Dialog open={showModal} onClose={close} title={"Copy current query to"} className={styles.copyQueryDialog}>
        <div className="px-5 pb-5">
          {!toAccount && (
            <>
              <h5 className="mt-0 mb-3">Select the account to copy the query to:</h5>
              <div className={getClassName(styles.accounts, "py-3")}>
                {accounts.map((account) => {
                  return (
                    <div
                      key={account.accountName}
                      onClick={() => submit(account.accountName)}
                      className={getClassName(styles.account, "p-2")}
                      role="button"
                      tabIndex={0}
                    >
                      <Avatar
                        size="lg"
                        avatarName={account.accountName}
                        avatarUrl={account.avatarUrl}
                        title={account.accountName}
                        alt=""
                      />
                      <div className={getClassName(styles.accountName, "mt-2")}>{account.accountName}</div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {!error && toAccount && (
            <div>
              <FontAwesomeIcon icon={["fas", "cog"]} spin /> {`Copying query to ${toAccount}`}
            </div>
          )}
          {toAccount && error && (
            <Alert variant="outlined" className="mt-3">
              {error}
            </Alert>
          )}
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};
export default QueryCopyModal;
