import { OrgRole } from "@triply/utils/Models.js";

export const STATUS_IRI = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/status";
export const CREATED_AT_IRI = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/createdAt";
export const MODIFIED_AT_IRI = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/modifiedAt";
export const AUTHOR_IRI = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/creator";
export const EDITOR_NOTE_IRI = "https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/editorialNote";

export const IGNORED_EDITOR_PREDICATE_IRIS = [STATUS_IRI, CREATED_AT_IRI, AUTHOR_IRI, MODIFIED_AT_IRI, EDITOR_NOTE_IRI];

type ResourceAction =
  | "AddResource"
  | "StageResource"
  | "EditResource"
  | "DeleteResource"
  | "RejectResource"
  | "ApproveResource"
  | "PublishResource"
  | "ArchiveResource"
  | "CopyResource";

export function getAllowedActions(status: string | undefined, userRole: OrgRole | undefined): ResourceAction[] {
  const actions: ResourceAction[] = [];
  switch (userRole) {
    case undefined:
      return actions;
    // case "editor":
    //   actions.push("AddResource", "CopyResource");
    //   if (status === "draft" || status === undefined) actions.push("StageResource", "EditResource");
    //   if (status === "teruggetrokken" || status === "draft") actions.push("DeleteResource");
    //   return actions;
    // case "reviewer":
    //   if (status === "in review") actions.push("RejectResource", "ApproveResource");
    //   return actions;
    // case "publisher":
    //   if (status === "gereviewd") actions.push("PublishResource");
    //   if (status === "gepubliceerd") actions.push("ArchiveResource");
    //   return actions;
    case "member":
    case "owner":
      actions.push("AddResource", "CopyResource");
      if (status === "draft" || status === undefined) actions.push("StageResource", "EditResource");
      if (status === "teruggetrokken" || status === "draft") actions.push("DeleteResource");
      if (status === "in review") actions.push("RejectResource", "ApproveResource");
      if (status === "gereviewd") actions.push("PublishResource");
      if (status === "gepubliceerd") actions.push("ArchiveResource");
      return actions;
    default:
      return actions;
  }
}
