import * as React from "react";
import { useSparqlIDEContext } from "../../../containers/SparqlIde/useSparqlIDEContext";
import { LinkButton } from "../..";
import { VisualizationLabel } from ".";

interface Props {
  name: string;
  query: string;
  visualization: VisualizationLabel;
}

const ReadOnlySparqlQuery: React.FC<Props> = ({ name, query, visualization }) => {
  const { addTab } = useSparqlIDEContext();
  if (!addTab) {
    return null;
  }
  return (
    <span>
      Or{" "}
      <LinkButton
        onClickOrEnter={() => {
          addTab({
            name: name,
            query: query,
            visualization: visualization,
            visualizationConfig: undefined,
          });
        }}
      >
        open the example query
      </LinkButton>{" "}
    </span>
  );
};

export default ReadOnlySparqlQuery;
