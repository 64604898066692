import { Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import * as styles from "./style.scss";

interface BaseProps extends Omit<MuiButtonProps, "children" | "disableElevation"> {
  elevation?: boolean;
  component?: "span";
  drawTextInSpan?: boolean;
}
interface MaterialUiButtonProps extends BaseProps {
  children: string | (string | number)[];
}
interface MaterialUiButtonNoContentWithAriaProps extends BaseProps {
  children?: never;
  "aria-label": string;
}
interface MaterialUiButtonNoContentWithTitleProps extends BaseProps {
  children?: never;
  title: string;
}

export type ButtonProps =
  | MaterialUiButtonProps
  | MaterialUiButtonNoContentWithAriaProps
  | MaterialUiButtonNoContentWithTitleProps;

const MaterialUIButton: React.FC<ButtonProps> = ({
  elevation,
  className,
  drawTextInSpan: drawChildrenInSpan,
  ...props
}) => {
  const iconButton = !props.children && props.startIcon;
  if (!props.children && props.title && !props["aria-label"]) props["aria-label"] = props.title;
  if (props.children && drawChildrenInSpan) props.children = (<span>{props.children}</span>) as any;
  return (
    <MuiButton
      {...props}
      disableElevation={!elevation}
      className={getClassName(className, { [styles.iconButton]: iconButton })}
    />
  );
};

export default MaterialUIButton;
