import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ButtonProps } from "@mui/material";
import dedent from "dedent";
import * as React from "react";
import { useLocation } from "react-router";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";

type TermStatus = "draft" | "in review" | "gereviewd" | "teruggetrokken" | "gepubliceerd";

interface Props {
  buttonLabel: string;
  title: string;
  icon: FontAwesomeIconProps["icon"];
  confirmationLabel: string;
  newStatus: TermStatus;
  color: ButtonProps["color"];
}

const ResourceAction: React.FC<Props> = ({ buttonLabel, title, confirmationLabel, newStatus, color, icon }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;

  return (
    <>
      <Button
        color={color}
        elevation
        onClick={() => setOpen(true)}
        title={title}
        startIcon={<FontAwesomeIcon icon={icon} />}
        size="small"
      >
        {buttonLabel}
      </Button>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel={buttonLabel}
        title={title}
        onConfirm={async () => {
          setSaving(true);

          const query = dedent`
                prefix vs: <http://www.w3.org/2003/06/sw-vocab-status/ns#>
                prefix skos: <http://www.w3.org/2004/02/skos/core#>
                delete {
                  ?id vs:term_status ?status.
                } insert {
                  ?id vs:term_status "${newStatus}"@nl
                } where {
                  bind(<${resource}> as ?id)
                  optional {
                    ?id vs:term_status ?status
                  }
                }`;

          const body = new FormData();
          body.set("update", query);

          await fetch(updateUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            }),
          );

          setSaving(false);
          setOpen(false);
        }}
        description={confirmationLabel}
      />
    </>
  );
};

export default ResourceAction;

export const ArchiveResource = () => (
  <ResourceAction
    buttonLabel="Archive instance"
    color="error"
    icon="box-archive"
    confirmationLabel="Permanently archive this instance"
    newStatus="teruggetrokken"
    title="Archive this instance"
  />
);
export const StageResource = ({ resource }: { resource: string }) => (
  <ResourceAction
    buttonLabel="Move to review"
    color="warning"
    icon="clipboard"
    confirmationLabel={`Move instance '${resource}' over to review.`}
    newStatus="in review"
    title="Mark this instance for review"
  />
);
export const ApproveResource = ({ resource }: { resource: string }) => (
  <ResourceAction
    buttonLabel="Approve"
    color="warning"
    icon="clipboard-check"
    confirmationLabel={`Approve the review for instance '${resource}'`}
    newStatus="gereviewd"
    title="Approve instance"
  />
);
export const PublishResource = () => (
  <ResourceAction
    buttonLabel="Publish instance"
    color="success"
    icon="check"
    confirmationLabel="Publish instance"
    title="Publish instance"
    newStatus="gepubliceerd"
  />
);
