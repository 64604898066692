import { Alert } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import { Button, MuiTextFieldRedux } from "#components/index.ts";

namespace RenameGraphForm {
  export interface FormData {
    graphName: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    handleCancel: (e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent) => void;
  }
}

const _RenameGraphForm: React.FC<RenameGraphForm.FormData & RenameGraphForm.Props> = ({
  handleSubmit,
  handleCancel,
  pristine,
  invalid,
  error,
}) => {
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
        name="graphName"
        props={{
          fullWidth: true,
          label: "Graph name",
          autoFocus: true,
        }}
        component={MuiTextFieldRedux}
      />

      {error && (
        <Alert severity="error" className="mt-5">
          {error}
        </Alert>
      )}

      <div className="mt-5 flex g-3">
        <Button type="submit" color="secondary" disabled={pristine || invalid}>
          Save
        </Button>

        <Button onClick={handleCancel} variant="text">
          Cancel
        </Button>
      </div>
    </form>
  );
};
const RenameGraphForm = ReduxForm.reduxForm<RenameGraphForm.FormData, RenameGraphForm.Props>({
  validate: memoizee(
    (formData: RenameGraphForm.FormData, _props: RenameGraphForm.Props) => {
      return {
        graphName: (function (graphName) {
          // validation.toStringValidator(iriValidations) returns '<graphname> is not a valid IRI'
          // very long graph names yield error messages going offscreen
          // to avoid this, don't include the graph name in the error message
          if (validation.toStringValidator(validation.iriValidations)(graphName) !== undefined) {
            return "Invalid IRI";
          }
        })(formData.graphName),
      };
    },
    { max: 10 },
  ),
})(_RenameGraphForm as any);
export default RenameGraphForm;
