import { Alert, Button } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { UnreachableCaseError } from "ts-essentials";
import { Models } from "@triply/utils";
import { FontAwesomeButton, FontAwesomeIcon } from "#components/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "../../helpers/hooks/useAcl.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { updateQuery } from "../../reducers/queries.ts";
import { storyElementToUpdateObj, updateStory } from "../../reducers/stories.ts";
import { LocationState } from "./index.tsx";
import Paragraph from "./Paragraph.tsx";
import Query from "./Query.tsx";
import { isStricterThan } from "./utils.ts";
import * as styles from "./StoryElement.scss";

const DragHandle = SortableHandle(() => (
  <div title="Move story element" className={getClassName("clickableIcon", styles.dragHandle)}>
    <FontAwesomeIcon icon="bars" />
  </div>
));

const Element: React.FC<{
  storyElement: Models.StoryElement;
  editMode: boolean;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
}> = (props) => {
  switch (props.storyElement.type) {
    case "query":
      return (
        <Query
          storyElement={props.storyElement}
          editMode={props.editMode}
          handleStoryElementHeightChange={props.handleStoryElementHeightChange}
          handleStoryElementWidthChange={props.handleStoryElementWidthChange}
        />
      );
    case "paragraph":
      return <Paragraph storyElement={props.storyElement} />;
    default:
      throw new UnreachableCaseError(props.storyElement);
  }
};

const StoryElement = SortableElement<{
  storyElement: Models.StoryElement;
  story: Models.Story;
  editMode: boolean;
  dragging: boolean;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
}>(
  (props: {
    storyElement: Models.StoryElement;
    story: Models.Story;
    editMode: boolean;
    dragging: boolean;
    handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
    handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
  }) => {
    const history = useHistory<LocationState>();
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    const confirm = useConfirmation();

    return (
      <div className={getClassName({ [styles.paragraphWrapper]: props.storyElement.type === "paragraph" })}>
        <div
          id={props.storyElement.id}
          className={getClassName(styles.storyElement, "pb-3 mt-7 mb-4", {
            [styles.editable]: props.editMode,
            [styles.dragging]: props.dragging,
          })}
        >
          {props.editMode && (
            <div className={getClassName("flex center pr-3 pb-2", styles.storyElementToolbar)}>
              <DragHandle />
              <FontAwesomeButton
                title="Edit story element"
                onClick={() =>
                  history.push({
                    search: location.search,
                    state: { storyElementEditModalShown: props.storyElement.id, preserveScrollPosition: true },
                  })
                }
                icon="pencil"
              />
              <FontAwesomeButton
                title="Delete story element"
                onClick={(e) => {
                  e.stopPropagation();
                  confirm({
                    description: "Are you sure you want to delete this story element?",
                    title: "Delete story element?",
                    actionLabel: "Delete",
                    onConfirm: () => {
                      if (props.story) {
                        return dispatch<typeof updateStory>(
                          updateStory(props.story, {
                            content: props.story.content
                              .filter((e) => e.id !== props.storyElement.id)
                              .map(storyElementToUpdateObj),
                          }),
                        );
                      }
                    },
                  });
                }}
                icon="times"
              />
            </div>
          )}
          {props.editMode && props.storyElement.type === "query" && (
            <div className={getClassName("my-2", styles.warningElement)}>
              <Warning storyAccessLevel={props.story?.accessLevel} query={props.storyElement?.query} />
            </div>
          )}
          <Element
            storyElement={props.storyElement}
            editMode={props.editMode}
            handleStoryElementWidthChange={props.handleStoryElementWidthChange}
            handleStoryElementHeightChange={props.handleStoryElementHeightChange}
          />
        </div>
      </div>
    );
  },
);

export default StoryElement;

const Warning: React.FC<{ query?: Models.Query; storyAccessLevel: Models.AccessLevel }> = ({
  query,
  storyAccessLevel,
}) => {
  const acl = useAcl();
  const dispatch = useDispatch();

  const allowedToEditQuery = acl.check({
    action: "manageQuery",
    context: {
      accessLevel: query?.accessLevel,
      newAccessLevel: storyAccessLevel,
      roleInOwnerAccount: acl.getRoleInAccount(query?.owner),
    },
  }).granted;
  const allowedToEditDataset = acl.check({
    action: "editDatasetMetadata",
    context: {
      accessLevel: query?.dataset?.accessLevel,
      newAccessLevel: storyAccessLevel,
      roleInOwnerAccount: acl.getRoleInAccount(query?.dataset?.owner),
    },
  }).granted;

  if (!query) {
    return (
      <Alert severity="warning" className={styles.warningElement}>
        The Query is not accessible
      </Alert>
    );
  }
  const queryLink = `/${query.owner.accountName}/-/queries/${query.name}/${query.version}`;
  if (!query.dataset) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button to={queryLink} component={Link} size="small" variant="text" color="warning">
              Go to query
            </Button>
          )
        }
      >
        The dataset is not accessible
      </Alert>
    );
  }
  if (!query.service && query.serviceConfig.type !== "speedy") {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button to={queryLink} component={Link} size="small" variant="text" color="warning">
              Go to query
            </Button>
          )
        }
      >
        The service is not accessible
      </Alert>
    );
  }
  if (isStricterThan(query.dataset?.accessLevel, storyAccessLevel)) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditDataset && (
            <Button
              to={`/${query.dataset.owner.accountName}/${query.dataset.name}/settings`}
              component={Link}
              size="small"
              variant="text"
              color="warning"
            >
              Go to dataset
            </Button>
          )
        }
      >
        The dataset connected to this query has a more restrictive access level than the story
      </Alert>
    );
  }
  if (isStricterThan(query.accessLevel, storyAccessLevel)) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button
              variant="text"
              color="warning"
              size="small"
              onClick={() => {
                return dispatch<typeof updateQuery>(
                  query &&
                    updateQuery(query, {
                      accessLevel: storyAccessLevel,
                    }),
                );
              }}
            >
              Set query access level to {storyAccessLevel}
            </Button>
          )
        }
      >
        This query has a more restrictive access level than the story
      </Alert>
    );
  }
  return null;
};
