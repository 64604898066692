import * as React from "react";
import { useLocation } from "react-router-dom";
import { CachePolicies } from "use-http";
import { AdminPipelineJob } from "@triply/utils/Models.js";
import QueryJobTable from "#components/QueryJobs/QueryJobsTable.tsx";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";

const QueryJobs: React.FC<{}> = ({}) => {
  const url = useConstructUrlToApi()({ pathname: "/admin/jobs" });
  const location = useLocation();
  const { data, loading, error } = useFetch<AdminPipelineJob[]>(
    url,
    {
      cachePolicy: CachePolicies.NO_CACHE,
      credentials: "same-origin",
    },
    [location],
  );
  return <QueryJobTable queryJobs={data || []} loading={!data && loading} error={error} />;
};

export default QueryJobs;
