import { FormControlLabel, MenuItem, Radio } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { RedirectRule } from "@triply/utils/Models.js";
import { validation } from "@triply/utils-private";
import {
  Avatar,
  Button,
  Dialog,
  FormField,
  Highlight,
  MaterialRadioButtonGroup,
  MuiTextFieldRedux,
} from "#components/index.ts";
import { MuiAutosuggest, MuiAutosuggestRedux } from "#components/MuiAutosuggest/index.ts";
import fetch from "#helpers/fetch.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import * as styles from "./style.scss";

const prefixValidator = validation.toStringValidator(validation.redirectPrefixValidations);
const regexValidator = validation.toStringValidator(validation.redirectRegexValidations);
const requiredValidator = validation.toStringValidator([validation.required]);

namespace RedirectForm {
  export interface FormData extends RedirectRule {
    toDataset: Dataset;
    datasetName: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    cancelFunction: () => void;
    datasetsUrl: string;
    updating: boolean;
    className?: string;
    ruleId: string;
  }
}

const RedirectForm = ReduxForm.reduxForm<RedirectForm.FormData, RedirectForm.Props>({
  form: "redirectsForm",
  validate: memoizee((formData: RedirectForm.FormData, _props: RedirectForm.Props) => {
    return {
      matchingMethod: requiredValidator(formData.matchingMethod),
      toDataset: ((formData: RedirectForm.FormData) => {
        if (!formData.toDataset) return "No dataset selected";
        if (formData.toDataset.owner.accountName + " / " + formData.toDataset.name !== formData.datasetName)
          return "Dataset not found";
        return requiredValidator(formData.datasetName);
      })(formData),
      match: formData.matchingMethod === "prefix" ? prefixValidator(formData.match) : regexValidator(formData.match),
    };
  }),
})(
  class RedirectForm extends React.PureComponent<RedirectForm.Props> {
    renderRadioField = () => {
      return (
        <MaterialRadioButtonGroup.Field name={"matchingMethod"} component={MaterialRadioButtonGroup}>
          <FormControlLabel value={"prefix"} label={"Prefix"} control={<Radio color="primary" />} />
          <FormControlLabel value={"regexp"} label={"Regular Expression"} control={<Radio color="primary" />} />
        </MaterialRadioButtonGroup.Field>
      );
    };
    getDatasetName = (dataset: Dataset) => {
      return dataset.owner.accountName + " / " + dataset.name;
    };
    getItemValue = (dataset: Dataset) => {
      this.props.change?.("datasetName", dataset.owner.accountName + " / " + dataset.name);
    };
    searchDataset = (queryString: string) => this.search(this.props.datasetsUrl + "/?q=" + queryString);

    search(url: string): Promise<Dataset[]> {
      return this.fetch(url).then((results) => {
        if (results && Array.isArray(results)) {
          return results;
        }
        return [];
      });
    }

    fetch = memoizee(
      async (url: string) => {
        return fetch(url, { credentials: "same-origin" })
          .then((response) => {
            if (response.status === 200) return response.json() as Promise<Dataset[]>;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      { primitive: true, async: true },
    );

    render() {
      const { updating, invalid, cancelFunction, pristine } = this.props;
      return (
        <Dialog
          open={true}
          onClose={cancelFunction}
          maxWidth="md"
          fullWidth
          title={`${updating ? "Edit" : "Create"} rule`}
        >
          <form onSubmit={this.props.handleSubmit} className="pb-5 pl-5 pr-5">
            <FormField label="Matching method" className="mt-4 mb-5">
              <ReduxForm.Field name="matchingMethod" component={this.renderRadioField} />
            </FormField>

            <FormField label="Pattern to match" className="mb-5">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="match"
                props={{ fullWidth: true }}
                component={MuiTextFieldRedux}
              />
            </FormField>

            <FormField label="Dataset to redirect to" className="mb-5">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<Dataset, Dataset>>>
                name="toDataset"
                props={{
                  placeholder: "Select dataset",
                  TextFieldProps: { helperText: "Type to search", fullWidth: true },
                  loadSuggestions: this.searchDataset,
                  transformInitialValueToSearchText: this.getDatasetName,
                  onSuggestionSelected: (_event, data) => this.getItemValue(data.suggestion),
                  getSuggestionSearchText: this.getDatasetName,
                  renderSuggestion: (ds, { query, isHighlighted }) => {
                    return (
                      <MenuItem selected={isHighlighted} component="div">
                        <Avatar
                          size="sm"
                          className="mr-2"
                          avatarUrl={ds.avatarUrl}
                          avatarName={ds.displayName || ds.name}
                          alt=""
                        />
                        <Highlight fullText={this.getDatasetName(ds)} highlightedText={query} />
                      </MenuItem>
                    );
                  },
                }}
                component={MuiAutosuggestRedux}
              />
            </FormField>

            <div className={getClassName(styles.btns, "form-group mt-5")}>
              <Button color="secondary" disabled={invalid || pristine} type="submit">
                {updating ? "Update" : "Create"} rule
              </Button>

              <Button onClick={cancelFunction} className="mx-3" variant="text">
                Cancel
              </Button>
            </div>
          </form>
        </Dialog>
      );
    }
  } as any,
);

export default RedirectForm;
