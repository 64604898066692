// This file was generated by lezer-generator. You probably shouldn't edit it.
export const BASE_KEY = 1,
  PREFIX_KEY = 2,
  STR_KEY = 3,
  LANG_KEY = 4,
  LANGMATCHES_KEY = 5,
  SELECT_KEY = 6,
  DISTINCT_KEY = 7,
  REDUCED_KEY = 8,
  AS_KEY = 9,
  CONSTRUCT_KEY = 10,
  WHERE_KEY = 11,
  DESCRIBE_KEY = 12,
  ALL_KEY = 13,
  ASK_KEY = 14,
  FROM_KEY = 15,
  NAMED_KEY = 16,
  GROUP_KEY = 17,
  BY_KEY = 18,
  HAVING_KEY = 19,
  ORDER_KEY = 20,
  ASC_KEY = 21,
  DESC_KEY = 22,
  LIMIT_KEY = 23,
  OFFSET_KEY = 24,
  VALUES_KEY = 25,
  SILENT_KEY = 26,
  LOAD_KEY = 27,
  INTO_KEY = 28,
  CLEAR_KEY = 29,
  DROP_KEY = 30,
  CREATE_KEY = 31,
  ADD_KEY = 32,
  TO_KEY = 33,
  MOVE_KEY = 34,
  COPY_KEY = 35,
  DATA_KEY = 36,
  INSERT_KEY = 37,
  DELETE_KEY = 38,
  WITH_KEY = 39,
  USING_KEY = 40,
  DEFAULT_KEY = 41,
  GRAPH_KEY = 42,
  OPTIONAL_KEY = 43,
  SERVICE_KEY = 44,
  BIND_KEY = 45,
  UNDEF_KEY = 46,
  MINUS_KEY = 47,
  UNION_KEY = 48,
  FILTER_KEY = 49,
  DATATYPE_KEY = 50,
  BOUND_KEY = 51,
  IRI_KEY = 52,
  URI_KEY = 53,
  BNODE_KEY = 54,
  RAND_KEY = 55,
  ABS_KEY = 56,
  CEIL_KEY = 57,
  FLOOR_KEY = 58,
  ROUND_KEY = 59,
  CONCAT_KEY = 60,
  STRLEN_KEY = 61,
  UCASE_KEY = 62,
  LCASE_KEY = 63,
  ENCODE_FOR_URI_KEY = 64,
  CONTAINS_KEY = 65,
  STRSTARTS_KEY = 66,
  STRENDS_KEY = 67,
  STRBEFORE_KEY = 68,
  STRAFTER_KEY = 69,
  YEAR_KEY = 70,
  MONTH_KEY = 71,
  DAY_KEY = 72,
  HOURS_KEY = 73,
  MINUTES_KEY = 74,
  SECONDS_KEY = 75,
  TIMEZONE_KEY = 76,
  TZ_KEY = 77,
  NOW_KEY = 78,
  UUID_KEY = 79,
  STRUUID_KEY = 80,
  MD5_KEY = 81,
  SHA1_KEY = 82,
  SHA256_KEY = 83,
  SHA384_KEY = 84,
  SHA512_KEY = 85,
  COALESCE_KEY = 86,
  IF_KEY = 87,
  STRLANG_KEY = 88,
  STRDT_KEY = 89,
  SAMETERM_KEY = 90,
  ISIRI_KEY = 91,
  ISURI_KEY = 92,
  ISBLANK_KEY = 93,
  ISLITERAL_KEY = 94,
  ISNUMERIC_KEY = 95,
  REGEX_KEY = 96,
  SUBSTR_KEY = 97,
  REPLACE_KEY = 98,
  EXISTS_KEY = 99,
  NOT_KEY = 100,
  COUNT_KEY = 101,
  SUM_KEY = 102,
  MIN_KEY = 103,
  MAX_KEY = 104,
  AVG_KEY = 105,
  SAMPLE_KEY = 106,
  GROUP_CONCAT_KEY = 107,
  SEPARATOR_KEY = 108,
  TRUE_KEY = 109,
  FALSE_KEY = 110,
  IN_KEY = 111,
  Comment = 112,
  Annotation = 113,
  Program = 114,
  QueryUnit = 115,
  Query = 116,
  Prologue = 117,
  BaseDecl = 118,
  IRIREF = 119,
  PrefixDecl = 120,
  PNAME_NS = 121,
  SelectQuery = 122,
  SelectClause = 123,
  Var = 124,
  INVALID_VAR = 125,
  VALID_VAR = 126,
  Expression = 128,
  ConditionalOrExpression = 129,
  ConditionalAndExpression = 130,
  ValueLogical = 131,
  RelationalExpression = 132,
  NumericExpression = 133,
  AdditiveExpression = 134,
  MultiplicativeExpression = 135,
  UnaryExpression = 136,
  PrimaryExpression = 137,
  BrackettedExpression = 139,
  BuiltInCall = 140,
  Aggregate = 141,
  String = 143,
  STRING_LITERAL1 = 144,
  STRING_LITERAL2 = 145,
  STRING_LITERAL_LONG1 = 146,
  STRING_LITERAL_LONG2 = 147,
  NIL = 149,
  ExpressionList = 150,
  SubstringExpression = 151,
  StrReplaceExpression = 152,
  RegexExpression = 153,
  ExistsFunc = 154,
  GroupGraphPattern = 157,
  SubSelect = 158,
  WhereClause = 159,
  SolutionModifier = 160,
  GroupClause = 161,
  GroupCondition = 162,
  FunctionCall = 163,
  PrefixedName = 164,
  PNAME_LN = 165,
  ArgList = 166,
  HavingClause = 167,
  HavingCondition = 168,
  Constraint = 169,
  OrderClause = 170,
  OrderCondition = 171,
  LimitOffsetClauses = 172,
  LimitClause = 173,
  INTEGER = 174,
  OffsetClause = 175,
  ValuesClause = 176,
  DataBlock = 177,
  InlineDataOneVar = 178,
  DataBlockValue = 179,
  RDFLiteral = 180,
  LANGTAG = 181,
  DataType = 182,
  NumericLiteral = 183,
  NumericLiteralUnsigned = 184,
  DECIMAL = 185,
  DOUBLE = 186,
  NumericLiteralPositive = 187,
  INTEGER_POSITIVE = 188,
  DECIMAL_POSITIVE = 189,
  DOUBLE_POSITIVE = 190,
  NumericLiteralNegative = 191,
  INTEGER_NEGATIVE = 192,
  DECIMAL_NEGATIVE = 193,
  DOUBLE_NEGATIVE = 194,
  BooleanLiteral = 195,
  InlineDataFull = 196,
  GroupGraphPatternSub = 197,
  TriplesBlock = 198,
  TriplesSameSubjectPath = 199,
  VarOrTerm = 200,
  GraphTerm = 201,
  BlankNode = 202,
  BLANK_NODE_LABEL = 203,
  ANON = 204,
  PropertyListPathNotEmpty = 205,
  VerbPath = 206,
  Path = 207,
  PathAlternative = 208,
  PathSequence = 209,
  PathEltOrInverse = 210,
  PathElt = 211,
  PathPrimary = 212,
  PathNegatedPropertySet = 213,
  PathOneInPropertySet = 214,
  PathMod = 215,
  VerbSimple = 216,
  ObjectListPath = 217,
  ObjectPath = 218,
  GraphNodePath = 219,
  TriplesNodePath = 220,
  CollectionPath = 221,
  BlankNodePropertyListPath = 224,
  PropertyListPath = 225,
  GraphPatternNotTriples = 227,
  GroupOrUnionGraphPattern = 228,
  OptionalGraphPattern = 229,
  MinusGraphPattern = 230,
  GraphGraphPattern = 231,
  VarOrIri = 232,
  ServiceGraphPattern = 233,
  Filter = 234,
  Bind = 235,
  InlineData = 236,
  NotExistsFunc = 237,
  DatasetClause = 238,
  DefaultGraphClause = 239,
  SourceSelector = 240,
  NamedGraphClause = 241,
  ConstructQuery = 242,
  ConstructTemplate = 243,
  ConstructTriples = 244,
  TriplesSameSubject = 245,
  PropertyListNotEmpty = 246,
  Verb = 247,
  ObjectList = 248,
  Object = 249,
  GraphNode = 250,
  TriplesNode = 251,
  Collection = 252,
  BlankNodePropertyList = 253,
  PropertyList = 254,
  TriplesTemplate = 255,
  DescribeQuery = 256,
  AskQuery = 257,
  UpdateUnit = 258,
  Update = 259,
  Update1 = 260,
  Load = 261,
  GraphRef = 262,
  Clear = 263,
  GraphRefAll = 264,
  Drop = 265,
  Add = 266,
  GraphOrDefault = 267,
  Move = 268,
  Copy = 269,
  Create = 270,
  InsertData = 271,
  QuadData = 272,
  Quads = 273,
  QuadsNotTriples = 274,
  DeleteData = 275,
  DeleteWhere = 276,
  QuadPattern = 277,
  Modify = 278,
  DeleteClause = 279,
  InsertClause = 280,
  UsingClause = 281;
