import { jsonLanguage } from "@codemirror/lang-json";
import { IconButton, Paper, Table, TableContainer, Toolbar, Tooltip, Typography } from "@mui/material";
import {
  CellContext,
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import { capitalize } from "lodash-es";
import moment from "moment";
import numeral from "numeral";
import * as React from "react";
import { Link } from "react-router-dom";
import { JobStatus, PipelineJob, PipelineStatus } from "@triply/utils/Models.js";
import { Color as CircleColor } from "#components/Circle/index.tsx";
import fetch from "#helpers/fetch.ts";
import { Circle, Dialog, FontAwesomeIcon, LinkButton } from "../../components";
import IdeToolTipButton from "../../components/IdeTooltipButton";
import { TableFooter } from "../../components/ReactTableUtils";
import { TableHumanizedDateRenderer } from "../../components/ReactTableUtils/DateRenderer";
import ReactTableBody from "../../components/ReactTableUtils/TableBody";
import TableHeader from "../../components/ReactTableUtils/TableHeader";
import { useConfirmation } from "../../helpers/hooks/confirmation";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import * as styles from "./styles.scss";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

const colorMap: Record<JobStatus | PipelineStatus, CircleColor> = {
  finished: "green",
  error: "red",
  running: "orange",
  pending: "default",
  cancelled: "default",
};

const Actions: React.FC<CellContext<PipelineJob, any>> = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const confirm = useConfirmation();
  const cancelUrl = useConstructUrlToApi()({
    pathname: `/pipelines/${row.original.__unstable.pipeline.owner}/${row.original.pipelineId}/cancel`,
    fromBrowser: true,
  });
  const queryJobArtifactsUrl = useConstructUrlToApi()({
    pathname: `/jobs/${row.original.query?.owner}/${row.original.id}/artifacts`,
    fromBrowser: true,
  });
  const status = row.original.status;
  const removeCancelIcon = status === "cancelled" || status === "error" || status === "finished";
  const isAdmin = "rawDocument" in row.original;
  return (
    <div className={styles.nowrap}>
      <IdeToolTipButton
        queryString={row.original.query?.queryString || ""}
        queryName={row.original.query?.name}
        queryOwner={row.original.query?.owner}
      />
      {isAdmin && (
        <IconButton
          size="small"
          component="a"
          href={queryJobArtifactsUrl}
          target="_blank"
          download
          title="Export artifacts"
          aria-label="Export artifacts"
          className={styles.noLink}
        >
          <FontAwesomeIcon icon="arrow-to-bottom" fixedWidth />
        </IconButton>
      )}

      {"rawDocument" in row.original && (
        <>
          <IconButton
            size="small"
            title="View raw document"
            aria-label="View raw document"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={["far", "brackets-curly"]} />
          </IconButton>
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
            <CodeMirror
              value={JSON.stringify(row.original.rawDocument, null, 2)}
              readOnly
              extensions={[jsonLanguage, githubLight]}
            />
          </Dialog>
        </>
      )}
      {!removeCancelIcon && (
        <IconButton
          color="error"
          onClick={() => {
            confirm({
              title: "Cancel query job",
              description: `Are you sure you want to cancel this query job?`,
              actionLabel: "Yes, cancel query job",
              onConfirm: () => {
                fetch(cancelUrl, {
                  credentials: "same-origin",
                  method: "POST",
                }).catch(() => {});
              },
            });
          }}
          aria-label="Remove query job"
        >
          <FontAwesomeIcon icon="ban" />
        </IconButton>
      )}
    </div>
  );
};

const StatusRenderer: React.FC<CellContext<PipelineJob, unknown>> = ({
  row: {
    original: { status },
  },
}) => {
  return (
    <div className="flex">
      <Circle color={colorMap[status]} />
      <div className={"pl-2"} style={{ wordBreak: "keep-all" }}>
        {capitalize(status)}
      </div>
    </div>
  );
};

const PipelineStatus: React.FC<CellContext<PipelineJob, unknown>> = ({ row: { original } }) => {
  return (
    <div className="flex">
      <Circle color={colorMap[original.__unstable.pipeline.status]} />
      <div className={"pl-2"} style={{ wordBreak: "keep-all" }}>
        {capitalize(original.__unstable.pipeline.status)}
      </div>
    </div>
  );
};

const columnHelper = createColumnHelper<PipelineJob>();

const columns = [
  columnHelper.accessor("pipelineId", {
    header: "Pipeline",
    filterFn: "includesString",
    cell: (info) => {
      return (
        <LinkButton
          onClickOrEnter={() => {
            info.column.setFilterValue(info.getValue());
          }}
        >
          {"..." + info.getValue()?.slice(-3)}
        </LinkButton>
      );
    },
  }),
  columnHelper.accessor((row) => row.__unstable.pipeline.status, {
    header: "Pipeline status",
    sortingFn: "alphanumeric",
    cell: PipelineStatus,
    filterFn: "equals",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    sortingFn: "alphanumeric",
    cell: StatusRenderer,
    filterFn: "equals",
  }),
  columnHelper.accessor((row) => row.__unstable.pipeline.owner, {
    header: "Owner",
    cell: (props) => <Link to={`/${props.getValue()}/-/settings/jobs`}>{props.getValue()}</Link>,
    enableColumnFilter: true,
  }),
  columnHelper.accessor((row) => row.__unstable.pipeline.createdBy || "unknown", {
    header: "Created by",
    cell: (props) => <Link to={`/${props.getValue()}`}>{props.getValue()}</Link>,
    enableColumnFilter: true,
  }),
  columnHelper.accessor(
    (row) =>
      row.query ? `${row.query?.owner}/${row.query?.name}${row.query.version ? `/${row.query.version}` : ""}` : null,
    {
      header: "Query",
      cell: ({ row, getValue }) => (
        <Link
          to={`/${row.original.query?.owner}/-/queries/${row.original.query?.name}/${
            row.original.query?.version || ""
          }`}
        >
          {getValue()}
        </Link>
      ),
      enableColumnFilter: true,
    },
  ),
  columnHelper.accessor((queryJob) => queryJob.startedAt || queryJob.__unstable.pipeline.createdAt, {
    header: "Started at",
    cell: TableHumanizedDateRenderer,
    enableColumnFilter: false,
  }),
  columnHelper.accessor(
    (row) =>
      row.__unstable.source?.datasetName
        ? `${row.__unstable.source!.datasetOwner}/${row.__unstable.source.datasetName}`
        : null,
    {
      header: "Source dataset",
      cell: ({ getValue }) => <Link to={`/${getValue()}`}>{getValue()}</Link>,
      enableColumnFilter: true,
    },
  ),
  columnHelper.accessor(
    (row) =>
      row.__unstable.target?.datasetName
        ? `${row.__unstable.target!.datasetOwner}/${row.__unstable.target.datasetName}`
        : null,
    {
      header: "Target dataset",
      cell: ({ getValue }) => <Link to={`/${getValue()}/graphs`}>{getValue()}</Link>,
      enableColumnFilter: true,
    },
  ),
  columnHelper.accessor("durationInMs", {
    header: "Duration",
    cell: ({ row, getValue }) => {
      let processingTime = getValue();
      if (processingTime === undefined) {
        if (row.original.status === "error") {
          return null;
        } else {
          if (row.original.startedAt === undefined) {
            processingTime = 0;
          } else {
            processingTime = moment.duration(Date.now() - Date.parse(row.original.startedAt)).asMilliseconds();
          }
        }
      }
      return (
        <Tooltip
          title={moment.utc(moment.duration(processingTime, "milliseconds").asMilliseconds()).format("HH:mm:ss")}
          placement="bottom-end"
        >
          <span>{moment.duration(processingTime, "milliseconds").humanize()}</span>
        </Tooltip>
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("numberOfStatements", {
    header: "Number of statements",
    cell: ({ row, getValue }) =>
      getValue() === undefined
        ? row.original.status === "finished"
          ? 0
          : "Not finished"
        : numeral(getValue()).format("0,0"),
    filterFn: "inNumberRange",
  }),
  columnHelper.accessor("progress", {
    header: "Progress",
    cell: ({ getValue }) =>
      getValue() === undefined || getValue() === -1 ? "Not finished" : `${(getValue() * 100).toFixed(0)}%`,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: "actions",
    cell: Actions,
  }),
];

const QueryJobTable: React.FC<{ queryJobs: PipelineJob[]; loading: boolean; error: Error | undefined }> = ({
  queryJobs,
  loading,
  error,
}) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable<PipelineJob>({
    columns: columns,
    // Do isArray check, as in dev mode we may get a string back (from the ingress, when the API is restarting)
    data: Array.isArray(queryJobs) ? queryJobs : [],
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (queryJob) => queryJob.id,
  });
  return (
    <Paper className={tableStyles.tablePaper}>
      <Toolbar className="mb-3">
        <Typography variant="h5">Query jobs</Typography>
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody rows={table.getRowModel().rows} loading={loading} error={error?.message} columnCount={7} />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
              className="mb-3"
            />
          </Table>
        </div>
      </TableContainer>
    </Paper>
  );
};

export default QueryJobTable;
