import { Alert } from "@mui/material";
import * as React from "react";
import { DashViewer, DashViewerProps } from ".";

const UriViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  if (property.nodeKind !== "IRI") return <Alert severity="error">Wrongly configured URI viewer</Alert>;
  return (
    <a href={property.value} rel="noopener noreferrer">
      {property.value}
    </a>
  );
};

const UriViewer: DashViewer = {
  component: UriViewerComponent,
  getScore({ nodeKind }) {
    if (nodeKind === "IRI") return 1;
    return 0;
  },
};

export default UriViewer;
