import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./style.scss";

interface Props extends MuiDialogProps {
  title?: string;
  closeButton?: boolean;
  children: React.ReactNode;
  spacing?: boolean;
}

const Dialog = React.memo<Props>(({ title, children, closeButton, spacing, onClose, ...muiProps }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Make sure we disable going back twice. When using the location state the onClose can be called multiple times
  const [closing, setClosing] = React.useState(false);
  React.useEffect(() => {
    // Only reset closing when the dialog is set to open
    if (muiProps.open) setClosing(false);
  }, [muiProps.open]);
  const ourOnClose =
    onClose &&
    ((event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setClosing(true);
      if (!closing) onClose?.(event, reason);
    });

  if (spacing) {
    muiProps.className = getClassName(muiProps.className, styles.spacing);
  }

  return (
    <MuiDialog fullScreen={fullScreen} {...muiProps} onClose={ourOnClose}>
      {closeButton && ourOnClose && (
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={(e) => ourOnClose?.(e, "escapeKeyDown")}
          size="large"
        >
          <FontAwesomeIcon icon="times" size="1x" fixedWidth />
        </IconButton>
      )}
      {title && <MuiDialogTitle className="p-5">{title}</MuiDialogTitle>}
      {children}
    </MuiDialog>
  );
});

export default Dialog;
