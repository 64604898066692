import React from "react";
import { FooterContext } from "../../containers/NavConsole";

export default function useHideFooter() {
  const { hideFooter } = React.useContext(FooterContext);
  React.useEffect(() => {
    hideFooter(true);
    return () => hideFooter(false);
  }, [hideFooter]);
}
