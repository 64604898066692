import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";

export default function useRemovePrefixes() {
  const prefixes = useDatasetPrefixes();
  return <A extends string | undefined | null>(prefixed: A) => {
    if (prefixed === undefined || prefixed === null) return prefixed as A;
    for (const p of prefixes) {
      if (prefixed.startsWith(p.prefixLabel + ":")) {
        return `${p.iri}${prefixed.slice(p.prefixLabel.length + 1)}` as A;
      }
    }
    return prefixed as A;
  };
}
