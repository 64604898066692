import { Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import getClassName from "classnames";
import { Location } from "history";
import React from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "#components/index.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

const Toolbar: React.FC<{
  setHighlightOwl: React.Dispatch<React.SetStateAction<boolean>>;
  setHighlightShacl: React.Dispatch<React.SetStateAction<boolean>>;
  setHighlightHierarchy: React.Dispatch<React.SetStateAction<boolean>>;
  setHighlightProperties: React.Dispatch<React.SetStateAction<boolean>>;
  containsShacl: boolean;
  location: Location;
}> = ({
  setHighlightOwl,
  setHighlightShacl,
  setHighlightHierarchy,
  setHighlightProperties,
  containsShacl,
  location,
}) => {
  const history = useHistory();
  const query = parseSearchString(location.search);

  const content = (query.c || "both") as string;
  const edges = (query.e || "both") as string;
  const ranker = (query.r || "network-simplex") as string;
  const rankDirection = (query.d || "LR") as string;
  const align = query.a as string | undefined;
  const hasFocusNode = !!location.hash;

  return (
    <div className={getClassName(styles.toolbarContainer, "mui-fixed")}>
      <Paper className={getClassName(styles.toolbar, { [styles.hidden]: hasFocusNode })} elevation={6}>
        {containsShacl && (
          <ToggleButtonGroup
            value={content}
            exclusive
            onChange={(_event, newContent) => {
              history.replace({
                search: stringifyQuery({ ...query, c: newContent === "both" ? undefined : newContent }),
              });
            }}
            aria-label="OWL and SHACL"
          >
            <ToggleButton
              value="owl"
              className={getClassName(styles.owl, styles.toggleButton, { [styles.color]: content === "both" })}
              onMouseEnter={() => setHighlightOwl(true)}
              onMouseLeave={() => setHighlightOwl(false)}
              color="primary"
            >
              OWL
            </ToggleButton>
            <ToggleButton value="both" className={styles.toggleButton}>
              Both
            </ToggleButton>
            <ToggleButton
              value="shacl"
              className={getClassName(styles.shacl, styles.toggleButton, { [styles.color]: content === "both" })}
              onMouseEnter={() => setHighlightShacl(true)}
              onMouseLeave={() => setHighlightShacl(false)}
              color="error"
            >
              SHACL
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <ToggleButtonGroup
          value={edges}
          exclusive
          onChange={(_event, newEdges) => {
            history.replace({
              search: stringifyQuery({ ...query, e: newEdges === "both" ? undefined : newEdges }),
            });
          }}
          aria-label="Hierarchy and properties"
        >
          <ToggleButton
            value="hierarchy"
            className={getClassName(styles.hierarchy, styles.toggleButton, { [styles.color]: edges === "both" })}
            onMouseEnter={() => setHighlightHierarchy(true)}
            onMouseLeave={() => setHighlightHierarchy(false)}
            color="success"
          >
            Hierarchy
          </ToggleButton>
          <ToggleButton value="both" className={styles.toggleButton}>
            Both
          </ToggleButton>
          <ToggleButton
            value="properties"
            className={getClassName(styles.properties, styles.toggleButton, { [styles.color]: edges === "both" })}
            onMouseEnter={() => setHighlightProperties(true)}
            onMouseLeave={() => setHighlightProperties(false)}
            color="secondary"
          >
            Properties
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={ranker}
          exclusive
          onChange={(_event, newRanker) => {
            history.replace({
              search: stringifyQuery({ ...query, r: newRanker === "network-simplex" ? undefined : newRanker }),
            });
          }}
          aria-label="Ranker"
        >
          <ToggleButton value="longest-path" title="Ranker: longest-path" className={styles.toggleButton}>
            longest-path
          </ToggleButton>
          <ToggleButton value="network-simplex" title="Ranker: network-simplex" className={styles.toggleButton}>
            network-simplex
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={rankDirection}
          exclusive
          onChange={(_event, newRankdir) => {
            history.replace({
              search: stringifyQuery({ ...query, d: newRankdir === "LR" ? undefined : newRankdir }),
            });
          }}
          aria-label="Direction"
        >
          <ToggleButton value="TB" title="Direction: top to bottom" aria-label="Top to bottom">
            <FontAwesomeIcon icon="arrow-down" />
          </ToggleButton>
          <ToggleButton value="LR" title="Direction: left to right" aria-label="Left to right">
            <FontAwesomeIcon icon="arrow-right" />
          </ToggleButton>
          <ToggleButton value="BT" title="Direction: bottom to top" aria-label="Bottom to top">
            <FontAwesomeIcon icon="arrow-up" />
          </ToggleButton>
          <ToggleButton value="RL" title="Direction: right to left" aria-label="Right to left">
            <FontAwesomeIcon icon="arrow-left" />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={align || "none"}
          exclusive
          onChange={(_event, newAlign) => {
            history.replace({ search: stringifyQuery({ ...query, a: newAlign === "none" ? undefined : newAlign }) });
          }}
          aria-label="Align"
        >
          <ToggleButton value="UL" title="Align: upper left" aria-label="Upper left">
            <FontAwesomeIcon
              icon="align-left"
              style={{ borderLeft: "1px solid", paddingLeft: "1px", marginLeft: "-2px" }}
            />
          </ToggleButton>
          <ToggleButton value="DL" title="Align: lower left" aria-label="Lower left">
            <FontAwesomeIcon icon="align-left" />
          </ToggleButton>
          <ToggleButton value="none" title="Align: center" aria-label="Center">
            <FontAwesomeIcon icon="align-center" />
          </ToggleButton>
          <ToggleButton value="DR" title="Align: lower right" aria-label="Lower right">
            <FontAwesomeIcon icon="align-right" />
          </ToggleButton>
          <ToggleButton value="UR" title="Align: upper right" aria-label="Upper right">
            <FontAwesomeIcon
              icon="align-right"
              style={{ borderRight: "1px solid", paddingRight: "1px", marginRight: "-2px" }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default Toolbar;
