import * as React from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router";
import { RouteConfig } from "react-router-config";
import { IComponentProps } from "#containers/index.ts";
import useRunningServices from "#helpers/hooks/useRunningServices.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { ErrorPage } from "../../components";

interface Props {
  route: RouteConfig;
}

const Redirector: React.FC<IComponentProps> = () => {
  const location = useLocation();
  const match = useRouteMatch<{ account: string; dataset: string; serviceName?: string }>();
  const currentDs = useCurrentDataset();
  const [cookies] = useCookies(["ecp", `${currentDs?.id}-cse-elasticsearch`]);
  const runningServiceNames = useRunningServices("elasticsearch").map((s) => s.name);

  const lastSearchTerm = useSelector((state: GlobalState) => {
    return (currentDs && state.datasets[currentDs.id]?.lastSearchQuery) || undefined;
  });
  const query = parseSearchString(location.search);
  const preferQueryView = !!cookies["ecp"];
  const serviceInPath = !!match.params.serviceName;
  let basePath = match.url.replace(/\/+$/, "");

  if (runningServiceNames.length > 0) {
    const serviceNameToFind = match.params.serviceName || cookies[`${currentDs?.id}-cse-elasticsearch`];
    if (serviceNameToFind && runningServiceNames.find((serviceName) => serviceName === serviceNameToFind)) {
      if (!serviceInPath) basePath += `/${serviceNameToFind}`;
    } else if (!serviceInPath) {
      basePath += `/${runningServiceNames[0]}`;
    }
    return (
      <Redirect
        to={{
          pathname: `${basePath}/${preferQueryView ? "query" : "text"}`,
          search: query.q ? location.search : stringifyQuery({ q: lastSearchTerm }),
          hash: location.hash,
        }}
      />
    );
  }
  return <ErrorPage statusCode={404} />;
};

export default Redirector;
