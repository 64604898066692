import * as React from "react";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FormField, Label, MuiSwitchRedux, MuiTextFieldRedux } from "#components/index.ts";

namespace AuthSettingsForm {
  export interface FormData {
    passwordSignup: boolean;
    permittedSignupDomains: string;
  }
}

interface Props extends Partial<ReduxForm.InjectedFormProps<AuthSettingsForm.FormData>> {}

const AuthSettingsComponent: React.FC<Props> = ({ handleSubmit, submitting, submitSucceeded, error, pristine }) => {
  const renderSwitchField = (props: MuiSwitchRedux.Props) => {
    return (
      <div style={{ marginTop: -3, marginLeft: -12, marginBottom: -4 }}>
        <MuiSwitchRedux {...props} />
      </div>
    );
  };
  return (
    <div>
      <form method="POST" onSubmit={handleSubmit}>
        <FormField labelWidth={220} label="Password signup enabled" className="mt-6 mb-5">
          <ReduxForm.Field color="primary" name="passwordSignup" component={renderSwitchField} />
        </FormField>
        <FormField
          labelWidth={220}
          label="Permitted signup domains"
          helperText="Only users with e-mail addresses that match these domains are allowed to sign-up. Wildcards are allowed and domains are comma separated, for example: mydomain.com,*.mydomain.com"
          className="mb-6"
        >
          <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
            name="permittedSignupDomains"
            props={{
              type: "email",
              rows: 3,
              multiline: true,
              fullWidth: true,
            }}
            component={MuiTextFieldRedux}
          />
        </FormField>
        <Alert transparent message={error} />
        <div className="form-group mt-5">
          <LoadingButton
            type="submit"
            color="secondary"
            disabled={pristine}
            onClick={handleSubmit}
            loading={submitting}
          >
            Save
          </LoadingButton>
        </div>
      </form>
      {submitSucceeded && pristine && (
        <div className="mt-4">
          <Label success message="You have successfully updated the authentication settings." />
        </div>
      )}
    </div>
  );
};

const AuthSettingsForm = ReduxForm.reduxForm<AuthSettingsForm.FormData, Props>({
  form: "authSettings",
  validate: (values) => {
    if (!values.permittedSignupDomains) return { permittedSignupDomains: "Required value" };
    return {};
  },
  enableReinitialize: true,
})(AuthSettingsComponent as any);

export default AuthSettingsForm;
