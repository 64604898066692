import { syntaxTree } from "@codemirror/language";
import { Diagnostic } from "@codemirror/lint";
import { EditorView } from "@codemirror/view";
import Debug from "debug";
import { uniq, uniqBy } from "lodash-es";
import { parsers } from "@triplydb/sparql-ast";
import { SparqlAstError } from "@triplydb/sparql-ast/error.js";

const debug = Debug("triply:sparql-editor:linting:syntax");

function getDiagnostic(node: { from: number; to: number }, message: string): readonly Diagnostic[] {
  console.warn(message);
  return [
    {
      from: node.from,
      to: node.to,
      severity: "error",
      // We don't want long unreadable error messages.
      message: "",
    },
  ];
}

function syntaxLinter(view: EditorView): readonly Diagnostic[] {
  debug("start");
  const diagnostics: Diagnostic[] = [];
  let isParsingError = false;
  try {
    parsers.compliant(view.state.doc.toString(), {
      assertValidAnnotations: true,
      baseIri: "https://triplydb.com/",
    });
  } catch (e: any | SparqlAstError) {
    const sparqlAstError = e as SparqlAstError;
    if (sparqlAstError && sparqlAstError.location) {
      const errorStartLineFrom = view.state.doc.line(sparqlAstError.location.startLine).from;
      const errorEndLineFrom = view.state.doc.line(sparqlAstError.location.endLine).from;
      const actualErrorStart = errorStartLineFrom + sparqlAstError.location.startColumn - 1;
      const actualErrorEnd =
        errorEndLineFrom + sparqlAstError.location.endColumn >= view.state.doc.length
          ? view.state.doc.length
          : errorEndLineFrom + sparqlAstError.location.endColumn;
      const isLineZeroEmpty = view.state.doc.line(1).length == 0;

      let diagnostic: Diagnostic = {
        from: actualErrorStart,
        to: actualErrorEnd,
        severity: "error",
        message: sparqlAstError.message,
      };

      // DISABLING ACTIONS FOR NOW UNTIL WE ARE READY TO WORK ON THEM....
      switch (true) {
        case sparqlAstError.message.includes("Parser error on line"):
          diagnostic.message = "";
          isParsingError = true;
          break;
        // case sparqlAstError.message.includes("Unsupported location of annotation"):
        // case sparqlAstError.message.includes("annotations should be placed on a separate line"):
        //   const annotation = view.state.sliceDoc(actualErrorStart, actualErrorEnd);
        //   if (!annotation.includes("paginate")) {
        //     diagnostic.actions = [
        //       {
        //         name: "Reorganize",
        //         apply(view) {
        //           view.dispatch({
        //             changes: [
        //               { from: actualErrorStart, to: actualErrorEnd },
        //               { from: 0, insert: `${annotation}${isLineZeroEmpty ? "" : "\n"}` },
        //             ],
        //           });
        //         },
        //       },
        //     ];
        //   }
        //   break;
        // case sparqlAstError.message.includes("duplicate annotation keys detected"):
        //   let uniqueValidAnnotations: (string | undefined)[] = [];
        //   const annotations = view.state.doc
        //     .slice(actualErrorStart, actualErrorEnd)
        //     .toJSON()
        //     .map((annotation) => annotation.toLowerCase());
        //   uniqueValidAnnotations.push(annotations.find((annotation) => annotation.includes("optimize")));
        //   uniqueValidAnnotations.push(annotations.find((annotation) => annotation.includes("debug")));
        //   uniqueValidAnnotations.push(annotations.find((annotation) => annotation.includes("cache")));
        //   uniqueValidAnnotations.push(annotations.find((annotation) => annotation.includes("reorder")));
        //   uniqueValidAnnotations = uniqueValidAnnotations.filter((annotation) => annotation !== undefined);
        //   let otherAnnotions = annotations.filter((annotation) => !annotation.includes("optimize") && !annotation.includes("debug") && !annotation.includes("cache") && !annotation.includes("reorder"));
        //   diagnostic.actions = [
        //     {
        //       name: "Remove duplicates",
        //       apply(view) {
        //         view.dispatch({
        //           changes: [
        //             { from: actualErrorStart, to: actualErrorEnd },
        //             { from: 0, insert: `${uniqueValidAnnotations.join("\n")}${otherAnnotions.length > 0 ?  '\n' + otherAnnotions.join("\n") : ''}${isLineZeroEmpty ? "" : "\n"}` },
        //           ],
        //         });
        //       },
        //     },
        //   ];
        //   break;
        default:
      }
      diagnostics.push(diagnostic);
    }
  }

  if (!isParsingError) {
    let treeState = syntaxTree(view.state);
    const cursor = treeState.cursor();
    while (cursor.next()) {
      let node = cursor.node;
      if (node.type.isError) {
        // We used to re-parse the query using sparqljs, as that (arguably) gave good error messages.
        // Given that sparql-ast doesn't come with nice-enough error messages, we've removed this re-parse step.
        // This is something we may want to change later
        diagnostics.push({
          from: node.from,
          to: node.to,
          severity: "error",
          message: "",
        });
      }
    }
  }

  debug("end");
  return diagnostics;
}

export default syntaxLinter;
