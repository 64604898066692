import { last, sortBy } from "lodash-es";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { PropertyModel } from "../PropertyShape";
import { FormValues } from "../Types";
import AutoCompleteEditor from "./AutoCompleteEditor";
import BlankNodeEditor from "./BlankNodeEditor";
import BooleanEditor from "./BooleanEditor";
import TextFieldEditor from "./TextFieldEditor";

export interface Editor {
  Component: React.FC<{
    name: `properties.${string}.${number}`;
    propertyModel: PropertyModel;
  }>;
  getScore: (opts: { nodeKind: "IRI" | "Literal" | "BlankNode"; datatype: string | undefined }) => number;
}

const Editor: React.FC<{
  name: `properties.${string}.${number}`;
  propertyModel: PropertyModel;
}> = ({ name, propertyModel }) => {
  const { getValues } = useFormContext<FormValues>();

  const value = getValues(name);

  const Editor = last(
    sortBy(
      [TextFieldEditor, AutoCompleteEditor, BlankNodeEditor, BooleanEditor].map((editor) => {
        return {
          component: editor.Component,
          score: editor.getScore({
            nodeKind: value?.nodeKind || propertyModel.nodeKind || "Literal",
            datatype: (value?.nodeKind === "Literal" && value.datatype) || propertyModel.datatype,
          }),
        };
      }),
      "score",
    ),
  )!;

  return <Editor.component name={name} propertyModel={propertyModel} />;
};

export default Editor;
