import { HttpMethodTemplate } from "@triply/utils/Routes.js";
import { stringifyQuery } from "./utils.ts";

type GenericRequestOptions = {
  headers?: { [key: string]: string };
  timeout?: number;
} & RequestInit;

// This function is mostly copy-pasted from utils-private, but it differs subtly in the folloing ways:
// - we don't use node-fetch
// - we don't throw errors, but log them instead
// - we check whether the session ended
// - we don't support HTTPS proxy stuff
export async function fetchJson<Method extends HttpMethodTemplate>(
  url: string,
  opts: {
    method: "GET" | "DELETE";
    query?: Method["Req"]["Query"];
    jsonBody?: Method["Req"]["Body"];
  } & GenericRequestOptions,
): Promise<{ json: Method["Res"]["Body"]; status: Number } | { errorBody: any; status?: Number }>;
export async function fetchJson<Method extends HttpMethodTemplate>(
  url: string,
  opts: {
    method: "POST" | "PATCH";
    query?: Method["Req"]["Query"];
    jsonBody: Method["Req"]["Body"];
  } & GenericRequestOptions,
): Promise<{ json: Method["Res"]["Body"]; status: Number } | { errorBody: any; status?: Number }>;
export async function fetchJson<Method extends HttpMethodTemplate>(
  url: string,
  opts: {
    method: "POST" | "PATCH" | "GET" | "DELETE";
    query?: Method["Req"]["Query"];
    jsonBody?: Method["Req"]["Body"];
  } & GenericRequestOptions,
): Promise<{ json: Method["Res"]["Body"]; status: Number } | { errorBody: any; status?: Number }> {
  const { timeout, jsonBody, ...reqOptions } = { headers: {}, timeout: 0, ...opts };

  if (opts.jsonBody) {
    reqOptions.body = JSON.stringify(opts.jsonBody);
    reqOptions.headers["Content-Type"] = "application/json";
  }
  if (opts.query) {
    url += "?" + stringifyQuery(opts.query);
  }

  let response: Response;
  if (timeout) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    reqOptions.signal = controller.signal;
    try {
      response = await fetch(url, reqOptions);
    } catch (err: any) {
      if (err.name === "AbortError") {
        console.error(`Could not complete request to ${url} within ${timeout}ms`);
      }
      console.error(err);
      return { errorBody: err };
    } finally {
      clearTimeout(timeoutId);
    }
  } else {
    response = await fetch(url, reqOptions);
  }

  checkSessionEnd(response.headers.get("X-Triply-Session-Ended"));

  if (response.status >= 300) {
    let responseBody: Object | string;
    if (response.headers.get("content-type")?.includes("application/json")) {
      responseBody = (await response.json()) as Object;
    } else {
      responseBody = await response.text();
    }

    console.error(response.statusText, JSON.stringify({ url, responseBody, status: response.status }));
    return { errorBody: responseBody, status: response.status };
  }

  return { json: await response.json(), status: response.status };
}

export default function (input: RequestInfo, init?: RequestInit): Promise<Response> {
  return fetch(input, init).then((response) => {
    checkSessionEnd(response.headers.get("X-Triply-Session-Ended"));
    return response;
  });
}

export function checkSessionEnd(sessionHeader: string | undefined | null) {
  if (!__CLIENT__ || !sessionHeader) return;

  window.location.replace(
    "/login?" +
      stringifyQuery({
        returnTo: window.location.pathname + window.location.search + window.location.hash,
        reason: sessionHeader,
      }),
  );
}
