import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";
import { Account } from "./accountCollection.ts";

export const LocalActions = {
  GET_HOOKS: "triply/hooks/GET_HOOKS",
  GET_HOOKS_SUCCESS: "triply/hooks/GET_HOOKS_SUCCESS",
  GET_HOOKS_FAIL: "triply/hooks/GET_HOOKS_FAIL",

  PUT_HOOKS: "triply/hooks/PUT_HOOKS",
  PUT_HOOKS_SUCCESS: "triply/hooks/PUT_HOOKS_SUCCESS",
  PUT_HOOKS_FAIL: "triply/hooks/PUT_HOOKS_FAIL",

  REMOVE_HOOK: "triply/hooks/REMOVE_HOOK",
  REMOVE_HOOK_SUCCESS: "triply/hooks/REMOVE_HOOK_SUCCESS",
  REMOVE_HOOK_FAIL: "triply/hooks/REMOVE_HOOK_FAIL",

  UPDATE_HOOK: "triply/hooks/UPDATE_HOOK",
  UPDATE_HOOK_SUCCESS: "triply/hooks/UPDATE_HOOK_SUCCESS",
  UPDATE_HOOK_FAIL: "triply/hooks/UPDATE_HOOK_FAIL",

  NEW_HOOK: "triply/hooks/NEW_HOOK",
  NEW_HOOK_SUCCESS: "triply/hooks/NEW_HOOK_SUCCESS",
  NEW_HOOK_FAIL: "triply/hooks/NEW_HOOK_FAIL",
} as const;

type GET_HOOKS = GlobalAction<
  {
    types: [typeof LocalActions.GET_HOOKS, typeof LocalActions.GET_HOOKS_SUCCESS, typeof LocalActions.GET_HOOKS_FAIL];
    forDataset: Dataset;
  },
  Routes.datasets._account._dataset.hooks.Get
>;

type PUT_HOOKS = GlobalAction<{
  types: [typeof LocalActions.PUT_HOOKS, typeof LocalActions.PUT_HOOKS_SUCCESS, typeof LocalActions.PUT_HOOKS_FAIL];
}>;

type REMOVE_HOOK = GlobalAction<
  {
    types: [
      typeof LocalActions.REMOVE_HOOK,
      typeof LocalActions.REMOVE_HOOK_SUCCESS,
      typeof LocalActions.REMOVE_HOOK_FAIL,
    ];
    forDataset: Dataset;
    hookId: string;
  },
  Routes.datasets._account._dataset.hooks._hookId.Delete
>;

type UPDATE_HOOK = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_HOOK,
      typeof LocalActions.UPDATE_HOOK_SUCCESS,
      typeof LocalActions.UPDATE_HOOK_FAIL,
    ];
    forDataset: Dataset;
    hookId: string;
  },
  Routes.datasets._account._dataset.hooks._hookId.Patch
>;

type NEW_HOOK = GlobalAction<
  {
    types: [typeof LocalActions.NEW_HOOK, typeof LocalActions.NEW_HOOK_SUCCESS, typeof LocalActions.NEW_HOOK_FAIL];
    forDataset: Dataset;
  },
  Routes.datasets._account._dataset.hooks.Post
>;

export type LocalAction = GET_HOOKS | PUT_HOOKS | REMOVE_HOOK | UPDATE_HOOK | NEW_HOOK;

export type Hook = Models.Hook;

export interface State {
  [datasetId: string]: Hook[];
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.NEW_HOOK_SUCCESS:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = [];
        draftState[action.forDataset.id].push(action.result);
        return;

      case Actions.GET_HOOKS_SUCCESS:
        draftState[action.forDataset.id] = action.result;
        return;

      case Actions.REMOVE_HOOK_SUCCESS:
        draftState[action.forDataset.id] = (draftState[action.forDataset.id] || []).filter(
          (h) => h.id !== action.hookId,
        );
        return;

      case Actions.UPDATE_HOOK_SUCCESS:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = [];
        const hookIndex = draftState[action.forDataset.id].findIndex((h) => h.id === action.hookId);
        if (hookIndex >= 0) draftState[action.forDataset.id][hookIndex] = action.result;
        return;
    }
  },
  <State>{},
);

export function getHooks(forAccount: Account, forDataset: Dataset): BeforeDispatch<GET_HOOKS> {
  return {
    types: [Actions.GET_HOOKS, Actions.GET_HOOKS_SUCCESS, Actions.GET_HOOKS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/hooks`,
        method: "get",
      }),
    forDataset,
  };
}

export function removeHook(forAccount: Account, forDataset: Dataset, hook: Models.Hook): BeforeDispatch<REMOVE_HOOK> {
  return {
    types: [Actions.REMOVE_HOOK, Actions.REMOVE_HOOK_SUCCESS, Actions.REMOVE_HOOK_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/hooks/${hook.id}`,
        method: "delete",
      }),
    forDataset,
    hookId: hook.id,
  };
}

export function newHook(forAccount: Account, forDataset: Dataset, hook: Models.HookCreate): BeforeDispatch<NEW_HOOK> {
  return {
    types: [Actions.NEW_HOOK, Actions.NEW_HOOK_SUCCESS, Actions.NEW_HOOK_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/hooks/`,
        method: "post",
        body: {
          // couldn't figure how to set default values on a new-hook form.
          // untouched checkboxes and 'active' slider become undefined, rather than false.
          // payloadformat is empty string unless an option is specified.
          // if none of the onEvents checkboxes were touched, the onEvents object becomes undefined

          // this resolves the issue, but would be better to set in forms/Hook.ts,
          // since the default value of 'active' would be visible to the user

          url: hook.url, // no default for this field, wouldn't make sense
          active: !!hook.active,
          payloadFormat: hook.payloadFormat, // don't set default when it's not visible
          onEvents: {
            fileUpload:
              hook.onEvents !== undefined && hook.onEvents.fileUpload !== undefined ? hook.onEvents.fileUpload : false,
            graphImport:
              hook.onEvents !== undefined && hook.onEvents.graphImport !== undefined
                ? hook.onEvents.graphImport
                : false,
            linkedDataUpload:
              hook.onEvents !== undefined && hook.onEvents.linkedDataUpload !== undefined
                ? hook.onEvents.linkedDataUpload
                : false,
          },
        },
      }),
    forDataset,
  };
}

export function updateHook(forAccount: Account, forDataset: Dataset, hook: Models.Hook): BeforeDispatch<UPDATE_HOOK> {
  return {
    types: [Actions.UPDATE_HOOK, Actions.UPDATE_HOOK_SUCCESS, Actions.UPDATE_HOOK_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/hooks/${hook.id}`,
        method: "patch",
        body: hook,
      }),
    forDataset,
    hookId: hook.id,
  };
}
