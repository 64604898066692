// extracted by mini-css-extract-plugin
var _1 = "style--collapsed--VxnirYVL";
var _2 = "style--content--a0sxWoJX";
var _3 = "style--header--r_8h2cnn";
var _4 = "style--headerTitle--uqHahN9L";
var _5 = "style--homeButton--zzeBN9uQ";
var _6 = "style--main--dwd_jKNy";
var _7 = "style--menuButton--jb7zdCUP";
var _8 = "style--nav--VYiysAyt";
var _9 = "style--toggler--Ohp_RUm2";
export { _1 as "collapsed", _2 as "content", _3 as "header", _4 as "headerTitle", _5 as "homeButton", _6 as "main", _7 as "menuButton", _8 as "nav", _9 as "toggler" }
