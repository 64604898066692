import { Models, Routes } from "@triply/utils";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";

export const LocalActions = {
  GET_HOOK_RECORDS: "triply/hookrecords/GET_HOOK_RECORDS",
  GET_HOOK_RECORDS_SUCCESS: "triply/hookrecords/GET_HOOK_RECORDS_SUCCESS",
  GET_HOOK_RECORDS_FAIL: "triply/hookrecords/GET_HOOK_RECORDS_FAIL",
} as const;

type GET_HOOK_RECORDS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_HOOK_RECORDS,
      typeof LocalActions.GET_HOOK_RECORDS_SUCCESS,
      typeof LocalActions.GET_HOOK_RECORDS_FAIL,
    ];
    forDataset: Dataset;
    hookId: string;
  },
  Routes.datasets._account._dataset.hookRecords._hookId.Get
>;

export type LocalAction = GET_HOOK_RECORDS;

export function getHookRecords(
  forAccount: Account,
  forDataset: Dataset,
  hook: Models.Hook,
  offset: number,
  limit: number,
): BeforeDispatch<GET_HOOK_RECORDS> {
  return {
    types: [Actions.GET_HOOK_RECORDS, Actions.GET_HOOK_RECORDS_SUCCESS, Actions.GET_HOOK_RECORDS_FAIL],
    promise: (client) =>
      client.req({
        pathname: `/datasets/${forAccount.accountName}/${forDataset.name}/hookrecords/${hook.id}`,
        method: "get",
        query: {
          offset: offset,
          limit: limit,
        },
      }),
    forDataset,
    hookId: hook.id,
  };
}
