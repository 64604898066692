import { useNProgress } from "@tanem/react-nprogress";
import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "#reducers/index.ts";
import Bar from "./bar.tsx";
import Container from "./container.tsx";

const Wrapper = React.memo<{}>(() => {
  const isAnimating = useSelector((state: GlobalState) => !state.reduxAsyncConnect.loaded);
  const [show, setShow] = React.useState(false);
  //not using hooks here as we assume that this component never unmounts
  if (show && !isAnimating) setTimeout(() => setShow(false), 1000);
  if (!show && isAnimating) setTimeout(() => setShow(true), 0);
  if (!show && !isAnimating) return null;
  return <Progress isAnimating={isAnimating} />;
});

const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <Container isFinished={isFinished} animationDuration={animationDuration}>
      <Bar progress={progress} animationDuration={animationDuration} />
    </Container>
  );
};

export default Wrapper;
