import getClassName from "classnames";
import * as React from "react";
import { FontAwesomeButton, FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./styles.scss";

namespace RemoveBtn {
  export interface Props {
    className?: string;
    disabled?: boolean;
    title: string;
    onClick: React.MouseEventHandler;
    size?: FontAwesomeIcon.Props["size"];
  }
}

const RemoveBtn: React.FC<RemoveBtn.Props> = ({ className, title, onClick, size, disabled }) => {
  return (
    <FontAwesomeButton
      icon="times"
      size={size}
      title={title}
      className={getClassName(styles.remove, className)}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default RemoveBtn;
