import { SocketEvent, SocketEventWithType } from "@triply/utils/SocketEvents.js";
import { BeforeDispatch, GlobalAction } from "#reducers/index.ts";

const SocketActionPrefix = "triply/socket/SOCKET_EVENT" as const;
type ReduxSocketActions = { [type in SocketEvent["eventType"]]: `${typeof SocketActionPrefix}/${type}` };
const reduxSocketActions: ReduxSocketActions = {
  indexJobFinished: `${SocketActionPrefix}/indexJobFinished`,
  indexJobUpdate: `${SocketActionPrefix}/indexJobUpdate`,
  indexJobIndexingProgress: `${SocketActionPrefix}/indexJobIndexingProgress`,
  serviceMetadataUpdate: `${SocketActionPrefix}/serviceMetadataUpdate`,
  serviceGraphUpdate: `${SocketActionPrefix}/serviceGraphUpdate`,
};

export const LocalActions = {
  SOCKET_EVENT: reduxSocketActions,
} as const;

/**
 * We infer the event type here, to make sure that whenever (in a reducer) we match for the redux action with the event type,
 * that we're also getting the matching redux action data object (instead of all possible socket event data objects)
 */
type SOCKET_EVENT = GlobalAction<
  SocketEvent extends { eventType: infer T }
    ? T extends SocketEvent["eventType"]
      ? {
          type: `${typeof SocketActionPrefix}/${T}`;
          data: SocketEventWithType<T>;
        }
      : never
    : never
>;

export type LocalAction = SOCKET_EVENT;

export function socketEventAsReduxAction(data: SocketEvent): BeforeDispatch<SOCKET_EVENT> {
  return {
    type: `${SocketActionPrefix}/${data.eventType}`,
    data: data,
  } as BeforeDispatch<SOCKET_EVENT>;
}
