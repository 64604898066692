import { produce } from "immer";
import { Models, Routes } from "@triply/utils";
import { Action, Actions, BeforeDispatch, GlobalAction, GlobalState } from "#reducers/index.ts";
import { Account } from "./accountCollection.ts";

export const LocalActions = {
  GET_TOKENS: "triply/accounts/GET_TOKENS",
  GET_TOKENS_SUCCESS: "triply/accounts/GET_TOKENS_SUCCESS",
  GET_TOKENS_FAIL: "triply/accounts/GET_TOKENS_FAIL",
  GET_TOKEN: "triply/accounts/GET_TOKEN",
  GET_TOKEN_SUCCESS: "triply/accounts/GET_TOKEN_SUCCESS",
  GET_TOKEN_FAIL: "triply/accounts/GET_TOKEN_FAIL",
  REVOKE_TOKEN: "triply/accounts/REVOKE_TOKEN",
  REVOKE_TOKEN_SUCCESS: "triply/accounts/REVOKE_TOKEN_SUCCESS",
  REVOKE_TOKEN_FAIL: "triply/accounts/REVOKE_TOKEN_FAIL",
  CREATE_TOKEN: "triply/accounts/CREATE_TOKEN",
  CREATE_TOKEN_SUCCESS: "triply/accounts/CREATE_TOKEN_SUCCESS",
  CREATE_TOKEN_FAIL: "triply/accounts/CREATE_TOKEN_FAIL",
  UPDATE_TOKEN: "triply/accounts/UPDATE_TOKEN",
  UPDATE_TOKEN_SUCCESS: "triply/accounts/UPDATE_TOKEN_SUCCESS",
  UPDATE_TOKEN_FAIL: "triply/accounts/UPDATE_TOKEN_FAIL",
} as const;

export type GET_TOKENS = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_TOKENS,
      typeof LocalActions.GET_TOKENS_SUCCESS,
      typeof LocalActions.GET_TOKENS_FAIL,
    ];
    account: Account;
  },
  Routes.accounts._account.tokens.Get
>;

export type GET_TOKEN = GlobalAction<
  {
    types: [typeof LocalActions.GET_TOKEN, typeof LocalActions.GET_TOKEN_SUCCESS, typeof LocalActions.GET_TOKEN_FAIL];
  },
  Routes.accounts._account.tokens._tokenId.Get
>;

export type REVOKE_TOKEN = GlobalAction<
  {
    types: [
      typeof LocalActions.REVOKE_TOKEN,
      typeof LocalActions.REVOKE_TOKEN_SUCCESS,
      typeof LocalActions.REVOKE_TOKEN_FAIL,
    ];
    tokenId: string;
  },
  Routes.accounts._account.tokens._tokenId.Delete
>;

export type CREATE_TOKEN = GlobalAction<
  {
    types: [
      typeof LocalActions.CREATE_TOKEN,
      typeof LocalActions.CREATE_TOKEN_SUCCESS,
      typeof LocalActions.CREATE_TOKEN_FAIL,
    ];
  },
  Routes.accounts._account.tokens.Post
>;

export type UPDATE_TOKEN = GlobalAction<
  {
    types: [
      typeof LocalActions.UPDATE_TOKEN,
      typeof LocalActions.UPDATE_TOKEN_SUCCESS,
      typeof LocalActions.UPDATE_TOKEN_FAIL,
    ];
  },
  Routes.accounts._account.tokens._tokenId.Patch
>;

export type LocalAction = GET_TOKENS | GET_TOKEN | REVOKE_TOKEN | CREATE_TOKEN | UPDATE_TOKEN;

export type Token = Models.Token;

export interface State {
  list?: Token[];
  listFor?: string;
  currentToken?: Token;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_TOKENS_SUCCESS:
        draftState.list = action.result;
        draftState.listFor = action.account.uid;
        return;
      case Actions.GET_TOKENS_FAIL:
        draftState.list = undefined;
        draftState.listFor = undefined;
        return;
      case Actions.REVOKE_TOKEN_SUCCESS:
        if (!draftState.list) return;
        draftState.list = draftState.list.filter((token) => token.tokenId !== action.tokenId);
        return;
      case Actions.CREATE_TOKEN_SUCCESS:
      case Actions.UPDATE_TOKEN_SUCCESS:
      case Actions.GET_TOKEN_SUCCESS:
        draftState.currentToken = action.result;
        if (draftState.list && draftState.listFor === action.result.ownerId) {
          //we've already fetched the token list, make sure we update this list with the latest changes
          const tokenIndex = draftState.list.findIndex((token) => token.tokenId === action.result.tokenId);
          if (tokenIndex >= 0) {
            draftState.list[tokenIndex] = action.result;
          } else {
            draftState.list = [action.result, ...draftState.list];
          }
        }
        return;
      // Because the token form is still open for showing the form, we need to wait until the form is destroyed before we set currentToken to null
      case "@@redux-form/DESTROY":
        if (action.meta.form && action.meta.form.indexOf("tokenForm") >= 0) {
          draftState.currentToken = undefined;
        }
        return;
    }
  },
  <State>{
    list: undefined,
    listFor: undefined,
    currentToken: undefined,
  },
);

function getAccountBaseRequestPath(account: Account) {
  return "/accounts/" + account.accountName;
}

export function isTokenLoaded(state: GlobalState, tokenId: string) {
  return state.tokens.currentToken && state.tokens.currentToken.tokenId === tokenId;
}

export function tokensLoadedFor(state: GlobalState, accountId: string) {
  return state.tokens.list && state.tokens.listFor === accountId;
}

export function getTokens(account: Account): BeforeDispatch<GET_TOKENS> {
  return {
    types: [LocalActions.GET_TOKENS, LocalActions.GET_TOKENS_SUCCESS, LocalActions.GET_TOKENS_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account) + "/tokens",
        method: "get",
      }),
    account: account,
  };
}

export function getToken(account: Account, tokenId: string): BeforeDispatch<GET_TOKEN> {
  return {
    types: [LocalActions.GET_TOKEN, LocalActions.GET_TOKEN_SUCCESS, LocalActions.GET_TOKEN_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account) + "/tokens/" + tokenId,
        method: "get",
      }),
  };
}

export function revokeToken(account: Account, tokenId: string): BeforeDispatch<REVOKE_TOKEN> {
  return {
    types: [LocalActions.REVOKE_TOKEN, LocalActions.REVOKE_TOKEN_SUCCESS, LocalActions.REVOKE_TOKEN_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account) + "/tokens/" + tokenId,
        method: "delete",
      }),
    tokenId: tokenId,
  };
}

export function createToken(
  account: Account,
  description: string,
  scopes: Models.Scopes,
): BeforeDispatch<CREATE_TOKEN> {
  return {
    types: [LocalActions.CREATE_TOKEN, LocalActions.CREATE_TOKEN_SUCCESS, LocalActions.CREATE_TOKEN_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account) + "/tokens",
        method: "post",
        body: {
          description,
          scopes,
        },
      }),
  };
}

export function updateToken(
  account: Account,
  tokenId: string,
  description: string,
  scopes: Models.Scopes,
): BeforeDispatch<UPDATE_TOKEN> {
  return {
    types: [LocalActions.UPDATE_TOKEN, LocalActions.UPDATE_TOKEN_SUCCESS, LocalActions.UPDATE_TOKEN_FAIL],
    promise: (client) =>
      client.req({
        pathname: getAccountBaseRequestPath(account) + "/tokens/" + tokenId,
        method: "patch",
        body: {
          description,
          scopes,
        },
      }),
  };
}
