import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SquareLogo from "#components/SquareLogo/index.tsx";
import { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

namespace NavIcon {
  export interface Props {
    className?: string;
    svg: string;
    title: string;
    to: string;
    //because of their layout (e.g. adding some margins)
  }
}

const NavIcon: React.FC<NavIcon.Props> = ({ className, to, svg, title }) => {
  const logoBgType = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgType);
  const logoBgColor = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoBgColor);

  return (
    <Link to={to} title={title} className={getClassName(styles.wrapper, className)}>
      <div className={styles.navIcon}>
        <SquareLogo logo={svg} logoBgType={logoBgType} logoBgColor={logoBgColor} heightWidthPx={0.8 * 50} />
      </div>
    </Link>
  );
};

export default NavIcon;
