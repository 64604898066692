import {
  Button,
  Chip,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import getClassName from "classnames";
import { stringify as serializeCSV } from "csv-stringify/sync";
import * as React from "react";
import { formatNumber } from "@triply/utils-private/formatting.js";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import useAcl from "../../../helpers/hooks/useAcl";
import { FontAwesomeIcon } from "../..";
import {
  CacheHeader,
  isAskResponse,
  isConstructResponse,
  isSelectResponse,
  RawResponse,
  SparqlResponse,
} from "../SparqlUtils";
import DocumentationDialog from "./DocumentationDialog";
import { canCreateShapeFile, downloadSparqlResponseAsShapefile } from "./shapeFileExport";
import { DownloadVisualization } from "./SparqlVisualizationContext";
import { VisualizationLabel, visualizationMeta } from ".";
import * as styles from "./styles.scss";

type ViewTabLabel = "Gallery" | "Geo" | "Charts" | "Pivot" | "Network" | "Timeline" | "LDFrame" | "JSON";

type DevTabLabel = "Table" | "Response" | "Visualization";

function getSelectedTab(tab: VisualizationLabel | undefined): DevTabLabel | "Visualization" {
  if (tab === undefined) return "Table";
  if (tab === "Table" || tab === "Response") return tab;
  return "Visualization";
}
function getSelectedVisualization(tab: VisualizationLabel | undefined): ViewTabLabel | undefined {
  if (tab === undefined || tab === "Table" || tab === "Response") return undefined;
  return tab;
}

const _VisualizationSelector: React.FC<{
  className?: string;
  currentVisualization: VisualizationLabel;
  setCurrentVisualization: (newVisualization: VisualizationLabel) => void;
  error: boolean;
  loading: boolean;
  duration?: number;
  delay?: number;
  cache?: CacheHeader;
  data: SparqlResponse | undefined;
  rawData: RawResponse | undefined;
  visualizationActionsCtrId: string;
  downloads?: DownloadVisualization[];
  hideVisualizations?: (ViewTabLabel | DevTabLabel)[];
  queryName: string;
}> = ({
  currentVisualization,
  setCurrentVisualization,
  error,
  loading,
  duration,
  delay = 0,
  cache,
  data,
  rawData,
  visualizationActionsCtrId,
  className,
  downloads,
  queryName,
  hideVisualizations,
}) => {
  const [showJsonVisualization] = useLocalStorage("triply-json-visualization", "");
  if (!showJsonVisualization) hideVisualizations = [...(hideVisualizations || []), "JSON"];
  const acl = useAcl();
  const selectedTab = getSelectedTab(currentVisualization);
  const resultCount =
    data && (isConstructResponse(data) ? data.length : isAskResponse(data) ? 1 : data?.results.bindings.length);
  // We want to persist the selected visualization when the user switches between the major visualizations
  const [selectedVisualization, setSelectedVisualization] = React.useState(() =>
    getSelectedVisualization(currentVisualization),
  );
  React.useEffect(() => {
    const selectedVisualization = getSelectedVisualization(currentVisualization);
    if (selectedVisualization !== undefined) setSelectedVisualization(selectedVisualization);
  }, [currentVisualization]);
  const menuAnchor = React.useRef<HTMLButtonElement>(null);
  const [selectedVisualizationMenuOpen, setSelectedVisualizationMenuOpen] = React.useState(false);

  return (
    <div className={getClassName(styles.visualizationContainer, className)}>
      <ToggleButtonGroup
        exclusive
        disabled={error}
        value={error ? "Error" : selectedTab}
        onChange={(_event, value: DevTabLabel | undefined) => {
          // Value is undefined when the already active button is clicked
          if (value) {
            if (value === "Visualization") {
              if (selectedVisualization === undefined) {
                setSelectedVisualizationMenuOpen(true);
              } else {
                setCurrentVisualization(selectedVisualization);
              }
            } else {
              setCurrentVisualization(value);
            }
          }
        }}
        color="primary"
      >
        <ToggleButton className={styles.visualizationTab} value="Table">
          Table
        </ToggleButton>
        <ToggleButton className={styles.visualizationTab} value="Response">
          Response
        </ToggleButton>
        <ToggleButton
          ref={menuAnchor}
          className={getClassName(styles.visualizationTab, styles.visualizationDropdownTab)}
          value="Visualization"
          aria-expanded={selectedVisualizationMenuOpen}
          aria-label={selectedVisualization || "Pick visualization"}
        >
          {selectedVisualization || "Visualization"}
        </ToggleButton>
        <ToggleButton
          className={getClassName(styles.visualizationTab, styles.visualizationDropdownCaret)}
          value={selectedTab === "Visualization" ? "Visualization" : ""}
          onClick={() => {
            setSelectedVisualizationMenuOpen(true);
          }}
          aria-label="Pick visualization"
        >
          <Divider orientation="vertical" variant="middle" />
          <FontAwesomeIcon icon={["fas", selectedVisualizationMenuOpen ? "caret-up" : "caret-down"]} className="px-3" />
        </ToggleButton>
      </ToggleButtonGroup>
      <Menu
        open={selectedVisualizationMenuOpen}
        onClose={() => setSelectedVisualizationMenuOpen(false)}
        anchorEl={menuAnchor.current}
      >
        {Object.entries(visualizationMeta)
          .filter(([label, _]) => !(hideVisualizations || []).includes(label as VisualizationLabel))
          .map(([label, visualization]) => (
            <MenuItem
              key={label}
              selected={label === selectedVisualization}
              onClick={() => {
                setCurrentVisualization(label as VisualizationLabel);
                setSelectedVisualizationMenuOpen(false);
              }}
            >
              {label}
              {!visualization.canBeDrawn(data) && (
                <span
                  aria-label="This data doesn't support rendering of the current selection"
                  title="This data doesn't support rendering of the current selection"
                >
                  <FontAwesomeIcon className="ml-1" icon="exclamation-triangle" />
                </span>
              )}
            </MenuItem>
          ))}
      </Menu>
      {loading && (
        <Chip
          className="ml-3"
          label={"Fetching results"}
          icon={<FontAwesomeIcon icon="spinner-third" spin />}
          size="small"
        />
      )}
      {!loading && error && duration !== undefined && (
        <Chip className="ml-3" color="error" label={`${duration / 1000} seconds`} size="small" />
      )}
      {!loading && !error && duration !== undefined && (
        <Chip
          className="ml-3"
          label={
            <>
              {`${!!resultCount ? `${formatNumber(resultCount)} results in ` : ""}${formatNumber(
                duration / 1000,
                "0,0.000",
              )} seconds`}
              {acl.check({ action: "seeQueryDelayAndCacheInfo" }).granted && (
                <>
                  {delay >= 100 && (
                    <Tooltip title={`${formatNumber(delay / 1000, "0,0.000")} seconds in queue`}>
                      <span className="ml-2">
                        <FontAwesomeIcon icon="hourglass-clock" fixedWidth />
                      </span>
                    </Tooltip>
                  )}
                  {cache === "HIT" && (
                    <Tooltip title="Cache hit">
                      <span className="ml-2">
                        <FontAwesomeIcon icon={["fas", "database"]} fixedWidth />
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          }
          size="small"
        />
      )}
      <div id={visualizationActionsCtrId} className={`ml-3 ${styles.visualizationResultsActions}`}>
        <MemoizedDownload
          data={data}
          downloads={downloads}
          currentVisualization={currentVisualization}
          queryName={queryName}
          rawData={rawData}
        />
      </div>
      <DocumentationDialog visualization={currentVisualization} />
    </div>
  );
};

const VisualizationSelector = React.memo(_VisualizationSelector);

export default VisualizationSelector;

function sparqlResponse2csv(data: SparqlResponse): string {
  if (isSelectResponse(data) || isAskResponse(data)) {
    if ("boolean" in data) return `boolean\n${data.boolean}\n`;
    return serializeCSV(
      data.results.bindings.map((binding) => {
        const rootBinding: { [key: string]: string } = {};
        for (const property in binding) {
          const term = binding[property];
          if (term != undefined) {
            rootBinding[property] = (term.type === "bnode" ? "_:" : "") + term.value;
          }
        }
        return rootBinding;
      }),
      { columns: data.head.vars },
    );
  } else {
    return serializeCSV(
      data.map((row) => {
        return {
          subject: row.subject.value,
          object: row.object.value,
          predicate: row.predicate.value,
        };
      }),
    );
  }
}

const Download: React.FC<{
  data: SparqlResponse | undefined;
  rawData: RawResponse | undefined;
  downloads?: DownloadVisualization[];
  currentVisualization: VisualizationLabel;
  queryName: string;
}> = ({ data, rawData, downloads, currentVisualization, queryName }) => {
  const downloadButtonAnchor = React.useRef<HTMLButtonElement>(null);
  const [downloadVisualizationMenuOpen, setDownloadVisualizationMenuOpen] = React.useState(false);
  const [csvResult, rawResult] = React.useMemo(() => {
    if (data === undefined) return [undefined, undefined];
    // Note potential memory leak, as we might need to call revokeObjectUrl
    const csvResult = window.URL.createObjectURL(new Blob([sparqlResponse2csv(data)], { type: "text/csv" }));
    const rawResult = window.URL.createObjectURL(
      typeof rawData === "string"
        ? new Blob([rawData], { type: "application/n-triples" })
        : new Blob([JSON.stringify(rawData, null, 2)], { type: "application/json" }),
    );
    return [csvResult, rawResult];
  }, [data, rawData]);

  const menuId = React.useId();

  if (!data) return null;

  return (
    <>
      <Button
        ref={downloadButtonAnchor}
        aria-controls={menuId}
        aria-expanded={downloadVisualizationMenuOpen ? "true" : "false"}
        onClick={() => {
          setDownloadVisualizationMenuOpen(true);
        }}
        className="my-2 mx-3"
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={"file-export"} />}
        endIcon={<FontAwesomeIcon icon={["fas", downloadVisualizationMenuOpen ? "caret-up" : "caret-down"]} />}
      >
        Export
      </Button>
      <Menu
        id={menuId}
        open={downloadVisualizationMenuOpen}
        onClose={() => setDownloadVisualizationMenuOpen(false)}
        anchorEl={downloadButtonAnchor.current}
        className={styles.downloadList}
        disablePortal
        MenuListProps={{
          dense: true,
        }}
      >
        <ListSubheader className={styles.downloadListHeader}>Results</ListSubheader>
        <MenuItem className={styles.downloadListItem} href={csvResult} download={`${queryName}.csv`} component="a">
          CSV
        </MenuItem>
        <MenuItem
          className={styles.downloadListItem}
          href={rawResult}
          download={`${queryName}.${typeof rawData === "string" ? "nt" : "json"}`}
          component="a"
        >
          Response
        </MenuItem>
        {canCreateShapeFile(data) && (
          <MenuItem
            onClick={() => downloadSparqlResponseAsShapefile(data, queryName)}
            className={styles.downloadListItem}
          >
            Shapefile
          </MenuItem>
        )}
        {downloads &&
          downloads.length > 0 && [
            <Divider key={"visualization-divider"} />,
            <ListSubheader key={"visualization-header"} className={styles.downloadListHeader}>
              {currentVisualization}
            </ListSubheader>,

            ...downloads.map((d) => {
              return (
                <MenuItem
                  key={d.id}
                  className={styles.downloadListItem}
                  component="a"
                  onClick={async () => {
                    const link = document.createElement("a");
                    link.href = await d.getDataUrl();
                    link.setAttribute("download", `${queryName}.${d.extension}`);
                    link.click();
                    link.remove();
                  }}
                >
                  {d.displayName}
                </MenuItem>
              );
            }),
            ,
          ]}
      </Menu>
    </>
  );
};

const MemoizedDownload = React.memo(Download);
