import getClassName from "classnames";
import * as React from "react";
import * as styles from "./style.scss";

export type Color = "red" | "default" | "green" | "flash-green" | "flash-orange" | "orange";
const Circle: React.FC<{
  color?: Color;
  className?: string;
}> = ({ color, className }) => {
  const enabledClasses = {
    [className || ""]: !!className,
    [styles.circle]: !!styles.circle,
    [styles.red]: color === "red",
    [styles.green]: color === "green",
    [styles.flashGreen]: color === "flash-green",
    [styles.flashOrange]: color === "flash-orange",
    [styles.orange]: color === "orange",
  };
  return <div className={getClassName(enabledClasses)} />;
};

export default Circle;
