import { Checkbox, CheckboxProps } from "@mui/material";
import { assign, omit, pickBy } from "lodash-es";
import * as React from "react";
import * as ReduxForm from "redux-form";

class MaterialCheckbox extends React.PureComponent<
  MaterialCheckbox.Props & ReduxForm.WrappedFieldProps,
  MaterialCheckbox.State
> {
  adjustOwnProps(): CheckboxProps {
    //map redux props to material-ui props
    const props = assign<{}, CheckboxProps, Partial<CheckboxProps>>(
      {},
      this.props,
      omit(this.props.input, ["onChange", "value"]),
    );

    props.checked = this.props.input.value ? true : false;
    props.onChange = this.props.input.onChange;

    return pickBy(props, (_val, key) => {
      if (["input", "meta"].indexOf(key) >= 0) return false;
      if (key.substring(0, 2) === "t_") return false;
      return true;
    });
  }

  render() {
    return (
      <div>
        <Checkbox
          color="primary"
          {...this.adjustOwnProps()}
          style={{
            fontSize: "inherit",
          }}
        />
      </div>
    );
  }
}
namespace MaterialCheckbox {
  export const Field: new () => React.Component<Props> = ReduxForm.Field as any;
  export interface Props extends CheckboxProps {
    label?: string;
    name: string;
    component: any;
  }
  export interface State {}
}
export default MaterialCheckbox;
