import getClassName from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { asyncConnect } from "redux-connect";
import { Dataset, RedirectRule } from "@triply/utils/Models.js";
import { RedirectForm } from "#components/Forms/index.ts";
import { Button, ErrorPage, FlexContainer, Label, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { AclContext } from "#context.ts";
import { Acl } from "#helpers/Acl.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { getRedirects, needToFetchRedirects, putRedirects } from "#reducers/redirects.ts";
import { getConstructUrlToApi, urlInfoToString } from "#staticConfig.ts";
import ReadOnlyRule from "./ReadOnlyRule/index.tsx";
import * as styles from "./style.scss";

export namespace AdminRedirects {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    putRedirects: DispatchedFn<typeof putRedirects>;
  }
  export interface PropsFromState {
    redirects: RedirectRule[];
    datasetsUrl?: string;
    baseUrl?: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
  export interface State {
    editingRuleId?: string;
    addingRule: boolean;
  }
}

@asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (needToFetchRedirects(getState())) {
        return dispatch<any>(getRedirects());
      }
    },
  },
])
class AdminRedirects extends React.PureComponent<AdminRedirects.Props, AdminRedirects.State> {
  static contextType = AclContext;
  context!: Acl;
  state: AdminRedirects.State = {
    editingRuleId: undefined,
    addingRule: false,
  };

  hideForm = () => {
    this.setState({ editingRuleId: undefined, addingRule: false });
  };

  showEditForm = (ruleId: string) => {
    this.setState({ editingRuleId: ruleId, addingRule: false });
  };
  showAddForm = () => {
    this.setState({ editingRuleId: undefined, addingRule: true });
  };
  setNewRules = (rules: RedirectRule[]) => {
    this.hideForm();
    this.props.putRedirects(rules).catch(() => {});
  };

  submitNew = (rule: RedirectForm.FormData) => {
    const parsedRule: RedirectRule = {
      id: rule.id,
      match: rule.match,
      matchingMethod: rule.matchingMethod,
      toDataset: rule.toDataset,
    };
    this.setNewRules([parsedRule, ...this.props.redirects]);
  };

  submitEdit = (changedRule: RedirectForm.FormData) => {
    this.setNewRules(
      this.props.redirects.map((rule) => (rule.id === changedRule.id ? { ...rule, ...changedRule } : rule)),
    );
  };

  submitDelete = (toRemoveId: string) => {
    this.setNewRules(this.props.redirects.filter((rule) => rule.id !== toRemoveId));
  };

  handleSortEnd = ({ oldIndex, newIndex }: any) => {
    // Don't do anything when nothing will change
    if (oldIndex === newIndex) return;
    const newRules = [...this.props.redirects];
    newRules.splice(newIndex, 0, newRules.splice(oldIndex, 1)[0]);
    this.setNewRules(newRules);
  };

  getAdjustedRule = (rule: RedirectRule): RedirectForm.FormData => {
    const dataset = rule.toDataset as Dataset;
    return {
      ...rule,
      toDataset: dataset,
      datasetName: dataset.owner.accountName + " / " + dataset.name,
    };
  };

  renderForm = () => {
    const rule = this.props.redirects.find((r) => r.id === this.state.editingRuleId);
    if (!this.props.datasetsUrl) return <></>;
    return (
      <RedirectForm
        //make sure the form is scoped to this redirect, to avoid race conditions
        //and accidentally re-using a form from a different redirect
        ruleId={this.state.editingRuleId || ""}
        cancelFunction={this.hideForm}
        datasetsUrl={this.props.datasetsUrl}
        updating={!this.state.addingRule}
        onSubmit={this.state.addingRule ? this.submitNew : this.submitEdit}
        initialValues={rule ? this.getAdjustedRule(rule) : { matchingMethod: "prefix" }}
      />
    );
  };
  getExampleLink = (link: string) => {
    return (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {link}
      </a>
    );
  };

  renderSortableList = () => {
    if (!this.props.redirects || !this.props.redirects.length) return;
    const SortableItem = SortableElement<{ rule: RedirectRule }>((item: { rule: RedirectRule }) => {
      return (
        <ReadOnlyRule
          key={`readonly-${item.rule.id}`}
          rule={this.getAdjustedRule(item.rule)}
          onEdit={this.showEditForm}
          onDelete={this.submitDelete}
        />
      );
    });
    const SortableList = SortableContainer<{ items: RedirectRule[] }>((args: { items: RedirectRule[] }) => (
      <div>
        {args.items.map((rule: RedirectRule, index: any) => (
          <SortableItem key={`sortable-${JSON.stringify(rule)}`} index={index} rule={rule} />
        ))}
      </div>
    ));

    return (
      <SortableList
        items={this.props.redirects}
        onSortEnd={this.handleSortEnd}
        helperClass={styles.cursorOverride}
        useDragHandle
      />
    );
  };
  shouldRenderForm() {
    return this.state.addingRule || !!this.state.editingRuleId;
  }

  render() {
    if (!this.context.check({ action: "manageRedirects" }).granted) return <ErrorPage statusCode={401} />;
    return (
      <div>
        <Meta currentPath={this.props.location.pathname} title="Redirects - Admin settings" />

        <FlexContainer className="mt-5">
          <div className={"whiteSink"}>
            <h4>Redirects</h4>
            <p>
              Redirects enable easy dereferencing of resources. For example, you can dereference a resource{" "}
              {this.getExampleLink("https://example.org/resource/Amsterdam")} into dataset{" "}
              {this.getExampleLink(`${this.props.baseUrl}/MyAccount/MyDataset`)} by following these steps:
            </p>
            <ol>
              <li>
                Update the webserver of {this.getExampleLink("https://example.org")} to redirect all subpaths of{" "}
                <code>/resource</code> to {this.getExampleLink(`${this.props.baseUrl}/redirect/$requestUri`)}.
              </li>
              <li>
                Add rules below to map incoming IRIs to datasets. For example, the prefix{" "}
                {this.getExampleLink("https://example.org/resource/City/")} could be mapped to dataset{" "}
                {this.getExampleLink(`${this.props.baseUrl}/myAccount/myCities`)}, and the prefix{" "}
                {this.getExampleLink("https://example.org/resource/Country/")} could be mapped to dataset{" "}
                {this.getExampleLink(`${this.props.baseUrl}/myAccount/myCountries`)}. Mapping rules are evaluated from
                top (highest priority) to bottom (lowest priority).
              </li>
            </ol>
            <p>Two types of mapping rules are supported:</p>
            <ul>
              <li className="flex">
                <div className={getClassName("flex", styles ? styles.redirectLabel : undefined)}>
                  <Label primary>Prefix</Label>
                </div>{" "}
                Prefix rules trigger when the start of a resource matches the specified string.
              </li>
              <li className="flex">
                <div className={getClassName("flex", styles ? styles.redirectLabel : undefined)}>
                  <Label error>Regex</Label>
                </div>{" "}
                Regular Expression rules trigger when a resource matches a Regular Expression.
              </li>
            </ul>
            <div>
              <Button color="secondary" title="Add redirection rule" onClick={this.showAddForm}>
                Add rule
              </Button>
            </div>
            {this.renderSortableList()}
          </div>
          {this.shouldRenderForm() && this.renderForm()}
        </FlexContainer>
      </div>
    );
  }
}

export default connect<
  AdminRedirects.PropsFromState,
  { [K in keyof AdminRedirects.DispatchProps]: any },
  AdminRedirects.OwnProps,
  GlobalState
>(
  (state, _ownProps): AdminRedirects.PropsFromState => {
    return {
      datasetsUrl:
        state.config.staticConfig &&
        getConstructUrlToApi({
          consoleUrlInfo: state.config.staticConfig.consoleUrl,
          apiUrlInfo: state.config.staticConfig.apiUrl,
        })({ pathname: "/datasets" }),
      redirects: state.redirects.list,
      baseUrl: state.config.staticConfig && urlInfoToString(state.config.staticConfig.apiUrl),
    };
  },
  // dispatch
  {
    putRedirects: putRedirects,
  },
)(AdminRedirects);
