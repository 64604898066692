import { Root } from "hast";
import rehypeParseClient from "rehype-dom-parse";
import rehypeStringifyClient from "rehype-dom-stringify";
import rehypeParseServer from "rehype-parse";
import rehypeRaw from "rehype-raw"; //Allow raw html, e.g. specifying raw html in markdown
import rehypeRemoveEmptyAttributes from "rehype-remove-empty-attribute"; //Removes attributes like class=""
import rehypeSanitize from "rehype-sanitize"; //Cleanup the html
import rehypeStringifyServer from "rehype-stringify";
import gfm from "remark-gfm";
import parseMarkdown from "remark-parse";
import remark2rehype from "remark-rehype"; //Convert markdown (remark) to hypertext (html, rehype)
import { Plugin, unified } from "unified";
import {
  applyPreloadToVideo,
  cleanupClasses,
  getSanitizeSchema,
  handleEmptyDocument,
  mermaidCodeblockToDiv,
  modifyAnchorTags,
  rehypeToPlainText,
  removeMermaidCodeblock,
  sanitizeStyles,
  wrapTable,
} from "./unifiedPlugins.ts";

const rehypeParse = __CLIENT__ ? rehypeParseClient : rehypeParseServer;
let rehypeStringify = __CLIENT__ ? rehypeStringifyClient : rehypeStringifyServer;

const sanitizeSchemaFull = getSanitizeSchema("fullHtml");
const sanitizeSchemaCompact = getSanitizeSchema("compactHtml");
const sanitizeSchemaText = getSanitizeSchema("text");

export interface MarkupConfig {
  openLinkInCurrentWindow: ((href: string) => boolean) | undefined;
  from: "markdown" | "html";
  to: "html" | "text";
  compact?: boolean;
}

export default function renderMarkup(markup: string | undefined, config: MarkupConfig) {
  if (!markup || !markup.trim().length) return "";
  const processor = unified();
  if (config.from === "markdown") {
    processor.use(parseMarkdown);
    processor.use(gfm);
    //Allowing dangerous html. This is fine, as we're sanitizing this ourselves
    processor.use(remark2rehype, { allowDangerousHtml: true });
    processor.use(rehypeRaw);
  } else {
    processor.use(rehypeParse);
  }
  if (config.to === "html") {
    processor.use(sanitizeStyles);
    processor.use(applyPreloadToVideo);
    if (config.compact) {
      processor.use(removeMermaidCodeblock);
    } else {
      processor.use(mermaidCodeblockToDiv);
    }
    processor.use(cleanupClasses, { compact: !!config.compact });
    processor.use(wrapTable, { compact: !!config.compact });

    if (config.compact) {
      processor.use(rehypeSanitize, sanitizeSchemaCompact);
    } else {
      processor.use(rehypeSanitize, sanitizeSchemaFull);
    }
    processor.use(modifyAnchorTags(config.openLinkInCurrentWindow));
    processor.use(rehypeRemoveEmptyAttributes);

    processor.use(handleEmptyDocument);
    processor.use(rehypeStringify);
  } else {
    //text
    processor.use(rehypeSanitize, sanitizeSchemaText);
    processor.use(handleEmptyDocument);
    processor.use(rehypeToPlainText);
  }

  const renderResult = processor.processSync(markup).toString();
  if (config.to === "text") {
    //Removing superfluous linebreaks on the rendered result. Could do this as a rehype plugin, but there are quite some caveats
    //there where it comes to which linebreaks to remove. The below is simple, and seems to work well for most (all?) cases
    return renderResult.replace(/\n\n/g, "\n");
  }
  return renderResult;
}
