import { max, min } from "lodash-es";
import * as React from "react";
import { lexicalToValue } from "@triplydb/recognized-datatypes/wkt.js";
import { TermLiteral, TermLiteralDefault } from "#components/index.ts";
import * as styles from "./style.scss";

export type Coords = [number, number];
export interface Svg {
  points: string;
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}
const wkt = [
  "https://triply.cc/wkt/multiPolygon",
  "https://triply.cc/wkt/polygon",
  "http://www.opengis.net/ont/geosparql#wktLiteral",
];

class TermLiteralWkt extends React.PureComponent<TermLiteral.Props, any> {
  static shouldRender(props: TermLiteral.Props) {
    return props.datatype && wkt.indexOf(props.datatype) >= 0;
  }
  getBorders(poly: Array<Coords>) {
    const x = poly.map((c) => c[0]);
    const y = poly.map((c) => c[1]);

    return {
      minX: min(x),
      minY: min(y),
      maxX: max(x),
      maxY: max(y),
    };
  }
  getPolySVG(poly: Array<Coords>): Svg | undefined {
    if (poly === undefined) {
      return undefined;
    }
    poly = poly.map((c) => [c[0], -c[1]] as Coords);
    const { minX, minY, maxX, maxY } = this.getBorders(poly);
    const points = poly.map((c) => c.join(", ")).join(" ");
    if (minX === undefined || minY === undefined || maxX === undefined || maxY === undefined) {
      return undefined;
    }
    return { points, minX, minY, maxX, maxY };
  }
  polygon(wkt: any) {
    //first array is outer poly, subsequent arrays are holes
    const svg = this.getPolySVG(wkt.coordinates[0]);
    if (!svg) return undefined;
    const width = svg.maxX - svg.minX;
    const height = svg.maxY - svg.minY;

    const svgProps: { viewBox: string; width?: number; height?: number } = {
      viewBox: `${svg.minX} ${svg.minY} ${width} ${height}`,
    };
    svgProps[width > height ? "width" : "height"] = 48;

    return (
      <div className={styles.svg}>
        <svg {...svgProps}>
          <polygon points={svg.points} />
        </svg>
      </div>
    );
  }
  multiPolygon(wkt: any) {
    const svgs: Array<Svg> = wkt.coordinates.map((poly: any) => this.getPolySVG(poly[0]));

    const minX = min(svgs.map((s) => s.minX));
    const minY = min(svgs.map((s) => s.minY));
    const maxX = max(svgs.map((s) => s.maxX));
    const maxY = max(svgs.map((s) => s.maxY));
    if (minX === undefined || minY === undefined || maxX === undefined || maxY === undefined) {
      return undefined;
    }
    const width = maxX - minX;
    const height = maxY - minY;

    const svgProps: { viewBox: string; width?: number; height?: number } = {
      viewBox: `${minX} ${minY} ${width} ${height}`,
    };
    svgProps[width > height ? "width" : "height"] = 48;

    return (
      <div className={styles.svg}>
        <svg {...svgProps}>
          {svgs.map((svg, k) => (
            <polygon key={k} points={svg.points} />
          ))}
        </svg>
      </div>
    );
  }
  renderImage() {
    let wkt;
    try {
      wkt = lexicalToValue(this.props.value);
    } catch {}
    if (!wkt) return;
    if (wkt.type === "Polygon") return this.polygon(wkt);
    if (wkt.type === "MultiPolygon") return this.multiPolygon(wkt);
  }

  render() {
    return (
      <div title={this.props.expanded ? this.props.datatype : ""}>
        {this.renderImage()}
        <TermLiteralDefault {...this.props} />
      </div>
    );
  }
}
export default TermLiteralWkt;
