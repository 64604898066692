import { produce } from "immer";
import { Action, Actions } from "#reducers/index.ts";

interface AssetUpload {
  requestId: string;
  fileName: string;
  progress: number;
  message?: string;
  fileSize?: number;
  failTime?: number;
}

export interface AssetUploadMap {
  [requestId: string]: AssetUpload;
}
export interface State {
  [datasetId: string]: AssetUploadMap;
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.POST_ASSET_FAIL:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = {};

        draftState[action.forDataset.id][action.requestId] = {
          ...draftState[action.forDataset.id][action.requestId],
          requestId: action.requestId,
          message: action.message,
          failTime: action.failTime,
        };
        if (action.file) {
          draftState[action.forDataset.id][action.requestId].fileName = action.file.name;
          draftState[action.forDataset.id][action.requestId].fileSize = action.file.size;
        }
        return;

      case Actions.ASSET_UPLOAD_PROGRESS:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = {};

        if (draftState[action.forDataset.id][action.requestId]) {
          draftState[action.forDataset.id][action.requestId].progress = action.uploadProgress;
        }
        return;
      case Actions.POST_ASSET:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = {};

        draftState[action.forDataset.id][action.requestId] = {
          requestId: action.requestId,
          fileName: action.fileName,
          fileSize: action.fileSize,
          progress: 0,
        };
        return;

      case Actions.POST_ASSET_SUCCESS:
      case Actions.CANCEL_ASSET_UPLOAD:
      case Actions.ADD_ASSET_VERSION_SUCCESS:
        if (!draftState[action.forDataset.id]) draftState[action.forDataset.id] = {};

        delete draftState[action.forDataset.id][action.requestId];
        return;

      case Actions.CANCEL_ALL_ASSET_UPLOADS:
        draftState[action.forDataset.id] = {};
        return;
    }
  },
  <State>{},
);
