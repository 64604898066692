import { InputAdornment } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  Alert,
  FontAwesomeIcon,
  ForgotPasswordLink,
  FormField,
  Label,
  MuiPasswordFieldRedux,
  MuiTextFieldRedux,
} from "#components/index.ts";
import { NewPasswordProps } from "#components/MuiPasswordField/index.tsx";

namespace ResetPasswordForm {
  export interface FormData {
    password: string;
    currentPassword?: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    askForCurrentPw?: boolean;
    submitBehavior: "resetForm" | "lockForm";
    email?: string;
  }
  export interface State {
    myRef: HTMLFormElement | null;
    isPasswordValid: Boolean;
  }
}

const passwordValidator = validation.toStringValidator(validation.passwordValidations);
const ResetPasswordForm = ReduxForm.reduxForm<ResetPasswordForm.FormData, ResetPasswordForm.Props>({
  form: "resetPassword",
  validate: memoizee(
    (formData: ResetPasswordForm.FormData, props: ResetPasswordForm.Props) => {
      return {
        password: passwordValidator(formData.password),
        currentPassword: props.askForCurrentPw ? passwordValidator(formData.currentPassword) : undefined,
      };
    },
    { max: 10 },
  ),
})(
  class ResetPasswordForm extends React.PureComponent<ResetPasswordForm.Props, ResetPasswordForm.State> {
    constructor(props: ResetPasswordForm.Props) {
      super(props);
      this.state = {
        myRef: null,
        isPasswordValid: false,
      };
    }
    onSubmit = (values: any) => {
      const result = this.props.handleSubmit?.(values);

      if (this.props.submitBehavior === "lockForm") return;
      if (result.then) {
        // r is a Promise iff client-side validations passed and a API request was sent
        result.then((response?: { _error: string }) => {
          // d is an {_error:string} object iff the server rejected the pw change
          if (response && response._error) return;

          ["password", "currentPassword"].map((field: string) => {
            this.props.change?.(field, "");
            this.props.untouch?.(field);
          });
        });
      }
    };

    setIsPasswordValid = (boolean: boolean) => {
      this.setState({ isPasswordValid: boolean });
    };

    render() {
      const { askForCurrentPw, pristine, submitting, submitSucceeded, error, email, valid, submitBehavior } =
        this.props;

      return (
        <div>
          <form method="POST" onSubmit={this.onSubmit} className="mt-6">
            {askForCurrentPw && (
              <FormField label="Current password" className="mb-5">
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="currentPassword"
                  props={{
                    autoComplete: "current-password",
                    disabled: submitBehavior === "lockForm" && (submitting || submitSucceeded),
                    fullWidth: true,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon="lock" />
                        </InputAdornment>
                      ),
                    },
                  }}
                  component={MuiPasswordFieldRedux}
                />
              </FormField>
            )}
            <FormField label="New password" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props & NewPasswordProps>>
                name="password"
                props={{
                  autoComplete: "new-password",
                  fullWidth: true,
                  disabled: submitBehavior === "lockForm" && (submitting || submitSucceeded),
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon="lock" />
                      </InputAdornment>
                    ),
                  },
                  isNewPasswordField: true,
                  setIsPasswordValid: (boolean) => this.setIsPasswordValid(boolean),
                }}
                component={MuiPasswordFieldRedux}
              />
            </FormField>
            {askForCurrentPw && (
              <div className="mb-5">
                <ForgotPasswordLink email={email} />
              </div>
            )}
            <Alert transparent message={error} className="mb-5" />
          </form>
          <div className="form-group">
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={
                pristine || !valid || !this.state.isPasswordValid || (submitBehavior === "lockForm" && submitSucceeded)
              }
              onClick={this.onSubmit}
              loading={submitting}
            >
              Set password
            </LoadingButton>
          </div>
          {submitBehavior === "resetForm" && submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="You have successfully updated your password." />
            </div>
          )}
        </div>
      );
    }
  } as any,
);

export default ResetPasswordForm;
