import { Pagination } from "@mui/material";
import { sortBy } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import { CachePolicies } from "use-http";
import { Models } from "@triply/utils";
import { SERVICE_GRAPHS_PAGE_SIZE } from "@triply/utils/Constants.js";
import { SocketEvent } from "@triply/utils/SocketEvents.js";
import { Alert, FontAwesomeIcon } from "#components/index.ts";
import LinkButton from "#components/LinkButton/index.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import useWebSocket from "#helpers/hooks/useWebSocket.ts";
import { ensureTrailingDot, stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

function getStatusLogo(info: Models.ServiceGraphInfo) {
  switch (info.status) {
    case "error":
      return <FontAwesomeIcon icon="exclamation-triangle" fixedWidth />;
    case "loading":
      return <FontAwesomeIcon icon={["fas", "cog"]} spin fixedWidth />;
    case "loaded":
      return <FontAwesomeIcon icon="check" fixedWidth />;
    case "notLoaded":
      return <FontAwesomeIcon icon="hourglass" fixedWidth />;
    default:
      return null;
  }
}

export const ServiceGraphs: React.FC<{
  consoleDatasetPath: string;
  apiServicePath: string;
  datasetId: string;
  serviceId: string;
  numberOfGraphs: number;
}> = ({ serviceId, consoleDatasetPath, apiServicePath, datasetId, numberOfGraphs }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const [page, setPage] = React.useState(1);
  const { error, loading, data, get } = useFetch<Models.ServiceGraphInfo[]>(
    constructUrlToApi({ pathname: `${apiServicePath}/graphs`, query: { page: page.toString() } }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
      onNewData: (_, newGraphs) => {
        setGraphs(newGraphs);
      },
    },
    [page],
  );
  const [graphs, setGraphs] = React.useState<Models.ServiceGraphInfo[] | undefined>(data);
  const { subscribe, unsubscribe } = useWebSocket();
  const graphsLoaded = graphs !== undefined;

  React.useEffect(() => {
    const listener = (event: SocketEvent) => {
      if (!graphsLoaded || event.eventType !== "serviceGraphUpdate") return;
      if (
        event.graphUpdate.type === "finished" ||
        event.graphUpdate.type === "add" ||
        event.graphUpdate.type === "remove"
      ) {
        get().catch(() => {});
        return;
      }

      setGraphs((graphs) => {
        if (!graphs) return;

        const graphUpdate = event.graphUpdate;
        switch (graphUpdate.type) {
          case "rename": {
            return graphs.map((graph) => {
              const rename = graphUpdate.graphs.find((g) => g.fromName === graph.graphName);
              if (rename) {
                return { ...graph, graphName: rename.toName };
              } else {
                return graph;
              }
            });
          }
          case "progress": {
            return graphs.map((graph) => {
              const progressGraph = graphUpdate.graphs.find((g) => g.graphName === graph.graphName);
              if (progressGraph) {
                return { ...graph, ...progressGraph };
              } else {
                return graph;
              }
            });
          }
        }

        return graphs;
      });
    };
    subscribe(`/datasets/${datasetId}`, `/services/${serviceId}/graphUpdate`, listener);

    return () => {
      unsubscribe(`/datasets/${datasetId}`, `/services/${serviceId}/graphUpdate`, listener);
    };
  }, [graphsLoaded, datasetId, serviceId, subscribe, unsubscribe, get]);

  return (
    <>
      {!!error && (
        <>
          Error loading graphs. <LinkButton onClickOrEnter={() => get()}>Try again</LinkButton>
        </>
      )}
      {!!graphs && (
        <div className="mt-2 mx-3">
          {sortBy(graphs).map((info) => {
            return (
              <React.Fragment key={info.graphName}>
                <div className={"flex"}>
                  <div className={styles.graphIcon} aria-label={info.status}>
                    {getStatusLogo(info)}
                  </div>
                  <Link
                    to={{
                      pathname: `${consoleDatasetPath}/table`,
                      search: stringifyQuery({
                        graph: info.graphName,
                      }),
                    }}
                  >
                    {info.graphName}
                  </Link>
                </div>
                {info.status === "error" && (
                  <div className={styles.graphError}>
                    <Alert
                      hideIcon
                      size="sm"
                      warning
                      transparent
                      message={ensureTrailingDot(info.error || "An unknown error occurred while loading this file.")}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
      <div className="flex center">
        {!!graphs && numberOfGraphs > SERVICE_GRAPHS_PAGE_SIZE && (
          <Pagination
            page={page}
            onChange={(_, value) => setPage(value)}
            count={Math.ceil(numberOfGraphs / SERVICE_GRAPHS_PAGE_SIZE)}
            disabled={loading}
            className="my-3"
          />
        )}
        {loading && <FontAwesomeIcon icon="spinner" pulse className="m-3" />}
      </div>
    </>
  );
};
