// extracted by mini-css-extract-plugin
var _1 = "style--box--UM4adZwr";
var _2 = "style--boxHighlighted--BCOFh3kr";
var _3 = "style--error--GROLUFN4";
var _4 = "style--inputField--Pbx2PQAg";
var _5 = "style--label--YnCwRrQk";
var _6 = "style--markdown--DBe5DR4h";
var _7 = "style--tab--RusrFnRa";
var _8 = "style--tabContent--H6xXYpbI";
var _9 = "style--tabPanel--PROjJVob";
var _a = "style--tabs--PJQX7e3f";
var _b = "style--tabsHighlighted--WWoqJSa7";
export { _1 as "box", _2 as "boxHighlighted", _3 as "error", _4 as "inputField", _5 as "label", _6 as "markdown", _7 as "tab", _8 as "tabContent", _9 as "tabPanel", _a as "tabs", _b as "tabsHighlighted" }
