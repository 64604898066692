import { Container, Paper } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import Abstract from "./Abstract";
import ClassInfo from "./ClassInfo";
import Description from "./Description";
import Instances from "./Instances";
import Iri from "./Iri";
import Label from "./Label";
import Links from "./Links";
import * as styles from "./styles/index.scss";

const Class: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  return (
    <Paper className={getClassName("p-5", styles.classPaper)}>
      {!!currentClass && (
        <Container className={getClassName("flex column g-5", styles.classContainer)}>
          <div>
            <Label />
            <Iri />
          </div>
          <Abstract />
          <Description />
          <ClassInfo />
          <Links />
          <Instances />
        </Container>
      )}
    </Paper>
  );
};

export default Class;
