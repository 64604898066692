// extracted by mini-css-extract-plugin
var _1 = "tableStyle--cell--d6fKtxFt";
var _2 = "tableStyle--filterCell--EZN82qNJ";
var _3 = "tableStyle--header--_lVNAN3K";
var _4 = "tableStyle--noBorderBottom--qz5ymIhA";
var _5 = "tableStyle--noContentCell--pB3P3mJ5";
var _6 = "tableStyle--paginationOptions--iAKaRqAH";
var _7 = "tableStyle--slider--lrJgVchy";
var _8 = "tableStyle--sorting--ilRm8hZI";
var _9 = "tableStyle--space--smjtMjE1";
var _a = "tableStyle--subComponentCell--QFMOAZfc";
var _b = "tableStyle--tableContainer--MZTiETAt";
var _c = "tableStyle--tablePaper--YGtXMzVk";
export { _1 as "cell", _2 as "filterCell", _3 as "header", _4 as "noBorderBottom", _5 as "noContentCell", _6 as "paginationOptions", _7 as "slider", _8 as "sorting", _9 as "space", _a as "subComponentCell", _b as "tableContainer", _c as "tablePaper" }
