import { createTheme } from "@mui/material";

const localTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          width: "fit-content",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
        sx: {
          width: 350,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          width: "fit-content",
        },
      },
    },
  },
});

export default localTheme;
