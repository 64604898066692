// extracted by mini-css-extract-plugin
var _1 = "Paragraph--bottom--rykqJc_7";
var _2 = "Paragraph--constrainWidth--tqJeWMmd";
var _3 = "Paragraph--container--ryRbi_qF";
var _4 = "Paragraph--content--G0uUDclh";
var _5 = "Paragraph--editIcon--SlRbyqzZ";
var _6 = "Paragraph--fabWrapper--MoRM9Hzw";
var _7 = "Paragraph--lncs--aXmgxPwr";
var _8 = "Paragraph--markdown--R7fDHoLW";
var _9 = "Paragraph--paragraph--x_74DKJ2";
var _a = "Paragraph--story--bLw2UX3z";
var _b = "Paragraph--top--iefUSryU";
export { _1 as "bottom", _2 as "constrainWidth", _3 as "container", _4 as "content", _5 as "editIcon", _6 as "fabWrapper", _7 as "lncs", _8 as "markdown", _9 as "paragraph", _a as "story", _b as "top" }
