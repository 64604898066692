import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { matchRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import {
  AccountListItem,
  DatasetListItem,
  FlexContainer,
  Meta,
  QueryListItem,
  SearchField,
  SinkList,
  StoryListItem,
} from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import BasicTree from "#helpers/BasicTree.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import { Account } from "#reducers/accountCollection.ts";
import { getAccountList, getAccounts, isAccountListLoaded } from "#reducers/accounts.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import {
  Dataset,
  getDatasetList,
  getDatasetsWithLink,
  isDatasetsListLoadedForQuery,
  searchDatasets,
} from "#reducers/datasetManagement.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import { getQueries, getQueryList, isQueryListLoaded, Query } from "#reducers/queries.ts";
import { getStories, getStoryList, isStoryListLoaded, Story } from "#reducers/stories.ts";
import { areTopicsLoaded, getTopics, Topic } from "#reducers/topics.ts";
import * as styles from "./style.scss";

export type SearchCategory = "datasets" | "accounts" | "queries" | "stories";
export type PathBasename = SearchCategory | "search" | "error";

function getSearchTerm(queryString: string) {
  const query = parseSearchString(queryString);
  return typeof query.q === "string" && !!query.q ? query.q : undefined;
}
export namespace Search {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    getDatasetsWithLink: DispatchedFn<typeof getDatasetsWithLink>;
    getAccounts: DispatchedFn<typeof getAccounts>;
    getQueries: DispatchedFn<typeof getQueries>;
    getStories: DispatchedFn<typeof getStories>;
    pushState: typeof connectedReactRouter.push;
  }
  export interface PropsFromState {
    datasetResults: Dataset[];
    accountResults: Account[];
    queryResults: Query[];
    storyResults: Story[];
    fetchingList: boolean;
    fetchingDatasetsError?: string;
    fetchingAccountsError?: string;
    topics?: Topic[];
    dsNextPage?: string;
    accNextPage?: string;
    qNextPage?: string;
    storiesNextPage?: string;
    authenticatedUser?: Account;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
  export interface State {
    locationQueryTerm: string;
    pathBasename: PathBasename;
  }
}

@asyncConnect<GlobalState>([
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const state = getState();
      const query = parseSearchString(location.search);
      if (query?.q && !isAccountListLoaded(state, location.search)) {
        return dispatch<any>(getAccounts(undefined, query));
      }
    },
  },
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const state = getState();
      const query = parseSearchString(location.search);
      if (query?.q && !isDatasetsListLoadedForQuery(state, location.search)) {
        return dispatch<any>(searchDatasets(query));
      }
    },
  },
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const state = getState();
      const searchTerm = getSearchTerm(location.search);
      if (!isQueryListLoaded(state, { account: false, searchTerm: searchTerm })) {
        return dispatch<any>(getQueries(undefined, parseSearchString(location.search)));
      }
    },
  },
  {
    promise: ({ location, store: { dispatch, getState } }) => {
      const searchTerm = getSearchTerm(location.search);

      if (!isStoryListLoaded(getState(), { account: false, searchTerm: searchTerm })) {
        return dispatch<any>(getStories(undefined, parseSearchString(location.search)));
      }
    },
  },
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (!areTopicsLoaded(getState())) {
        return dispatch<any>(getTopics());
      }
    },
  },
])
class Search extends React.PureComponent<Search.Props, Search.State> {
  constructor(props: Search.Props) {
    super(props);
    this.state = this.constructStateObject(props.location);
  }

  constructStateObject(location: Location): Search.State {
    if (!this.props.route?.routes) throw new Error("Incomplete route information");
    const matches = matchRoutes(this.props.route.routes, location.pathname);
    const searchTerm = getSearchTerm(location.search);
    return {
      locationQueryTerm: searchTerm || "",
      pathBasename: matches.length > 0 ? (matches[0].route as any).pathBasename : "error",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Search.Props) {
    if (nextProps.location !== this.props.location) {
      this.setState(this.constructStateObject(nextProps.location));
    }
  }

  search = (term: string) => {
    this.props.pushState({
      pathname: "/search/" + this.state.pathBasename,
      search: stringifyQuery({ q: term.trim() }),
    });
  };

  renderTab(key: SearchCategory, nextPage: string | undefined, count: number) {
    const { location } = this.props;
    const { locationQueryTerm, pathBasename } = this.state;
    const overflowPlusSign = nextPage && count > 0 ? "+" : "";
    return (
      <Link
        to={`/search/${key}${location?.search ? location.search : ""}`}
        className={getClassName(styles.tab, pathBasename === key ? styles.activeTab : "")}
      >
        {key.charAt(0).toUpperCase() + key.slice(1, key.length)}
        {pathBasename !== "search" && locationQueryTerm !== "" && ` (${count + "" + overflowPlusSign})`}
      </Link>
    );
  }

  loadNextDsPage = () => {
    this.props.dsNextPage && this.props.getDatasetsWithLink(this.props.dsNextPage).catch(() => {});
  };

  loadNextAccountsPage = () => {
    this.props.getAccounts(this.props.accNextPage).catch(() => {});
  };

  loadNextQueriesPage = () => {
    this.props.getQueries(undefined, undefined, this.props.qNextPage).catch(() => {});
  };

  loadNextStoriesPage = () => {
    this.props.getStories(undefined, undefined, this.props.storiesNextPage).catch(() => {});
  };

  render() {
    const {
      datasetResults,
      accountResults,
      queryResults,
      storyResults,
      dsNextPage,
      accNextPage,
      qNextPage,
      storiesNextPage,
      topics,
      fetchingList,
      fetchingAccountsError,
      fetchingDatasetsError,
    } = this.props;

    const { locationQueryTerm, pathBasename } = this.state;

    // by default, all results are listed. when there is no query, we don't want to show results.
    // when there is a topic, we have a query for datasets, but topics don't apply for accounts or queries.
    // so to determine whether there is an accounts/queries query, we consider the query without any topic part.
    const topicOnlySearch = locationQueryTerm.replace(/topic:([^\s]+)/, "").trim() === "";

    // redirect from /search?q=someQuery to /search/datasets?q=someQuery
    if (locationQueryTerm && pathBasename === "search") {
      return <Redirect to={"/search/datasets?q=" + locationQueryTerm} />;
    }

    // redirect to /search when the path does not match with anything
    if (pathBasename === "error") {
      return <Redirect to={"/search?q=" + locationQueryTerm} />;
    }

    return (
      <>
        <Meta currentPath={this.props.location.pathname} title={locationQueryTerm || "Search"} />

        <div className={getClassName(styles.searchBanner, { [styles.onSearchPage]: pathBasename === "search" })}>
          <div
            className={getClassName(styles.outerSearchContainer, { [styles.onSearchPage]: pathBasename === "search" })}
          >
            <div className={getClassName("my-7 px-1", styles.innerSearchContainer)}>
              <SearchField
                search={this.search}
                searching={fetchingList}
                initialSearchTerm={locationQueryTerm}
                topics={BasicTree.fromArray(topics || [], "iri")}
                ariaLabel="Sitewide"
                placeHolder="Search ..."
                autoFocus
              />
            </div>
          </div>

          {pathBasename !== "search" && (
            <FlexContainer innerClassName={getClassName(styles.tabContainer)}>
              {this.renderTab("datasets", dsNextPage, datasetResults?.length || 0)}
              {this.renderTab("stories", storiesNextPage, (!topicOnlySearch && storyResults?.length) || 0)}
              {this.renderTab("queries", qNextPage, (!topicOnlySearch && queryResults?.length) || 0)}
              {this.renderTab("accounts", accNextPage, (!topicOnlySearch && accountResults?.length) || 0)}
            </FlexContainer>
          )}
        </div>

        {pathBasename !== "search" && locationQueryTerm !== "" && (
          <FlexContainer className="mt-3" innerClassName={styles.searchResults}>
            {pathBasename === "datasets" && (
              <SinkList
                loadNextPage={(!!dsNextPage && this.loadNextDsPage) || undefined}
                noContentMsg={"No datasets found"}
                fetchingListError={fetchingDatasetsError}
              >
                {datasetResults.map((ds) => (
                  <DatasetListItem key={ds.id} ds={ds} />
                ))}
              </SinkList>
            )}
            {pathBasename === "accounts" && !topicOnlySearch && (
              <SinkList
                loadNextPage={(!!accNextPage && this.loadNextAccountsPage) || undefined}
                noContentMsg={"No accounts found"}
                fetchingListError={fetchingAccountsError}
              >
                {accountResults.map((account) => (
                  <AccountListItem key={account.accountName} account={account} />
                ))}
              </SinkList>
            )}
            {pathBasename === "queries" && !topicOnlySearch && (
              <SinkList
                loadNextPage={(!!qNextPage && this.loadNextQueriesPage) || undefined}
                noContentMsg={"No queries found"}
              >
                {queryResults.map((query) => (
                  <QueryListItem key={query.id} query={query} showOwner />
                ))}
              </SinkList>
            )}
            {pathBasename === "stories" && !topicOnlySearch && (
              <SinkList
                loadNextPage={(!!storiesNextPage && this.loadNextStoriesPage) || undefined}
                noContentMsg={"No stories found"}
              >
                {storyResults.map((story) => (
                  <StoryListItem key={story.id} story={story} showOwner />
                ))}
              </SinkList>
            )}
          </FlexContainer>
        )}
      </>
    );
  }
}

export default connect<Search.PropsFromState, { [K in keyof Search.DispatchProps]: any }, Search.OwnProps, GlobalState>(
  (state, props) => {
    const loggedInUser = getLoggedInUser(state);
    const searchTerm = getSearchTerm(props.location.search) || "";
    return {
      datasetResults: getDatasetList(state, { account: false, searchTerm: searchTerm, admin: false }),
      accountResults: getAccountList(state),
      queryResults: getQueryList(state, { account: false, searchTerm: searchTerm }),
      storyResults: getStoryList(state, { account: false, searchTerm: searchTerm }),
      fetchingList: !!(state.datasetManagement.fetchingList || state.accounts.fetchingList),
      fetchingDatasetsError: state.datasetManagement.fetchingListError,
      fetchingAccountsError: state.accounts.fetchingListError,
      topics: state.topics.list,
      authenticatedUser: loggedInUser,
      dsNextPage: state.datasetManagement.nextPage,
      accNextPage: state.accounts.nextPage,
      qNextPage: state.queries.nextPage,
      storiesNextPage: state.stories.nextPage,
    };
  },
  //dispatch
  {
    getDatasetsWithLink,
    getAccounts,
    getQueries,
    getStories,
    pushState: connectedReactRouter.push,
  },
)(Search);
