// extracted by mini-css-extract-plugin
var _1 = "style--authForm--HKc6wrKB";
var _2 = "style--box--YyniC_oa";
var _3 = "style--container--B4I47nLY";
var _4 = "style--content--gqt0e5de";
var _5 = "style--errorText--GQB5LqdM";
var _6 = "style--label--OdptHmhv";
var _7 = "style--labelSubtle--bdT6hgdy";
var _8 = "style--large--LxwC47nc";
var _9 = "style--line--TeWIGpMp";
var _a = "style--openIdProviders--dahJLCPH";
var _b = "style--paper--AnIl923d";
var _c = "style--passwordSignUpDisabled--X6yx_6AA";
var _d = "style--recoverText--c4tZMLTy";
var _e = "style--recoveryCodeContainer--L0UveEGQ";
var _f = "style--separator--GxSKSILB";
var _10 = "style--small--T46x2ISs";
var _11 = "style--ssoDisabled--TEP4vU6W";
var _12 = "style--title--_RA87NDe";
var _13 = "style--wordwrapper--nUuk40uH";
export { _1 as "authForm", _2 as "box", _3 as "container", _4 as "content", _5 as "errorText", _6 as "label", _7 as "labelSubtle", _8 as "large", _9 as "line", _a as "openIdProviders", _b as "paper", _c as "passwordSignUpDisabled", _d as "recoverText", _e as "recoveryCodeContainer", _f as "separator", _10 as "small", _11 as "ssoDisabled", _12 as "title", _13 as "wordwrapper" }
