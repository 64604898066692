/**
 * This metadata object comes with sensible defaults for all pages that belong to a dataset
 */

import React from "react";
import { useSelector } from "react-redux";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { default as Meta, Props as MetaProps } from "./Meta.tsx";

export interface Props extends MetaProps {
  currentAccount: Account;
  currentDs: Dataset;
}

const DatasetMeta: React.FC<Props> = (props) => {
  const { currentDs, currentAccount, ...mainMetaProps } = props;
  const ownerName = currentDs.owner.name || currentDs.owner.accountName;
  const brandingName = useSelector((state: GlobalState) => state.config.clientConfig?.branding.name);
  const longTitle = `${currentDs.displayName || currentDs.name} by ${ownerName} on ${brandingName}`;
  const logoLg = useSelector((state: GlobalState) => state.config.clientConfig?.branding.logoLg);
  const imageUrl = currentDs.avatarUrl || currentAccount.avatarUrl || logoLg;
  const imageAlt = `Logo of ${currentDs.displayName || currentDs.name}`;

  const description =
    props.description ||
    currentDs.description ||
    `Dataset ${currentDs.displayName || currentDs.name} created by ${
      currentDs.owner.type === "org" ? "organization" : "user"
    } ${ownerName}.`;

  if (mainMetaProps.title) {
    mainMetaProps.title = `${mainMetaProps.title} - ${currentDs.displayName || currentDs.name} - ${
      currentDs.owner.accountName
    }`;
  } else {
    mainMetaProps.title = `${currentDs.displayName || currentDs.name} - ${currentDs.owner.accountName}`;
  }

  return (
    <Meta
      longTitle={longTitle}
      type="article"
      image={imageUrl}
      imageAlt={imageAlt}
      articleAuthor={ownerName}
      description={description}
      {...mainMetaProps}
    />
  );
};

export default DatasetMeta;
