import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { produce } from "immer";
import { Models } from "@triply/utils";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";

export const LocalActions = {
  ADD_HISTORY_ITEM: "triply/sessionHistory/ADD_HISTORY_ITEM",
} as const;

export type Type = "dataset" | "query" | "story";

type ADD_HISTORY_ITEM = GlobalAction<{
  type: typeof LocalActions.ADD_HISTORY_ITEM;
  historyItem: Item;
}>;

export type LocalAction = ADD_HISTORY_ITEM;
export interface BaseItem {
  id: string; // Only used for checking updates
  type: Type;
  name: string;
  displayName?: string;
  accountId: string; // Only used for checking updates
  accountName: string;
  accountDisplayName?: string;
}

export interface DatasetItem extends BaseItem {
  type: "dataset";
  avatarUrl?: string;
}
export interface QueryItem extends BaseItem {
  type: "query";
  icon: IconName;
}
export interface StoryItem extends BaseItem {
  type: "story";
  bannerUrl?: string;
}

export type Item = DatasetItem | QueryItem | StoryItem;
export type State = { list: Item[] };

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.ADD_HISTORY_ITEM:
        const positionInHistory = findInHistory(action.historyItem.id, action.historyItem.type, draftState.list);
        // Item is already on the list
        if (positionInHistory >= 0) {
          // remove the old item from the list, the new item will contain better data anyway
          draftState.list.splice(positionInHistory, 1);
        }
        // Add the new item to the front, and limit it to 10 max
        draftState.list = [action.historyItem, ...draftState.list].slice(0, 10);
        return;
      case Actions.DELETE_DATASET_SUCCESS:
        const datasetPosition = findInHistory(action.dataset.id, "dataset", draftState.list);
        if (datasetPosition >= 0) {
          // remove the old item from the list
          draftState.list.splice(datasetPosition, 1);
        }
        return;
      case Actions.DELETE_QUERY_SUCCESS:
        const queryPosition = findInHistory(action.query.id, "query", draftState.list);
        if (queryPosition >= 0) {
          // remove the old item from the list
          draftState.list.splice(queryPosition, 1);
        }
        return;
      case Actions.DELETE_STORY_SUCCESS:
        const storyPosition = findInHistory(action.story.id, "story", draftState.list);
        if (storyPosition >= 0) {
          // remove the old item from the list
          draftState.list.splice(storyPosition, 1);
        }
        return;
      case Actions.DELETE_ACCOUNT_SUCCESS:
        draftState.list = draftState.list.filter((item) => {
          return item.accountId !== action.account.uid;
        });
        return;
      case Actions.UPDATE_PROFILE_SUCCESS:
        draftState.list = draftState.list.map((item) => {
          if (item.accountId !== action.accountId) return item;
          item.accountName = action.result.accountName;
          item.accountDisplayName = action.result.name;
          return item;
        });
        return;
      case Actions.UPDATE_DATASET_SUCCESS:
      case Actions.UPLOAD_DATASET_AVATAR_SUCCESS:
        const datasetIndex = findInHistory(action.result.id, "dataset", draftState.list);
        if (datasetIndex >= 0) {
          draftState.list[datasetIndex] = datasetToItem(action.result);
        }
        return;
      case Actions.UPDATE_QUERY_SUCCESS:
        const queryIndex = findInHistory(action.result.id, "query", draftState.list);
        if (queryIndex >= 0) {
          draftState.list[queryIndex] = queryToItem(action.result);
        }
        return;
      case Actions.UPDATE_STORY_SUCCESS:
        const storyIndex = findInHistory(action.result.id, "story", draftState.list);
        if (storyIndex >= 0) {
          draftState.list[storyIndex] = storyToItem(action.result);
        }
        return;
    }

    return;
  },
  <State>{ list: [] },
);
function findInHistory(id: string, type: Type, draftList: Item[]) {
  return draftList.findIndex((item) => item.id === id && item.type === type);
}

function datasetToItem(dataset: Pick<Models.Dataset, "id" | "name" | "displayName" | "owner" | "avatarUrl">): Item {
  return {
    type: "dataset",
    id: dataset.id,
    name: dataset.name,
    displayName: dataset.displayName,
    accountId: dataset.owner.uid,
    accountName: dataset.owner.accountName,
    accountDisplayName: dataset.owner.name,
    avatarUrl: dataset.avatarUrl,
  };
}
function queryToItem(query: Models.Query): Item {
  return {
    type: "query",
    id: query.id,
    name: query.name,
    displayName: query.displayName,
    accountId: query.owner.uid,
    accountName: query.owner.accountName,
    accountDisplayName: query.owner.name,
    icon: getQueryIcon(query),
  };
}
function storyToItem(story: Pick<Models.Story, "id" | "name" | "displayName" | "owner" | "bannerUrl">): Item {
  return {
    type: "story",
    id: story.id,
    name: story.name,
    displayName: story.displayName,
    accountId: story.owner.uid,
    accountName: story.owner.accountName,
    accountDisplayName: story.owner.name,
    bannerUrl: story.bannerUrl,
  };
}

export function addDatasetHistoryItem(
  dataset: Pick<Models.Dataset, "id" | "name" | "displayName" | "owner" | "avatarUrl">,
): BeforeDispatch<ADD_HISTORY_ITEM> {
  return {
    type: Actions.ADD_HISTORY_ITEM,
    historyItem: datasetToItem(dataset),
  };
}
export function addQueryHistoryItem(query: Models.Query): BeforeDispatch<ADD_HISTORY_ITEM> {
  return {
    type: Actions.ADD_HISTORY_ITEM,
    historyItem: queryToItem(query),
  };
}
export function addStoryHistoryItem(
  story: Pick<Models.Story, "id" | "name" | "displayName" | "owner">,
): BeforeDispatch<ADD_HISTORY_ITEM> {
  return {
    type: Actions.ADD_HISTORY_ITEM,
    historyItem: storyToItem(story),
  };
}
