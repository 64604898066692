import { NoSsr, Skeleton } from "@mui/material";
import * as React from "react";
import { DatasetMetadata, ErrorPage } from "#components/index.ts";
import { KeyboardShortcut, useDisplayHotkeys } from "#containers/Hotkeys/index.tsx";
import { FooterContext } from "#containers/NavConsole/index.tsx";
import { useGraphqlEnabled } from "#helpers/hooks/useGraphQLEnabled.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";

const GraphQLIde = React.lazy(() => import("./GraphQLIde"));

const GraphQL: React.FC<{}> = ({}) => {
  const { hideFooter } = React.useContext(FooterContext);
  const graphqlEnabled = useGraphqlEnabled();
  const keyBindings: KeyboardShortcut[] = [
    { component: "GRAPHQL Editor", keyBinds: "Mod+shift+p", description: "Prettify query" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+Enter", description: "Execute query" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+d", description: "Select and add cursor to next occurrence" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+f", description: "Search in editor" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+L", description: "Select line" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+[", description: "Add indent" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+]", description: "Remove indent" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+Shift+k", description: "Delete current line" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+/", description: "Comment/Uncomment line" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+u", description: "Undo selection" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+z", description: "Undo" },
    { component: "GRAPHQL Editor", keyBinds: "Mod+shift+z, Mod+y", description: "Redo" },
  ];

  React.useEffect(() => {
    hideFooter(true);
    return () => hideFooter(false);
  }, [hideFooter]);

  useDisplayHotkeys(keyBindings);

  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();

  if (!graphqlEnabled) return <ErrorPage statusCode={404} />;

  if (!currentAccount || !currentDs) return null;

  return (
    <NoSsr>
      <DatasetMetadata currentAccount={currentAccount} currentDs={currentDs} title="GraphQL" />
      <React.Suspense fallback={<Skeleton height="inherit" variant="rectangular" />}>
        <GraphQLIde />
      </React.Suspense>
    </NoSsr>
  );
};

export default GraphQL;
