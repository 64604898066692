import { Alert, Skeleton, Typography } from "@mui/material";
import { groupBy, toPairs } from "lodash-es";
import * as React from "react";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { FontAwesomeIcon, Prefixed } from "../../components";

const Outlinks: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparql<[{ p: string; o: string }?]>(
    currentClass &&
      `
    select ?p ?o where {
      bind(<${currentClass}> as ?currentClass)
      ?currentClass ?p ?o.
    }
    group by ?p ?o
    limit 100
    `,
  );

  if (error) return <Alert severity="warning">Properties could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  if (!currentClass || !data || !data[0]) return null;

  const grouped = toPairs(groupBy(data, "p"));

  return (
    <div>
      <Typography variant="h6" component="h2">
        Links
      </Typography>
      <dl>
        {grouped.map(([predicate, objects]) => (
          <React.Fragment key={predicate}>
            <dt>
              <Prefixed>{predicate}</Prefixed>
            </dt>
            <dd>
              {objects.map((object, i) => {
                return (
                  <div key={"" + object?.o + i}>
                    <Prefixed>{object?.o || ""}</Prefixed>
                  </div>
                );
              })}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};

const Inlinks: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparql<[{ s: string; p: string }?]>(
    currentClass &&
      `
    prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    select ?s ?p where {
      bind(<${currentClass}> as ?currentClass)
      ?s ?p ?currentClass.
      filter(?p != rdf:type)
    }
    group by ?s ?p
    limit 100
    `,
  );

  if (error) return <Alert severity="warning">Inlinks could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  if (!data || !data[0]) return null;

  const grouped = toPairs(groupBy(data, "p"));

  return (
    <dl>
      {grouped.map(([predicate, objects]) => (
        <React.Fragment key={predicate}>
          <dt>
            <FontAwesomeIcon icon="arrow-left" className="mr-2" />
            <Prefixed>{predicate}</Prefixed>
          </dt>
          <dd>
            {objects.map((object, i) => {
              return (
                <div key={"" + object?.s + i}>
                  <Prefixed>{object?.s || ""}</Prefixed>
                </div>
              );
            })}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

const Links: React.FC<{}> = ({}) => {
  return (
    <div>
      <Outlinks />
      <Inlinks />
    </div>
  );
};

export default Links;
