import { InputAdornment, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import urlParse from "url-parse";
import { Prefix, prefixUtils } from "@triply/utils";
import { stringifyQuery } from "@triply/utils-private";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import { isLinkToDomain } from "#helpers/markup/unifiedPlugins.ts";
import { PrefixUpdate } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { Ellipsis, FontAwesomeIcon } from "..";
import * as styles from "./styles.scss";

interface Props {
  children: string;
  prefixes: Prefix[];
  /** Customize the link location, defaults to IRI value */
  to?: string;
  /** Add an action if the create prefix logic is available */
  onCreatePrefix?: (update: PrefixUpdate) => Promise<true | string>;
  /** When no prefix matches, this function is called to determine how the IRI should be displayed */
  shortenIri?: (iri: string) => string;
  /** Makes sure the link suggestion are applicable for a particular IRI (defaults to subject) */
  position?: "subject" | "predicate" | "object" | "graph";
  /** Try routing via first */
  preferRouter?: true;
  datasetPath: string | undefined;
}

const IRI: React.FC<Props> = ({
  children: iri,
  prefixes,
  shortenIri,
  onCreatePrefix,
  to,
  position,
  preferRouter,
  datasetPath,
}) => {
  const domain = useSelector((state: GlobalState) => state.config.staticConfig?.consoleUrl.domain);
  const { ref, copyToClipboard } = useCopyToClipboard();
  const [open, setOpen] = React.useState<{
    mouseX: number;
    mouseY: number;
  }>();
  const prefixed = prefixUtils.getPrefixed(iri, prefixes);
  const [error, setError] = React.useState<string>();
  const [addingPrefix, setAddingPrefix] = React.useState(false);
  const linkDestination = to || iri;
  const isSkolemIri = iri.indexOf("/.well-known/genid") > 0;

  const linkToDisplay = isSkolemIri ? (
    <>
      <FontAwesomeIcon icon={"bracket-square"} />
      ...{iri.substring(iri.length - 3)}
      <FontAwesomeIcon icon={"bracket-square-right"} />
    </>
  ) : (
    prefixed || shortenIri?.(iri) || iri
  );

  const createPrefix = React.useCallback(
    (update: PrefixUpdate) => {
      if (!onCreatePrefix) return;
      onCreatePrefix(update)
        .then((res) => {
          if (res === true) {
            setAddingPrefix(false);
          } else {
            setError(res);
          }
        })
        .catch((e: any) => {
          setError(e.message);
        });
    },
    [onCreatePrefix],
  );

  // Use router for internal links, use a tag for external
  const link =
    preferRouter && (linkDestination.startsWith("/") || isLinkToDomain(linkDestination, domain)) ? (
      <Link
        // Parse to object, since router expects relative links
        to={linkDestination.startsWith("/") ? linkDestination : urlParse(linkDestination)}
        onContextMenu={(e) => {
          e.preventDefault();
          setOpen({
            mouseX: e.clientX,
            mouseY: e.clientY,
          });
        }}
        aria-label={isSkolemIri ? "Skolemized Iri" : undefined}
      >
        {linkToDisplay}
      </Link>
    ) : (
      <a
        rel="noopener noreferrer"
        href={linkDestination}
        target="_blank"
        onContextMenu={(e) => {
          e.preventDefault();
          setOpen({
            mouseX: e.clientX,
            mouseY: e.clientY,
          });
        }}
        aria-label={isSkolemIri ? "Skolemized Iri" : undefined}
      >
        {linkToDisplay}
      </a>
    );
  if (onCreatePrefix && addingPrefix) {
    return (
      <CreatePrefix
        definedPrefixes={prefixes}
        iri={iri}
        error={error}
        onCancel={() => {
          setError(undefined);
          setAddingPrefix(false);
        }}
        onSubmit={createPrefix}
      />
    );
  }
  return (
    <span>
      <Tooltip
        placement="bottom-start"
        title={
          (isSkolemIri || prefixed || shortenIri) && (
            <dl>
              <dt>Full IRI</dt>
              <dd>
                {preferRouter && (iri.startsWith("/") || isLinkToDomain(iri, domain)) ? (
                  // Parse to object, since router expects relative links
                  <Link to={iri.startsWith("/") ? iri : urlParse(iri)}>{iri}</Link>
                ) : (
                  <a rel="noopener noreferrer" href={iri} target="_blank">
                    {iri}
                  </a>
                )}
              </dd>
            </dl>
          )
        }
        componentsProps={{
          tooltip: {
            className: getClassName(styles.prefixTooltip, "shadow"),
          },
        }}
      >
        {link}
      </Tooltip>
      <Menu
        open={!!open}
        MenuListProps={{ dense: true }}
        onClose={() => setOpen(undefined)}
        anchorReference="anchorPosition"
        anchorPosition={open ? { top: open.mouseY, left: open.mouseX } : undefined}
      >
        <MenuItem
          dense
          onClick={() => {
            copyToClipboard(iri);
            setOpen(undefined);
          }}
        >
          <FontAwesomeIcon fixedWidth icon="clipboard" className="mr-3" /> Copy IRI
          <div ref={ref} />
        </MenuItem>
        {!isSkolemIri && !prefixed && !!onCreatePrefix && (
          <MenuItem
            dense
            onClick={() => {
              setAddingPrefix(true);
              setOpen(undefined);
            }}
          >
            <FontAwesomeIcon fixedWidth className="mr-3" icon="arrows-to-dotted-line" rotation={90} /> Create Prefix
          </MenuItem>
        )}
        {datasetPath && (
          <MenuItem
            dense
            className={styles.menuLink}
            component={Link}
            to={{
              pathname: `/${datasetPath}/browser`,
              search: stringifyQuery({ resource: iri }),
            }}
          >
            <FontAwesomeIcon fixedWidth className="mr-3" icon="id-card" /> Open in browser
          </MenuItem>
        )}
        {datasetPath && (
          <MenuItem
            dense
            className={styles.menuLink}
            component={Link}
            to={{
              pathname: `/${datasetPath}/table`,
              search: stringifyQuery({ [position || "subject"]: iri }),
            }}
          >
            <FontAwesomeIcon fixedWidth className="mr-3" icon="th" /> Open in table
          </MenuItem>
        )}
      </Menu>
    </span>
  );
};

export default IRI;

const CreatePrefix: React.FC<{
  definedPrefixes: Prefix[];
  iri: string;
  onCancel: () => void;
  error?: string;
  onSubmit: (update: PrefixUpdate) => void;
}> = ({ definedPrefixes, iri, onSubmit, onCancel, error }) => {
  const prefixInfo = prefixUtils.getPrefixInfoFromIri(iri, definedPrefixes);
  const [newLabel, setNewLabel] = React.useState("");
  return (
    <form
      onBlur={onCancel}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit({
          ...prefixInfo,
          prefixLabel: newLabel,
        });
      }}
    >
      <TextField
        value={newLabel}
        autoFocus
        onChange={(event) => {
          event.preventDefault();
          setNewLabel(event.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            e.target.blur();
          }
        }}
        error={!!error}
        helperText={error}
        InputProps={{
          endAdornment: <InputAdornment position="end">:{prefixInfo.localName}</InputAdornment>,
        }}
      />
    </form>
  );
};
