import { Drawer, InputLabel, MenuItem, Select } from "@mui/material";
import getClassName from "classnames";
import { intersection, omit } from "lodash-es";
import * as React from "react";
import { useParams } from "react-router";
import { CachePolicies } from "use-http";
import { DatasetFacets, OrganizationFacets, QueryFacets, StoryFacets } from "@triply/utils/Models.js";
import { Button, ErrorPage, FontAwesomeIcon, LoadingPage, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import CurrentFilters, { filters } from "./CurrentFilters.tsx";
import Facets from "./Facets.tsx";
import Filters from "./Filters.tsx";
import PageSelect from "./PageSelect.tsx";
import Results from "./Results.tsx";
import SearchField from "./SearchField.tsx";
import * as styles from "./style.scss";

export type Page = "datasets" | "stories" | "queries" | "organizations";

const FacetedSearch: React.FC<IComponentProps> = ({ location, history }) => {
  const { page } = useParams<{ page: Page }>();
  let query = parseSearchString(location.search);
  const constructUrlToApi = useConstructUrlToApi();
  const [data, setData] = React.useState<DatasetFacets | StoryFacets | QueryFacets | OrganizationFacets>();
  const { loading, error } = useFetch(
    constructUrlToApi({ pathname: "/facets/" + page, query: query }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
      onNewData: (_, newData) => {
        setData(newData);
      },
    },
    [page, location.search],
  );
  const [filterDrawerOpen, setFilterDrawerOpen] = React.useState(false);

  if (error) return <ErrorPage />;

  query = omit(query, "page");

  const numberOfActiveFilters = intersection(filters[page], Object.keys(query)).length;

  const facets = (
    <>
      <PageSelect page={page} resultCount={loading ? undefined : data?.count} />

      <CurrentFilters data={data} query={query} page={page} />

      <div>
        <div className={getClassName("mb-2", styles.title)}>Keywords</div>
        <SearchField
          key={location.search}
          search={(term) => history.push({ search: stringifyQuery({ ...query, q: term || undefined }) })}
          searching={loading}
          className={styles.keywordField}
        />
      </div>

      {data?.facets && <Facets facets={data.facets} query={query} />}

      {data && "filters" in data && <Filters filters={data.filters} query={query} />}
    </>
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <Meta currentPath={location.pathname} title={`Browse ${page}`} />

        <Drawer
          container={__CLIENT__ ? () => window.document.body : undefined}
          variant="temporary"
          open={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
          sx={{ "& .MuiDrawer-paper": { width: 300 } }}
          className={styles.filterDrawer}
          anchor="right"
          id="facet-drawer"
        >
          <div className={styles.modalFacets}>{facets}</div>
        </Drawer>

        <div className={getClassName("rounding", styles.fixedFacets)}>{facets}</div>

        <div className={getClassName("grow", styles.results)}>
          <div className={getClassName("flex wrap center mx-3", styles.listHeader)}>
            <div className={styles.filterButton}>
              <Button
                onClick={() => setFilterDrawerOpen(true)}
                title="Filters"
                startIcon={<FontAwesomeIcon icon="filter" />}
                aria-expanded={filterDrawerOpen}
                aria-controls="facet-drawer"
              >
                Filters{numberOfActiveFilters > 0 ? ` (${numberOfActiveFilters})` : ""}
              </Button>
            </div>
            <div className="flex wrap center grow">
              <InputLabel id="sort-label" className="mr-2">
                Sort by
              </InputLabel>
              <Select<string>
                labelId="sort-label"
                value={[(query.sort as string) || "relevance", "asc" in query ? "asc" : "desc"].join(",")}
                onChange={(event) => {
                  const [sort, direction] = event.target.value.split(",");
                  history.push({
                    search: stringifyQuery({ ...query, sort: sort, asc: direction === "asc" ? "" : undefined }),
                  });
                }}
                className={styles.sortSelect}
              >
                <MenuItem value={"relevance,desc"}>relevance (best matches)</MenuItem>
                <MenuItem value={"created,desc"}>created (newest first)</MenuItem>
                <MenuItem value={"created,asc"}>created (oldest first)</MenuItem>
                <MenuItem value={"name,asc"}>name (A-Z)</MenuItem>
                <MenuItem value={"name,desc"}>name (Z-A)</MenuItem>
                {page === "datasets" && (
                  <MenuItem value={"statements,desc"}>number of statements (descending)</MenuItem>
                )}
                {page === "datasets" && <MenuItem value={"statements,asc"}>number of statements (ascending)</MenuItem>}
              </Select>
            </div>
          </div>

          <hr />

          {loading && <LoadingPage className={styles.loading} />}

          {!loading && data?.results && <Results data={data} page={page} />}
        </div>
      </div>
    </div>
  );
};

export default FacetedSearch;
