import { Alert, Paper } from "@mui/material";
import * as React from "react";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import PropertiesViewer from "./PropertiesViewer";
import { DashViewer, DashViewerProps } from ".";

const BlankNodeViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  const currentDs = useCurrentDataset();
  if (property.nodeKind !== "BlankNode") return <Alert severity="error">Wrongly configured BlankNode viewer</Alert>;
  if (property.properties === undefined) return <span>{property.value}</span>;
  if (!currentDs?.id) return null;
  return (
    <Paper variant="outlined" className="p-3">
      <PropertiesViewer dsId={currentDs.id} description={property} />
    </Paper>
  );
};

const BlankNodeViewer: DashViewer = {
  component: BlankNodeViewerComponent,
  getScore({ nodeKind }) {
    if (nodeKind === "BlankNode") return 1;
    return 0;
  },
};

export default BlankNodeViewer;
