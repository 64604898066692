import { Paper } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { EnforceTFA } from "#components/Forms/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { updateTfaSettings } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";

interface Props {}

const EnforceTfa: React.FC<Props> = ({}) => {
  const userRole = useSelector((state: GlobalState) => state.config.clientConfig?.enforceTfa?.userRole);
  const authMethod = useSelector((state: GlobalState) => state.config.clientConfig?.enforceTfa?.authMethod);
  const dispatch = useDispatch();

  const handleMfaSettings = (values: EnforceTFA.FormData) => {
    return dispatch<typeof updateTfaSettings>(updateTfaSettings(values)).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  return (
    <Paper variant="outlined" className="p-5">
      <h4>Two-factor authentication (2FA)</h4>
      <EnforceTFA
        initialValues={{
          enforceTfa: !!userRole,
          role: userRole || "all",
          authMethod: authMethod || "password",
        }}
        onSubmit={handleMfaSettings}
      />
    </Paper>
  );
};
export default EnforceTfa;
