import bytes from "bytes";
import { replace } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { SubmissionError } from "redux-form";
import { AVATAR_SIZE_LIMIT } from "@triply/utils/Constants.js";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import * as Forms from "#components/Forms/index.ts";
import { Alert, Avatar, DatasetMetadata, ErrorPage, FlexContainer } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { cancelTusUploadsOfDataset, datasetHasOngoingUploads } from "#helpers/tusUploadManagement.ts";
import { Account, User } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { getLoggedInUser } from "#reducers/auth.ts";
import {
  Dataset,
  deleteDataset,
  getCurrentDataset,
  transferDataset,
  updateDataset,
  updateDatasetPrefixes,
  uploadAvatar,
} from "#reducers/datasetManagement.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export namespace DatasetSettings {
  export interface OwnProps extends IComponentProps {}
  export interface DispatchProps {
    updateDataset: DispatchedFn<typeof updateDataset>;
    replace: typeof replace;
    uploadAvatar: DispatchedFn<typeof uploadAvatar>;
    deleteDataset: DispatchedFn<typeof deleteDataset>;
    updateDatasetPrefixes: DispatchedFn<typeof updateDatasetPrefixes>;
    transferDataset: DispatchedFn<typeof transferDataset>;
  }
  export interface PropsFromState {
    currentDs?: Dataset;
    currentAccount?: Account;
    authenticatedUser?: User;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
}

const DatasetSettings: React.FC<DatasetSettings.Props> = ({
  currentDs,
  location,
  currentAccount,
  updateDataset,
  uploadAvatar,
  deleteDataset,
  updateDatasetPrefixes,
  transferDataset,
}) => {
  const acl = useAcl();
  const constructUrlToApi = useConstructUrlToApi();
  const confirm = useConfirmation();
  const history = useHistory();
  const [deletingDs, setDeletingDs] = React.useState(false);

  if (!currentAccount || !currentDs) return <ErrorPage statusCode={404} />;

  const handleAvatarUpload = (values: Forms.IconUpload.FormData) => {
    const avatarLimitBytes = bytes(AVATAR_SIZE_LIMIT);
    if (values.avatarFile.size > avatarLimitBytes) {
      throw new SubmissionError({
        _error: `File too large. Maximum file size is ${bytes.format(avatarLimitBytes, { decimalPlaces: 0 })}.`,
      });
    }
    return uploadAvatar(currentDs, values.avatarFile).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  const handleDelete = async () => {
    confirm({
      description: `Are you sure you want to delete dataset '${currentDs.name}'?`,
      title: "Delete dataset?",
      actionLabel: "Delete",
      onConfirm: () => {
        setDeletingDs(true);
        cancelTusUploadsOfDataset(currentDs.id);
        deleteDataset(currentDs)
          .then(() => history.push(`/${currentAccount.accountName}/-/datasets`))
          .catch(() => setDeletingDs(false));
      },
    });
  };
  const handleProfileUpdate = (values: Forms.DatasetProfile.FormData) => {
    const nameChange = values.name !== currentDs.name;
    if (nameChange && datasetHasOngoingUploads(currentDs.id)) {
      throw new SubmissionError({ _error: "Changing the dataset URL is not possible while uploading" });
    }
    return updateDataset(currentAccount, currentDs, {
      name: values.name,
      displayName: values.displayName,
      description: values.description,
      accessLevel: values.accessLevel,
      license: values.license,
      topics: values.topics.map((topic) => topic.iri),
      exampleResources: values.exampleResources,
    }).then(
      () => {
        if (nameChange && __CLIENT__) window.location.replace(`/${currentAccount.accountName}/${values.name}`);
      },
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  const handlePrefixSubmit = (values: Forms.Prefix.FormData) => {
    return updateDatasetPrefixes(values.prefixes, currentAccount, currentDs).then(
      () => {},
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };
  const handleTransfer = (values: Forms.TransferDataset.FormData) => {
    if (datasetHasOngoingUploads(currentDs.id)) {
      throw new SubmissionError({ _error: "Transferring a dataset is not possible while uploading" });
    }
    return transferDataset(currentDs, values.name).then(
      (ctx) => {
        if (__CLIENT__) window.location.replace(`/${values.name}/${ctx.body.name}`);
      },
      (e: any) => {
        throw new SubmissionError({ _error: e.message });
      },
    );
  };

  if (
    !acl.check({
      action: "editDatasetMetadata",
      context: {
        roleInOwnerAccount: acl.getRoleInAccount(currentAccount),
        accessLevel: currentDs.accessLevel,
        newAccessLevel: undefined,
      },
    }).granted
  )
    return <ErrorPage statusCode={401} />;

  return (
    <FlexContainer>
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Dataset Settings"
      />

      <div className="whiteSink">
        <h3>Update dataset profile</h3>
        <div>
          <div className={styles.flex}>
            <div>
              <Forms.DatasetProfile
                initialValues={{
                  name: currentDs.name,
                  displayName: currentDs.displayName || currentDs.name,
                  description: currentDs.description,
                  accessLevel: currentDs.accessLevel,
                  license: currentDs.license,
                  topics: currentDs.topics,
                  exampleResources: currentDs.exampleResources,
                }}
                termPath={constructUrlToApi({
                  pathname: `/datasets/${currentAccount.accountName}/${currentDs.name}/terms`,
                })}
                topicPath={constructUrlToApi({ pathname: `/topics` })}
                enableReinitialize
                currentDs={currentDs}
                onSubmit={handleProfileUpdate}
              />
            </div>
            <div className="py-5 px-2">
              <Forms.AvatarUpload onSubmit={handleAvatarUpload} accept="image/*">
                <Avatar
                  size="lg"
                  avatarUrl={currentDs.avatarUrl}
                  avatarName={currentDs.displayName || currentDs.name}
                  alt=""
                />
              </Forms.AvatarUpload>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteSink">
        <h3>Prefixes</h3>
        {currentDs.prefixes && (
          <Forms.Prefix
            form="prefixes"
            enableReinitialize
            initialValues={{
              prefixes: [
                ...currentDs.prefixes.map((prefix) => {
                  const { scope, ...rest } = prefix;
                  return rest;
                }),
                //append empty prefix to initialvalues (we always want an empty line via which a user can _add_ prefixes
                //don't want this in the componant on-mount, as that would make the form dirty
                {} as any,
              ],
            }}
            onSubmit={handlePrefixSubmit}
          />
        )}
      </div>
      <div className="whiteSink">
        <Alert
          warning
          message="Transferring dataset ownership will break existing links to the web pages and API addresses of the dataset"
          className={styles.alert}
        />
        <h3>Transfer ownership</h3>
        <Forms.TransferDataset
          currentAccount={currentAccount}
          accountsUrl={constructUrlToApi({ pathname: `/accounts` })}
          onSubmit={handleTransfer}
        />
      </div>

      <div className="whiteSink">
        <Alert error message="This is an irreversible action!" className={styles.alert} />
        <h3>Delete dataset</h3>
        <div className="pt-3">
          <LoadingButton color="error" onClick={handleDelete} disabled={deletingDs} loading={deletingDs}>
            Delete dataset
          </LoadingButton>
        </div>
      </div>
    </FlexContainer>
  );
};

export default connect<
  DatasetSettings.PropsFromState,
  { [K in keyof DatasetSettings.DispatchProps]: any },
  DatasetSettings.OwnProps,
  GlobalState
>(
  (state) => {
    const loggedInUser = getLoggedInUser(state);
    return {
      currentDs: getCurrentDataset(state),
      currentAccount: getCurrentAccount(state),
      authenticatedUser: loggedInUser,
    };
  },
  //dispatch
  {
    updateDataset,
    replace,
    uploadAvatar,
    deleteDataset,
    updateDatasetPrefixes,
    transferDataset,
  },
)(DatasetSettings);
