import { Box, Container, IconButton, Popover, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import useClickOrHover from "#helpers/hooks/useClickOrHover.ts";
import FontAwesomeIcon from "../FontAwesomeIcon/index.tsx";
import * as styles from "./style.scss";

interface Props {
  hint: string;
  className?: string;
  children: React.ReactNode;
}

const HintWrapper: React.FC<Props> = ({ hint, className, children }) => {
  const moreInfoPopupRef = React.useRef<HTMLDivElement>(null);
  const moreInfoButtonRef = React.useRef<HTMLButtonElement>(null);
  const [moreInfoOpen, setMoreInfoOpen] = useClickOrHover(moreInfoButtonRef, moreInfoPopupRef);
  return (
    <Box className={getClassName(styles.hintField, className)}>
      {children}
      {
        <IconButton
          className={styles.hintButton}
          ref={moreInfoButtonRef}
          size="small"
          disableRipple
          onClick={() => setMoreInfoOpen(true)}
          aria-label="Details"
        >
          <FontAwesomeIcon icon={["fas", "info"]} />
        </IconButton>
      }
      <Popover
        open={moreInfoOpen}
        ref={moreInfoPopupRef}
        className={styles.hintPopup}
        anchorEl={moreInfoButtonRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Container className={styles.hintContent}>
          <Typography>{hint}</Typography>
        </Container>
      </Popover>
    </Box>
  );
};
export default HintWrapper;
