import { Container, Divider, Typography } from "@mui/material";
import { flatten, groupBy, uniqBy } from "lodash-es";
import * as React from "react";
import { useHotkeys as useHotkeyLib } from "react-hotkeys-hook";
import { HotkeyCallback, OptionsOrDependencyArray } from "react-hotkeys-hook/dist/types.ts";
import { Dialog, KeyboardKey, KeyboardKeyCombination } from "#components/index.ts";
import * as styles from "./style.scss";
/**
 * Keyboard shortcut
 */
export interface KeyboardShortcut {
  keyBinds: string; // Keybinds split by +, use Mod indicator both ctrl as cmd
  component: string;
  description: string;
}

/**
 * Converts the hotkey string from our own to the react-use-hotkeys lib
 * It currently, simultanious keys should be separated by '+' and 'Mod' is used for both control and command
 */
function parseHotkeyBinding(shortcut: string) {
  if (shortcut === "+") return shortcut;
  const part = shortcut.split("+");
  const modAt = part.indexOf("Mod");
  if (modAt === -1) return shortcut;
  return ["control", "command"]
    .map((mod) => {
      part[modAt] = mod;
      return part.join("+");
    })
    .join(",");
}

export const KeyboardShortcutContext = React.createContext({
  registerHotKeys: (identifier: string, key: KeyboardShortcut | KeyboardShortcut[]) => {},
  unRegisterHotKeys: (identifier: string) => {},
  openShortcutDialog: () => {},
});

export const useHotkeys = (opts: KeyboardShortcut, event: HotkeyCallback, options?: OptionsOrDependencyArray) => {
  useHotkeyLib(parseHotkeyBinding(opts.keyBinds), event, options);
  useDisplayHotkeys([opts]);
};

export const useDisplayHotkeys = (keybinds: KeyboardShortcut[]) => {
  const id = React.useId();
  const { registerHotKeys, unRegisterHotKeys } = React.useContext(KeyboardShortcutContext);
  React.useEffect(() => {
    registerHotKeys(id, keybinds);
    return () => unRegisterHotKeys(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const HotKeysProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [registeredHotkeys, setRegisteredHotKeys] = React.useState<Record<string, KeyboardShortcut[]>>({});
  const dialogId = React.useId();
  const openDialog = React.useCallback(
    (event: KeyboardEvent) => {
      if (!event.repeat) setOpen((open) => !open);
    },
    [setOpen],
  );
  useHotkeyLib("control+shift+slash,command+shift+slash", openDialog, {
    enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"],
    enableOnContentEditable: true,
  });

  const registerHotKeys = (identifier: string, newBindings: KeyboardShortcut | KeyboardShortcut[]) => {
    setRegisteredHotKeys((registeredHotKeys) => {
      registeredHotKeys[identifier] = Array.isArray(newBindings) ? newBindings : [newBindings];
      return registeredHotKeys;
    });
  };

  const unRegisterHotKeys = (identifier: string) => {
    setRegisteredHotKeys((registerHotKeys) => {
      delete registerHotKeys[identifier];
      return registerHotKeys;
    });
  };

  const openShortcutDialog = React.useCallback(() => {
    setOpen(true);
  }, []);

  const groupedHotKeys = groupBy(
    uniqBy(flatten(Object.values(registeredHotkeys)), (key) => key.component + key.keyBinds),
    "component",
  );
  return (
    <KeyboardShortcutContext.Provider
      value={{
        registerHotKeys: registerHotKeys,
        unRegisterHotKeys: unRegisterHotKeys,
        openShortcutDialog: openShortcutDialog,
      }}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={`${dialogId}-title`}
        aria-describedby={`${dialogId}-description`}
        maxWidth="xs"
      >
        <Container className="py-5">
          <div className="flex center pt-2">
            <Typography id={`${dialogId}-title`} variant="h5" component="h1">
              Keyboard shortcuts
            </Typography>
            <KeyboardKeyCombination className="ml-5">Mod+shift+/</KeyboardKeyCombination>
          </div>
          <p id={`${dialogId}-description`}>
            This page shows the keyboard shortcuts available on this page! Be sure to check back later as new ones will
            be added!
          </p>
          <Container maxWidth="xs" className="mt-6 mb-4">
            {Object.entries(groupedHotKeys).map(([group, shortcuts]) => {
              return (
                <React.Fragment key={group}>
                  <Typography variant="h6" component="h2" fontSize="1.1rem" className="mt-5 mb-3">
                    {group}
                  </Typography>
                  <dl className={styles.list}>
                    {shortcuts.map((shortcut) => (
                      <div className={styles.keyBind} key={group + shortcut.keyBinds}>
                        <dt>
                          <Typography variant="body2">{shortcut.description}</Typography>
                        </dt>
                        <dd className="flex gx-2">
                          {shortcut.keyBinds.split(",").map((keyBind, i, array) => (
                            <span key={keyBind}>
                              <KeyboardKeyCombination>{keyBind}</KeyboardKeyCombination>
                              {i < array.length - 1 && ","}
                            </span>
                          ))}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </React.Fragment>
              );
            })}
          </Container>
        </Container>
      </Dialog>
      {children}
    </KeyboardShortcutContext.Provider>
  );
};

export default HotKeysProvider;
