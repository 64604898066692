import { uniqueId } from "lodash-es";
import { MarkOptional } from "ts-essentials";
import * as tus from "@triply/tus-js-client";
import { GENERIC_FILE_UPLOAD_ERROR_MESSAGE } from "@triply/utils/Constants.js";
import { Asset, IndexJob } from "@triply/utils/Models.js";

type UploadType = "asset" | "jobUpload";

export interface TusUploadConfig<J> {
  file: File;
  // currently only two types of upload are needed: graph upload and asset upload.
  // These types are used in the identifier of the upload
  type: UploadType;
  accountId: string;
  datasetId: string;
  //The endpoint is used to _create_ a new upload URL (i.e., upload a new file)
  endpoint: string;
  //We can't use the filename as identifier as you're able to upload files with the same name
  //I.e., this is a simple custom identifier with which to refer to this particular upload or it's retries
  uploadId: string;
  retryCount?: number;
  // only used by asset uploads
  versionOf?: string;

  onSuccess: (body: J, uploadId: string) => void;
  // File is optional, because it's not used by assetUploads
  onProgress: (percentage: number, uploadId: string, file?: File) => void;
  onError: (uploadId: string, message: string, file?: File) => void;
  onUploadObjectCreated: (uploadId: string, file: File) => void;
  validate?: (file: File) => string;
}

interface ExtendedUpload extends tus.Upload {
  uploadId: string;
  accountId: string;
  datasetId: string;
  type: UploadType;
}

const MAX_SIMULTANEOUS_UPLOADS = 5;

export const state = {
  navigationWarningActive: false,
  uploadQueue: [] as TusUploadConfig<IndexJob | Asset>[],
  ongoingUploads: [] as ExtendedUpload[],
};

let enqueuedUploadSize: { [key: string]: number } = {};

/**
 *
 *  PRIVATE FUNCTIONS
 *
 ***/

function onUnload(event: any) {
  const message = "Navigating away will stop your ongoing uploads. Continue?";
  event.returnValue = message;
  return message;
}

function syncNavigationWarning() {
  if (!state.navigationWarningActive && state.ongoingUploads.length + state.uploadQueue.length) {
    window.addEventListener("beforeunload", onUnload);
    state.navigationWarningActive = true;
  } else if (state.navigationWarningActive && state.ongoingUploads.length + state.uploadQueue.length === 0) {
    window.removeEventListener("beforeunload", onUnload);
    state.navigationWarningActive = false;
  }
}
function getConfigWithUploadId(config: MarkOptional<TusUploadConfig<IndexJob | Asset>, "uploadId">) {
  config.uploadId = config.uploadId || uniqueId(config.type);
  return config as TusUploadConfig<IndexJob | Asset>;
}

function initiateUpload(_config: MarkOptional<TusUploadConfig<IndexJob | Asset>, "uploadId">) {
  const config = getConfigWithUploadId(_config);
  const key = config.type + config.datasetId;
  let uploadObject;
  try {
    uploadObject = createTusUploadObject(config);
  } catch (e) {
    let message = "Initiating upload failed";
    if (e instanceof Error) {
      message = e.message;
    }

    numEnqueuedUploadsMap[key]--;
    enqueuedUploadSize[key] -= config.file.size;
    config.onError(config.uploadId, message, config.file);
    return;
  }

  uploadObject.start();
  state.ongoingUploads.push(uploadObject);
  numEnqueuedUploadsMap[key]--;
  enqueuedUploadSize[key] -= config.file.size;
  config.onUploadObjectCreated(uploadObject.uploadId, config.file);

  syncNavigationWarning();
}

function cancelAnyUploadsWhere(condition: (item: TusUploadConfig<IndexJob | Asset> | ExtendedUpload) => boolean) {
  for (let i = state.uploadQueue.length - 1; i >= 0; i--) {
    if (!condition(state.uploadQueue[i])) continue;
    const key = state.uploadQueue[i].type + state.uploadQueue[i].datasetId;
    if (numEnqueuedUploadsMap[key]) numEnqueuedUploadsMap[key]--;
    enqueuedUploadSize[key] -= state.uploadQueue[i].file.size;
    state.uploadQueue.splice(i, 1);
  }

  for (let i = state.ongoingUploads.length - 1; i >= 0; i--) {
    if (!condition(state.ongoingUploads[i])) continue;
    // @Robustness: abort() can reject if we enable the Termination extension, which deletes the file on the server.
    // Since we don't use it, and because there would need to be more code changes, we're ignoring the error here.
    state.ongoingUploads[i].abort().catch(() => {});
    state.ongoingUploads.splice(i, 1);
    initFromQueue();
  }
}

function cancelSingleUploadWhere(condition: (item: TusUploadConfig<IndexJob | Asset> | ExtendedUpload) => boolean) {
  for (let i = state.ongoingUploads.length - 1; i >= 0; i--) {
    if (!condition(state.ongoingUploads[i])) continue;
    // @Robustness: abort() can reject if we enable the Termination extension, which deletes the file on the server.
    // Since we don't use it, and because there would need to be more code changes, we're ignoring the error here.
    state.ongoingUploads[i].abort().catch(() => {});
    state.ongoingUploads.splice(i, 1);
    initFromQueue();
    return;
  }

  for (let i = state.uploadQueue.length - 1; i >= 0; i--) {
    if (!condition(state.uploadQueue[i])) continue;
    const key = state.uploadQueue[i].type + state.uploadQueue[i].datasetId;
    if (numEnqueuedUploadsMap[key]) numEnqueuedUploadsMap[key]--;
    enqueuedUploadSize[key] -= state.uploadQueue[i].file.size;
    state.uploadQueue.splice(i, 1);
    return;
  }
}

function initFromQueue() {
  while (state.uploadQueue.length && state.ongoingUploads.length < MAX_SIMULTANEOUS_UPLOADS) {
    const fromQueue = state.uploadQueue.shift();
    if (fromQueue) initiateUpload(fromQueue);
  }
}

function finishTusUpload(uploadId: string) {
  for (let i = state.ongoingUploads.length - 1; i >= 0; i--) {
    if (state.ongoingUploads[i].uploadId !== uploadId) continue;
    state.ongoingUploads.splice(i, 1);
    initFromQueue();
    break;
  }
  syncNavigationWarning();
}

function createTusUploadObject(conf: TusUploadConfig<IndexJob | Asset>) {
  function validate() {
    if (!conf.file) {
      // this is the case if for example a piece of text is drag&dropped to the file-drop area
      return "Not a file";
    }
    if (!conf.file.size) {
      return "Can't upload empty file";
    } else if (conf.validate) {
      return conf.validate(conf.file);
    }
  }

  const err = validate();
  if (err) {
    throw new Error(err);
  }

  let retryCount = conf.retryCount || 0;
  // VersionOf is needed for the assets
  const metadata: { filename: string; versionOf?: string } = { filename: conf.file.name };
  if (conf.versionOf) metadata.versionOf = conf.versionOf;

  const tusObject = new tus.Upload(conf.file, {
    endpoint: conf.endpoint,
    mapUrl: (url) => url.replace("/datasets/", "/_api/datasets/"),
    metadata,

    chunkSize: 5 * 1024 * 1024, // 5mb

    onError: (e: any) => {
      // check if the error is tus complaining about an unexpected response.
      // if so, it is most likely because of our custom error responses (i.e. not 500 with default tus-message)
      // in that case, we recognize that the upload failed and don't retry.
      const isCustomFailResponse = e.toString().indexOf("unexpected response") > -1;

      if (retryCount <= 3 && !isCustomFailResponse) {
        // current time for the timeout is a linear formula.
        // In future this may need to be improved based on duration of errors e.g. network loss
        setTimeout(() => tusObject.start(), ++retryCount * 1000);
      } else {
        let customErrorMessage = e.originalResponse?.getBody();
        if (customErrorMessage?.indexOf('"message":') > -1) {
          // if the error is thrown by the tus server, then it's already
          // a normal string with just the error message. If it was thrown
          // through our regular middleware, then it's a stringified {message:"..."} object
          customErrorMessage = JSON.parse(customErrorMessage).message;
        }
        cancelTusUpload(conf.uploadId);
        conf.onError(conf.uploadId, customErrorMessage || GENERIC_FILE_UPLOAD_ERROR_MESSAGE, conf.file);
      }
    },

    onProgress: (bytesUploaded: number, bytesTotal: number) => {
      const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
      // reset the retryCount if new progress has been made
      if (retryCount > 0) retryCount = 0;
      conf.onProgress(percentage, conf.uploadId, conf.file);
    },

    onSuccess: function (stringifiedResponse: string) {
      try {
        const jsonBody: IndexJob | Asset = JSON.parse(stringifiedResponse);
        finishTusUpload(conf.uploadId);
        conf.onSuccess(jsonBody, conf.uploadId);
      } catch {
        conf.onError(conf.uploadId, GENERIC_FILE_UPLOAD_ERROR_MESSAGE, conf.file);
      }
    },
  }) as ExtendedUpload;

  tusObject.uploadId = conf.uploadId;
  tusObject.type = conf.type;
  tusObject.datasetId = conf.datasetId;
  tusObject.accountId = conf.accountId;
  return tusObject;
}

/**
 *
 *  EXPORTED FUNCTIONS
 *
 ***/

let numEnqueuedUploadsMap: { [key: string]: number } = {};

export function getNumEnqueuedUploads(kind: UploadType, ds: string) {
  const key = kind + ds;
  if (!numEnqueuedUploadsMap[key]) return 0;
  return numEnqueuedUploadsMap[key];
}

export function cancelTusUpload(uploadId: string) {
  cancelSingleUploadWhere((item) => item.uploadId === uploadId);
  syncNavigationWarning();
}

export function cancelTusUploadsOfUser(userId: string) {
  cancelAnyUploadsWhere((item) => item.accountId === userId);
  syncNavigationWarning();
}

export function cancelTusUploadsOfDataset(datasetId: string) {
  cancelAnyUploadsWhere((item) => item.datasetId === datasetId);
  syncNavigationWarning();
}

export function cancelAllUploadsOfKindInDs(kind: UploadType, ds: string) {
  cancelAnyUploadsWhere((item) => item.datasetId === ds && item.type === kind);
  syncNavigationWarning();
}

export function cancelAllTusUploads() {
  state.uploadQueue = [];
  enqueuedUploadSize = {};
  // @Robustness: abort() can reject if we enable the Termination extension, which deletes the file on the server.
  // Since we don't use it, and because there would need to be more code changes, we're ignoring the error here.
  state.ongoingUploads.forEach((item) => item.abort().catch(() => {}));
  state.ongoingUploads = [];

  numEnqueuedUploadsMap = {};
  syncNavigationWarning();
}

export function datasetHasOngoingUploads(datasetId: string) {
  for (const upload of state.ongoingUploads) {
    if (upload.datasetId === datasetId) return true;
  }
  for (const config of state.uploadQueue) {
    if (config.datasetId === datasetId) return true;
  }
  return false;
}

export function accountHasOngoingUploads(accountId: string) {
  for (const upload of state.ongoingUploads) {
    if (upload.accountId === accountId) return true;
  }
  for (const config of state.uploadQueue) {
    if (config.accountId === accountId) return true;
  }
  return false;
}

export function getEnqueuedUploadSize(type: UploadType, ds: string) {
  return enqueuedUploadSize[type + ds];
}

export function getOngoingUploadSize(type: UploadType, ds: string) {
  return state.ongoingUploads
    .filter((u) => u.type === type && u.datasetId === ds)
    .map((u) => {
      if ("size" in u.file) {
        return u.file.size;
      } else {
        throw new Error("Size property doesn't exist for this object type.");
      }
    })
    .reduce((a, b) => a + b, 0);
}

export function registerTusUploads(configs: TusUploadConfig<IndexJob | Asset>[]) {
  if (!configs) return;

  configs = configs.sort((f1, f2) => f2.file.size - f1.file.size);

  const key = configs[0].type + configs[0].datasetId;
  if (!numEnqueuedUploadsMap[key]) numEnqueuedUploadsMap[key] = 0;
  numEnqueuedUploadsMap[key] += configs.length;
  if (!enqueuedUploadSize[key]) enqueuedUploadSize[key] = 0;
  enqueuedUploadSize[key] += configs.map((c) => c.file.size).reduce((a, b) => a + b, 0);

  let next = configs.shift();

  for (let i = 0; i < state.uploadQueue.length; i++) {
    if (!next) return;
    if (state.uploadQueue[i].file.size <= next.file.size) {
      // insert before the smaller one, so as to keep the largest first.
      state.uploadQueue.splice(i, 0, next);
      if (!configs.length) return;
      next = configs.shift();
    }
  }
  if (!next) return;
  state.uploadQueue.push(next, ...configs);

  initFromQueue();
}
