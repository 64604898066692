import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { HumanizedDate, Markdown, StoryBadge } from "#components/index.ts";
import { Story } from "#reducers/stories.ts";
import * as styles from "./style.scss";

interface Props {
  story: Story;
  className?: string;
  showOwner?: boolean;
}

const StoryListItem: React.FC<Props> = ({ story, className, showOwner }) => {
  return (
    <div className={getClassName(className, styles.container, "flex center")}>
      <div className={styles.colAvatar} aria-hidden="true">
        <StoryBadge bannerUrl={story.bannerUrl} size="md" />
      </div>
      <div className={styles.main}>
        <Link to={`/${story.owner.accountName}/-/stories/${story.name}`}>
          <h3 style={{ margin: 0 }}>{story.displayName || story.name}</h3>
        </Link>
        {showOwner && (
          <div>
            by <Link to={`/${story.owner.accountName}`}>{story.owner.name || story.owner.accountName}</Link>
          </div>
        )}
        <div className="pt-2">
          Created <HumanizedDate date={story.createdAt} />
        </div>
        <Markdown compact className={styles.description}>
          {(
            story.content.find(
              (element) => element.type === "paragraph" && element.paragraph,
            ) as Models.StoryElementParagraph
          )?.paragraph || ""}
        </Markdown>
      </div>
      <div className={styles.icons}>
        <AccessLevelIcon level={story.accessLevel} type="story" addTitleForAccountType={story.owner.type} />
      </div>
    </div>
  );
};

export default StoryListItem;
