// extracted by mini-css-extract-plugin
var _1 = "style--actions--VKReW0SQ";
var _2 = "style--bannerImage--WFVeYrKx";
var _3 = "style--contactEmail--egkfW6Fd";
var _4 = "style--customBanner--KZEB9Vse";
var _5 = "style--draggingElement--iGkwKD5f";
var _6 = "style--exampleText--e4k5zoux";
var _7 = "style--formItemAvatar--szBICJM_";
var _8 = "style--formItemIcon--yHLSlrN0";
var _9 = "style--handle--a2Vaog0e";
var _a = "style--itemLabel--E5q0Rc8y";
var _b = "style--label--rST3wCku";
var _c = "style--logo--Hc4cVfBe";
var _d = "style--logoSquare--yfVfyTcC";
var _e = "style--logoSquareBgShape--SZDfHVse";
var _f = "style--logoSquareCustomization--Fmftqygh";
var _10 = "style--secondaryLabel--gBIjcozn";
var _11 = "style--sortableElement--kArtqVpt";
var _12 = "style--sortableList--CL4u7Rj2";
var _13 = "style--uploadForm--RAUs4iAY";
export { _1 as "actions", _2 as "bannerImage", _3 as "contactEmail", _4 as "customBanner", _5 as "draggingElement", _6 as "exampleText", _7 as "formItemAvatar", _8 as "formItemIcon", _9 as "handle", _a as "itemLabel", _b as "label", _c as "logo", _d as "logoSquare", _e as "logoSquareBgShape", _f as "logoSquareCustomization", _10 as "secondaryLabel", _11 as "sortableElement", _12 as "sortableList", _13 as "uploadForm" }
