// extracted by mini-css-extract-plugin
var _1 = "LNCSMetadata--abstract--ntL9FErw";
var _2 = "LNCSMetadata--affiliations--MefDHbMk";
var _3 = "LNCSMetadata--author--hMvaVINB";
var _4 = "LNCSMetadata--authors--PPIybop2";
var _5 = "LNCSMetadata--bottom--kTXZV2cW";
var _6 = "LNCSMetadata--constrainWidth--NIbCQPHN";
var _7 = "LNCSMetadata--container--agH9z2iy";
var _8 = "LNCSMetadata--content--lkXHLSo2";
var _9 = "LNCSMetadata--editIcon--G4yS_cZQ";
var _a = "LNCSMetadata--fabWrapper--nZ9Kp03j";
var _b = "LNCSMetadata--keywords--chJuMGT0";
var _c = "LNCSMetadata--lncs--ziPu356a";
var _d = "LNCSMetadata--orcid--KIkkh_sC";
var _e = "LNCSMetadata--paragraph--Dq3e3oWP";
var _f = "LNCSMetadata--story--GgqM2AKz";
var _10 = "LNCSMetadata--top--Kh_mIjTV";
export { _1 as "abstract", _2 as "affiliations", _3 as "author", _4 as "authors", _5 as "bottom", _6 as "constrainWidth", _7 as "container", _8 as "content", _9 as "editIcon", _a as "fabWrapper", _b as "keywords", _c as "lncs", _d as "orcid", _e as "paragraph", _f as "story", _10 as "top" }
