import { useDispatch as origUseDispatch } from "react-redux";
import { Dispatched } from "#reducers/index.ts";

export interface Dispatch<I extends (...args: any[]) => unknown> {
  <T extends I>(action: ReturnType<I>): Dispatched<T>;
}

export default function useDispatch<I extends (...args: any[]) => unknown>(): Dispatch<I> {
  return origUseDispatch<any>();
}
