import { TabList } from "@mui/lab";
import { Box, Divider, IconButton, Menu, MenuItem, Tab, TextField } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import { useSparqlIDEContext } from "./useSparqlIDEContext.tsx";
import * as styles from "./styles.scss";

interface Props {}
/**
 * UX Insights
 *
 * Close tab should only be drawn on the current active tab so it doesn't conflict with opening another tab.
 * In practice, this means that the close current tab button comes after the tabs in the tab-order
 */
const SparqlIDETabMenu: React.FC<Props> = ({}) => {
  const [editingNameOf, setEditingNameOf] = React.useState<string>();
  const [closedTabs, setClosedTabs] = React.useState<string[]>([]);
  const [draggingTab, setDraggingTab] = React.useState<string>();
  const [contextMenuFor, setContextMenuFor] = React.useState<{ id: string; x: number; y: number }>();
  const { addTab, setSelectedTab, ideConfig, setTabOrder, setTabName } = useSparqlIDEContext();
  if (!ideConfig) return null;

  const { tabOrder, selectedTab, queries } = ideConfig;
  const currentTabIdx = tabOrder.indexOf(selectedTab);

  const openLastTab = () => {
    if (closedTabs.length === 0) return;
    setTabOrder([...tabOrder, closedTabs[0]]);
    setSelectedTab(closedTabs[0]);
    setClosedTabs((closedTabs) => {
      const [_, ...rest] = closedTabs;
      return rest;
    });
    setContextMenuFor(undefined);
  };

  const removeTab = (tabId: string) => {
    if (tabId === selectedTab && tabOrder.length > 1) {
      if (currentTabIdx === 0) {
        setSelectedTab(tabOrder[1]);
      } else {
        setSelectedTab(tabOrder[currentTabIdx - 1]);
      }
    }
    setTabOrder(tabOrder.filter((tab) => tab !== tabId));
    setClosedTabs((closedTabs) => [tabId, ...closedTabs]);
    if (tabOrder.length === 1) {
      addTab?.();
    }
  };

  const removeTabFromContext = () => {
    removeTab(contextMenuFor!.id);
    setContextMenuFor(undefined);
  };
  const removeOtherTabsFromContext = () => {
    const tabsToClose = tabOrder.filter((tab) => tab !== contextMenuFor!.id);
    setTabOrder([contextMenuFor!.id]);
    setSelectedTab(contextMenuFor!.id);
    setClosedTabs((closedTabs) => [...tabsToClose, ...closedTabs]);
    setContextMenuFor(undefined);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className={getClassName("flex", styles.tabList)}>
      <TabList
        onChange={handleTabChange}
        aria-label="Editor tabs"
        variant="scrollable"
        color="secondary"
        onKeyDown={(e) => {
          if (e.key === "Delete" && selectedTab !== "info") {
            removeTab(selectedTab);
            return true;
          }
          return false;
        }}
      >
        {tabOrder.map((tabId) => (
          <Tab
            key={`tab-${tabId}`}
            id={tabId}
            value={tabId}
            className={styles.tab}
            disableTouchRipple={tabId === selectedTab}
            disableRipple={tabId === editingNameOf}
            label={
              editingNameOf === tabId ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setEditingNameOf(undefined);
                    const input = (e.target as HTMLFormElement)[0] as HTMLInputElement;
                    if (input.value.trim().length > 0) {
                      setTabName(tabId, input.value);
                    }
                    // Manually blur the input, this makes sure the Tab will un-focus when removing the form
                    input.blur();
                  }}
                >
                  <TextField
                    aria-label="name"
                    autoFocus
                    defaultValue={queries[tabId].name}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        setEditingNameOf(undefined);
                        return true;
                      }
                    }}
                    onBlur={(e) => {
                      // When using the context menu, the field will be directly blurred until the menu is fully closed
                      // The timeout ensures that the input is focused after.
                      if (e.relatedTarget?.classList.contains("MuiMenu-paper")) {
                        setTimeout(() => e.target.focus(), 0);
                      } else {
                        setEditingNameOf(undefined);
                      }
                    }}
                  />
                </form>
              ) : (
                queries[tabId].name
              )
            }
            onDoubleClick={() => {
              setEditingNameOf(tabId);
            }}
            onAuxClick={(event) => {
              if (event.button === 1) removeTab(tabId);
            }}
            onContextMenu={(event) => {
              event.preventDefault();
              setContextMenuFor({ id: tabId, x: event.clientX, y: event.clientY });
            }}
            draggable
            onDragStart={(event) => {
              setDraggingTab(tabId);
              event.dataTransfer.dropEffect = "move";
              event.dataTransfer.effectAllowed = "move";
            }}
            onDrop={() => {
              setDraggingTab(undefined);
              setSelectedTab(tabId);
            }}
            onDragOver={(event) => {
              event.preventDefault();
              if (draggingTab === tabId || draggingTab === undefined) return;
              if (draggingTab === undefined) return tabOrder;
              const newOrder = tabOrder.filter((id) => id !== draggingTab);
              newOrder.splice(tabOrder.indexOf(tabId), 0, draggingTab);
              setTabOrder(newOrder);
            }}
            iconPosition="end"
            icon={
              tabId === selectedTab && tabId !== editingNameOf ? (
                <IconButton
                  color="default"
                  size="small"
                  component="span"
                  onClick={() => removeTab(tabId)}
                  aria-label="Close tab"
                  edge={false}
                  tabIndex={-1}
                  aria-hidden
                >
                  <FontAwesomeIcon icon="times" fixedWidth />
                </IconButton>
              ) : tabId !== selectedTab && queries[tabId].loading ? (
                <FontAwesomeIcon icon="spinner-third" spin />
              ) : undefined
            }
          />
        ))}
      </TabList>
      <Divider variant="middle" orientation="vertical" flexItem />
      {addTab && (
        <IconButton aria-label="Add new tab" edge="end" onClick={() => addTab()} className="mr-1">
          <FontAwesomeIcon icon="plus" fixedWidth />
        </IconButton>
      )}
      <Menu
        open={contextMenuFor !== undefined}
        anchorReference="anchorPosition"
        onClose={() => setContextMenuFor(undefined)}
        anchorPosition={contextMenuFor !== undefined ? { top: contextMenuFor.y, left: contextMenuFor.x } : undefined}
        onContextMenu={(e) => {
          // Prevent opening the browsers options menu, this is fine as long as the items only contain buttons
          // This mimics the browsers context interactions. (e.g. they close first on any click as well)
          // The only drawback is that hover interactions don't work
          e.preventDefault();
          setContextMenuFor(undefined);
        }}
      >
        <MenuItem
          onClick={() => {
            setContextMenuFor(undefined);
            setEditingNameOf(contextMenuFor!.id);
          }}
        >
          Rename tab
        </MenuItem>
        {addTab && (
          <MenuItem
            onClick={() => {
              const tabConfig = queries[contextMenuFor!.id];
              addTab({ ...tabConfig, name: `Copy of ${tabConfig.name}` });
              setContextMenuFor(undefined);
            }}
          >
            Duplicate tab
          </MenuItem>
        )}
        {addTab && (
          <MenuItem
            onClick={() => {
              addTab();
              setContextMenuFor(undefined);
            }}
          >
            Create new tab
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={removeTabFromContext}>Close tab</MenuItem>
        <MenuItem onClick={removeOtherTabsFromContext}>Close other tabs</MenuItem>
        <MenuItem onClick={openLastTab} disabled={closedTabs.length === 0}>
          Reopen tab
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default React.memo(SparqlIDETabMenu);
