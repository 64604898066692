import { MenuItem, Select } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { Alert, Avatar, RemoveBtn } from "#components/index.ts";
import { Member } from "#reducers/accountCollection.ts";
import * as styles from "./styles.scss";

namespace OrgMemberListItem {
  export interface Props {
    member: Member;
    className?: string;
    link?: string;
    changeRoleHandler?: (member: Member, role: Models.OrgRole) => Promise<any>;
    changeRoleDisabled?: string;
    removeMemberHandler?: (member: Member) => Promise<any>;
    removeMemberDisabled?: string;
  }
  export interface State {
    error?: string;
  }
}

const OrgMemberListItem: React.FC<OrgMemberListItem.Props> = ({
  member,
  className,
  changeRoleHandler,
  changeRoleDisabled,
  link,
  removeMemberHandler,
  removeMemberDisabled,
}) => {
  // state: OrgMemberListItem.State = { error: null };

  // removeMemberHandler = () => {

  // };
  // render() {
  // const { error } = this.state;
  const [error, setError] = React.useState<string>();
  const useLink = link || `/${member.user.accountName}`;

  return (
    <div className={className}>
      <div className={getClassName(styles.box)}>
        <div className={styles.colAvatar}>
          {member.user.accountName && (
            <Avatar
              linkTo={useLink}
              size="md"
              avatarName={member.user.accountName}
              avatarUrl={member.user.avatarUrl}
              className={styles.avatar}
              alt=""
            />
          )}
        </div>
        <div className={styles.colDescription}>
          <Link to={useLink}>
            <h3>{member.user.accountName}</h3>
          </Link>
          <p className={styles.description}>{member.user.email}</p>
        </div>
        <div className={styles.colIcons}>
          {changeRoleHandler ? (
            <Select
              title={changeRoleDisabled}
              className={styles.roleSelectField}
              value={member.role}
              onChange={(e) => {
                if (member.role === e.target.value) {
                  //nothing changed
                } else {
                  setError(undefined);
                  changeRoleHandler(member, e.target.value as any).catch((e) => setError(e.message));
                }
              }}
              disabled={!!changeRoleDisabled}
              id={`member_${member.user.accountName}`}
            >
              <MenuItem value="owner">Owner</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          ) : (
            <span>{member.role}</span>
          )}
          {removeMemberHandler && (
            <RemoveBtn
              onClick={() => {
                setError(undefined);
                removeMemberHandler(member).catch((e: Error) => setError(e.message));
              }}
              title={removeMemberDisabled || "Remove member"}
              disabled={!!removeMemberDisabled}
            />
          )}
        </div>
      </div>
      {error && (
        <div style={{ padding: "0px 15px 15px 15px" }}>
          <Alert transparent message={error} size="sm" />
        </div>
      )}
    </div>
  );
};
export default OrgMemberListItem;
