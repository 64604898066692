import getClassName from "classnames";
import { take } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import { CachePolicies } from "use-http";
import { Models } from "@triply/utils";
import { StoryBadge } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./style.scss";

export interface Props {
  queryId: string;
}

const Dependents: React.FC<Props> = ({ queryId }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const { data, error } = useFetch<Models.StoryFacets>(
    constructUrlToApi({ pathname: "/facets/stories", query: { query: queryId } }),
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );

  if (!data || error || data.count === 0) return null;

  return (
    <div className={getClassName("white rounding shadow my-6 px-5", styles.dependents)}>
      <h5 className={getClassName("paragraphHeader")} title="Stories that use this query">
        Stories that use this query
      </h5>
      <div className={getClassName("flex pb-5", styles.dependentsList)}>
        {take(data.results, 3).map((s) => {
          return (
            <div key={s.id}>
              <Link
                to={`/${s.ownerAccountName}/-/stories/${s.name}`}
                className="flex center"
                title={`${s.displayName || s.name} by ${s.ownerAccountDisplayName || s.ownerAccountName}`}
                aria-label={`${s.displayName || s.name} by ${s.ownerAccountDisplayName || s.ownerAccountName}`}
              >
                <StoryBadge bannerUrl={s.bannerUrl} className="mr-3" />
                <div>{s.displayName || s.name}</div>
              </Link>
            </div>
          );
        })}
        {data.count > 3 && (
          <Link
            to={{ pathname: "/browse/stories", search: stringifyQuery({ query: queryId }) }}
            aria-label="Show all dependent stories"
            className="mt-2"
          >
            {`Show all ${data.count} stories...`}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Dependents;
