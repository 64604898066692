import * as React from "react";
import { FACETED_SEARCH_NULL_ALIAS } from "@triply/utils/Constants.js";
import { DatasetFacets, OrganizationFacets, QueryFacets, StoryFacets } from "@triply/utils/Models.js";
import Facet from "./Facet.tsx";
import StatementsSlider from "./StatementsSlider.tsx";

interface Props {
  facets: DatasetFacets["facets"] | StoryFacets["facets"] | QueryFacets["facets"] | OrganizationFacets["facets"];
  query: any;
}

const Facets: React.FC<Props> = ({ facets, query }) => {
  return (
    <>
      {"dataset" in facets && !!facets.dataset?.length && (
        <Facet data={facets.dataset} query={query} title="Dataset" id="dataset" />
      )}

      {"query" in facets && !!facets.query?.length && (
        <Facet data={facets.query} query={query} title="Query" id="query" />
      )}

      {"visualization" in facets && !!facets.visualization?.length && (
        <Facet data={facets.visualization} query={query} title="Visualization" id="visualization" />
      )}

      {("sparql" in facets || "search" in facets) && (
        <div>
          <Facet data={[]} query={query} title="Services" id="titleOnly" />
          {"sparql" in facets && facets.sparql && <Facet data={[facets.sparql]} query={query} id="sparql" />}
          {"search" in facets && facets.search && <Facet data={[facets.search]} query={query} id="search" />}
        </div>
      )}

      {"statements" in facets && facets.statements && <StatementsSlider query={query} facets={facets.statements} />}

      {"topic" in facets && !!facets.topic?.length && (
        <Facet data={facets.topic} query={query} title="Topic" id="topic" />
      )}

      {"license" in facets &&
        !!facets.license?.length &&
        ("license" in query || facets.license.some((item) => item.id !== FACETED_SEARCH_NULL_ALIAS)) && (
          <Facet data={facets.license} query={query} title="License" id="license" />
        )}

      {"owner" in facets && !!facets.owner.length && (
        <Facet data={facets.owner} query={query} title="Owner" id="owner" />
      )}

      {!!facets.created.length && <Facet data={facets.created} query={query} title="Created" id="created" />}

      {"access" in facets && !!facets.access?.length && ("access" in query || facets.access.length > 1) && (
        <Facet data={facets.access} query={query} title="Access" id="access" />
      )}
    </>
  );
};

export default Facets;
