import { jsonLanguage } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import { omit } from "lodash-es";
import * as React from "react";
import { Alert } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import { Service } from "#reducers/services.ts";
import { ServiceGraphs } from "./Graphs.tsx";
import * as styles from "./style.scss";

interface Props {
  service: Service;
}

const AdminInfo: React.FC<Props> = ({ service }) => {
  const acl = useAcl();
  return (
    <>
      <h3>{service.name || "Unknown"}</h3>
      {service.foundInMongo === false && <Alert message="Docker container found, but metadata is missing" />}
      {service.foundInDocker === false && !service.error && service.status === "running" && (
        <Alert message="No docker container found" />
      )}

      {service.error && (
        <Alert
          message={
            <div>
              {service.foundInDocker === false && <div>No docker container found</div>}
              <div>{service.error.message}</div>
              {service.error.serverError && <div>Admin error message: {service.error.serverError}</div>}
            </div>
          }
        />
      )}
      {!!service.numberOfGraphs && !!service.dataset && (
        <>
          <h4>Graphs</h4>
          <div>
            <ServiceGraphs
              apiServicePath={`/datasets/${service.dataset.owner.accountName}/${service.dataset.name}/services/${service.name}`}
              consoleDatasetPath={`/${service.dataset.owner.accountName}/${service.dataset.name}`}
              datasetId={service.dataset.id}
              numberOfGraphs={service.numberOfGraphs}
              serviceId={service.id}
            />
          </div>
        </>
      )}
      {acl.check({ action: "showServiceDebugInformation" }).granted && (
        <CodeMirror
          className={styles.codemirror}
          value={JSON.stringify(
            //omit the dataset info: it's irrelevant for the service admin info doc, and only bloats the page
            omit(service, "dataset"),
            null,
            2,
          )}
          readOnly
          extensions={[jsonLanguage, githubLight, EditorView.lineWrapping]}
        />
      )}
    </>
  );
};
export default AdminInfo;
