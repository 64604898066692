/**
 * This file is responsible for mapping environment variables to a js object. It follows the following convention:
   - Every env variable starting with `TRIPLY__` is processed by this module
   - A double underscore (`__`) is a delimiter between objects. I.e. TRIPLY__A__B=whatever, is converted to `{a: {b: 'whatever'}}`
   - A single underscore is a delimiter for camelcasing. I.e. WHAT_EVER is converted to `whatEver`
 */
import fs from "fs";
import { fromPairs, merge } from "lodash-es";
import { ParsedQs } from "qs";
import UrlParse from "url-parse";
import { getEnvironmentVarsAsObject } from "@triply/utils/configUtils.js";
import { buildRefToVersion, stringifyQuery, UrlInfo } from "@triply/utils-private";

export interface BrandingLinks {
  twitter: string;
  github: string;
}

const DEFAULT_OSM_TILE_LAYER = {
  id: "osm",
  name: "OpenStreetMap",
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  maxZoom: 18,
  minZoom: 0,
};

/**
Get most of the config from a server '/info' request. We need some information before we can make that request though.
So, we're putting that part in this config
**/
export class Config {
  constructor() {
    //set before other defaults, as some values may inherit from others
    merge(this, getEnvironmentVarsAsObject({ env: process.env, envPrefix: "TRIPLY__" }));
    this.setDefaults();
    //also set from env after defaults, to overwrite them
    merge(this, getEnvironmentVarsAsObject({ env: process.env, envPrefix: "TRIPLY__" }));
    this.postProcess();
  }
  /**
   * Properties that are set in `setDefaults` (i.e., we can assume they aren't undefined)
   */
  public internalApiUrl!: UrlInfo;
  public consoleUrl!: UrlInfo;
  public apiUrl!: UrlInfo;
  public numberOfWorkers!: number;
  public brandingLinks!: BrandingLinks;
  public consoleVersion!: string;
  public consoleBuildDate!: string;
  public osmTileUrl!: string;
  public robotsTxtDisallow!: boolean;
  public dereferencePaths!: string[];
  public serveSourceMaps!: boolean;
  public dev!: boolean;
  public disableHostnameValidationFor!: string[];
  public theme!: {
    material: {
      primary: string;
      secondary: string;
      error: string;
      success: string;
      warning: string;
    };
    primary: string;
    secondary: string;
    banner: string;
    error: string;
    success: string;
    warning: string;
    info: string;
    link: string;
    linkHover: string;
    gray: string;
    grayLight: string;
    grayLighter: string;
    grayDark: string;
  };
  public tileLayers!: {
    id: string;
    name: string;
    tileUrl: string;
    attribution?: string;
    maxZoom?: number;
    minZoom?: number;
    bounds?: {
      lowerLat: number;
      lowerLng: number;
      upperLat: number;
      upperLng: number;
    };
  }[];
  public defaultTileMap!: "osm" | string;

  public editMode?: boolean;

  //pass this as `key1:val1,key2:val2`
  public htmlMetaFields?: { [key: string]: string };
  public plausible?: string;
  public imageProxy?: string;
  public buildRef?: string;
  public buildDate?: string;

  public customCss?: string;

  public etlLicenseKey?: string;

  setDefaults() {
    this.disableHostnameValidationFor = [];
    this.dev = false;
    this.editMode = false;
    const internalApiSsl = this.internalApiUrl?.ssl ?? false;
    this.internalApiUrl = {
      port: internalApiSsl ? 443 : 80,
      ssl: internalApiSsl,
      domain: "api",
    };
    const apiSsl = this.apiUrl?.ssl ?? true;
    this.apiUrl = {
      port: apiSsl ? 443 : 80,
      ssl: apiSsl,
      domain: "api.triply.cc",
    };
    const consoleSsl = this.consoleUrl?.ssl ?? true;
    this.consoleUrl = {
      port: consoleSsl ? 443 : 80,
      ssl: consoleSsl,
      domain: "console.triply.cc",
    };
    this.numberOfWorkers = 1;
    this.brandingLinks = {
      twitter: "https://twitter.com/TriplyDB",
      github: "https://github.com/OpenTriply",
    };
    this.buildRef = process.env["BUILD_REF"];
    this.buildDate = process.env["BUILD_DATE"];

    // we only expose the version when we're really in a version branch
    // Support anything that looks like xxxx-v-23.06.1-1
    this.consoleVersion = buildRefToVersion(this.buildRef) || "unset";
    this.consoleBuildDate = this.buildDate || "";
    this.osmTileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    this.theme = {
      material: {
        primary: "#1690c6",
        secondary: "#fa8d3e",
        error: "#f44336",
        success: "#5cb85c",
        warning: "#ff9800",
      },
      primary: "#1690c6",
      banner: "#1690c6",
      secondary: "#fa8d3e",
      error: "#f44336",
      success: "#5cb85c",
      warning: "#ff9800",
      info: "#1690c6",
      link: "#1690c6",
      linkHover: "#3fb5ea",
      grayLighter: "#ebedef",
      grayLight: "#b3b3b3",
      gray: "#666666",
      grayDark: "#333",
    };
    this.defaultTileMap = "osm";
    this.tileLayers = [];
    this.etlLicenseKey = undefined;
  }

  postProcess() {
    if (__SERVER__) {
      if (this.htmlMetaFields && typeof this.htmlMetaFields === "string") {
        const asString = this.htmlMetaFields as string;
        const pairs = asString
          .split(",")
          .map((keyValPair) => keyValPair.split(":"))
          .filter((p) => p.length === 2 && p[0].length && p[1].length);

        this.htmlMetaFields = fromPairs(pairs);
      }
      if (typeof (this.dereferencePaths as any) === "string") {
        this.dereferencePaths = (this.dereferencePaths as unknown as string).split(",").filter((p) => !!p);
      }
      if (!this.dereferencePaths) this.dereferencePaths = [];
      try {
        // store this file locally for debugging purposes. That way we can easily see the configuration used
        // will not work for kubernetes in production. For k8s, we only allow writing to volumes.
        fs.writeFileSync("./.staticConfig.dump.json", JSON.stringify(this, null, 2));
      } catch (e: any) {
        // do nothing, not a problem
      }
    }
    const osmTileIndex = this.tileLayers.findIndex((tileLayer) => tileLayer.id === "osm");
    if (osmTileIndex === -1) {
      this.tileLayers.push({
        ...DEFAULT_OSM_TILE_LAYER,
        tileUrl: this.osmTileUrl,
      });
    } else {
      this.tileLayers[osmTileIndex] = {
        ...DEFAULT_OSM_TILE_LAYER,
        tileUrl: this.osmTileUrl,
        ...(this.tileLayers[osmTileIndex] as any),
      };
    }
    this.tileLayers.sort((a, b) => {
      if (a.id === this.defaultTileMap) return -1;
      if (b.id === this.defaultTileMap) return 1;
      return 0;
    });
  }
}

export function urlInfoToString(config?: UrlInfo, url?: { pathname: string; query?: ParsedQs }) {
  if (url?.pathname && url.pathname[0] !== "/") {
    throw new Error(`Invalid path name passed. Expected an absoute path. Got '${url.pathname}'`);
  }
  if (!config) {
    throw new Error(`Expected a url info object`);
  }
  const scheme = config.ssl ? "https" : "http";
  const hidePortFromUrl = !config.port || (config.port === 80 && !config.ssl) || (config.port === 443 && config.ssl);
  const port = hidePortFromUrl ? "" : ":" + config.port;
  return (
    scheme + "://" + config.domain + port + (url?.pathname || "") + (url?.query ? "?" + stringifyQuery(url.query) : "")
  );
}

export type ConstructConsoleUrl = (opts?: { pathname: string; query?: ParsedQs }) => string;
export function getConstructConsoleUrl(opts: { consoleUrlInfo: UrlInfo }): ConstructConsoleUrl {
  const constructConsoleUrl: ConstructConsoleUrl = (url) => {
    return urlInfoToString(opts.consoleUrlInfo, url);
  };
  return constructConsoleUrl;
}

export type ConstructUrlToApi = (
  opts:
    | {
        fullUrl: string;
        query?: ParsedQs;
        fromBrowser?: boolean;
      }
    | { pathname: string; query?: ParsedQs; fromBrowser?: boolean },
) => string;
export function getConstructUrlToApi(opts: { consoleUrlInfo: UrlInfo; apiUrlInfo: UrlInfo }): ConstructUrlToApi {
  let internalApiUrl: UrlInfo | undefined;
  if (__SERVER__) {
    internalApiUrl = getConfig().internalApiUrl;
  }
  const constructUrlToApi: ConstructUrlToApi = (url) => {
    let parsed: UrlParse<{}>;

    if ("fullUrl" in url) {
      parsed = new UrlParse(url.fullUrl, true);
      if (parsed.hostname !== opts.apiUrlInfo.domain) {
        throw new Error(`Unexpected URL ${url.fullUrl}. Expected this url to point to the TriplyDB API.`);
      }
    } else {
      if (url.pathname[0] !== "/") {
        throw new Error(`Invalid path name passed. Expected an absoute path. Got '${url.pathname}'`);
      }
      parsed = new UrlParse("");
      parsed.set("pathname", url.pathname);
    }
    if (url.query) {
      for (const name in url.query) {
        if (Array.isArray(url.query[name])) {
          console.error("invalid query url", url.query);
          throw new Error(`Invalid query URL`);
        }
      }
      // Above, we checked whether the query url (output of QS parsing lib) does not contain any arrays as value
      // considering this is not supported by the UrlParse lib
      // That means we should be able to safely pass the var, and cast as any
      parsed.set("query", { ...parsed.query, ...(url.query as any) });
    }
    if (__SERVER__ && internalApiUrl && !url.fromBrowser) {
      parsed.set("protocol", internalApiUrl.ssl ? "https" : "http");
      parsed.set("hostname", internalApiUrl.domain);
      parsed.set("port", "" + internalApiUrl.port);
    } else {
      parsed.set("protocol", opts.consoleUrlInfo.ssl ? "https" : "http");
      parsed.set("hostname", opts.consoleUrlInfo.domain);
      if (opts.consoleUrlInfo.ssl) {
        //Assuming we always want to use the ssl port here
        parsed.set("port", undefined);
      } else {
        parsed.set("port", "" + opts.consoleUrlInfo.port);
      }
      parsed.set("pathname", "/_api" + parsed.pathname);
    }
    return parsed.toString();
  };
  return constructUrlToApi;
}

var config: Config;
export function getConfig() {
  if (!config) {
    config = new Config();
  }
  return config;
}
