import * as connectedReactRouter from "connected-react-router";
import * as ReduxForm from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { createDataset } from "#reducers/datasetManagement.ts";
import useDispatch from "./useDispatch.ts";

export function useCreateDataset(accountName: string | undefined, replaceCurrentPage: boolean) {
  const dispatch = useDispatch();
  return (values: Forms.DatasetAdd.FormData) => {
    if (!accountName) return;
    return dispatch<typeof createDataset>(
      createDataset(accountName, values.name || "", values.accessLevel, values.description, values.displayName),
    ).then(
      () => {
        dispatch(
          connectedReactRouter[replaceCurrentPage ? "replace" : "push"](`/${accountName}/${values.name}/graphs`),
        );
      },
      (e: any) => {
        if (e?.status === 409) {
          throw new ReduxForm.SubmissionError({ name: "URL is already in use." });
        }
        throw new ReduxForm.SubmissionError({ _error: e.message });
      },
    );
  };
}
