// extracted by mini-css-extract-plugin
var _1 = "style--activeTab--tUVjulQe";
var _2 = "style--innerSearchContainer--iAD_pZjZ";
var _3 = "style--onSearchPage--ZOaTXQfC";
var _4 = "style--outerSearchContainer--PiEvk6mI";
var _5 = "style--searchBanner--CCGzc_Y2";
var _6 = "style--searchResults--e0BZ8zJo";
var _7 = "style--tab--IYU7JEW8";
var _8 = "style--tabContainer--AfuhwroK";
export { _1 as "activeTab", _2 as "innerSearchContainer", _3 as "onSearchPage", _4 as "outerSearchContainer", _5 as "searchBanner", _6 as "searchResults", _7 as "tab", _8 as "tabContainer" }
