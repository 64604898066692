// extracted by mini-css-extract-plugin
var _1 = "styles--cell--bpApOEeT";
var _2 = "styles--cellContent--RFdvNMQY";
var _3 = "styles--dragHandle--njmvV0wE";
var _4 = "styles--footer--dPqeA3tE";
var _5 = "styles--header--hUGOpty9";
var _6 = "styles--headerButton--OkmWRT7f";
var _7 = "styles--headerCell--u9FwR_qj";
var _8 = "styles--hidePagination--XSp5aIUR";
var _9 = "styles--hidePaginationPerRows--hCZc9Sis";
var _a = "styles--isDragging--Ha9U8L9r";
var _b = "styles--prefixTooltip--o1sEA34V";
var _c = "styles--table--QGajZs7x";
var _d = "styles--tableContainer--gFoySY4t";
var _e = "styles--tableDrag--il3f9rjH";
export { _1 as "cell", _2 as "cellContent", _3 as "dragHandle", _4 as "footer", _5 as "header", _6 as "headerButton", _7 as "headerCell", _8 as "hidePagination", _9 as "hidePaginationPerRows", _a as "isDragging", _b as "prefixTooltip", _c as "table", _d as "tableContainer", _e as "tableDrag" }
