import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import * as Forms from "#components/Forms/index.ts";
import { Button, FontAwesomeIcon, QueryListItem } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import { getQueries, getQueryRecordForAccount } from "#reducers/queries.ts";
import { useCreateQuery } from "../../helpers/hooks/queries.ts";
import * as styles from "./style.scss";

const Queries: React.FC<{ mayCreateQuery: boolean }> = ({ mayCreateQuery }) => {
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();
  const currentAccount = useCurrentAccount();
  const createQuery = useCreateQuery(currentAccount?.accountName, false);
  const queryRecord = useSelector((state: GlobalState) =>
    currentAccount?.accountName ? getQueryRecordForAccount(state, currentAccount.accountName) : undefined,
  );
  const queries = (queryRecord && queryRecord.list && Object.values(queryRecord.list)) || [];
  const nextPage = queryRecord && queryRecord.nextPage;
  const getNextPage = () => nextPage && dispatch(getQueries(undefined, undefined, nextPage));

  if (!currentAccount) return null;

  const hasQueries = queries && queries.length > 0;
  const showForm = !hasQueries && mayCreateQuery;

  return (
    <div className="my-5">
      {!showForm && !hasQueries && (
        <div className={getClassName(styles.center, styles.empty)}>
          <FontAwesomeIcon size="3x" icon={["fal", "empty-set"]} />
        </div>
      )}

      {showForm && (
        <div>
          <h3 className={getClassName("headerSpacing", styles.title)}>Create your first query</h3>
          <Forms.QueryMeta.QueryMetaForm
            initialValues={{
              serviceType: "speedy",
              accessLevel: "private",
            }}
            isNewQuery
            onSubmit={createQuery}
            datasetSearchUrl={constructUrlToApi({ pathname: "/datasets" })}
            className={styles.form}
            owner={currentAccount}
          />
        </div>
      )}

      {!showForm && (
        <div>
          {queries && queries.map((q) => <QueryListItem query={q} key={q.id} className={styles.listItem} />)}

          {nextPage && (
            <div className={styles.showMore}>
              <Button elevation onClick={getNextPage} className="rounding mt-5">
                Show more...
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Queries;
