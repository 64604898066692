import * as React from "react";

export default function useLocalStorage(key: string, initialValue: string) {
  const [localStorageItem, setLocalStorageItem] = React.useState<string>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    const storage = localStorage.getItem(key);
    return storage || initialValue;
  });
  const setLocalStorage = (item: string) => {
    if (localStorage) {
      localStorage.setItem(key, item);
      setLocalStorageItem(item);
    }
  };
  return [localStorageItem, setLocalStorage] as const;
}
