import { Alert } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField, Label, MuiSwitchRedux } from "#components/index.ts";
import ToggleSwitch from "#components/Redux/ToggleSwitch/index.tsx";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";

namespace TfaSettingsForm {
  export interface FormData {
    enforceTfa: boolean;
    role: "all" | "admins";
    authMethod: "password" | "all";
  }
}

interface Props extends Partial<ReduxForm.InjectedFormProps<TfaSettingsForm.FormData>> {}

const formSelector = ReduxForm.formValueSelector("tfaSettings"); // <-- same as form name

const TfaSettingsComponent: React.FC<Props> = ({ handleSubmit, submitting, submitSucceeded, pristine }) => {
  const authenticatedUser = useAuthenticatedUser();
  const enforceTfa = useSelector(
    (state: GlobalState) => formSelector(state, "enforceTfa") ?? !!state.config.clientConfig?.enforceTfa,
  );

  if (!authenticatedUser) throw new Error("Expected AuthenticatedUser to be defined");

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit}>
        {!authenticatedUser.mfaEnabled && (
          <Alert className="mt-4" severity="warning">
            You need to enable 2FA for your own account to be able to use this feature.
          </Alert>
        )}
        <FormField
          labelWidth={300}
          label="Enforce 2FA"
          className="mt-6 mb-5 center mr-3"
          helperText="If a user has not configured 2FA yet they will be prompted to do so the next time they log in."
        >
          <ReduxForm.Field
            name="enforceTfa"
            component={MuiSwitchRedux}
            disabled={!authenticatedUser.mfaEnabled}
            className="ml-3"
          />
        </FormField>
        <FormField
          labelWidth={300}
          label="Enforce for roles"
          className="mt-6 mb-5 center mr-3"
          helperText="Select which roles are required to use 2FA."
        >
          <ReduxForm.Field
            name="role"
            suppressHydrationWarning
            component={ToggleSwitch}
            disabled={!enforceTfa || !authenticatedUser.mfaEnabled}
            props={{
              options: [
                { label: "Admins", title: "2FA is enforced for administrators", value: "admins" },
                { label: "All", title: "2FA is enforced for all users", value: "all" },
              ],
            }}
          />
        </FormField>
        <FormField
          labelWidth={300}
          label="Enforce for authentication methods"
          className="mt-6 mb-5 center mr-3"
          helperText="Select which authentication methods are required to use 2FA."
        >
          <ReduxForm.Field
            name="authMethod"
            component={ToggleSwitch}
            disabled={!enforceTfa || !authenticatedUser.mfaEnabled}
            props={{
              options: [
                {
                  label: "Password",
                  title: "2FA is enforced for users with password authentication",
                  value: "password",
                },
                {
                  label: "All",
                  title: "2FA is enforced for everyone regardless of authentication method",
                  value: "all",
                },
              ],
            }}
          />
        </FormField>
        <div className="form-group mt-5">
          <LoadingButton
            type="submit"
            color="secondary"
            disabled={pristine || !authenticatedUser.mfaEnabled}
            onClick={handleSubmit}
            loading={submitting}
          >
            Save
          </LoadingButton>
        </div>
      </form>
      {submitSucceeded && pristine && (
        <div className="mt-4">
          <Label success message="You have successfully updated the authentication settings." />
        </div>
      )}
    </div>
  );
};

const TfaSettingsForm = ReduxForm.reduxForm<TfaSettingsForm.FormData, Props>({
  form: "tfaSettings",
  enableReinitialize: true,
})(TfaSettingsComponent as any);

export default TfaSettingsForm;
