import { Alert, Chip } from "@mui/material";
import { upperFirst } from "lodash-es";
import * as React from "react";
import { CachePolicies } from "use-http";
import { Models } from "@triply/utils";
import { Button, ErrorPage, FontAwesomeButton, HumanizedDate, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import * as styles from "./style.scss";

export namespace AdminTasks {
  export interface OwnProps extends IComponentProps {}
  export type Props = OwnProps;
}

const AdminTasks: React.FC<AdminTasks.Props> = (props) => {
  const acl = useAcl();
  const constructUrlToApi = useConstructUrlToApi();
  const tasksApiUrl = constructUrlToApi({ pathname: "/admin/tasks" });
  const [tasks, setTasks] = React.useState<Models.BackgroundTask[]>([]);
  const { data, error } = useFetch(
    tasksApiUrl,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );
  React.useEffect(() => {
    if (data && !error) {
      setTasks(data);
    }
  }, [error, data, setTasks]);
  const updateTask = React.useCallback(
    (tasks: Models.BackgroundTask[], task: Models.BackgroundTask, i: number) => {
      const copyOfTasks = [...tasks];
      copyOfTasks[i] = task;
      setTasks(copyOfTasks);
    },
    [setTasks],
  );
  if (!acl.check({ action: "manageBackgroundTasks" }).granted) {
    return <ErrorPage statusCode={404} />;
  }
  return (
    <div>
      <Meta currentPath={props.location.pathname} title="Background tasks - Admin settings" />
      {error && <Alert severity="error">{error.message}</Alert>}
      <div className="constrainWidth mt-3">
        {tasks.map((task, i) => {
          return <TaskComponent key={task.id} i={i} updateTask={updateTask} tasks={tasks} />;
        })}
      </div>
    </div>
  );
};

interface TaskProps {
  tasks: Models.BackgroundTask[];
  updateTask: (tasks: Models.BackgroundTask[], task: Models.BackgroundTask, i: number) => void;
  i: number;
}

const TaskComponent: React.FC<TaskProps> = ({ tasks, updateTask, i }) => {
  const task = tasks[i];
  const constructUrlToApi = useConstructUrlToApi();
  const taskApiUrl = constructUrlToApi({ pathname: "/admin/tasks/" + tasks[i].id });
  const { post, error, response } = useFetch<Models.BackgroundTask>(taskApiUrl, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const [showHistory, setShowHistory] = React.useState(false);
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className="whiteSink">
      <div>
        <div className={styles.mainTask}>
          <div className={styles.textContent}>
            <div className={styles.header}>
              <h3>{task.label} </h3>
              {!task.current && task.history[0]?.status === "finished" && <Chip color="success" label="Finished" />}
              <Chip size="small" color={"default"} label={task.mode} />
            </div>
            <p>{task.description}</p>
          </div>

          <div className={styles.actions}>
            <Button
              size="small"
              title="Start"
              disabled={!!task.current}
              onClick={() => {
                (async () => {
                  const task = await post({ action: "start" });
                  if (response.ok) updateTask(tasks, task, i);
                })().catch(console.error);
              }}
            >
              Start
            </Button>
          </div>
        </div>
        {task.current && (
          <>
            <hr />
            <h4>Running task</h4>
            <TaskExecutionComponent i={i} updateTask={updateTask} tasks={tasks} taskExecution={task.current} />
          </>
        )}
        {!!task.history.length && (
          <>
            <div>
              <FontAwesomeButton
                iconOnStart
                aria-label={showHistory ? "Hide history" : "Show history"}
                icon={["fas", showHistory ? "caret-down" : "caret-right"]}
                onClick={() => setShowHistory(!showHistory)}
                aria-expanded={showHistory}
                aria-controls={`${task.id}-history`}
              >
                {showHistory ? "Hide history" : "Show history"}
              </FontAwesomeButton>
              {showHistory && (
                <div className={styles.historyList}>
                  {task.history.map((execution) => (
                    <React.Fragment key={`${task.history}${execution.startedAt}`}>
                      <TaskExecutionComponent updateTask={updateTask} i={i} tasks={tasks} taskExecution={execution} />
                      <hr />
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface TaskExecutionProps {
  tasks: Models.BackgroundTask[];
  updateTask: (tasks: Models.BackgroundTask[], task: Models.BackgroundTask, i: number) => void;
  i: number;
  taskExecution: Models.BackgroundTaskExecution;
}

const chipStatusColor = {
  finished: "success",
  aborted: "warning",
  running: "default",
} as const;
const TaskExecutionComponent: React.FC<TaskExecutionProps> = ({ tasks, i, updateTask, taskExecution }) => {
  const task = tasks[i];
  const constructUrlToApi = useConstructUrlToApi();
  const taskApiUrl = constructUrlToApi({ pathname: "/admin/tasks/" + task.id });
  const { post, error, response } = useFetch<Models.BackgroundTask>(taskApiUrl, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className={styles.taskExecution}>
      <div className={styles.taskExecutionDescription}>
        <dl>
          <dt>Status</dt>
          <dd>
            <Chip size="small" color={chipStatusColor[taskExecution.status]} label={upperFirst(taskExecution.status)} />
          </dd>
          {taskExecution.status === "running" && (
            <>
              <dt>Active workers</dt>
              <dd>
                <span title={taskExecution.activeWorkers.join(", ")}>{taskExecution.activeWorkers.length}</span>
              </dd>
            </>
          )}
          <dt>Started at</dt>
          <dd>
            <HumanizedDate date={taskExecution.startedAt} />
          </dd>
          {taskExecution.stoppedAt && (
            <>
              <dt>Stopped at</dt>
              <dd>
                <HumanizedDate date={taskExecution.stoppedAt} />
              </dd>
            </>
          )}
          <dt>API version</dt>
          <dd>{taskExecution.apiVersion}</dd>
          <dt>Trigger</dt>
          <dd>
            <Chip size="small" color={"default"} label={taskExecution.trigger} />
          </dd>
          <dt>Report</dt>
          <dd>
            <pre className={styles.taskExecutionReport}>{taskExecution.report}</pre>
          </dd>
        </dl>
      </div>

      <div className={styles.taskExecutionActions}>
        {taskExecution.status === "running" && (
          <Button
            size="small"
            title="Abort"
            onClick={() => {
              (async () => {
                const task = await post({ action: "abort" });

                if (response.ok) updateTask(tasks, task, i);
              })().catch(console.error);
            }}
          >
            Abort
          </Button>
        )}
      </div>
    </div>
  );
};
export default AdminTasks;
