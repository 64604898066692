import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import {
  applyRuleConfig,
  getAccountNameValidations,
  getProfileNameValidations,
  required,
  toStringValidator,
} from "@triply/utils-private/validation.js";
import { Alert, Button, LoadingButton, NameAndUrl } from "#components/index.ts";
import { checkAccountName } from "#reducers/accounts.ts";
import { DispatchedFn, GlobalState } from "#reducers/index.ts";

const accountNameValidator = toStringValidator(getAccountNameValidations());
const organizationNameValidator = toStringValidator([
  ...getProfileNameValidations({ messageSubject: "An organization name" }),
  applyRuleConfig(required, {
    formatMessage: () => `An organization name is required.`,
  }),
]);

namespace OrgAddForm {
  export interface FormData {
    name: string;
    accountName: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    className?: string;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
  }
  export interface DispatchProps {
    checkAccountName: DispatchedFn<typeof checkAccountName>;
  }
  export interface PropsFromState {}
  export interface State {}
}

// //use withRef: we need access to the form from a modal parent, to start submitting it

const OrgAddForm = connect<
  OrgAddForm.State,
  { [K in keyof OrgAddForm.DispatchProps]: any },
  OrgAddForm.OwnProps,
  GlobalState
>(
  () => ({}),
  {
    checkAccountName: checkAccountName,
  },
  undefined,
  { forwardRef: true },
)(
  ReduxForm.reduxForm<OrgAddForm.FormData, OrgAddForm.Props>({
    form: "orgAdd",
    validate: memoizee(
      (formData: OrgAddForm.FormData, _props: OrgAddForm.Props) => {
        return {
          name: organizationNameValidator(formData.name),
          accountName: accountNameValidator(formData.accountName),
        };
      },
      { max: 10 },
    ),
  })(
    class OrgAddForm extends React.PureComponent<
      OrgAddForm.Props & ReduxForm.InjectedFormProps<OrgAddForm.FormData>,
      OrgAddForm.State
    > {
      cancel = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        this.props.cancel?.(e);
      };
      render() {
        const { handleSubmit, error, submitting, className, cancel, invalid, change, initialValues, pristine } =
          this.props;

        return (
          <form onSubmit={handleSubmit} className={getClassName(className)}>
            <NameAndUrl
              className="mb-7"
              changeFormValues={change}
              initialSlug={initialValues?.name}
              formIsPristine={pristine}
              autoFocus
              nameFieldName="name"
              nameFieldLabel="Organization name"
              slugFieldName="accountName"
              slugFieldLabel="URL"
              urlPrefixPath="/"
            />

            <Alert className="mb-5" transparent message={error} />

            <div>
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={submitting || invalid}
                onClick={handleSubmit}
                loading={submitting}
              >
                Create
              </LoadingButton>
              {!!cancel && (
                <Button onClick={this.cancel} className="ml-2" variant="text">
                  Cancel
                </Button>
              )}
            </div>
          </form>
        );
      }
    } as any,
  ) as any,
);
export default OrgAddForm;
