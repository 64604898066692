// extracted by mini-css-extract-plugin
var _1 = "style--avatar--CZWKt5Tw";
var _2 = "style--avatar-lg--fAuKwd1F";
var _3 = "style--avatar-md--iVzYyn9C";
var _4 = "style--avatar-sm--z4hYXhQ4";
var _5 = "style--avatar-xl--kXzk6fIr";
var _6 = "style--avatar-xs--_JlJtlmR";
var _7 = "style--isLink--lAGD4LPW";
var _8 = "style--letter--iHfxwqk4";
var _9 = "style--square--BJTxIFF6";
export { _1 as "avatar", _2 as "avatarLg", _3 as "avatarMd", _4 as "avatarSm", _5 as "avatarXl", _6 as "avatarXs", _7 as "isLink", _8 as "letter", _9 as "square" }
