import * as React from "react";

export interface Props {
  isFinished: boolean;
  animationDuration: number;
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children, isFinished, animationDuration }) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: "none",
      transition: `opacity ${animationDuration}ms linear`,
    }}
  >
    {children}
  </div>
);

export default Container;
