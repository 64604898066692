import { Paper, Popper } from "@mui/material";
import getClassName from "classnames";
import { identity, merge, pickBy, throttle, toString } from "lodash-es";
import * as React from "react";
import Autosuggest from "react-autosuggest";
import * as ReduxForm from "redux-form";
import MuiTextField from "#components/MuiTextField/index.tsx";
import * as styles from "./style.scss";

namespace MuiAutosuggest {
  /**
   * Props
   * @template S Suggestion type, used for determining types to render, default string.
   * This is often the same interface as the one we get back from the API
   * @template R Type of t * ashe redux value (when this component is used as redux component.) Defaults to string
   */
  export interface Props<S = string, R = string> {
    showErrors?: boolean;
    noResultsMessage?: string;
    className?: string;
    loadSuggestions: (searchString: string) => Promise<S[]>;
    throttleDelay?: number;
    // Transform object to a text representation (used to populate text input field)
    getSuggestionSearchText: (suggestion: S) => string;
    renderSuggestion: (suggestion: S, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => React.ReactNode;
    onSuggestionSelected?: Autosuggest.AutosuggestProps<S, any>["onSuggestionSelected"];
    clearOnSelect?: boolean;
    onKeyPress?: (keyEvent: React.KeyboardEvent<HTMLDivElement>, searchString: string) => void;
    placeholder?: string;
    label?: string;
    error?: string;
    innerRef?: React.Ref<any>;
    initialValue?: string;
    TextFieldProps?: MuiTextField.Props;
    allowNewValues?: boolean;
    //Allows setting of values that are not part of the suggestions
    onInputChange?: (val: string) => void;

    // Redux specific
    transformInitialValueToSearchText?: (initialValue: R) => string;
    transformSuggestionToReduxValue?: (suggestion: S) => R;
  }
  export interface State<S> {
    suggestions: S[];
    searchText: string;
    hasSelectedSuggestion: boolean;
  }
}
//Exporting this class as-is, without being wrapped. That way we can use it's typings in React.Ref
export class _MuiAutosuggest<S = string, V = string> extends React.PureComponent<
  MuiAutosuggest.Props<S, V>,
  MuiAutosuggest.State<S>
> {
  state: MuiAutosuggest.State<S> = {
    suggestions: [],
    searchText: this.props.initialValue || "",
    hasSelectedSuggestion: !!this.props.initialValue,
  };

  componentWillUnmount() {
    this.loadSuggestions.cancel();
  }

  //Had some issues when upgrading material-ui typings.
  //Crude fix for now was to add any typings for missing keys.
  //When working in this codebase, we might want to improve this.
  //See the discussion in this PR for info about the typing changes:
  //https://github.com/DefinitelyTyped/DefinitelyTyped/pull/39186
  renderTextField = (inputProps: Autosuggest.InputProps<S> & { inputRef?: any; ref?: any }) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;
    const tempProps: MuiTextField.Props = {
      InputProps: {
        onFocus: (e) => {
          inputProps.onFocus?.(e);
          this.props.TextFieldProps?.onFocus?.(e);
          this.focusHandler();
        },
        onBlur: () => {
          //don't pass on the event, else the (string) value will be set as value...
          (inputProps.onBlur as any)?.();
          (this.props.TextFieldProps?.onBlur as any)?.();
        },
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
      },
      ...(other as any),
      helperText: undefined,
    };
    const props = merge({}, this.props.TextFieldProps, tempProps);
    return <MuiTextField {...props} />;
  };
  loadSuggestionsUnThrottled = (searchText: Autosuggest.SuggestionsFetchRequestedParams) => {
    this.props.loadSuggestions(searchText.value).then((suggestions) => {
      if (suggestions && searchText.value === this.state.searchText) this.setState({ suggestions });
    }, console.error);
  };

  loadSuggestions = throttle(this.loadSuggestionsUnThrottled, this.props.throttleDelay ?? 1000);
  clearSuggestions = () => {
    this.setState({
      suggestions: [],
    });
  };

  setSearchText = (searchText: string) => {
    // Also unset the selected
    this.setState({ searchText, hasSelectedSuggestion: false });
  };

  renderSuggestionsContainer: Autosuggest.RenderSuggestionsContainer = ({ children, containerProps }) => {
    return (
      <Popper className={styles.popper} placement="bottom-start" anchorEl={this.popperAnchor} open={!!children}>
        <Paper
          square
          {...containerProps}
          className={getClassName(containerProps.className, styles.paper)}
          style={{ minWidth: this.popperAnchor ? this.popperAnchor.clientWidth : null }}
        >
          {children}
        </Paper>
      </Popper>
    );
  };

  getError = () => {
    if (this.props.error) return this.props.error;
    if (
      !this.props.allowNewValues &&
      this.state.suggestions.length === 0 &&
      this.state.searchText &&
      !this.state.hasSelectedSuggestion
    ) {
      return this.props.noResultsMessage || "No results found.";
    }
  };
  focusHandler = () => {
    return this.state.searchText === "" ? this.loadSuggestions({ value: "", reason: "input-focused" }) : null;
  };
  popperAnchor: any;
  render() {
    const autosuggestProps: Omit<Autosuggest.AutosuggestPropsSingleSection<S>, "inputProps"> = {
      renderInputComponent: this.renderTextField as any,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.loadSuggestions,
      onSuggestionsClearRequested: this.clearSuggestions,
      getSuggestionValue: this.props.getSuggestionSearchText,
      renderSuggestion: this.props.renderSuggestion,
      focusInputOnSuggestionClick: false,
      renderSuggestionsContainer: this.renderSuggestionsContainer,
      highlightFirstSuggestion: true,
      shouldRenderSuggestions: () => true,
      onSuggestionSelected: (event, s) => {
        if (this.props.onSuggestionSelected) this.props.onSuggestionSelected(event, s);
        if (this.props.clearOnSelect) this.setSearchText("");
        this.setState({ hasSelectedSuggestion: true });
      },
    };
    const err = this.getError();
    //Have to modify the typing a bit to make sure Autosuggest still works
    const textFieldProps: Partial<Omit<MuiTextField.Props, "value" | "defaultValue" | "size" | "ref">> = {
      //These props are passed to the text field
      label: this.props.label || "",
      // onFocus: this.focusHandler,
      placeholder: this.props.placeholder || undefined,
      helperText: err || "",
      error: !!err,
      inputRef: (node: any) => {
        this.popperAnchor = node;
      },
      InputLabelProps: {
        shrink: true,
      },

      onKeyPress: (e) => {
        if (this.props.onKeyPress) {
          this.props.onKeyPress(e, this.state.searchText);
        }
        if (e.key === "Enter") {
          //prevent default, i.e. do not submit the form
          e.preventDefault();
        }
      },
    };
    return (
      <div className={getClassName(styles.autoSuggestRoot, this.props.className)}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            ...pickBy(textFieldProps, identity),
            value: this.state.searchText || "",
            onChange: (_event: React.ChangeEvent<any>, params) => {
              this.setSearchText(params.newValue);
              if (this.props.onInputChange) {
                this.props.onInputChange(params.newValue);
              }
            },
          }}
          theme={{
            suggestionsList: styles.suggestionsList,
            suggestion: styles.suggestion,
          }}
        />
      </div>
    );
  }
}
const MuiAutosuggest = _MuiAutosuggest;
export default MuiAutosuggest;

export namespace MuiAutosuggestRedux {
  export type Props<S = string, V = string> = ReduxForm.WrappedFieldProps & MuiAutosuggest.Props<S, V>;
}
export const MuiAutosuggestRedux = <S, V>({
  input,
  TextFieldProps,
  transformSuggestionToReduxValue,
  transformInitialValueToSearchText,
  meta,
  showErrors,
  ...props
}: MuiAutosuggestRedux.Props<S, V>) => {
  const newInput =
    input.value && transformInitialValueToSearchText
      ? transformInitialValueToSearchText(input.value)
      : toString(input.value);
  if (__DEVELOPMENT__ && input.value && typeof input.value !== "string" && !transformInitialValueToSearchText) {
    console.warn(
      `MuiAutoSuggest received an input value of type ${typeof input.value}, but expected a string. You might want  to use property transformInitialValueToSearchText`,
    );
  }
  const anchor = React.useRef<_MuiAutosuggest>();

  let hasError = false;
  let hasWarning = false;
  let helperText = "";
  if ((meta.touched || meta.dirty || showErrors) && meta.error) {
    helperText = meta.error;
    hasError = true;
  } else if (meta.warning) {
    helperText = meta.warning;
    hasWarning = true;
  }

  return (
    <MuiAutosuggest
      TextFieldProps={{
        ...TextFieldProps,
        onFocus: input.onFocus,
        onBlur: input.onBlur,
        helperText: helperText || TextFieldProps?.helperText,
        warning: hasWarning,
        error: hasError,
      }}
      innerRef={anchor}
      {...props}
      error={meta.touched && meta.error}
      initialValue={newInput || ""}
      onSuggestionSelected={(event, data) => {
        if (props.onSuggestionSelected) props.onSuggestionSelected(event, data);
        const transformedValue = transformSuggestionToReduxValue
          ? transformSuggestionToReduxValue(data.suggestion)
          : data.suggestion;
        input.onChange(transformedValue);
      }}
      onInputChange={
        //We only want to register this listener (that would propagate the input text to the redux-field value)
        //when setting new values via the input field is allowed
        props.allowNewValues
          ? (value) => {
              input.onChange(value);
            }
          : undefined
      }
    />
  );
};
