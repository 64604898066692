import { Fab, IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "#components/index.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import ReadOnlySparqlEditor from "../Sparql/Editor/ReadOnlyEditor";
import * as styles from "./styles.scss";

const IdeToolTipButton: React.FC<{ queryString: string; queryName?: string; queryOwner?: string }> = ({
  queryString,
  queryName,
  queryOwner,
}) => {
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();
  return (
    <CustomTooltip
      title={
        <div ref={copyFrameRef}>
          <ReadOnlySparqlEditor initialValue={queryString} />
          <Fab
            size="small"
            color="primary"
            className={styles.queryCopy}
            title="Copy query"
            aria-label="Copy query"
            onClick={() => copyToClipboard(queryString)}
          >
            <FontAwesomeIcon icon={"clipboard"} />
          </Fab>
        </div>
      }
      placement="left"
      arrow
    >
      <Link to={queryName ? `/${queryOwner}/-/queries/${queryName}` : ""}>
        <IconButton size="small" aria-label="View Query">
          <FontAwesomeIcon icon="code" />
        </IconButton>
      </Link>
    </CustomTooltip>
  );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    maxHeight: 310,
    padding: 1,
    borderRadius: 0,
    margin: 0,
  },
  [`& .cm-editor`]: {
    maxHeight: 300,
  },
});

export default IdeToolTipButton;
