import { Button, Popper, Skeleton } from "@mui/material";
import * as React from "react";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import * as styles from "./style.scss";

interface Props {
  color: string;
  disabled?: boolean;
  onChange: (color: string) => void;
}

//Lazyloading Color Picker lib component
const SketchPicker = React.lazy(() => import("react-color").then((module) => ({ default: module.SketchPicker })));

const ColorPicker: React.FC<Props> = ({ color, disabled, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  useClickOutside(() => {
    setDisplayColorPicker(false);
  }, containerRef);
  return (
    <div ref={containerRef} className={styles.colorPickerCtr}>
      <Button
        color="info"
        variant="outlined"
        onClick={() => {
          setDisplayColorPicker(!displayColorPicker);
        }}
        disabled={disabled}
      >
        <div className={styles.colorIndicator} style={{ backgroundColor: `${disabled ? "#d3d3d3" : color}` }} />
      </Button>
      <Popper
        disablePortal
        className={styles.sketchPicker}
        open={displayColorPicker}
        anchorEl={containerRef.current}
        placement={"bottom"}
      >
        <React.Suspense fallback={<Skeleton height="inherit" variant="rectangular" />}>
          <SketchPicker
            disableAlpha
            color={color}
            onChange={(color) => {
              onChange(color.hex);
            }}
          />
        </React.Suspense>
      </Popper>
    </div>
  );
};

export default ColorPicker;
