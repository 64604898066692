// extracted by mini-css-extract-plugin
var _1 = "style--bold--ZoLfeKgd";
var _2 = "style--dateLiteral--T0VT_wEY";
var _3 = "style--doubleWidget--C3xTP9ZL";
var _4 = "style--exceeded--J_ydBr0x";
var _5 = "style--exceededSymbol--MkdEdQbw";
var _6 = "style--flexColumn--dUhkUAsn";
var _7 = "style--invisible--V7ClQFKR";
var _8 = "style--literal--vErKud6T";
var _9 = "style--measure--xr0jEyHY";
var _a = "style--numberLiteralNumberPart--yL65v8td";
var _b = "style--numberLiteralUnitPart--M1xVjkcx";
var _c = "style--section--OgHFMSp3";
var _d = "style--singleWidget--UOltoPn3";
var _e = "style--warning--PRZPmaNM";
var _f = "style--warningSymbol--JBRUb4Rn";
var _10 = "style--widget--df4buogw";
var _11 = "style--widgetsContainer--DHamuQG8";
export { _1 as "bold", _2 as "dateLiteral", _3 as "doubleWidget", _4 as "exceeded", _5 as "exceededSymbol", _6 as "flexColumn", _7 as "invisible", _8 as "literal", _9 as "measure", _a as "numberLiteralNumberPart", _b as "numberLiteralUnitPart", _c as "section", _d as "singleWidget", _e as "warning", _f as "warningSymbol", _10 as "widget", _11 as "widgetsContainer" }
