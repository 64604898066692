import getClassName from "classnames";
import * as React from "react";
import { Ellipsis } from "#components/index.ts";
import * as styles from "./style.scss";

namespace Prefixable {
  export interface Props {
    iri: string;
    createPrefix: Function;
  }
}

const Prefixable: React.FC<Prefixable.Props> = ({ createPrefix, iri }) => {
  const [open, setOpen] = React.useState(false);

  const activeClasses = {
    [styles.prefixable]: !!styles.prefixable,
    [styles.open]: open,
    [styles.closed]: !open,
    [styles.canCreate]: !!createPrefix,
  };
  return (
    <div className={getClassName(activeClasses)}>
      <span className={styles.input}>
        <input
          ref={(el) => el && el.focus()}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              createPrefix({ prefixLabel: (e.target as any).value || ":", iri: iri });
            } else if (e.key === "Escape") {
              setOpen(false);
            }
          }}
          onBlur={() => setOpen(false)}
          type="text"
        />
        :
      </span>
      <div
        className={styles.basename}
        onDoubleClick={() => {
          if (createPrefix) setOpen(true);
        }}
      >
        <Ellipsis value={iri} />
      </div>
      <div className={styles.info}>
        <div className={styles.hintWhenOpen}>
          <div>Create a prefix for {iri}</div>
          <div className={styles.tip}>Hit Enter to save</div>{" "}
        </div>
        <div className={styles.hintWhenClosed}>Double click to create a prefix</div>
      </div>
    </div>
  );
};

export default Prefixable;
