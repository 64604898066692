import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import * as React from "react";
import * as ReduxForm from "redux-form";

/**
 * Regular switch. Use mui switch as-is
 */
namespace MuiCheckbox {
  export interface Props extends CheckboxProps {
    label: React.ReactElement;
    helperText?: React.ReactChild;
    error?: string;
  }
}
const MuiCheckbox: React.FC<MuiCheckbox.Props> = ({ label, helperText, error, className, ...props }) => (
  <FormControl error={!!error} className={className}>
    <FormControlLabel control={<Checkbox {...props} />} label={label} />
    {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
  </FormControl>
);
export default MuiCheckbox;

/**
 * Switch wrapped for easy integration in redux-form field
 */
export const MuiCheckboxRedux = React.memo<ReduxForm.WrappedFieldProps & MuiCheckbox.Props>(
  ({ input: { onChange, value, ...inputProps }, checked, meta, ...props }) => {
    return (
      <MuiCheckbox
        {...inputProps}
        {...props}
        onChange={onChange}
        checked={!!value}
        error={!meta.pristine && meta.error}
      />
    );
  },
);
