import debug from "debug";
import { Context } from "koa";
import { forEach, isEmpty, map, uniqueId } from "lodash-es";
import parseLinkHeader from "parse-link-header";
import superagent from "superagent";
import { Routes } from "@triply/utils";
import { GlobalState } from "#reducers/index.ts";
import { ConstructUrlToApi, getConfig, getConstructUrlToApi } from "#staticConfig.ts";
import { checkSessionEnd } from "./fetch.ts";
import { ensureTrailingDot } from "./utils.ts";

const log = debug("triply:console:requests");
export interface Links extends parseLinkHeader.Links {
  next: parseLinkHeader.Link;
  first: parseLinkHeader.Link;
}

export interface ResponseMetaData {
  status: number;
  links: Links;
  header: { [key: string]: string };
}
export interface ErrorResponse {
  requestTo?: string;
  status?: number;
  message?: string;
  error?: string;
  devError?: string;
}
export type RequestArguments<Q = { [key: string]: string }, B = Object | string> =
  | UrlRequestArguments<Q, B>
  | PathRequestArguments<Q, B>;
export interface BaseRequestArguments<Q = { [key: string]: string }, B = Object | string> {
  url?: string;
  pathname?: string;
  method: "get" | "post" | "delete" | "put" | "patch";
  query?: Q;
  body?: B;
  contentType?: string;
  accept?: string;
  statusCodeMap?: { [fromCode: number]: number }; //these status codes are set to 200 (OK).Useful if we expect e.g. a 404, but don't want to clobber the browser with errors
  files?: { [fieldName: string]: string | File };
  onProgress?: (event: any) => void;
  requestTag?: string; //when we're passing this arg, we're saving the request globally so reducers can e.g. cancel it
}

export interface UrlRequestArguments<Q = { [key: string]: string }, B = Object | string>
  extends BaseRequestArguments<Q, B> {
  url: string;
  pathname?: never;
}
export interface PathRequestArguments<Q = { [key: string]: string }, B = Object | string>
  extends BaseRequestArguments<Q, B> {
  pathname: string;
  url?: never;
}

export type ClientRequestArguments<Req extends Routes.RequestTemplate> = RequestArguments<Req["Query"], Req["Body"]>;

export type GenericReduxApiResponse<Res extends Routes.ResponseTemplate> = {
  body: Res["Body"];
  meta: ResponseMetaData;
};

export interface GenericReduxContext<Method extends Routes.HttpMethodTemplate> {
  req(args: ClientRequestArguments<Method["Req"]>): Promise<GenericReduxApiResponse<Method["Res"]>>;
}

export interface ClientResult {
  body: any;
  meta?: ResponseMetaData;
}
interface RunningRequests {
  [reqTag: string]: { [uuid: string]: superagent.Request };
}

export default class ApiClient {
  private static requests: RunningRequests = {};
  private ctx?: Context;
  private constructUrlToApi: ConstructUrlToApi;

  constructor(initialState: GlobalState, ctx?: Context) {
    if (initialState && initialState.config && initialState.config.staticConfig) {
      this.constructUrlToApi = getConstructUrlToApi({
        consoleUrlInfo: initialState.config.staticConfig.consoleUrl,
        apiUrlInfo: initialState.config.staticConfig.apiUrl,
      });
    } else {
      //just get state from module directly.
      //Would like to avoid this when the class is ran from the client,
      //because the client would not know about env variable overwrites
      const conf = getConfig();
      this.constructUrlToApi = getConstructUrlToApi({ consoleUrlInfo: conf.consoleUrl, apiUrlInfo: conf.apiUrl });
    }

    this.ctx = ctx;
  }

  private parseLinkHeader(linkHeader: string) {
    if (!linkHeader) return {};
    const links = parseLinkHeader(linkHeader);
    if (!links) return {};
    return links;
  }

  private addRequestReference(tag?: string, request?: superagent.Request) {
    if (tag && request) {
      if (!ApiClient.requests[tag]) ApiClient.requests[tag] = {};
      const uuid = uniqueId();

      ApiClient.requests[tag][uuid] = request;
      return uuid;
    }
  }
  private removeRequestReference(tag?: string, uuid?: string) {
    if (tag && uuid) {
      if (!ApiClient.requests[tag]) return;
      delete ApiClient.requests[tag][uuid];
      if (isEmpty(ApiClient.requests[tag])) delete ApiClient.requests[tag];
    }
  }

  public req<A extends RequestArguments, R extends ClientResult>(args: A) {
    return new Promise<R>((resolve, reject) => {
      const requestTo = this.constructUrlToApi(
        args.url ? { fullUrl: args.url, query: args.query } : { pathname: args.pathname as string, query: args.query },
      );

      log(`--> Fetching ${requestTo}`);
      const start = Date.now();
      if (__CLIENT__) console.info(requestTo);
      const request: superagent.Request = superagent[args.method](requestTo);
      const uuid = this.addRequestReference(args.requestTag, request);

      if (__SERVER__ && this.ctx) {
        //always behind a proxy anyway, so proxy these headers
        for (const key of ["cookie", "x-forwarded-for", "x-real-ip", "x-forwarded-proto", "x-dockerhealthcheck"]) {
          const val = this.ctx.get(key);
          if (val) request.set(key, val); // eslint-disable-line @typescript-eslint/no-floating-promises
        }
        // Also set a header that says we are server side rendering
        request.set("X-Triply-Render", "server"); // eslint-disable-line @typescript-eslint/no-floating-promises
      }
      if (args.contentType) {
        request.set("Content-Type", args.contentType); // eslint-disable-line @typescript-eslint/no-floating-promises
      }
      if (args.accept) {
        request.set("Accept", args.accept); // eslint-disable-line @typescript-eslint/no-floating-promises
      }
      if (args.files) {
        forEach(args.files, function (val, key) {
          request.attach(key, <any>val); // eslint-disable-line @typescript-eslint/no-floating-promises
        });
      }
      if (args.onProgress) {
        request.on("progress", args.onProgress); // eslint-disable-line @typescript-eslint/no-floating-promises
      }
      if (args.body) {
        request.send(args.body); // eslint-disable-line @typescript-eslint/no-floating-promises
      }
      if (args.statusCodeMap) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        request.set(
          "x-statusMap",
          map(args.statusCodeMap, function (val: string, key: string) {
            return key + ":" + val;
          }).join(","),
        );
      }
      const formatError = (err: any, body: any): ErrorResponse => {
        var returnErr: ErrorResponse = typeof body === "object" ? body : {};
        if (!returnErr.requestTo) returnErr.requestTo = requestTo;
        if (err.status) returnErr.error = ensureTrailingDot(err.status + ": " + (body.message || err.message));
        if (!returnErr.message) returnErr.message = err.message;
        if (!returnErr.status) returnErr.status = err.status;
        if (body?.serverError) returnErr.devError = body.serverError;
        if (!isEmpty(returnErr.message)) return returnErr;
        return err;
      };

      request.end((err: Error, res: superagent.Response) => {
        this.removeRequestReference(args.requestTag, uuid);
        const body = res ? (res.body ? res.body : res.text ? res.text : undefined) : undefined;

        // When server side rendering and the token is expired, remove the token cookie
        if (__SERVER__ && this.ctx && res?.get("X-Triply-Session-Ended")) {
          this.ctx.cookies.set("jwt", null, { overwrite: true });
        }

        // When in the browser and the session has expired, go to the login page
        checkSessionEnd(res?.get("X-Triply-Session-Ended"));

        if (err || !res) {
          log(`<-- Fetching ${requestTo} failed (${Date.now() - start}ms)`);
          return reject(formatError(err, body));
        }
        const meta = {
          status: res.status,
          header: res.header,
          links: this.parseLinkHeader(res.header.link),
          req: request,
        };
        log(
          `<-- Fetched ${requestTo} (${res.header["x-t-cache"] ? `cache-${res.header["x-t-cache"]} ` : ""}${
            Date.now() - start
          }ms)`,
        );
        return resolve(<any>{
          body,
          meta,
        });
      });
    });
  }
  public static abortRequestsByTag(tag: string) {
    if (ApiClient.requests[tag]) {
      forEach(ApiClient.requests[tag], (val) => {
        val.abort().catch(() => {});
      });
    }
  }
}
