import { Alert, Autocomplete, Skeleton, TextField, ThemeProvider, useTheme } from "@mui/material";
import { cloneDeep, merge } from "lodash-es";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { FormField } from "#components/index.ts";
import { validateIri } from "#containers/DataModel/Forms/helpers.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import NodeShape from "./NodeShape";
import localTheme from "./Theme";
import { FormValues } from "./Types";
import useClasses from "./useClasses";
import useFetchInitialValues from "./useFetchInitialValues";
import * as styles from "./style.scss";

const InstanceForm: React.FC<{ onSubmit: (values: FormValues) => Promise<void>; editingResource?: string }> = ({
  onSubmit,
  editingResource,
}) => {
  const theme = useTheme();
  const fetchInitialValues = useFetchInitialValues();

  const methods = useForm<FormValues>({
    shouldUnregister: false,
    defaultValues: (editingResource && (() => fetchInitialValues(editingResource))) || undefined,
  });

  const values = methods.watch();

  const type = methods.watch("type.id");

  const classes = useClasses();

  const submit = async (values: any) => {
    // console.log("submit", { values });
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      methods.setError("root.serverError", {
        type: "500",
      });
    }
  };

  const applyPrefixes = useApplyPrefixes();
  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const removePrefixes = useRemovePrefixes();

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  if (classes.length === 0) {
    return (
      <Alert severity="info">
        No SHACL shapes were found {editingResource ? "for this resource" : "in this dataset"}
      </Alert>
    );
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = methods;
  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <FormProvider {...methods}>
        <form method="POST" onSubmit={handleSubmit(submit)} className="flex column g-7">
          <FormField className={styles.formField} label="Type" required>
            <Controller
              name="type"
              control={control}
              defaultValue={null}
              rules={{ required: "A type is required." }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <Autocomplete
                  options={classes}
                  onChange={(_e, data: any) => {
                    onChange(data);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      required
                      error={!!error}
                      helperText={error?.message || rest.value?.description}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return option.label || applyPrefixes(option.id);
                  }}
                  {...rest}
                />
              )}
            />
          </FormField>

          {!editingResource && (
            <FormField className={styles.formField} label="Instance IRI" required>
              <Controller
                name="iri"
                control={control}
                rules={{
                  validate: (value) => validateIri(removePrefixes(value?.trim())),
                }}
                defaultValue={applyPrefixes(`${datasetUrl}/id/${uuidv4()}`)}
                render={({ field, fieldState }) => (
                  <TextField {...field} error={!!fieldState.error} required helperText={fieldState.error?.message} />
                )}
              />
            </FormField>
          )}

          <NodeShape classIri={type} namePrefix="properties" />

          <LoadingButton color="secondary" type="submit" loading={isSubmitting} disabled={!isDirty}>
            Save
          </LoadingButton>

          {errors.root && <Alert severity="error">Something went wrong on the server...</Alert>}
        </form>
      </FormProvider>
    </ThemeProvider>
  );
};

export default InstanceForm;
