// extracted by mini-css-extract-plugin
var _1 = "style--accountDetails--GLk9hrLc";
var _2 = "style--accountLabel--lw_GdRfD";
var _3 = "style--accountTitle--_TLIJFOF";
var _4 = "style--avatar--HOAEeuXR";
var _5 = "style--box--y3Wg0sxI";
var _6 = "style--colAvatar--RtKuN9ry";
var _7 = "style--colDescription--mWchykrK";
var _8 = "style--description--vde4BVq6";
var _9 = "style--descriptionCol--njaEsdKk";
var _a = "style--email--DGewb9af";
var _b = "style--space--mIjEMMgf";
export { _1 as "accountDetails", _2 as "accountLabel", _3 as "accountTitle", _4 as "avatar", _5 as "box", _6 as "colAvatar", _7 as "colDescription", _8 as "description", _9 as "descriptionCol", _a as "email", _b as "space" }
