import getClassName from "classnames";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { Alert, Button } from "#components/index.ts";
import * as styles from "./style.scss";

namespace SinkList {
  export interface Props {
    className?: string;
    fetchingListError?: string;
    loadNextPage?: React.EventHandler<React.MouseEvent<any>>;
    noContentMsg?: string;
    children: React.ReactNode;
  }
}

const SinkList: React.FC<SinkList.Props> = ({ loadNextPage, fetchingListError, className, children, noContentMsg }) => {
  return (
    <div className={getClassName(className)}>
      {fetchingListError ? (
        <div>
          <Alert error message={`${fetchingListError}`} />
        </div>
      ) : isEmpty(children) ? (
        <div className={styles.noContentMsg}>{noContentMsg}</div>
      ) : (
        React.Children.map(
          children as any,
          (listItem: React.ReactElement<any>) =>
            listItem && (
              <listItem.type {...listItem.props} className={getClassName(styles.item, listItem.props.className)} />
            ),
        )
      )}
      {loadNextPage && (
        <div className={styles.moreBtn}>
          <Button onClick={loadNextPage}>Show more...</Button>
        </div>
      )}
    </div>
  );
};
export default SinkList;
