import * as React from "react";
import { useSelector } from "react-redux";
import { mergePrefixArray } from "@triply/utils/prefixUtils.js";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";

export function useDatasetPrefixes() {
  const currentDs = useCurrentDataset();
  const globalPrefixes = useSelector((state: GlobalState) => state.config.clientConfig?.prefixes);
  const localPrefixes = currentDs?.prefixes;
  return React.useMemo(
    () => mergePrefixArray(localPrefixes || [], globalPrefixes || []),
    [globalPrefixes, localPrefixes],
  );
}
