import { unionBy, uniqBy } from "lodash-es";
import urlParse from "url-parse";

export interface Prefix {
  prefixLabel: string;
  iri: string;
  scope?: "global" | "local";
}
export interface PrefixInfo {
  iri: string;
  localName?: string;
  prefixLabel?: string;
}

export type PrefixesArray = Array<Prefix>;
export type PrefixMap = { [prefix: string]: Prefix };

const getLastSlashIndex = (pathname: string, offset?: number): number => {
  if (offset === undefined) offset = pathname.length;
  const i = pathname.lastIndexOf("/", offset);
  if (i >= 0 && i === pathname.length - 1) {
    //this is the last char. For paths with a trailing slash,
    //we want the parent pathname as localname, so go back in the string
    if (offset < 0) return i;
    return getLastSlashIndex(pathname, offset - 1);
  }
  return i;
};

export function getLocalNameInfo(iri: string): PrefixInfo {
  // Check if protocol is followed by :// or :/

  const parsed = urlParse(iri);

  if (parsed.pathname === "/" && !iri.includes(`${parsed.hostname}/`)) {
    /**
     * By default the parser parses the pathname of https://google.com as `/`
     * We dont want that (it's a different IRI)
     */
    parsed.set("pathname", "");
  }
  if (parsed.hash.length > 1) {
    var hashContent = parsed.hash.substring(1);
    parsed.set("hash", "#");
    return {
      iri: iri.slice(0, iri.length - hashContent.length),
      localName: hashContent,
    };
  }
  const i = getLastSlashIndex(parsed.pathname);

  if (i >= 0) {
    const localName = parsed.pathname.substring(i + 1) + parsed.query + parsed.hash;
    if (localName && localName.length) {
      parsed.set("pathname", parsed.pathname.substring(0, i + 1));
      parsed.set("query", "");
      parsed.set("hash", "");
      return {
        iri: iri.slice(0, iri.length - localName.length),
        localName: localName,
      };
    }
  }
  return { iri: iri };
}

export function getPrefixInfoFromPrefixedValue(value: string, prefixes: PrefixesArray | PrefixMap): PrefixInfo {
  for (const p of Array.isArray(prefixes) ? prefixes : Object.values(prefixes)) {
    if (!p.prefixLabel) continue;
    if (value.lastIndexOf(p.prefixLabel + ":", 0) === 0) {
      return {
        prefixLabel: p.prefixLabel,
        iri: p.iri,
        localName: value.substring(p.prefixLabel.length + 1),
      };
    }
  }
  // fallback to getting localname
  return getLocalNameInfo(value);
}

export function getPrefixInfoFromIri(value: string, prefixes: PrefixesArray | PrefixMap): PrefixInfo;
export function getPrefixInfoFromIri(value: string, prefixes: any): PrefixInfo {
  const matches = [];
  for (const p of Array.isArray(prefixes) ? prefixes : Object.values(prefixes)) {
    if (value.length >= p.iri.length && value.lastIndexOf(p.iri, 0) === 0) {
      matches.push({
        prefixLabel: p.prefixLabel,
        iri: p.iri,
        localName: value.substring(p.iri.length),
      });
    }
  }
  return matches.sort((a, b) => b.iri.length - a.iri.length)[0] || getLocalNameInfo(value);
}

export function getPrefixed(value: string, prefixes: PrefixesArray | PrefixMap): string;
export function getPrefixed(value: string, prefixes: any): string | undefined {
  const info = getPrefixInfoFromIri(value, prefixes);
  if (info && info.prefixLabel) return `${info.prefixLabel}:${info.localName}`;
}
/**
 * Merges prefix array
 * @param basePrefixes Base prefixes will take president when merging
 * @param secondPrefixes
 * @returns prefix array
 */
export function mergePrefixArray(basePrefixes: PrefixesArray, secondPrefixes: PrefixesArray): PrefixesArray {
  return uniqBy(
    unionBy(basePrefixes, secondPrefixes, (prefix) => prefix.iri),
    (prefix) => prefix.prefixLabel,
  );
}
