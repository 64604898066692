import * as React from "react";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { getQueryType } from "#helpers/FaIcons.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { getFrontPageInfo, updateFrontPageInfo } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import MainPageItemsForm, { FormData } from "./MainPageItemsForm.tsx";

interface Props {}

const MainPageItems: React.FC<Props> = ({}) => {
  const mainPageItems = useSelector((state: GlobalState) => state.config.frontpage?.items);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!mainPageItems) dispatch<typeof getFrontPageInfo>(getFrontPageInfo()).catch(() => {});
  }, [dispatch, mainPageItems]);
  const saveOverview = ({ mainPageItems }: FormData) => {
    return dispatch<typeof updateFrontPageInfo>(
      updateFrontPageInfo({
        items: mainPageItems?.map((item) => ({ type: item.type, item: item.item.id })) || [],
      }),
    ).catch((e) => {
      throw new SubmissionError({ _error: e.message });
    });
  };

  if (!mainPageItems) return <></>;
  return (
    <div className="whiteSink">
      <h3>Front-page items</h3>
      <MainPageItemsForm
        initialValues={{
          mainPageItems: mainPageItems.map((item) => {
            return {
              type: item.type,
              item: {
                id: item.item.id,
                name: item.item.name,
                displayName: item.item.displayName,
                avatarUrl: item.type === "Dataset" ? item.item.avatarUrl : undefined,
                bannerUrl: item.type === "Story" ? item.item.bannerUrl : undefined,
                resultType: item.type === "Query" ? getQueryType(item.item) : undefined,
                accountName: item.item.owner.accountName,
                accountDisplayName: item.item.owner.name,
              },
            };
          }),
        }}
        onSubmit={saveOverview}
      />
    </div>
  );
};
export default MainPageItems;
