import * as React from "react";
import { FontAwesomeIcon, TermLiteral, TermLiteralDefault } from "#components/index.ts";
import * as styles from "./style.scss";

class TermLiteralBoolean extends React.PureComponent<TermLiteral.Props, any> {
  static shouldRender(props: TermLiteral.Props) {
    return props.datatype === "http://www.w3.org/2001/XMLSchema#boolean";
  }
  render() {
    switch (this.props.value) {
      case "true":
        return (
          <div title={this.props.expanded ? this.props.datatype : "true"}>
            <FontAwesomeIcon className={styles.trueVal} icon="check" />
          </div>
        );
      case "false":
        return (
          <div title={this.props.expanded ? this.props.datatype : "false"}>
            <FontAwesomeIcon className={styles.falseVal} icon="times" />
          </div>
        );
      default:
        return <TermLiteralDefault {...this.props} />;
    }
  }
}
export default TermLiteralBoolean;
