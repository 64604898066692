import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Dialog } from "#components/index.ts";
import { getRedirectAction } from "#components/Notifications/index.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { deleteAccount, updateProfile } from "#reducers/accounts.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";

interface Props {}

const ConsentHelper: React.FC<Props> = ({}) => {
  const authenticatedAccount = useAuthenticatedUser();
  const generalTermsUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.generalTermsUrl);
  const privacyPolicyUrl = useSelector((state: GlobalState) => state.config.clientConfig?.legal?.privacyPolicyUrl);
  const latestRequiredConsentDateVal = useSelector(
    (state: GlobalState) => state.config.clientConfig?.legal?.latestRequiredConsentUpdate,
  );
  const latestRequiredConsentDate = latestRequiredConsentDateVal && moment(latestRequiredConsentDateVal);
  const shouldShowLegalConsent = !!generalTermsUrl || !!privacyPolicyUrl;
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  React.useEffect(() => {
    if (
      shouldShowLegalConsent &&
      authenticatedAccount?.legalConsent &&
      authenticatedAccount.legalConsent === "expiring"
    )
      dispatch<typeof showNotification>(
        showNotification(
          `Our general terms and privacy policy have been updated${
            latestRequiredConsentDate ? ` and will take effect on ${latestRequiredConsentDate.format("LL")}` : ""
          }. To keep your account active, please visit your account page for more information.`,
          "warning",
          { action: getRedirectAction("Go to account page", "/me/-/settings") },
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!shouldShowLegalConsent || !authenticatedAccount) return null;
  if (
    (authenticatedAccount.legalConsent && authenticatedAccount.legalConsent === "ok") ||
    authenticatedAccount.legalConsent === "expiring"
  )
    return null;
  return (
    <Dialog open={true} title="Consent" maxWidth="lg">
      <div className="px-5">
        In order to ensure compliance with the latest regulations,{" "}
        {authenticatedAccount.legalConsent === "expired" && "we have updated the "}
        {authenticatedAccount.legalConsent === "unset" && "you need to agree with the new "}
        <a href={generalTermsUrl} target="_blank">
          general terms
        </a>{" "}
        and{" "}
        <a href={privacyPolicyUrl} target="_blank">
          privacy policy
        </a>
        . You need to consent to these changes in order to continue using TriplyDB.
      </div>
      <div className="p-5">
        <Button
          className="mr-2"
          color="success"
          onClick={() => dispatch<typeof updateProfile>(updateProfile(authenticatedAccount, { legalConsent: true }))}
        >
          I agree with the general terms and privacy policy
        </Button>
        <Button
          color="error"
          onClick={() =>
            confirm({
              onConfirm: () => dispatch<typeof deleteAccount>(deleteAccount(authenticatedAccount, true)),
              title: "Revoke consent and delete my account",
              actionLabel: "Delete my account",
              description: (
                <p>
                  In order to ensure compliance with the latest regulations, TriplyDB requires the consent of all users
                  to our{" "}
                  <a href={generalTermsUrl} target="_blank">
                    general terms
                  </a>{" "}
                  and{" "}
                  <a href={privacyPolicyUrl} target="_blank">
                    privacy policy
                  </a>
                  . Not consenting to the general terms and privacy policy means your account will be rendered inactive.
                  To ensure that your personal information is cleared, we suggest you delete your TriplyDB account.
                  Deleting your account will also result in the deletion of any dataset, query, or story that you own,
                  as well as any organizations for which you are the sole owner.
                </p>
              ),
            })
          }
        >
          I disagree
        </Button>
      </div>
    </Dialog>
  );
};

export default ConsentHelper;
