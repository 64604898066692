import * as connectedReactRouter from "connected-react-router";
import { useSelector } from "react-redux";
import * as ReduxForm from "redux-form";
import * as Forms from "#components/Forms/index.ts";
import { GlobalState } from "#reducers/index.ts";
import { createStory, getStories } from "#reducers/stories.ts";
import useDispatch from "./useDispatch.ts";

export function useCreateStory(accountName: string | undefined, replaceCurrentPage: boolean) {
  const dispatch = useDispatch();
  return (values: Forms.Story.FormData) => {
    if (accountName) {
      return dispatch<typeof createStory>(createStory(accountName, values))
        .catch((e) => {
          if (e?.status === 409) {
            throw new ReduxForm.SubmissionError({ name: "URL is already in use." });
          }
          throw new ReduxForm.SubmissionError({ _error: e.message });
        })
        .then((response) => {
          dispatch(
            connectedReactRouter[replaceCurrentPage ? "replace" : "push"](
              `/${accountName}/-/stories/${response.body.name}`,
            ),
          );
        });
    }
  };
}

export function useLoadNextPage(accountName?: string) {
  const dispatch = useDispatch();
  const nextPage = useSelector((state: GlobalState) => state.stories.nextPage);
  return () => {
    if (accountName) return dispatch<typeof getStories>(getStories(undefined, undefined, nextPage));
  };
}
