import getClassName from "classnames";
import * as React from "react";
import { Prefixes } from "@triply/utils/Models.js";
import { LoadingButton } from "#components/index.ts";
import { PrefixUpdate } from "#reducers/datasetManagement.ts";
import { Position, Term, Triple, Triples } from "#reducers/triples.ts";
import Field from "./field.tsx";
import FilterField from "./filterField.tsx";
import { Query } from "./index.tsx";
import * as styles from "./style.scss";

interface Props {
  className?: string;
  triples: Triples;
  goToNextPage?: React.MouseEventHandler<any>;
  createPrefix?: (prefix: PrefixUpdate) => void;
  fetchingTriples: boolean;
  query: Query;
  prefixes: Prefixes;
  filter: (query: Object) => void;
  termPath: string;
  pathname: string;
}

// Get unique key for each triple in the tabular-browser
const getStatementId = (statement: Triple) =>
  statement[0].value +
  statement[1].value +
  statement[2].value +
  statement[2].datatype +
  statement[2].language +
  statement[3]?.value;

const TriplesTable: React.FC<Props> = ({
  className,
  goToNextPage,
  triples,
  fetchingTriples,
  query,
  createPrefix,
  filter,
  pathname,
  prefixes,
  termPath,
}) => {
  const activeClasses = {
    [styles.tableWrapper]: !!styles.tableWrapper,
    [className || ""]: !!className,
    [styles.filterSub]: !!query.subject,
    [styles.filterPred]: !!query.predicate,
    [styles.filterObj]: !!query.object,
    [styles.filterGraph]: !!query.graph,
    shadow: true,
  };
  const getFilterFieldProps = (pos: Position): FilterField.Props => {
    return {
      pos: pos,
      initialValue: query[pos] || "",
      selectedGraph: query.graph,
      prefixes: prefixes,
      handleFilterChange: (newVal, reset = false) => {
        if (reset) {
          filter({ [pos]: newVal });
        } else {
          filter({ ...query, [pos]: newVal });
        }
      },
      termPath: termPath,
    };
  };

  const renderField = (term: Term | undefined, pos: Position) => (
    <Field
      term={term}
      pos={pos}
      query={query}
      prefixes={prefixes}
      createPrefix={createPrefix}
      pathname={pathname}
      filter={filter}
    />
  );
  return (
    <div className={getClassName(activeClasses)}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <label htmlFor="subject">Subject</label>
              <FilterField key={"subject" + (query["subject"] || "")} {...getFilterFieldProps("subject")} />
            </th>
            <th>
              <label htmlFor="predicate">Predicate</label>
              <FilterField key={"predicate" + (query["predicate"] || "")} {...getFilterFieldProps("predicate")} />
            </th>
            <th>
              <label htmlFor="object">Object</label>
              <FilterField key={"object" + (query["object"] || "")} {...getFilterFieldProps("object")} />
            </th>
            <th>
              <label htmlFor="graph">Graph</label>
              <FilterField key={"graph" + (query["graph"] || "")} {...getFilterFieldProps("graph")} />
            </th>
          </tr>
        </thead>
        <tbody>
          {triples &&
            triples.map((triple) => (
              <tr key={getStatementId(triple)} className={styles.row}>
                <td className={styles.sub}>{renderField(triple[0], "subject")}</td>
                <td className={styles.pred}>{renderField(triple[1], "predicate")}</td>
                <td className={styles.obj}>{renderField(triple[2], "object")}</td>
                <td className={styles.graph}>{renderField(triple[3], "graph")}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {goToNextPage && (
        <div className={styles.moreBtn}>
          <LoadingButton elevation onClick={goToNextPage} loading={fetchingTriples}>
            Show more...
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default TriplesTable;
