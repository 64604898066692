import { flatMap } from "lodash-es";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { Properties } from "./Types";

export default function formValuesToSparqlValues(
  subject: string,
  properties: Properties,
  removePrefixes: ReturnType<typeof useRemovePrefixes>,
): string[] {
  return flatMap(properties, (propertyValues, key) => {
    const predicate = key.replace(/ /g, ".");
    return flatMap(propertyValues, (property) => {
      if (!property) return [];
      switch (property.nodeKind) {
        case "IRI":
          return [`(<${subject}> <${predicate}> <${removePrefixes(property.value.trim())}>)`];
        case "BlankNode":
          return [
            `(<${subject}> <${predicate}> <${property.value}>)`,
            `(<${property.value}> rdf:type <${property.type}>)`,
            ...formValuesToSparqlValues(property.value, property.properties, removePrefixes),
          ];
        case "Literal":
          return [
            `(<${subject}> <${predicate}> "${property.value.trim()}"${
              property.datatype === "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString"
                ? `@${property.language}`
                : `^^<${property.datatype}>`
            })`,
          ];
      }
    });
  });
}
