// extracted by mini-css-extract-plugin
var _1 = "styles--admin--bmkhnEY3";
var _2 = "styles--avatar--HRyt4DHg";
var _3 = "styles--box--D0XQAuZg";
var _4 = "styles--colAvatar--a3FW50pI";
var _5 = "styles--colDescription--_OUOGycm";
var _6 = "styles--colIcons--MSP9NBNE";
var _7 = "styles--description--H7fHVXn8";
var _8 = "styles--remove--ZrbtmsVI";
var _9 = "styles--roleSelectField--itVItJdw";
export { _1 as "admin", _2 as "avatar", _3 as "box", _4 as "colAvatar", _5 as "colDescription", _6 as "colIcons", _7 as "description", _8 as "remove", _9 as "roleSelectField" }
