import { Autocomplete, Fab, StandardTextFieldProps, TextField } from "@mui/material";
import getClassName from "classnames";
import { Location } from "history";
import React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import { useHotkeys } from "#containers/Hotkeys/index.tsx";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import ColorPrefixed from "./ColorPrefixed.tsx";
import { NodeData } from "./useData.ts";
import { prefixedOrLocalName } from "./utils.ts";
import * as styles from "./style.scss";

const SearchButton: React.FC<{
  nodes: NodeData[] | undefined;
  center: (iri: string) => void;
  location: Location;
}> = ({ nodes, center, location }) => {
  const [open, setOpen] = React.useState(false);
  const prefixesRef = React.useRef(useDatasetPrefixes());

  useHotkeys(
    { component: "Schema", description: "Search for a class", keyBinds: "Mod+f" },
    (event) => {
      if (location.hash.length > 1) return;
      setOpen(true);
      event.preventDefault();
    },
    {
      enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"],
    },
  );

  return (
    <>
      <Fab
        onClick={() => {
          if (!open) setOpen(true);
        }}
        aria-label="Search"
        variant="extended"
        size="medium"
        className={getClassName(styles.searchButton, { [styles.collapsed]: !open })}
        disableFocusRipple
        disableRipple
        classes={{
          focusVisible: styles.focusVisible,
        }}
        title="Search"
        component="span"
      >
        {open && (
          <Autocomplete
            autoHighlight
            className={styles.searchField}
            onChange={(e, newValue) => {
              e.stopPropagation();
              setOpen(false);
              center(newValue);
            }}
            onBlur={() => {
              setOpen(false);
            }}
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                setOpen(false);
              }
            }}
            options={nodes?.map((n) => n.id).sort() || []}
            noOptionsText="No matching classes"
            popupIcon={null}
            open
            disableClearable
            getOptionLabel={(option) => {
              return prefixedOrLocalName(prefixesRef.current, option);
            }}
            renderOption={(props, c) => {
              return (
                <li {...props}>
                  <ColorPrefixed>{c}</ColorPrefixed>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...(params as StandardTextFieldProps)} placeholder="Search..." autoFocus />
            )}
          />
        )}
        <FontAwesomeIcon icon="search" size="lg" />
      </Fab>
    </>
  );
};
export default SearchButton;
