import { Alert, DialogTitle } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Button, Dialog, FontAwesomeIcon, Markdown } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import { Story } from "#reducers/stories.ts";
import { LocationState } from ".";

interface EmbedDialogProps {
  goBack: () => void;
  story: Story;
}

const EmbedDialog: React.FC<EmbedDialogProps> = ({ goBack, story }) => {
  const location = useLocation<LocationState>();
  const constructConsoleUrl = useConstructConsoleUrl();
  const { ref: copyFrameRef, copyToClipboard } = useCopyToClipboard();

  const snippet = `<iframe
  id="${story.name}"
  src="${constructConsoleUrl({ pathname: location.pathname, query: { embed: "" } }).slice(0, -1)}"
  width="100%"
  height="1000"
  frameborder="0"
></iframe>
<script src="${constructConsoleUrl({ pathname: "/resizeIframe.js" })}" iframeId="${story.name}"></script>
`;

  return (
    <Dialog fullWidth maxWidth="md" open={!!location.state?.storyEmbedModalShown} onClose={goBack}>
      <DialogTitle>Embed story</DialogTitle>
      <div className="mx-5">
        {story.accessLevel !== "public" && (
          <Alert className="mb-3" severity="warning">
            This story is not public and can't be accessed from external sources
          </Alert>
        )}
        <Markdown>{`\`\`\`html\n${snippet}`}</Markdown>
        {/* Need a ref inside the Dialog here, as Dialogs catch events */}
        <div ref={copyFrameRef}></div>
      </div>
      <div className="m-5">
        <Button onClick={() => copyToClipboard(snippet)} startIcon={<FontAwesomeIcon icon="clipboard" />}>
          Copy to clipboard
        </Button>
        <Button onClick={goBack} variant="text" className="ml-3">
          Close
        </Button>
      </div>
    </Dialog>
  );
};
export default EmbedDialog;
