import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { useCurrentAccount, usePinnedItems } from "#reducers/app.ts";
import AddItemButton from "./AddItemButton.tsx";
import * as styles from "./style.scss";

interface Props {
  matchPath: string;
  mayCreateResource: boolean;
}

const Tabs: React.FC<Props> = ({ matchPath, mayCreateResource }) => {
  const currentAccount = useCurrentAccount();
  const numberOfPinnedItems = usePinnedItems(currentAccount?.uid).length;

  if (!currentAccount) return null;

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabs}>
        <Link
          to={{ pathname: `/${currentAccount.accountName}/-/overview`, state: { preserveScrollPosition: true } }}
          className={getClassName(styles.tab, { [styles.activeTab]: matchPath === "/:account/-/overview" })}
        >
          Overview
          <span className={styles.counter}>{numberOfPinnedItems}</span>
        </Link>
        <Link
          to={{ pathname: `/${currentAccount.accountName}/-/datasets`, state: { preserveScrollPosition: true } }}
          className={getClassName(styles.tab, { [styles.activeTab]: matchPath === "/:account/-/datasets" })}
        >
          Datasets
          <span className={styles.counter}>{currentAccount.datasetCount}</span>
        </Link>
        <Link
          to={{ pathname: `/${currentAccount.accountName}/-/stories`, state: { preserveScrollPosition: true } }}
          className={getClassName(styles.tab, { [styles.activeTab]: matchPath === "/:account/-/stories" })}
        >
          Stories
          <span className={styles.counter}>{currentAccount.storyCount}</span>
        </Link>
        <Link
          to={{ pathname: `/${currentAccount.accountName}/-/queries`, state: { preserveScrollPosition: true } }}
          className={getClassName(styles.tab, { [styles.activeTab]: matchPath === "/:account/-/queries" })}
        >
          Queries
          <span className={styles.counter}>{currentAccount.queryCount}</span>
        </Link>
      </div>

      {mayCreateResource && (
        <div>
          <AddItemButton matchPath={matchPath} />
        </div>
      )}
    </div>
  );
};

export default Tabs;
