import getClassName from "classnames";
import * as React from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "#components/index.ts";
import { parseSearchString } from "#helpers/utils.ts";
import * as styles from "./style.scss";

interface Props {
  searching: boolean;
  search: (term: string) => void;
  className?: string;
}

const SearchField: React.FC<Props> = (props) => {
  const location = useLocation();
  const query = parseSearchString(location.search);
  const [searchTerm, setSearchTerm] = React.useState(`${query.q || ""}`);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const search = () => {
    props.search(searchTerm.trim());
    if (inputRef.current) inputRef.current.blur();
  };

  const onSearchClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    search();
  };

  return (
    <form className={getClassName(styles.searchWidget, props.className)} aria-label="Sitewide" role="search">
      <input
        type="search"
        aria-label="Search"
        ref={inputRef}
        value={searchTerm}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            search();
          } else if (event.key === "Escape") {
            inputRef.current?.blur();
          }
        }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        className={styles.searchFieldInput}
        placeholder={"Refine results..."}
      />
      <FontAwesomeIcon
        tabIndex={-1}
        onMouseDown={onSearchClick}
        onFocus={() => inputRef.current?.focus()}
        icon="search"
        className={getClassName(styles.search, { [styles.searching]: props.searching })}
      />
    </form>
  );
};

export default SearchField;
