import getClassName from "classnames";
import * as d3 from "d3";
import { GraphEdge } from "dagre";
import React from "react";
import { EdgeData } from "./useData.ts";
import * as styles from "./style.scss";

const curvedLine = d3
  .line<{ x: number; y: number }>()
  .curve(d3.curveBasis)
  .x((d) => d.x)
  .y((d) => d.y);

const Edge: React.FC<{ data: EdgeData; layoutData?: GraphEdge }> = ({ data, layoutData }) => {
  return (
    <g
      className={getClassName(styles.edge, {
        [styles.hierarchy]: !data.label,
        [styles.property]: !!data.label,
        [styles.owl]: !!data.owl,
        [styles.shacl]: !!data.shacl,
        [styles.blur]: !!data.blur,
      })}
      stroke={data.color}
    >
      <path
        d={`${curvedLine(
          layoutData
            ? layoutData.points.flatMap((p, i) => {
                if (data.label && i === Math.floor(layoutData.points.length / 2)) {
                  // duplicate the mid point to give it more weight in the curve
                  return [p, p];
                } else {
                  return [p];
                }
              })
            : [
                { x: 0, y: 0 },
                { x: 1, y: 1 },
                { x: 2, y: 2 },
              ],
        )}`}
        markerEnd={data.label && "url(#arrowhead)"}
        markerStart={data.label || "url(#subClassArrow)"}
      />
    </g>
  );
};
export default Edge;
