import * as React from "react";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { getPrefixAndLabel } from "./utils.ts";

const Prefixed: React.FC<{ children: string }> = ({ children }) => {
  const prefixes = useDatasetPrefixes();
  const { prefix, label } = getPrefixAndLabel(prefixes, children);
  return <>{prefix ? `${prefix}:${label}` : label}</>;
};

export default Prefixed;
