import getClassName from "classnames";
import * as React from "react";
import { Constants } from "@triply/utils";
import { Button, FontAwesomeIcon, LoadingButton, ResourceWidget, TermLiteral } from "#components/index.ts";
import Tree from "#helpers/LdTree.ts";
import {
  getWidgetCollection,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./styles/outLink.scss";

namespace OutLink {
  export interface Props {
    label?: string;
    trees: Tree[];
    loadResource: (subject: string, label: string) => {};
    linkPath: string;
    className?: string;
    // We want a promise here as to not complicate the component with redux logic, Optional as for special widgets
    loadPage?: (direction: "forward" | "backward", page: number, predicate: string) => Promise<void>;
  }
  export interface State {
    loadedPages: number;
    loading: boolean;
  }
}

class OutLink extends React.PureComponent<OutLink.Props, OutLink.State> {
  constructor(props: OutLink.Props) {
    super(props);
    this.state = {
      loadedPages: 1,
      loading: false,
    };
  }

  renderResource = (subject: string) =>
    this.props.label ? this.props.loadResource(subject, this.props.label) : this.props.loadResource;

  onDragStart = (e: any) => {
    e.dataTransfer.setData("text/plain", this.props.trees[0].getPredicate());
  };

  loadPage = () => {
    const { trees, loadPage } = this.props;
    const { loadedPages } = this.state;
    //Only load more statements when needed
    if (loadPage && trees.length <= (loadedPages + 1) * Constants.BACKWARD_SUBJECTS_PAGE_SIZE) {
      this.setState({ loading: true });
      const pred = trees[0].getPredicate();
      if (pred) {
        loadPage("forward", loadedPages, pred)
          .then(() => {
            // Set loading animation on the button
            this.setState({ loading: false, loadedPages: loadedPages + 1 });
          })
          .catch(() => {
            // Set loading animation on the button
            this.setState({ loading: false });
          });
      }
    } else {
      this.setState({ loadedPages: loadedPages + 1 });
    }
  };

  render() {
    const { trees, label, className, linkPath } = this.props;

    if (!trees) return null;

    const numToRender = this.state.loadedPages * Constants.FORWARD_OBJECT_PAGE_SIZE;
    const thereIsNextPage = this.props.loadPage && trees.length > numToRender;

    const collections = (thereIsNextPage ? trees.slice(0, numToRender) : trees).map((tree) => ({
      tree,
      widgetCollection: getWidgetCollection(tree, [selectLabel, selectImage, selectGeometry, selectAudio, selectVideo]),
    }));
    const imageCollections = collections.filter(
      (c) =>
        c.widgetCollection.image || c.widgetCollection.geometry || c.widgetCollection.audio || c.widgetCollection.video,
    );
    const remainingCollections = collections.filter(
      (c) =>
        !c.widgetCollection.image &&
        !c.widgetCollection.geometry &&
        !c.widgetCollection.audio &&
        !c.widgetCollection.video,
    );
    const predicate = this.props.trees[0].getPredicate();

    return (
      <div className={getClassName(className, styles.container)}>
        {label && (
          <h5
            className={getClassName("headerSpacing", "paragraphHeader", styles.title)}
            title={predicate}
            draggable
            onDragStart={this.onDragStart}
          >
            {label}
          </h5>
        )}
        <div className={styles.values}>
          <div className={getClassName("flex", styles.imageWidgets)}>
            {imageCollections.map((c) => {
              return (
                <div key={c.tree.getKey()}>
                  {(c.tree.getTerm().termType === "Literal" && <TermLiteral {...c.tree.getTerm()} />) || (
                    <ResourceWidget
                      resource={c.tree.getTerm()}
                      widgetCollection={getWidgetCollection(c.tree, [
                        selectLabel,
                        selectImage,
                        selectGeometry,
                        selectVideo,
                        selectAudio,
                      ])}
                      loadResource={this.renderResource}
                      linkPath={linkPath}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div>
            {remainingCollections.map((c) => {
              return (
                <div key={c.tree.getKey()}>
                  {(c.tree.getTerm().termType === "Literal" && (
                    <TermLiteral className={styles.literal} {...c.tree.getTerm()} expanded />
                  )) || (
                    <ResourceWidget
                      resource={c.tree.getTerm()}
                      widgetCollection={getWidgetCollection(c.tree, [
                        selectLabel,
                        selectImage,
                        selectGeometry,
                        selectAudio,
                        selectVideo,
                      ])}
                      loadResource={this.renderResource}
                      linkPath={linkPath}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {thereIsNextPage && (
          <LoadingButton pulse loading={this.state.loading} onClick={this.loadPage}>
            Show more
          </LoadingButton>
        )}
      </div>
    );
  }
}

export default OutLink;
