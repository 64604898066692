import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { mergePrefixArray } from "@triply/utils/prefixUtils.js";
import { Button, DatasetMetadata, ErrorPage, FlexContainer, SinkList } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.ts";
import { getClassFrequency, getClassFrequencyFor } from "#reducers/insights.ts";
import BarChart from "./BarChart.tsx";
import * as styles from "./style.scss";

type Props = {
  graph: string;
};

const ClassFrequency: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedGraph = props.graph;

  const { classFrequency, currentAccount, currentDs, largestGraph, localPrefixes, globalPrefixes } = useSelector(
    (state: GlobalState) => {
      const currentDs = getCurrentDataset(state);

      return {
        classFrequency:
          (currentDs && selectedGraph && getClassFrequencyFor(state, currentDs, selectedGraph)) || undefined,
        currentAccount: getCurrentAccount(state),
        currentDs: currentDs,
        largestGraph: currentDs && state.graphs[currentDs.id].largestList[0]?.graphName,
        localPrefixes: currentDs?.prefixes,
        globalPrefixes: state.config.clientConfig?.prefixes,
      };
    },
  );

  React.useEffect(() => {
    if (currentDs && currentAccount && selectedGraph) {
      dispatch<typeof getClassFrequency>(getClassFrequency(currentAccount, currentDs, selectedGraph)).catch(() => {});
    }
    // Fetch class frequency again when it is outdated (when a job finishes).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNextPage = React.useCallback(() => {
    if (currentAccount && currentDs && selectedGraph) {
      dispatch(getClassFrequency(currentAccount, currentDs, selectedGraph, classFrequency?.nextPage));
    }
  }, [classFrequency, currentAccount, currentDs, dispatch, selectedGraph]);

  if (!currentAccount || !currentDs) {
    return <Alert severity="error">Data not found</Alert>;
  }

  if (!classFrequency?.data) {
    return <Skeleton variant="rectangular" />;
  }

  const prefixes = mergePrefixArray(localPrefixes || [], globalPrefixes || []);

  return (
    <div className={getClassName(styles.item, styles.barChartItem)}>
      {classFrequency.data.length === 0 ? (
        <Alert severity="warning">The class frequency cannot be visualized because it is too large.</Alert>
      ) : (
        <>
          <BarChart key={selectedGraph} data={classFrequency.data} prefixes={prefixes} />
          {classFrequency.nextPage && (
            <div className={styles.showMore}>
              <Button variant="text" size="small" onClick={loadNextPage}>
                Show more...
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClassFrequency;
