import * as React from "react";
import { showNotification } from "#reducers/notifications.ts";
import useDispatch from "./useDispatch.ts";

const useCopyToClipboard = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const copyToClipboard = (text: string) => {
    if (!ref.current) return;
    const textArea = document.createElement("textarea");
    textArea.style.position = "absolute";
    ref.current.appendChild(textArea);
    textArea.textContent = text;
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
    dispatch(showNotification("Copied to clipboard", "info"));
  };

  return { ref, copyToClipboard };
};

export default useCopyToClipboard;
