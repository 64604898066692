import { TableCell, TableHead, TableRow } from "@mui/material";
import { flexRender, HeaderGroup } from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import Filter from "./Filter.tsx";
import * as styles from "./tableStyle.scss";

interface Props {
  headerGroups: HeaderGroup<any>[];
  hideFilters?: boolean;
}

const TableHeader: React.FC<Props> = ({ headerGroups, hideFilters }) => {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className={getClassName(styles.header, { [styles.sorting]: header.column.getCanSort() })}
              sortDirection={header.column.getIsSorted()}
              onClick={() => {
                if (!header.column.getCanSort()) return;
                header.column.toggleSorting(header.column.getIsSorted() === "asc");
              }}
            >
              <div className="flex center ellipsis">
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && (
                  <FontAwesomeIcon
                    className={getClassName("ml-2", { invisible: !header.column.getIsSorted() })}
                    fixedWidth
                    icon={header.column.getIsSorted() === "desc" ? "chevron-down" : "chevron-up"}
                  />
                )}
              </div>
            </TableCell>
          ))}
        </TableRow>
      ))}
      {!hideFilters &&
        headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id + "-filter"}>
            {headerGroup.headers.map((header) => (
              <TableCell key={`${header.id}-filter`} className={styles.filterCell}>
                <Filter column={header.column} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableHead>
  );
};

export default TableHeader;
