import getClassName from "classnames";
import { isEmpty } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import * as styles from "./style.scss";

namespace ListWidget {
  export interface Props {
    title: string;
    linkTo: string;
    noContentMsg?: string;
    showMoreLink?: string;
    onAddItem?: React.MouseEventHandler<HTMLButtonElement>;
    addItemTitle: string;
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
  }
}

const ListWidget: React.FC<ListWidget.Props> = ({
  className,
  children,
  noContentMsg,
  title,
  style,
  onAddItem,
  linkTo,
  showMoreLink,
  addItemTitle,
}) => {
  return (
    <div className={getClassName(className, "rounding shadow white", styles.container)} style={style}>
      <div className={getClassName("flex center px-3", styles.titleContainer)}>
        <Link to={linkTo} className={getClassName("noLinkDecoration", styles.title)}>
          {title}
        </Link>
        {!!onAddItem && (
          <FontAwesomeButton
            title={addItemTitle}
            icon="plus"
            className={styles.plusIcon}
            onClick={onAddItem}
            size={"lg"}
            disableHoverStyling
          />
        )}
      </div>
      <div className="py-3">
        {isEmpty(children) ? <div className="py-3 px-4">{noContentMsg}</div> : children}
        {showMoreLink && (
          <div className="px-3 py-2 mt-2">
            <Link to={showMoreLink}>Show more...</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListWidget;
