import { extname } from "path";
import UrlParse from "url-parse";

const numericDatatypes = [
  "http://www.w3.org/2001/XMLSchema#decimal",
  "http://www.w3.org/2001/XMLSchema#integer",
  "http://www.w3.org/2001/XMLSchema#nonPositiveInteger",
  "http://www.w3.org/2001/XMLSchema#long",
  "http://www.w3.org/2001/XMLSchema#nonNegativeInteger",
  "http://www.w3.org/2001/XMLSchema#negativeInteger",
  "http://www.w3.org/2001/XMLSchema#int",
  "http://www.w3.org/2001/XMLSchema#unsignedLong",
  "http://www.w3.org/2001/XMLSchema#positiveInteger",
  "http://www.w3.org/2001/XMLSchema#short",
  "http://www.w3.org/2001/XMLSchema#unsignedInt",
  "http://www.w3.org/2001/XMLSchema#byte",
  "http://www.w3.org/2001/XMLSchema#unsignedShort",
  "http://www.w3.org/2001/XMLSchema#unsignedByte",
  "http://www.w3.org/2001/XMLSchema#double",
  "http://www.w3.org/2001/XMLSchema#float",
];
export function isNumericDatatype(datatype: string) {
  return numericDatatypes.indexOf(datatype) >= 0;
}

const wktDatatypes = [
  "https://triply.cc/wkt/multiPolygon",
  "https://triply.cc/wkt/polygon",
  "http://www.opengis.net/ont/geosparql#wktLiteral",
];

export function isWktDatatype(datatype: string) {
  return wktDatatypes.includes(datatype);
}

export function isUriDatatype(datatype: string) {
  return datatype === "http://www.w3.org/2001/XMLSchema#anyURI";
}

export function isBooleanDatatype(datatype: string) {
  return datatype === "http://www.w3.org/2001/XMLSchema#boolean";
}
