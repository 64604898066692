import * as React from "react";
import { Redirect, useLocation } from "react-router";

interface Props {}

const FacetsRedirector: React.FC<Props> = ({}) => {
  const { search, hash } = useLocation();

  return (
    <Redirect
      to={{
        pathname: "/browse/datasets",
        search: search,
        hash: hash,
      }}
    />
  );
};

export default FacetsRedirector;
