import { defaultKeymap } from "@codemirror/commands";
import { jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";
import { EditorView, keymap } from "@codemirror/view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Badge, Stack } from "@mui/material";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import getClassName from "classnames";
import * as React from "react";
import { Button } from "#components/index.ts";
import { useDisplayHotkeys } from "#containers/Hotkeys/index.tsx";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import { SparqlVisualizationContext } from "../../SparqlVisualizationContext";
import * as styles from "./style.scss";

interface FrameEditorProps {
  onCommitFrame: (currentFrame: object) => void;
}
const buttonAnchor = {
  vertical: "bottom",
  horizontal: "right",
} as const;

const hotKeys = [
  { component: "LDFrame editor", keyBinds: "Mod+Enter", description: "Apply frame to results" },
  { component: "LDFrame editor", keyBinds: "Mod+d", description: "Select and add cursor to next occurrence" },
  { component: "LDFrame editor", keyBinds: "Mod+[", description: "Add indent" },
  { component: "LDFrame editor", keyBinds: "Mod+]", description: "Remove indent" },
  { component: "LDFrame editor", keyBinds: "Mod+Shift+k", description: "Delete current line" },
  { component: "LDFrame editor", keyBinds: "Mod+u", description: "Undo selection" },
  { component: "LDFrame editor", keyBinds: "Mod+z", description: "Undo" },
  { component: "LDFrame editor", keyBinds: "Mod+shift+z, Mod+y", description: "Redo" },
  { component: "LDFrame editor", keyBinds: "F8", description: "Move to next diagnostic" },
];

const FrameEditor: React.FC<FrameEditorProps> = ({ onCommitFrame }) => {
  const { getVisualizationConfig, setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const [dirty, setDirty] = React.useState(false);
  const config = getVisualizationConfig("LDFrame");
  const [frameSyntaxError, _setFrameSyntaxError] = React.useState<string | undefined>();
  const [value, setValue] = React.useState(JSON.stringify(config?.frame || {}, null, 2));
  const setFrameSyntaxError = useDebounce((frameError: string | undefined) => _setFrameSyntaxError(frameError), 250);
  useDisplayHotkeys(hotKeys);
  const onFrameUpdate = React.useCallback(
    (value: string) => {
      setValue(value);
      setDirty(true);
      try {
        setVisualizationConfig?.("LDFrame", { frame: JSON.parse(value) });
        setFrameSyntaxError(undefined);
      } catch (e) {
        if (e instanceof SyntaxError) {
          setFrameSyntaxError(e.message);
        } else {
          throw e;
        }
      }
    },
    [setVisualizationConfig, setFrameSyntaxError],
  );
  return (
    <>
      <Stack direction="row" alignItems="center" className={getClassName("p-2", styles.frameTitle)}>
        <h5 className="">Ld-Frame</h5>
        <div className="grow" />
        <div>
          <Badge
            className="pl-2"
            color="error"
            overlap="circular"
            title={
              frameSyntaxError ? "The frame is invalid" : !dirty ? "The current frame is already applied" : undefined
            }
            anchorOrigin={buttonAnchor}
            badgeContent={!!frameSyntaxError ? <FontAwesomeIcon size="sm" icon="exclamation-triangle" /> : null}
          >
            <Button
              color="primary"
              aria-label="Apply frame to results"
              title="Apply frame to results"
              aria-keyshortcuts="Control+Enter Command+Enter"
              disabled={!dirty || !!frameSyntaxError}
              className={styles.executeQueryButton}
              startIcon={<FontAwesomeIcon icon={["fas", "play"]} className={styles.playIcon} size="3x" />}
              onClick={() => {
                setDirty(false);
                onCommitFrame(JSON.parse(value));
              }}
            />
          </Badge>
        </div>
      </Stack>
      <CodeMirror
        className={styles.codeMirror}
        value={value}
        onChange={onFrameUpdate}
        basicSetup={{
          defaultKeymap: false, // this removes all the existing keymappings of codemirror (refer to https://github.com/uiwjs/react-codemirror/issues/356)
        }}
        extensions={[
          jsonLanguage,
          linter(jsonParseLinter()),
          githubLight,
          EditorView.lineWrapping,
          keymap.of([
            {
              key: "Mod-Enter",
              run: () => {
                setDirty(false);
                onCommitFrame(JSON.parse(value));
                return true;
              },
              preventDefault: true,
            },
            ...defaultKeymap, // keeping the rest of the default keymappings from codemirror (refer to https://github.com/uiwjs/react-codemirror/issues/356)
          ]),
        ]}
      />
      {frameSyntaxError && (
        <Alert severity="error" variant="outlined" className={styles.squareCorners}>
          {frameSyntaxError}
        </Alert>
      )}
    </>
  );
};

export const MemoFrameEditor = React.memo(FrameEditor);
export default MemoFrameEditor;
