import getClassName from "classnames";
import * as React from "react";
import { Prefix } from "@triply/utils/prefixUtils.js";
import { PrefixUpdate } from "../../../reducers/datasetManagement.ts";
import { RawResponse as RawResponseType, SparqlResponse } from "../SparqlUtils.ts";
import Charts, {
  canDraw as chartsCanDraw,
  PluginConfig as ChartsConfig,
  Props as ChartProps,
} from "./Visualizations/Chart/index.tsx";
import ErrorResponse from "./Visualizations/Error/index.tsx";
import Gallery, {
  canDraw as galleryCanDraw,
  PluginConfig as GalleryConfig,
  Props as GalleryProps,
} from "./Visualizations/Gallery/index.tsx";
import Geo, { canDraw as geoCanDraw, PluginConfig as GeoConfig } from "./Visualizations/Geo/index.tsx";
import Json, { canDraw as jsonCanDraw } from "./Visualizations/Json/index.tsx";
import JsonLD, { canDraw as ldFrameCanDraw, PluginConfig as JsonLdConfig } from "./Visualizations/LDFrame/index.tsx";
import Network, { canDraw as networkCanDraw, PluginConfig as NetworkConfig } from "./Visualizations/Network/index.tsx";
import Pivot, { canDraw as pivotCanDraw, PluginConfig as PivotConfig } from "./Visualizations/PivotTable/index.tsx";
import Response from "./Visualizations/Response/index.tsx";
import Table, { PluginConfig as TableConfig, Props as TableProps } from "./Visualizations/Table/index.tsx";
import Timeline, { canDraw as timelineCanDraw } from "./Visualizations/Timeline/index.tsx";
import { DownloadVisualization, SparqlVisualizationContext } from "./SparqlVisualizationContext.tsx";
import * as styles from "./styles.scss";

export interface RenderConfig {
  Table?: TableConfig;
  Response?: never;
  JSON?: never;
  Geo?: GeoConfig;
  Gallery?: GalleryConfig;
  Charts?: ChartsConfig;
  Pivot?: PivotConfig;
  Timeline?: never;
  Network?: NetworkConfig;
  LDFrame?: JsonLdConfig;
}
export type VisualizationLabel = keyof RenderConfig;
export type VisualizationConfig = RenderConfig[keyof RenderConfig];

export type VisualizationProperties = {
  Table?: TableProps;
  Response?: never;
  // Json?: never;
  Geo?: never;
  Gallery?: GalleryProps;
  Charts?: ChartProps;
  Pivot?: never;
  Timeline?: never;
  Network?: never;
  JsonLD?: never;
};

interface VisualizationMeta {
  canBeDrawn: (data?: SparqlResponse) => boolean;
}

export const visualizationMeta: Record<keyof Omit<RenderConfig, "Table" | "Response">, VisualizationMeta> = {
  Gallery: {
    canBeDrawn: galleryCanDraw,
  },
  Geo: {
    canBeDrawn: geoCanDraw,
  },
  Charts: {
    canBeDrawn: chartsCanDraw,
  },
  Pivot: {
    canBeDrawn: pivotCanDraw,
  },
  Timeline: {
    canBeDrawn: timelineCanDraw,
  },
  Network: {
    canBeDrawn: networkCanDraw,
  },
  LDFrame: {
    canBeDrawn: ldFrameCanDraw,
  },
  JSON: {
    canBeDrawn: jsonCanDraw,
  },
};

interface Props {
  className?: string;
  error: Error | undefined;
  data: SparqlResponse | undefined;
  rawData: RawResponseType | undefined;
  prefixes: Prefix[];
  setVisualizationConfig?: (selectedPlugin: VisualizationLabel, config: VisualizationConfig) => void;
  onCreatePrefix?: (prefix: PrefixUpdate) => Promise<true | string>;
  datasetPath?: string;
  visualization: VisualizationLabel;
  visualizationConfig: VisualizationConfig;
  visualizationActionsCtrId?: string;
  registerDownload?: (download: DownloadVisualization) => void;
  unregisterDownload?: (id: VisualizationLabel) => void;
  customNoResultsMessage?: string;
  customErrorMessage?: string;
  visualizationProperties: VisualizationProperties;
  responseSize?: number;
  checkLimits?: boolean;
  queryName?: string;
}
const SparqlResults: React.FC<Props> = ({
  className,
  error,
  data,
  rawData,
  setVisualizationConfig,
  prefixes,
  datasetPath,
  visualization,
  visualizationConfig,
  visualizationActionsCtrId,
  registerDownload,
  unregisterDownload,
  customNoResultsMessage,
  onCreatePrefix,
  customErrorMessage,
  visualizationProperties,
  responseSize,
  checkLimits,
  queryName,
}) => {
  const getConfig = React.useCallback(
    <T extends keyof RenderConfig>(config: T) => {
      if (visualization === config) {
        return visualizationConfig as RenderConfig[T];
      } else {
        throw new Error("Requesting unknown config");
      }
    },
    [visualization, visualizationConfig],
  );

  let content = null;
  if (error) {
    content = <ErrorResponse error={error} />;
  } else {
    switch (visualization) {
      case "Table":
        content = (
          <Table
            hideFilters={visualizationProperties?.Table?.hideFilters}
            hidePagination={visualizationProperties?.Table?.hidePagination}
          />
        );
        break;
      case "Response":
        content = <Response />;
        break;
      case "Geo":
        content = <Geo />;
        break;
      case "Gallery":
        content = (
          <Gallery
            minimizeColumns={visualizationProperties?.Gallery?.minimizeColumns}
            reduceSpacing={visualizationProperties?.Gallery?.reduceSpacing}
          />
        );
        break;
      case "Charts":
        content = <Charts reducePadding={visualizationProperties?.Charts?.reducePadding} />;
        break;
      case "Pivot":
        content = <Pivot />;
        break;
      case "Timeline":
        content = <Timeline />;
        break;
      case "Network":
        content = <Network />;
        break;
      case "LDFrame":
        content = <JsonLD />;
        break;
      case "JSON":
        content = <Json />;
        break;
    }
  }
  return (
    <SparqlVisualizationContext.Provider
      value={{
        data: data,
        rawData: rawData,
        prefixes: prefixes,
        datasetPath: datasetPath,
        onCreatePrefix: onCreatePrefix,
        visualizationActionsCtrId: visualizationActionsCtrId,
        setVisualizationConfig: setVisualizationConfig,
        getVisualizationConfig: getConfig,
        registerDownload: registerDownload,
        unregisterDownload: unregisterDownload,
        customNoResultsMessage: customNoResultsMessage,
        customErrorMessage: customErrorMessage,
        checkLimits: !!checkLimits,
        responseSize: responseSize,
        queryName: queryName,
      }}
    >
      <div
        className={getClassName(
          {
            // Gharts tooltips are allowed to overflow.
            [styles.visualizerContent]: visualization !== "Charts" || !visualizationProperties.Charts?.reducePadding,
          },
          className,
        )}
      >
        {content}
      </div>
    </SparqlVisualizationContext.Provider>
  );
};

export default React.memo(SparqlResults);
