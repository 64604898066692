import * as d3 from "d3";
import debug from "debug";
import { MutableRefObject, useEffect, useRef } from "react";
import { useHotkeys } from "#containers/Hotkeys/index.tsx";
import { escapeId } from "./utils.ts";

const log = debug("triply:console:schema:zoom");
const MIN_ZOOM = 0.1;
const MAX_ZOOM = 5;

export default function useZoomableSVG(
  svgRef: MutableRefObject<SVGSVGElement | null>,
  graphWidth: number,
  graphHeight: number,
) {
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown>>();

  const svg = svgRef.current;
  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select<Element, unknown>(svgRef.current);
    zoomRef.current = d3
      .zoom()
      .clickDistance(3)
      .scaleExtent([MIN_ZOOM, MAX_ZOOM])
      .on("zoom", (event: d3.D3ZoomEvent<SVGElement, unknown>) => {
        event.sourceEvent?.stopPropagation();
        svg.select<SVGGElement>("g#zoom").attr("transform", event.transform.toString());
      });
    svg.call(zoomRef.current);

    return () => {
      svg?.on(".zoom", null);
    };
  }, [svgRef, svg]);

  const centerScale = (iri?: string) => {
    if (!svgRef.current || !zoomRef.current || graphWidth <= 0 || graphHeight <= 0) return;

    const svgSelection = d3.select<Element, unknown>(svgRef.current);
    const svgNode = svgSelection.node();
    if (!svgNode) {
      log("Missing SVG element. Possibly called before first draw is done");
      return;
    }

    const svgBox = svgNode.getBoundingClientRect();

    if (iri) {
      const zoomElement = svgSelection.select<SVGGElement>("g#zoom");
      const zoomNode = zoomElement.node();
      if (!zoomNode) return;
      const originalZoomTransform = d3.zoomTransform(zoomNode);
      // Temporarily reset zoom so we can properly measure the items
      zoomElement.attr("transform", d3.zoomIdentity.toString());

      const bbox = (svgSelection.select(`#node-${escapeId(iri)}`).node() as any)?.getBoundingClientRect();

      zoomElement.attr("transform", originalZoomTransform.toString());

      if (!bbox) {
        console.error("Couldn't find node", iri);
        return;
      }

      svgSelection
        ?.transition()
        .duration(1500)
        .call(
          zoomRef.current?.transform,
          d3.zoomIdentity
            .translate(
              -(bbox.x - svgBox.x - svgBox.width / 2 + bbox.width / 2),
              -(bbox.y - svgBox.y - (svgBox.height - 70) / 2 + bbox.height / 2),
            )
            .scale(1),
        );
    } else {
      const scaleX = (svgBox.width - 40) / graphWidth;
      const scaleY = (svgBox.height - 110) / graphHeight;
      const newScale = Math.max(Math.min(scaleX, scaleY, 1), MIN_ZOOM);
      const location: [number, number] = [
        (svgBox.width - graphWidth * newScale) / 2,
        (svgBox.height - graphHeight * newScale) / 2 - 35,
      ];
      svgSelection
        ?.transition()
        .duration(500)
        .call(zoomRef.current?.transform, d3.zoomIdentity.translate(...location).scale(newScale));
    }
  };

  useHotkeys({ component: "Schema", description: "Center view", keyBinds: "c, home" }, () => {
    centerScale();
  });

  useHotkeys({ component: "Schema", description: "Zoom", keyBinds: "-, =" }, (_, { keys }) => {
    if (!svgRef.current) return;
    switch (keys?.join("")) {
      case "slash":
        return zoomRef.current?.scaleBy(d3.select<Element, unknown>(svgRef.current), 2 / 3);
      case "=":
        return zoomRef.current?.scaleBy(d3.select<Element, unknown>(svgRef.current), 3 / 2);
    }
  });

  const PAN_DELTA = 150;
  useHotkeys({ component: "Schema", description: "Pan", keyBinds: "right,left,up,down,a,d,w,s" }, (_, { keys }) => {
    if (!svgRef.current) return;
    const svgElement = d3.select<Element, unknown>(svgRef.current);
    const zoomNode = svgElement.select<SVGGElement>("g#zoom")?.node();
    const currentScale = zoomNode ? d3.zoomTransform(zoomNode).k : 1;
    switch (keys?.join("")) {
      case "right":
      case "d":
        return zoomRef.current?.translateBy(svgElement, -PAN_DELTA / currentScale, 0);
      case "left":
      case "a":
        return zoomRef.current?.translateBy(svgElement, PAN_DELTA / currentScale, 0);
      case "up":
      case "w":
        return zoomRef.current?.translateBy(svgElement, 0, PAN_DELTA / currentScale);
      case "down":
      case "s":
        return zoomRef.current?.translateBy(svgElement, 0, -PAN_DELTA / currentScale);
    }
  });

  return { centerScale: centerScale };
}
