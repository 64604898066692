import { CellContext } from "@tanstack/react-table";
import * as React from "react";
import humanizeDuration from "#helpers/HumanizeDate.ts";
import HumanizedDate from "../HumanizedDate";

const HumanizedDateInPast: React.FC<CellContext<any, string>> = ({ cell }) => {
  const value = cell.getValue();
  if (value === undefined) {
    return <>Never</>;
  } else {
    return (
      <HumanizedDate
        date={value}
        humanizeDuration={(date) => humanizeDuration(date, "future", "capitalize")}
        hideTitle
      />
    );
  }
};

export default HumanizedDateInPast;
