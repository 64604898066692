import memoizee from "memoizee";
import * as React from "react";
import { Models } from "@triply/utils";
import { ErrorPage, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import fetch from "#helpers/fetch.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import QueriesTable from "./QueriesTable";

export interface Props extends IComponentProps {}

const AdminQueries: React.FC<Props> = ({ location }) => {
  const constructUrlToApi = useConstructUrlToApi();
  const [queries, setQueries] = React.useState<Models.AdminQueries | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchQueries = React.useCallback(
    async (q?: string) => {
      setLoading(true);
      return fetch(
        constructUrlToApi({
          pathname: `/admin/queries/${q ? "?q=" + encodeURIComponent(q) : ""}`,
        }),
        { credentials: "same-origin" },
      )
        .then(async (response) => {
          if (response.status === 200) {
            const results = response.json();
            results
              .then((values: Models.AdminQueries) => {
                setQueries(values);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [constructUrlToApi],
  );
  React.useEffect(() => {
    fetchQueries().catch((e) => {
      console.error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const acl = useAcl();
  if (!acl.check({ action: "manageQueries" }).granted) return <ErrorPage statusCode={401} />;

  return (
    <>
      <Meta currentPath={location.pathname} title="Queries - Admin settings" />
      {!!queries && <QueriesTable queries={queries} loading={loading} fetchQueriesFunc={fetchQueries} />}
    </>
  );
};

export default AdminQueries;
