// extracted by mini-css-extract-plugin
var _1 = "style--caption--oplq4Hos";
var _2 = "style--geoWidget--R2uPcIYW";
var _3 = "style--image--UbpLvbMj";
var _4 = "style--imageContainer--mOkp_N9R";
var _5 = "style--imageWidget--q9BwXLh_";
var _6 = "style--imageWidgetImage--ZahYidsI";
var _7 = "style--link--WNE0T_mo";
var _8 = "style--mediaWidget--xkA5lv53";
export { _1 as "caption", _2 as "geoWidget", _3 as "image", _4 as "imageContainer", _5 as "imageWidget", _6 as "imageWidgetImage", _7 as "link", _8 as "mediaWidget" }
