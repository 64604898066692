import { difference, flatten, isEmpty, isEqual, mapValues, uniq, uniqWith } from "lodash-es";
import { MarkOptional, UnionToIntersection } from "ts-essentials";
import { Actions, default as baseAclDefinition, Role } from "./roles.js";

export class AclError extends Error {}

export type Action = keyof Actions;

export type PossibleActionContextValues = keyof UnionToIntersection<Actions[Action]>;
export type Grant<A extends Action = Action> = A extends A ? { action: A; when: GrantCondition<A> } : never;

export type GrantCondition<A extends Action> = Partial<Actions[A]> | ((args: Actions[A]) => boolean);

export interface RoleDefinition {
  extends?: ReadonlyArray<Role>;
  grants: ReadonlyArray<Grant>;
}
export type AclDefinition = {
  [key in Role]: RoleDefinition;
};

export interface CheckInfoBase<R extends Role, A extends Action, PC extends PredefinedContext> {
  action: A;
  role: R;
  context: Omit<Actions[A], keyof PC> & Partial<Actions[A]>;
}

/**
 * Make the `role` property optional when it's already pre-filled in (via the Acl constructor)
 */
export type CheckInfoWrapper<
  R extends Role,
  A extends Action,
  PC extends PredefinedContext,
  PR extends Role | undefined,
> = PR extends Role ? MarkOptional<CheckInfoBase<R, A, PC>, "role"> : CheckInfoBase<R, A, PC>;

/**
 * Make the `context` property optional when all of it's values are optional as well
 * Useful when all context properties are already pre-filled in (via the Acl constructor)
 */
export type CheckInfo<R extends Role, A extends Action, PC extends PredefinedContext, PR extends Role | undefined> =
  Partial<CheckInfoWrapper<R, A, PC, PR>["context"]> extends CheckInfoWrapper<R, A, PC, PR>["context"]
    ? MarkOptional<CheckInfoWrapper<R, A, PC, PR>, "context">
    : CheckInfoWrapper<R, A, PC, PR>;

export function deriveFullHierarchyForRole(aclDef: AclDefinition, role: Role, hierarchy: Role[] = []): Role[] {
  const extending = aclDef[role].extends;
  if (!extending?.length) return hierarchy;
  const newRoles = difference(extending, hierarchy);

  /**
   * Check whether new roles are defined
   */
  for (const newRole of newRoles) {
    if (!aclDef[newRole]) throw new AclError(`Role ${newRole} is not defined in the acl definition`);
  }

  hierarchy = uniq([...hierarchy, ...newRoles]);
  for (const newRole of newRoles) {
    hierarchy = deriveFullHierarchyForRole(aclDef, newRole, hierarchy);
  }
  return hierarchy;
}

/**
 * Followed the `extends` keyword and returns the 'closure' of the acl
 */
export function expandRoleHierarchy(aclDef: AclDefinition): AclDefinition {
  return mapValues(aclDef, (val: RoleDefinition, key: Role) => {
    val.extends = deriveFullHierarchyForRole(aclDef, key);

    //Assigning all grants to the parent. We're not making this list unique by action type,
    //as we cannot be sure that e.g. an action of superadmin fully includes all the children
    //action contexts (e.g., think about restricting superadmin actions by web tokens only)
    //Instead, just make it unique by full deep-equals comparison
    val.grants = uniqWith([...val.grants, ...flatten(val.extends.map((e) => aclDef[e].grants))], isEqual);
    return val as RoleDefinition;
  }) as any;
}
let globalAcl: AclDefinition | undefined;
export class Permissions {
  public granted: boolean;
  private thrower?: Thrower;
  constructor(granted: boolean, thrower?: Thrower) {
    this.granted = granted;
    this.thrower = thrower;
  }
  throw<E extends Error>(err?: E) {
    if (!this.granted) {
      if (err) {
        throw err;
      } else if (this.thrower) {
        this.thrower("Permission denied");
      } else {
        throw new AclError("Permission denied");
      }
    }
  }
}
const PermissionGranted = new Permissions(true);
export type Thrower = (msg: string) => never;
export type PredefinedContext = Partial<{
  [key in PossibleActionContextValues]: UnionToIntersection<Actions[Action]>[key];
}>;
export class BaseAcl<PR extends Role | undefined, PC extends PredefinedContext = {}> {
  private predefinedContext?: PC | (() => PC);
  private predefinedRole?: PR | (() => PR);
  private thrower?: Thrower;
  private acl: AclDefinition;
  constructor(opts?: {
    roles?: AclDefinition;
    predefinedContext?: PC | (() => PC);
    thrower?: Thrower;
    predefinedRole?: PR | (() => PR);
  }) {
    if (opts?.predefinedContext) this.predefinedContext = opts.predefinedContext;
    if (opts?.predefinedRole) this.predefinedRole = opts.predefinedRole;
    if (opts?.thrower) this.thrower = opts.thrower;
    if (!globalAcl) globalAcl = expandRoleHierarchy(baseAclDefinition);
    this.acl = globalAcl;
  }

  private getRole(role: Role | undefined) {
    role = role || (typeof this.predefinedRole === "function" ? this.predefinedRole() : this.predefinedRole);
    if (!role) throw new AclError(`Role missing, cannot check the ACL`);
    if (!this.acl[role]) throw new AclError(`Unknown role ${role}`);
    return role;
  }

  public check<R extends Role, A extends Action>(checkInfo: CheckInfo<R, A, PC, PR>) {
    const role = this.getRole(checkInfo?.role);

    if (!checkInfo?.action) throw new AclError(`Action missing, cannot check the ACL`);
    const roleDef = this.acl[role];
    if (!roleDef.grants) throw new AclError(`No grants are defined for role ${role}`);
    const context = {
      ...((typeof this.predefinedContext === "function" ? this.predefinedContext() : this.predefinedContext) || {}),
      ...(checkInfo.context || {}),
    };
    for (const grant of roleDef.grants) {
      if (grant.action === checkInfo.action) {
        if (!grant.when) return PermissionGranted;
        if (isEmpty(context)) continue; //We require a context, but none was given
        if (typeof grant.when === "function") {
          if (grant.when(context as any)) return PermissionGranted;
        } else {
          if (isMatch(grant.when, context)) {
            return PermissionGranted;
          }
        }
      }
    }
    return new Permissions(false, this.thrower);
  }
}

type MatchCheck = { [key: string]: unknown };
export function isMatch(lhs: MatchCheck, rhs: MatchCheck) {
  for (const key in lhs) {
    if (lhs[key] !== rhs[key]) return false;
  }
  return true;
}
