import { push } from "connected-react-router";
import { sortBy } from "lodash-es";
import * as React from "react";
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import { ErrorPage, Meta } from "#components/index.ts";
import { IComponentProps } from "#containers/index.ts";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  deleteServiceFromAdminList,
  getListAsAdmin,
  GLOBAL_ID,
  issueServiceCommand,
  needToFetchAdminList,
  Service,
  Services,
} from "#reducers/services.ts";
import BulkRecreateDialog from "./BulkRecreateDialog.tsx";
import BulkRemoveDialog from "./BulkRemoveDialog.tsx";
import BulkUpdateDialog from "./BulkUpdateDialog.tsx";
import ServiceTableWrapper, { hasFatalError, isExpired } from "./ServiceTable.tsx";

export namespace AdminServices {
  export interface OwnProps extends IComponentProps {}
  export interface PropsFromState {
    services?: Services;
  }
  export type Props = OwnProps & PropsFromState;
}

const AdminServices: React.FC<AdminServices.Props> = ({ location, services }) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  const confirm = useConfirmation();
  const [currentDialog, setCurrentDialog] = React.useState<"Delete" | "Update" | "Recreate">();
  const [bulkServices, setBulkServices] = React.useState<Service[]>([]);
  const syncServiceHandler = (service: Service) => {
    if (service.dataset) {
      confirm({
        title: "Sync service",
        description: `Are you sure you want to update this service?`,
        actionLabel: "Synchronize",
        onConfirm: () => {
          if (service?.dataset) {
            dispatch<typeof issueServiceCommand>(
              issueServiceCommand(service.dataset.owner, service.dataset, service, "sync"),
            )
              .then(() => {
                if (service.dataset)
                  dispatch(
                    push({ pathname: `/${service.dataset.owner.accountName}/${service.dataset.name}/services` }),
                  );
              })
              .catch(() => {});
          }
        },
      });
    }
  };
  const deleteServiceHandler = (service: Service) => {
    confirm({
      title: "Delete service",
      description: `Are you sure you want to delete this service?`,
      actionLabel: "Delete",
      onConfirm: () => {
        dispatch<typeof deleteServiceFromAdminList>(deleteServiceFromAdminList(service)).catch(() => {});
      },
    });
  };

  const Table = React.useMemo(
    () => (
      <ServiceTableWrapper
        services={sortBy(services || [], (s) => {
          if (!s.foundInMongo) return 0;
          if (!s.foundInDocker && hasFatalError(s)) return 1; // If a service is stopped, then it's fine if the container does not exist
          if (isExpired(s)) return Number.MAX_SAFE_INTEGER; // bottom of the list
          if (s.error) return 2;
          return Date.now() - new Date(s.queriedAt || 0).getTime();
        })}
        loading={services === undefined}
        removeService={deleteServiceHandler}
        syncService={syncServiceHandler}
        removeServiceBulk={(services: Service[]) => {
          setBulkServices(services);
          setCurrentDialog("Delete");
        }}
        updateServiceBulk={(services: Service[]) => {
          setBulkServices(services);
          setCurrentDialog("Update");
        }}
        recreateServiceBulk={(services: Service[]) => {
          setBulkServices(services);
          setCurrentDialog("Recreate");
        }}
      />
    ),
    // We need to memoize the table ourselves as redux return a new Object every time the redux state updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [services],
  );
  if (!acl.check({ action: "listServicesOfInstance" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <main>
      <Meta currentPath={location.pathname} title="Services - Admin settings" />
      {Table}
      <BulkRemoveDialog
        open={currentDialog === "Delete"}
        services={bulkServices}
        onClose={() => setCurrentDialog(undefined)}
      />
      <BulkUpdateDialog
        open={currentDialog === "Update"}
        services={bulkServices}
        onClose={() => setCurrentDialog(undefined)}
      />
      <BulkRecreateDialog
        open={currentDialog === "Recreate"}
        services={bulkServices}
        onClose={() => setCurrentDialog(undefined)}
      />
    </main>
  );
};

export default connect<AdminServices.PropsFromState, {}, AdminServices.OwnProps, GlobalState>(
  (state, _ownProps): AdminServices.PropsFromState => {
    return {
      services: state.services[GLOBAL_ID],
    };
  },
  //dispatch
  {},
)(
  asyncConnect<GlobalState>([
    {
      promise: ({ store: { dispatch, getState } }) => {
        if (needToFetchAdminList(getState())) {
          return dispatch<any>(getListAsAdmin());
        }
      },
    },
  ])(AdminServices) as typeof AdminServices,
);
